// import {t} from 'i18next';
import React, { useEffect, useState } from "react";
// import {useNavigate } from "react-router-dom";
// import Selector from '../../../../components/atoms/Selector/Selector';
// import { useTranslation } from "react-i18next";
// import { toast } from "react-hot-toast";
// import Input from "../../../../components/form/Input";
// import Company from '../../../../api/Company';
// import BusinessDropDown from '../../../../components/atoms/BusinessDropDown/BusinessDropDown';
// import {FormNotification} from '../../../../components/atoms/Notification/FormNotification';
import MultiSelector from "../../../../components/atoms/Selector/MultiSelector";
// import {useContext} from 'react';
import { useSettingsContext } from "../SettingsContext";
import FloatingInputText from "../../../../components/form/FloatingInputText";
import TurnKeyBusinesses from "../../../../api/TurnKeyBusinesses";
import FormWarningWithButton from "../../../../components/atoms/Notification/FormWarningWithButton";

const CompanyDetails = ({ handleBusinessTypeUpdate }) => {
  // const { t } = useTranslation();
  // const navigate = useNavigate();
  const [name, setName] = useState("");
  // const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  // const [draftLoading, setDraftLoading] = useState(false);
  // const [draft, setDraft] = useState(null);
  // const [isEnterprise, setIsEnterprise] = useState(false);
  const [turnKeyAccountList, setTurnKeyAccountList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [flushId, setFlushId] = useState(0);

  const {
    // company,
    setCompanyName,
    // businessType,
    setBusinessType,
    // scrollUpRef,
    // existingTurnKeyAccount,
    setExistingTurnKeyAccounts,
    // turnKeyAccountId,
    setTurnKeyAccountId,
  } = useSettingsContext();
  // const [selectedLocation, setSelectedLocation] = useState([]);
  const [existingCompanySelection, setExistingCompanySelection] =
    useState(false);

  useEffect(() => {
    setTurnKeyAccountId(null);
    setExistingTurnKeyAccounts([]);
    getAllTurnKeyAccount();
  }, []);

  useEffect(() => {
    setTurnKeyAccountId(null);
    setName([]);
    setBusinessType([]);
    setFlushId(flushId + 1);
    handleBusinessTypeUpdate(null);
  }, [existingCompanySelection]);

  // const addCompany = () => {
  //   setLoading(true);

  //   Company.add({ name: name, is_enterprise: isEnterprise ? 1 : 0 })
  //     .then(() => {
  //       toast.success(t("company-added"));
  //       //emitCustomEvent("companyAdded");
  //       navigate("/companies");
  //     })
  //     .catch((error) => {
  //       if (error.response && error.response.status === 422) {
  //         // Validation error
  //         setValidationErrors(error.response.data.errors);
  //       } else {
  //         if(error.response.status !== 403){
  //           toast.error(t("common.something-went-wrong-please-try-again"));
  //         }
  //       }
  //     })
  //     .then(() => setLoading(false));
  // };

  const getAllTurnKeyAccount = async () => {
    const getAllAccounts = await TurnKeyBusinesses.getAllAccounts();
    let catData = [];
    setExistingTurnKeyAccounts(getAllAccounts.data.data);
    const accountsPayload = getAllAccounts.data.data;
    for (let i = 0; i < accountsPayload.length; i++) {
      const el = accountsPayload[i];
      const parsedJSONPayload = JSON.parse(el["payload"]);

      catData.push({
        label: parsedJSONPayload.turnKeyCompanyName,
        value: el["id"],
      });
    }
    setTurnKeyAccountList(catData);
    setRefresh(true);
    //  console.log("catDATA : " + JSON.stringify(catData));
  };

  const businessTypes = [
    {
      label: "Enterprise Business",
      value: 1,
    },
    {
      label: "Single Business",
      value: 2,
    },
    {
      label: "TurnKey Account",
      value: 3,
    },
  ];

  const recordCompanyName = (e) => {
    setName(e.target.value);
    setCompanyName({ companyId: 1, companyName: e.target.value });
    setExistingCompanySelection(false);
  };

  const registerExistingCompanyFormClick = (e) => {
    setExistingCompanySelection(true);
  };

  const registerNewCompanyFormClick = (e) => {
    setExistingCompanySelection(false);
  };

  const handleBusinessSelection = (businessId, businessName) => {
    handleBusinessTypeUpdate(businessId);
    setBusinessType({ businessId: businessId, businessName: businessName });
  };

  const handleTurnkeyAccountSelection = (accountId, accountName) => {
    console.log(accountId);
    setTurnKeyAccountId(accountId);
    handleBusinessTypeUpdate(3);
    setBusinessType({ businessId: 3, businessName: "TurnKey Account" });
  };

  return (
    <div>
      <h2 className={"uppercase text-2xl font-bold"}>
        Create Turnkey Account{" "}
        {/* {businessType.businessName && (
          <span>
            {" "}
            -{" "}
            <small class="ml-2 font-semibold text-gray-500 dark:text-gray-400">
              {businessType.businessName}
            </small>
          </span>
        )} */}
      </h2>
      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
      <div
        className="disable grid gap-6 mb-6 md:grid-cols-1 lg:w-1/2"
        onClick={registerNewCompanyFormClick}
      >
        <FloatingInputText
          key={flushId}
          className="my-3 transition-all"
          name="name"
          label="Turnkey Account"
          type="text"
          value={name}
          placeholder={` ${
            !existingCompanySelection ? "Enter Here" : "Click to enable"
          } `}
          error={
            validationErrors && validationErrors.name
              ? validationErrors.name[0]
              : null
          }
          onChange={recordCompanyName}
          disabled={existingCompanySelection}
        />
      </div>
      <div
        className="grid gap-6 mb-6 md:grid-cols-1 lg:w-1/2"
        onClick={registerNewCompanyFormClick}
      >
        <MultiSelector
          key={flushId}
          placeholder="Choose business type"
          dropDownData={businessTypes}
          callBackHandler={handleBusinessSelection}
          disabled={existingCompanySelection}
        ></MultiSelector>
        <div className="relative bottom-10">
          <FormWarningWithButton
            status={existingCompanySelection}
            title="Disabled"
            content="You are selecting an existing account."
          />
        </div>
      </div>

      <div className="inline-flex items-center justify-center w-full pb-16">
        <hr className="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
        <span className="absolute px-3 font-medium -translate-x-1/2 left-1/2 dark:text-white bg-primary-700">
          or
        </span>
      </div>

      <div
        className="pb-16 lg:w-1/2"
        onClick={registerExistingCompanyFormClick}
      >
        <h2 className={"text-2xl font-bold"}>Select Turnkey Account</h2>
        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
        <MultiSelector
          key={flushId}
          placeholder="Choose / Search Turnkey Account"
          dropDownData={turnKeyAccountList}
          refresh={refresh}
          isSearchable={true}
          disabled={!existingCompanySelection}
          callBackHandler={handleTurnkeyAccountSelection}
        ></MultiSelector>

        <div className="lg:w-full relative bottom-4">
          <FormWarningWithButton
            status={!existingCompanySelection}
            title="Disabled"
            content="You are creating a new account."
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
