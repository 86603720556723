import React, { useEffect, useState } from "react";
import MultiSelect from "../../../../components/MultiSelect";
import Location from "../../../../api/Location";
import AddFeatureModal from "../../../../components/modals/AddFeatureModal";
import RBAC from "../../../../api/RBAC";
import AddRoleModal from "../../../../components/modals/AddRoleModal";
import { useDispatch, useSelector } from "react-redux";
import {
  refreshFeature,
  refreshRole,
  setRefreshFeature,
} from "../../../../store/authSlice";
import { toast } from "react-hot-toast";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";

const RBACPanel = ({ callback = false }) => {
  const dispatch = useDispatch();
  const options = [
    { value: 1, label: "Location" },
    { value: 2, label: "User" },
  ];
  const [userType, setUserType] = useState(null);
  const [data, setData] = useState([]);
  const [optionSelected, setOptionSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRoles, setIsLoadingRoles] = useState(false);
  const [isLoadingAttachedRoles, setIsLoadingAttachedRoles] = useState(false);
  const [loadingFeature, setLoadingFeature] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [featuresList, setFeaturesList] = useState([]);
  const refreshFeatureList = useSelector(refreshFeature);
  const refreshRolesList = useSelector(refreshRole);
  const [expandedRows, setExpandedRows] = useState({});
  const [permissionsArray, setPermissionsArray] = useState([]);
  const [selectedAccess, setSelectedAccess] = useState([]);
  const [loadingPermissions, setLoadingPermissions] = useState(false);
  const [editPermission, setEditPermission] = useState(null);
  const [selectRole, setSelectRole] = useState(null);
  const [selectEditRole, setSelectEditRole] = useState(null);
  const [existingRoles, setExistingRoles] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isDisableRoles, setDisableRoles] = useState(true);
  const [attachedRoles, setAttachedRoles] = useState([]);
  const [isRefreshAttachedRoles, setRefreshAttachedRoles] = useState(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);
  const [attachedRoleId, setAttachedRoleId] = useState(false);
  const [selectedDeleteRole, setSelectedDeleteRole] = useState(null);

  const deleteAttachedRole = async (item) => {
    setSelectedDeleteRole(item);
    setAttachedRoleId(item?.feature_role_id);
    setOpenDeleteConfirmationModal(true);
  };

  const handleRemoveAttachedRole = async (id) => {
    if (userType && optionSelected && selectedDeleteRole) {
      try {
        if (userType?.value === 1) {
          setIsLoadingAttachedRoles(true);
          await RBAC.deleteRoleForLocation(
            selectedDeleteRole?.location_id,
            id
          ).then((response) => {
            if (
              response?.data?.message === "Location role deleted successfully"
            ) {
              setRefreshAttachedRoles(true);
              toast.success(response?.data?.message);
            }
          });
          setIsLoadingAttachedRoles(false);
        } else {
          setIsLoadingAttachedRoles(true);
          await RBAC.deleteRoleForUser(selectedDeleteRole?.user_id, id).then(
            (response) => {
              if (
                response?.data?.message === "User role deleted successfully"
              ) {
                setIsLoadingAttachedRoles(true);
                toast.success(response?.data?.message);
              }
              setIsLoadingAttachedRoles(false);
            }
          );
        }
      } catch (err) {
        console.error(err);
      }
    }
    setOpenDeleteConfirmationModal(false);
  };

  const loadFeatures = async () => {
    setLoadingFeature(true);
    await RBAC.getAll().then((response) => {
      const organizedData = {};

      response.data.forEach((item) => {
        const { feature, ...rest } = item;
        if (!organizedData[feature]) {
          organizedData[feature] = [];
        }
        organizedData[feature].push(rest);
      });

      setFeaturesList(organizedData);
      setLoadingFeature(false);
    });
  };

  const loadRolePermissions = async (id) => {
    setLoadingFeature(true);
    try {
      const foundRole = rolesList.find((role) => role.id === id);

      if (foundRole) {
        const initialPermissions = [];
        Object.values(featuresList).forEach((records) => {
          records.forEach((record) => {
            initialPermissions.push({ feature_list_id: record.id, allow: 0 });
          });
        });
        const updatedPermissions = initialPermissions.map((permission) => {
          const existingPermission = JSON.parse(foundRole.data).find(
            (p) => p.feature_id === permission.feature_list_id && p.allow === 1
          );
          return existingPermission ? { ...permission, allow: 1 } : permission;
        });
        setPermissionsArray(updatedPermissions);
      }

      setLoadingFeature(false);
    } catch (error) {
      console.error("Error loading permissions:", error);
    }
    setLoadingFeature(false);
  };

  useEffect(() => {
    loadFeatures();
    dispatch(setRefreshFeature(false));
  }, [refreshFeatureList]);

  useEffect(() => {
    if (featuresList && selectEditRole) {
      loadRolePermissions(selectEditRole?.value);
    }
  }, [featuresList, selectEditRole]);

  useEffect(() => {
    const initialExpandedRows = {};
    Object.keys(featuresList).forEach((key, index) => {
      initialExpandedRows[index] = true;
    });
    setExpandedRows(initialExpandedRows);
  }, [featuresList]);

  useEffect(() => {
    if (userType && optionSelected) {
      if (userType?.value === 1) {
        getAllAttachedRolesLocation(optionSelected?.value);
      } else if (userType?.value === 2) {
        getAllAttachedRolesUser(optionSelected?.value);
      }
    }
  }, [optionSelected]);

  useEffect(() => {
    if (userType && optionSelected && isRefreshAttachedRoles) {
      if (userType?.value === 1) {
        getAllAttachedRolesLocation(optionSelected?.value);
      } else if (userType?.value === 2) {
        getAllAttachedRolesUser(optionSelected?.value);
      }
      setRefreshAttachedRoles(false);
    }
  }, [isRefreshAttachedRoles]);

  const getAllAttachedRolesLocation = async (id) => {
    setIsLoadingAttachedRoles(true);
    await RBAC.getAttachedRolesForLocationBusiness(id).then((response) => {
      setAttachedRoles(response?.data?.locationRole);
    });
    setIsLoadingAttachedRoles(false);
  };

  const getAllAttachedRolesUser = async (id) => {
    setIsLoadingAttachedRoles(true);
    await RBAC.getAttachedRolesForUser(id).then((response) => {
      setAttachedRoles(response?.data?.userRoles);
    });
    setIsLoadingAttachedRoles(false);
  };

  const handleChange = (selected) => {
    if (selected === null) {
      setPermissionsArray([]);
      setSelectedAccess([]);
      setSelectRole(null);
      setDisableRoles(true);
    } else {
      setDisableRoles(false);
    }
    setOptionSelected(selected);
  };

  const handleRoleChange = (selected) => {
    if (selected) {
      setSelectRole(selected);
    } else {
      setSelectRole(null);
    }
  };

  const handleEditRoleChange = (selected) => {
    if (selected) {
      setSelectEditRole(selected);
      if (userType || optionSelected || selectRole) {
        setUserType(null);
        setOptionSelected(null);
        setSelectRole(null);
      }
    } else {
      setSelectEditRole(null);
      setPermissionsArray([]);
    }
  };

  const handleRowClick = (index) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [index]: !prevExpandedRows[index],
    }));
  };

  const handleCheckClick = (id, checked, records) => {
    if (records) {
      const updatedPermissionsArray = permissionsArray.map((permission) => {
        const recordExists = records.some(
          (record) => record.id === permission.feature_list_id
        );
        return {
          ...permission,
          allow: checked
            ? recordExists
              ? 0
              : permission.allow
            : recordExists
            ? 1
            : permission.allow,
        };
      });
      setSelectedAccess(updatedPermissionsArray);
      setPermissionsArray(updatedPermissionsArray);
    } else {
      const updatedPermission = permissionsArray.find(
        (permission) => permission.feature_list_id === id
      );
      if (updatedPermission) {
        const updatedPermissionsArray = permissionsArray.map((permission) => {
          if (permission.feature_list_id === id) {
            return {
              ...permission,
              allow: checked ? 0 : 1,
            };
          }
          return permission;
        });
        setSelectedAccess(updatedPermissionsArray);
        setPermissionsArray(updatedPermissionsArray);
      }
    }
  };

  const captureUserType = async (value) => {
    setUserType(value);
    setIsLoading(true);
    setPermissionsArray([]);
    setSelectedAccess([]);
    setOptionSelected(null);
    setSelectRole(null);
    setData([]);
    setSelectEditRole(null);
    setDisableRoles(true);
    if (value?.value === 1) {
      await Location.getAll().then((response) => {
        setLocations(response.data.data);
        let fetchData = response.data.data;
        let locationData = [];

        for (let i = 0; i < fetchData.length; i++) {
          const el = fetchData[i];
          locationData.push({
            label: el.name,
            value: el.id,
          });
        }
        setData(locationData);
      });
    } else if (value?.value === 2) {
      await RBAC.getAllUsers().then((response) => {
        let fetchData = response.data.data;
        let userData = [];

        for (let i = 0; i < fetchData.length; i++) {
          const el = fetchData[i];
          userData.push({
            label: el?.first_name + " " + el?.last_name,
            value: el?.id,
          });
        }
        setData(userData);
      });
    }
    setIsLoading(false);
  };

  const handlePostPermissions = async () => {
    setLoadingPermissions(true);
    let payload;
    if (userType && optionSelected && selectRole) {
      try {
        if (userType?.value === 1) {
          const selectedLocation = locations?.find(
            (location) => location.id === optionSelected?.value
          );
          payload = {
            company_id: selectedLocation?.company?.id,
            location_id: optionSelected?.value,
            role_id: selectRole?.value,
          };
        } else if (userType?.value === 2) {
          payload = {
            user_id: optionSelected?.value,
            role_id: selectRole?.value,
          };
        }
        const response =
          userType &&
          optionSelected &&
          selectRole &&
          (userType?.value === 1
            ? await RBAC.addPermissionsRolesLocation(payload)
            : userType?.value === 2
            ? await RBAC.addPermissionsRolesUser(payload)
            : null);
        if (response?.data?.message === "Role attached to location") {
          setSelectRole(null);
          setRefreshAttachedRoles(true);
          toast.success("Role Attached to Location Successfully");
        } else if (response?.data?.message === "Role attached to user") {
          setSelectRole(null);
          setRefreshAttachedRoles(true);
          toast.success("Role Attached to User Successfully");
        }
      } catch (error) {
        setSelectRole(null);
        console.error("Error loading permissions:", error);
      }
    }
    setLoadingPermissions(false);
  };

  const handleEditRole = async () => {
    setLoadingPermissions(true);
    const payload = {
      roles: selectEditRole?.label,
      permissions: selectedAccess.map((access) => ({
        feature_id: access.feature_list_id,
        allow: access.allow,
      })),
    };

    try {
      const response = await RBAC.getRoleById(selectEditRole?.value, payload);
      if (response) {
        await refreshRoles();
        toast.success("Role Edited Successfully");
      }
    } catch (error) {
      console.error("Error loading permissions:", error);
    }

    setLoadingPermissions(false);
  };

  const loadExistingRoles = async () => {
    setIsLoadingRoles(true);
    try {
      const response = await RBAC.getRolesList();
      setRolesList(response.data);
      let fetchdata = response.data;
      let rolesData = [];

      for (let i = 0; i < fetchdata.length; i++) {
        const el = fetchdata[i];
        rolesData.push({
          label: el.name,
          value: el.id,
        });
      }
      setExistingRoles(rolesData);
    } catch (error) {
      console.error("Error getting roles:", error);
    }
    setIsLoadingRoles(false);
  };

  const refreshRoles = async () => {
    await loadExistingRoles();
    if (rolesList && selectEditRole) {
      loadRolePermissions(selectEditRole?.id);
    }
  };

  useEffect(() => {
    refreshRoles();
  }, [refreshRolesList]);

  const handleEditFeature = async (item, permission) => {
    const editedPermission = { ...permission, item };
    setEditPermission(editedPermission);
    setShowModal(true);
  };

  return (
    <div>
      {showModal && (
        <AddFeatureModal
          open={showModal}
          setOpen={setShowModal}
          editPermission={editPermission}
          setEditPermission={setEditPermission}
        />
      )}

      {showRoleModal && (
        <AddRoleModal
          open={showRoleModal}
          setOpen={setShowRoleModal}
          featuresList={featuresList}
        />
      )}

      <ConfirmationModal
        openTrigger={openDeleteConfirmationModal}
        closeTrigger={() => setOpenDeleteConfirmationModal(false)}
        type="error"
        title="Confirm Action"
        confirmText="Delete"
        selectedId={attachedRoleId}
        description="Are you sure you want to delete this role?"
        confirm={handleRemoveAttachedRole}
      />

      <div className="my-8 flex gap-4 flex-col">
        <div className="w-full flex flex-col md:flex-row items-center justify-end gap-2">
          <button
            type="button"
            className="cursor-pointer font-bold text-sm px-6 py-2 w-fit rounded-md bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
            onClick={() => setShowModal(true)}
          >
            Add New Feature
          </button>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-4 w-full xl:w-3/4 2xl:w-1/2">
            <div className="flex flex-col justify-center md:w-[300px] xl:w-[360px]">
              <p className="text-md font-medium">Select Type</p>
              <div className="mt-auto border border-warmgray-300 rounded-md">
                <MultiSelect
                  key="userType"
                  options={options}
                  onChange={captureUserType}
                  value={userType}
                  menuPlacement={"bottom"}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex flex-col justify-start md:min-w-[300px] xl:min-w-[360px]">
                <p className="text-md font-medium">
                  {userType?.value === 1
                    ? "Select Location"
                    : userType?.value === 2
                    ? "Select User"
                    : "Sort By"}
                </p>
                <div className="border border-warmgray-300 rounded-md">
                  <MultiSelect
                    key="sort"
                    options={data}
                    onChange={handleChange}
                    value={optionSelected}
                    menuPlacement={"bottom"}
                    isLoading={isLoading}
                  />
                </div>
              </div>

              {userType && optionSelected && (
                <div>
                  {isLoadingAttachedRoles ? (
                    <div className="my-auto">
                      <p>Loading Attached Roles...</p>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-1">
                      <p className="font-bold">Attached Roles</p>
                      <div className="w-full flex flex-wrap gap-2">
                        {attachedRoles?.map((item) => {
                          return (
                            <div
                              key={item?.id}
                              className={`border-2 border-primary-500 px-2.5 py-1 rounded-xl flex justify-center items-center bg-primary-500`}
                            >
                              <p
                                className={`font-bold text-center text-white text-xs`}
                              >
                                {item?.role_name}
                              </p>
                              <i
                                className="fas fa-times-circle ml-1.5 text-white cursor-pointer"
                                onClick={() => deleteAttachedRole(item)}
                              />
                            </div>
                          );
                        })}
                        {attachedRoles?.length === 0 && (
                          <p className="text-xs">No Roles Attached Yet</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="flex flex-col justify-center md:w-[300px] xl:w-[360px]">
              <p className="text-md font-medium">Select Existing Roles</p>
              <div className="mt-auto border border-warmgray-300 rounded-md">
                <MultiSelect
                  key="roles"
                  options={existingRoles}
                  onChange={handleRoleChange}
                  value={selectRole}
                  menuPlacement={"bottom"}
                  isLoading={isLoadingRoles}
                  isLoadingAttachedRoles={isLoadingAttachedRoles}
                  disabledSelect={isDisableRoles}
                />
              </div>
            </div>

            <div className="mx-auto md:w-[300px] xl:w-[360px]">
              <button
                type="button"
                className="mt-4 mx-auto cursor-pointer font-bold text-sm px-6 py-2 w-fit rounded-md bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                onClick={() =>
                  userType &&
                  optionSelected &&
                  selectRole &&
                  handlePostPermissions()
                }
                disabled={
                  userType && optionSelected && selectRole ? false : true
                }
              >
                {userType?.value === 1
                  ? "Attach Role to Location"
                  : userType?.value === 2
                  ? "Attach Role to User"
                  : "Attach Role"}
              </button>
            </div>
          </div>

          <hr className="border-b-1 border-gray-100 w-full xl:w-3/4 2xl:w-1/2 my-4" />

          <div className="flex flex-col md:flex-row gap-4 justify-between w-full xl:w-3/4 2xl:w-1/2">
            <div className="flex flex-col justify-center md:w-[300px] xl:w-[360px]">
              <p className="text-md font-medium">Edit Existing Roles</p>
              <div className="mt-auto border border-warmgray-300 rounded-md">
                <MultiSelect
                  key="roles"
                  options={existingRoles}
                  onChange={handleEditRoleChange}
                  value={selectEditRole}
                  menuPlacement={"bottom"}
                  isLoading={isLoadingRoles}
                />
              </div>
            </div>

            <div className="my-auto">
              <button
                type="button"
                className="whitespace-nowrap cursor-pointer font-bold text-sm px-6 py-2 w-32 rounded-md bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                onClick={() => setShowRoleModal(true)}
              >
                Add New Role
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full xl:w-3/4 2xl:w-1/2 overflow-auto">
        {selectEditRole && (
          <p className="mb-2 text-xl font-bold">
            Role Name: {selectRole?.label}
          </p>
        )}
        {loadingFeature || loadingPermissions ? (
          <div className="flex justify-center w-full">Loading...</div>
        ) : (
          <>
            <table className="min-w-full divide-y divide-gray-500 whitespace-nowrap">
              <thead>
                <tr>
                  <th className="w-1/3 py-2.5 pr-3 text-left text-lg font-bold text-gray-900 whitespace-nowrap">
                    Features
                  </th>
                  <th className="w-1/3 py-2.5 pr-3 text-left text-lg font-bold text-gray-900 whitespace-nowrap">
                    Permission
                  </th>
                  <th className="w-1/4 py-2.5 px-3 text-center text-lg font-bold text-gray-900 whitespace-nowrap">
                    Access
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(featuresList).map(([item, records], index) => (
                  <React.Fragment key={index}>
                    <tr className="border-y border-warmgray-300">
                      <td className="text-left whitespace-nowrap py-2 pr-3 text-[16px] font-extrabold text-gray-900">
                        <i
                          className={`fas ${
                            expandedRows[index]
                              ? "fa-chevron-up"
                              : "fa-chevron-down"
                          } cursor-pointer mr-2`}
                          onClick={() => handleRowClick(index)}
                        ></i>
                        <i className="fas fa-bars text-primary-500 mr-1"></i>
                        {item}
                      </td>
                      <td className="text-left">{item}</td>
                      <td className="text-center">
                        <input
                          className="cursor-pointer"
                          type="checkbox"
                          checked={records.every((record) =>
                            permissionsArray.some(
                              (permission) =>
                                permission.feature_list_id === record.id &&
                                permission.allow === 1
                            )
                          )}
                          onChange={() =>
                            !userType &&
                            !optionSelected &&
                            !selectRole &&
                            selectEditRole &&
                            handleCheckClick(
                              index,
                              records.every((record) =>
                                permissionsArray.some(
                                  (permission) =>
                                    permission?.feature_list_id === record.id &&
                                    permission.allow === 1
                                )
                              ),
                              records
                            )
                          }
                        />
                      </td>
                    </tr>
                    {expandedRows[index] &&
                      records &&
                      records.length > 0 &&
                      records.map((permission, permissionIndex) => (
                        <tr key={`${index}_expanded_${permissionIndex}`}>
                          <td className="pt-1 pl-4 text-left">
                            <i className="fas fa-circle text-[8px] mr-1 mb-2 text-primary-500"></i>
                            {permission.operations}
                            <i
                              className="fad fa-pencil-alt ml-4 text-[12px] text-primary-500 cursor-pointer"
                              onClick={() =>
                                handleEditFeature(item, permission)
                              }
                            />
                          </td>
                          <td className="text-left">
                            {item + "." + permission.operations}
                          </td>
                          <td className="text-center">
                            <input
                              className="cursor-pointer"
                              type="checkbox"
                              checked={
                                permissionsArray?.find(
                                  (item) =>
                                    item.feature_list_id === permission.id
                                )?.allow === 1
                              }
                              onChange={() =>
                                !userType &&
                                !optionSelected &&
                                !selectRole &&
                                selectEditRole &&
                                handleCheckClick(
                                  permission?.id,
                                  permissionsArray?.find(
                                    (item) =>
                                      item.feature_list_id === permission.id
                                  )?.allow === 1
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>

            {selectEditRole && (
              <button
                type="button"
                className="mt-4 md:mt-12 mx-auto cursor-pointer font-bold text-sm px-6 py-2 w-fit rounded-md bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                onClick={() => handleEditRole()}
                disabled={selectEditRole || loadingPermissions ? false : true}
              >
                Save Changes
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RBACPanel;
