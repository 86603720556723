import React from "react";

const DropdownMenuItem = ({ label, icon, onClick, isMobile, isLast }) => {
  const eventHandler = isMobile ? "onPointerEnter" : "onClick";
  return (
    <a
      href="#nav"
      className={`hover:text-white hover:bg-primary-500 dark:hover:bg-gray-600 block px-3 py-2 ${
        !isLast ? "border-b border-gray-300 dark:border-gray-500" : ""
      }`}
      role="menuitem"
      tabIndex="-1"
      {...{ [eventHandler]: onClick }}
      id="menu-item-0"
    >
      {icon ? <i className={icon + " mr-2"} /> : ""}
      {label}
    </a>
  );
};

export default DropdownMenuItem;
