import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DashboardLayout } from "../../components/organisms/Layout";
import QRCodes from "../../api/Qrcode";
import dayjs from "dayjs";
import { DateFormat } from "../../utils";
import Posters from "../../api/Psoters";
import PaginationView from "../../components/molecules/Pagination";
import TableLoading from "../../components/Loading/TableLoading";
import Table from "../../components/atoms/Tables/Table";
import { addIcon } from "../../assets/images";
// import useQuery from "../../hooks/useQuery";

const Poster = () => {
  const { t } = useTranslation();
  // const query = useQuery();
  // const [pageLimit, setPagelimit] = useState(query.get("pageSize") || 10);
  const navigate = useNavigate();
  const [datas, setDatas] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [qrList, setQrList] = useState([]);
  const [metaData, setMetaData] = useState({});

  const loadPosters = async () => {
    try {
      const qrs = await Posters.getPosters();
      setDatas(qrs.data.data);
      setMetaData(qrs.data.meta);
    } catch (error) {
      console.log(error);
    }
  };

  const loadQRList = async () => {
    setLoading(false);
    await QRCodes.getAll()
      .then((response) => {
        setQrList(response.data.data);
      })
      .catch((error) => {
        console.log("loadQRList -> error", error);
      })
      .finally(() => setLoading(true));
  };

  const handlecPageChange = (currentPage, pageSize) => {
    // setPagelimit(pageSize);
    setSearchParams({ ...searchParams, page: currentPage, pageSize: pageSize });
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Manage Poster";
    loadPosters();
    loadQRList();
  }, []);

  return (
    <DashboardLayout darmo={true}>
      <div className="pt-3 md:pt-6 pb-12">
        <div className="overflow-x-auto overflow-hidden">
          {!loading ? (
            <TableLoading Columns={5} />
          ) : (
            <>
              <Table
                mainHeader={t("qrcode.manage-qrcode")}
                headers={
                  datas?.length > 0
                    ? [
                        "Poster Name",
                        "Banner Type",
                        "Text Color",
                        "Footer Color",
                        "Footer Text",
                        "Date Created",
                        "Edit",
                      ]
                    : ["No QR Code Yet"]
                }
                data={datas?.map((poster, index) => {
                  const _temp = JSON.parse(poster?.payload);
                  const _name = qrList?.find((i) => i?.id === _temp?.qr_id);
                  return (
                    <tr key={index}>
                      <td className="pr-3 py-3 text-sm md:text-base text-gray-900 dark:text-gray-300 whitespace-nowrap lg:whitespace-normal">
                        {_name?.name}
                      </td>
                      <td className="pr-3 py-3 text-sm md:text-base text-gray-900 dark:text-gray-300 whitespace-nowrap lg:whitespace-normal">
                        {_temp?.template}
                      </td>
                      <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900">
                        <div
                          class="border-2 rounded h-8 w-8 border-slate-500"
                          style={{
                            backgroundColor: _temp?.colors?.text.toString(),
                          }}
                        />
                      </td>
                      <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900">
                        <div
                          class="border-2 rounded h-8 w-8 border-slate-500"
                          style={{
                            backgroundColor:
                              _temp?.colors?.footer_background.toString(),
                          }}
                        />
                      </td>
                      <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900">
                        <div
                          class="border-2 rounded h-8 w-8 border-slate-500"
                          style={{
                            backgroundColor:
                              _temp?.colors?.footer_text.toString(),
                          }}
                        />
                      </td>
                      <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900 dark:text-gray-300">
                        {dayjs(poster?.created_at).format(DateFormat)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                        <div
                          className="text-indigo-600 dark:text-indigo-300 hover:text-indigo-900 hover:underline cursor-pointer"
                          onClick={() =>
                            navigate(`/business/posters/${poster.id}/edit`, {
                              state: { qrcode: poster },
                            })
                          }
                        >
                          Edit
                        </div>
                      </td>
                    </tr>
                  );
                })}
                buttonData={
                  <button
                    onClick={() => navigate("/business/posters/add")}
                    className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                  >
                    <div className="flex">
                      <img
                        src={addIcon}
                        alt="logo"
                        className="mr-2 my-auto w-4 h-4"
                      />
                      Add poster
                    </div>
                  </button>
                }
              />
              <div className="flex w-full h-20 mt-2 justify-end items-center">
                <PaginationView
                  current={metaData?.current_page}
                  next={metaData?.to}
                  pageSize={metaData?.per_page}
                  total={metaData?.total}
                  onChange={handlecPageChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Poster;
