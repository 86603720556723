import React, { useState } from "react";
import { DropFileSection } from "../../components/molecules/DropFileSection";
import HeyHireLogo from "../../assets/images/powered-by.png";
import FileUploadApi from "../../api/FileUpload";
import { Promotion } from "../../components/atoms/Promotion";
import { PublicFooter } from "../../components/organisms/Footer";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

const FileUpload = () => {
  const { userId } = useParams();
  const [uploadStatus, setUploadStatus] = useState("no-file");
  const [fileLabel, setFileLabel] = useState(
    "Upload PDF or DOC File (.pdf, .docx)"
  );
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [doneUpload, setDoneUpload] = useState(false);

  const handleFileUpload = (event) => {
    const fileSource = event.target.files[0];
    setFile(event.target.files[0]);
    setFileLabel(fileSource.name);
    setUploadStatus("upload");
  };

  const handleUploadAgainClick = () => {
    setDoneUpload(false);
    setLoading(false);
    setUploadStatus("no-file");
    setFileLabel("Upload PDF or DOC File (.pdf, .docx)");
  };

  const handleUploadButtonClick = () => {
    if (fileLabel !== "Upload PDF or DOC File (.pdf, .docx)") {
      const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB
      if (file.size <= maxSizeInBytes) {
        setLoading(true);

        const formData = new FormData();
        formData.append("job_seeker_id", userId);
        formData.append("file", file);

        const fileNameParts = fileLabel.split(".");
        const fileType = fileNameParts[fileNameParts.length - 1].toLowerCase();
        const fileTypeWithPrefix = `application/${fileType}`;

        formData.append("file_type", fileTypeWithPrefix);
        formData.append("file_name", fileLabel);
        FileUploadApi.validateResumeUpload(formData)
          .then((response) => {
            if (response.data.message === "Success") {
              setDoneUpload(true);
              toast.success("File uploaded successfully!");
            } else {
              setUploadStatus("no-file");
              toast.error("Upload failed. Please try again later.");
            }
          })
          .catch((error) => {
            setUploadStatus("no-file");
            toast.error("Please upload only PDF or DOC.");
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast.error("File size exceeds the 10MB limit.");
      }
    } else {
      toast.error("Please select a file first!");
    }
  };

  return (
    <>
      <div>
        <svg
          className="absolute inset-0 h-full w-full stroke-primary-100
               [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
              width={200}
              height={200}
              x="100%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M130 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <rect width="100%" height="100%" strokeWidth={0} fill="white" />
          <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
            <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
          />
        </svg>
      </div>
      <section className="text-gray-600 body-font bg-white lg:h-full md:h-full h-full">
        <header class="text-gray-600 body-font drop-shadow-md bg-gradient-to-r from-white via-primary-200 to-white fixed w-full">
          <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
            <img className="w-24 lg:w-36" src={HeyHireLogo} alt="heyhire" />
          </div>
        </header>
        {userId ? (
          <>
            <div className="container mx-auto -mt-15 flex px-5 py-20 lg:py-28 md:flex-row flex-col items-center bg-white">
              <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                <h3 class="title-font sm:text-xl text-3xl mb-4 font-medium text-gray-900">
                  Resume
                </h3>
                <div class="w-full md:justify-start justify-center items-end">
                  <div class="relative md:w-full lg:w-full">
                    <DropFileSection
                      status={uploadStatus}
                      label={fileLabel}
                      handleFileUpload={handleFileUpload}
                      icon={
                        loading ? (
                          <i
                            className="fad fa-circle-notch fa-spin ml-2 my-1.5 fa-3x"
                            style={{ color: "#594A9E" }}
                          />
                        ) : doneUpload ? (
                          <i
                            class="fa fa-check-circle fa-3x"
                            aria-hidden="true"
                            style={{ color: "#379459" }}
                          ></i>
                        ) : (
                          <DocumentIcon className="mx-auto h-10 w-10 text-gray-300" />
                        )
                      }
                    />
                  </div>
                  {doneUpload ? (
                    <button
                      onClick={handleUploadAgainClick}
                      className="relative w-full md:w-full lg:w-full mt-4 justify-center rounded-xl
                   bg-success-text px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm 
                    transition-all"
                    >
                      Done! Click to upload again.
                    </button>
                  ) : (
                    <button
                      onClick={handleUploadButtonClick}
                      disabled={loading}
                      className="relative w-full md:w-full lg:w-full mt-4 justify-center rounded-xl
                   bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm 
                   focus-visible:outline-primary-700 hover:bg-primary-700 transition-all"
                    >
                      {loading ? "Uploading . . ." : "Upload"}
                    </button>
                  )}
                </div>
              </div>
              <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                <Promotion
                  header={
                    "Find a local job without the hassle in 3 simple steps"
                  }
                  subHeader={"Start Applying today"}
                  buttonText={"View list of open jobs"}
                  content={
                    "Once downloading the HeyHire App you can Open your map to find the hiring businesses around you and apply in 2 taps! Also, find our QR banners around town!"
                  }
                />
              </div>
            </div>
            <div className="pb-10 -mt-16 bg-white">
              <PublicFooter />
            </div>
          </>
        ) : (
          <div className="grid place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 h-full">
            <div className="text-center">
              <p className="text-base font-semibold text-indigo-600">404</p>
              <h1 className="mt-4 text-3xl font-bold text-gray-900 sm:text-5xl">
                Page not found
              </h1>
              <p className="mt-6 text-base leading-7 text-gray-600">
                Sorry, we couldn't find the page you're looking for.
              </p>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default FileUpload;
