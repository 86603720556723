import React, { useState, useEffect, Fragment } from "react";
import { toast } from "react-hot-toast";
import JobPosition from "../../api/JobPosition";
import moment from "moment";
import { Dialog, Transition } from "@headlessui/react";

const NotesModal = ({ isOpen, onClose, application }) => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [isLoadingNotes, setIsLoadingNotes] = useState(false);
  const [isSavingNotes, setIsSavingNotes] = useState(false);

  useEffect(() => {
    if (isOpen && application) {
      collectInterviewNotes();
    }
  }, [isOpen, application]);

  const collectInterviewNotes = async () => {
    try {
      setIsLoadingNotes(true);
      const data = {
        job_seeker_id: application?.job_seeker_id,
        job_id: application?.job_id,
      };
      const interviewNotes = await JobPosition.collectInterviewNotes(data);
      if (interviewNotes.data) {
        setNotes(
          Array.isArray(interviewNotes.data.data)
            ? interviewNotes.data.data
            : []
        );
      }
    } catch (error) {
      toast.error("Failed to fetch interview notes");
      console.error(error);
    } finally {
      setIsLoadingNotes(false);
    }
  };

  const saveInterviewNotes = async () => {
    console.log(application);
    try {
      setIsSavingNotes(true);
      const data = {
        notes: newNote,
        job_seeker_id: application?.job_seeker_id,
        job_id: application?.job_id,
      };
      await JobPosition.addInterviewNotes(data);
      setNewNote("");
      toast.success("Notes saved!");
      collectInterviewNotes(); // Refresh notes after saving
    } catch (error) {
      toast.error("Failed to save interview notes");
      console.error(error);
    } finally {
      setIsSavingNotes(false);
    }
  };

  const handleDelete = async (noteId) => {
    try {
      await JobPosition.deleteInterviewNote(noteId);
      toast.success("Note deleted!");
      collectInterviewNotes(); // Refresh notes after saving
    } catch (error) {
      toast.error("Failed to delete interview note");
      console.error(error);
    }
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-black dark:bg-opacity-70 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-[#1F1F1F] shadow-xl transition-all max-h-[90vh] w-full max-w-2xl flex flex-col">
                {/* Modal Header */}
                <div className="relative flex items-center justify-center p-4 border-b dark:border-darkGray">
                  <h2 className="text-lg font-bold absolute mx-auto dark:text-gray-300">
                    Notes for{" "}
                    {application?.seeker?.first_name +
                      " " +
                      application?.seeker?.last_name}
                  </h2>
                  <button
                    onClick={onClose}
                    className="ml-auto text-gray-500 hover:text-gray-600 dark:hover:text-gray-300 text-2xl font-light"
                    disabled={isSavingNotes}
                  >
                    ×
                  </button>
                </div>

                {/* Notes List */}
                <div className="flex-grow overflow-y-auto px-4">
                  {isLoadingNotes ? (
                    <p className="text-center dark:text-gray-300 mt-4">
                      Loading Notes...
                    </p>
                  ) : (
                    <>
                      <div className="space-y-4 mt-4">
                        {notes.map((note) => (
                          <div
                            key={note.id}
                            className="inline-block relative w-full xl:w-[90%]"
                          >
                            <div className="flex items-start justify-between">
                              <div className="flex-1">
                                <p className="text-[10px] text-gray-500 dark:text-gray-400 mb-1">
                                  {moment(note.updated_at).format(
                                    "MMM D, YYYY, h:mm A"
                                  )}{" "}
                                  - {note.user.first_name} {note.user.last_name}
                                </p>
                                <div className="flex items-center">
                                  <span className="bg-gray-300 dark:bg-darkGray dark:text-gray-300 px-3 py-2 rounded-xl text-base mr-2">
                                    {note.note}
                                  </span>
                                  <button
                                    onClick={() => handleDelete(note?.id)}
                                    className="text-red-500 text-xs underline"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="mt-4 mb-4">
                        <textarea
                          value={newNote}
                          onChange={(e) => setNewNote(e.target.value)}
                          placeholder="Enter Note (Notes are private)"
                          className="w-full flex-grow p-2 border rounded-xl resize-none text-sm dark:text-gray-300 dark:bg-gray-600 dark:border-darkGray"
                          rows={4}
                          disabled={isLoadingNotes || isSavingNotes}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="flex mx-4 gap-2 ml-auto mt-3 mb-4">
                  <button
                    onClick={onClose}
                    className="font-bold text-primary-500 dark:text-gray-500 dark:hover:text-gray-300 px-4 py-2 cursor-pointer"
                    disabled={isLoadingNotes || isSavingNotes}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={saveInterviewNotes}
                    className="font-bold bg-primary-500 dark:bg-darkGray hover:bg-primary-400 dark:hover:bg-gray-600 text-white text-sm rounded-full py-2 px-4"
                    disabled={
                      isLoadingNotes || isSavingNotes || !newNote.trim()
                    }
                  >
                    Add Note
                    {isSavingNotes && (
                      <i className="fad fa-circle-notch fa-spin ml-2" />
                    )}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NotesModal;
