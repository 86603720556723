import { useEffect, useState } from "react";
import Brand from "../../../../api/Brand";
import TurnKeyBusinesses from "../../../../api/TurnKeyBusinesses";
import PinCodeField from "../../../../components/form/PinCodeField";
import QRCodeDisplayModal from "../../../../components/molecules/ORCodeDisplayModal/QRCodeDisplayModal";
import { useSettingsContext } from "../SettingsContext";
// import FormActivationCode from "../../../../components/atoms/FormActivationCode/FormActivationCode";
// import qs from "qs";
// import CompanyDetails from "./CompanyDetails";
// import { useNavigate } from "react-router-dom";

export default function FinalTurnKeyConfirmation() {
  const [activationCode, setActivationCode] = useState([]);
  // const [QRCodeId, setQRCodeID] = useState([]);
  // const [imageUID,setImageUUID] = useState([]);
  // const navigate = useNavigate();
  const [isAccountActivated, setIsAccountActivated] = useState(false);
  const [processingStatus, setProcessingStatus] = useState(true);

  const {
    company,
    // setCompanyName,
    // businessType,
    // setBusinessType,
    turnKeyObject,
    setTurnKeyObject,
    scrollUpRef,
    imageUploadFile,
    // setImageUploadFile,
    // existingTurnKeyAccount,
    // setExistingTurnKeyAccounts,
    turnKeyAccountId,
    // setTurnKeyAccountId
  } = useSettingsContext();

  const QRURL =
    "https://api.qrserver.com/v1/create-qr-code/?size=600x600&&color=000000&data=" +
    process.env.REACT_APP_URL +
    "/easy-login/";

  useEffect(() => {
    scrollUpRef.current.scrollIntoView();
    if (turnKeyAccountId) {
      editTurnKeyAccount();
    } else {
      addNewTurnKeyAccount();
    }
  }, []);

  const uploadBrandPhoto = (activation_code) => {
    Brand.uploadImage(Math.random(), imageUploadFile[0].file)
      .then((response) => {
        setTurnKeyObject((prevState) => ({
          ...prevState,
          brandLogoUUID: response.data.data.uuid,
        }));
        setTurnKeyObject((prevState) => ({
          ...prevState,
          brandLogoUrl: response.data.data.original_url,
        }));
        addPhotoDetails(
          activation_code,
          response.data.data.original_url,
          response.data.data.uuid
        );
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation error
        } else {
        }
      });
  };

  const addPhotoDetails = (activation_code, url, uuid) => {
    const data = { activation_code: activation_code, url: url, uuid: uuid };
    TurnKeyBusinesses.addPhotoDetails(data)
      .then((response) => {
        setProcessingStatus(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation error
        } else {
        }
      });
  };

  const addNewTurnKeyAccount = () => {
    const data = { payload: turnKeyObject };

    TurnKeyBusinesses.addTurnKeyAccount(data)
      .then((response) => {
        // setQRCodeID(response.data.data.uuid);
        setActivationCode(response.data.data.activation_code);
        setTurnKeyObject((prevState) => ({
          ...prevState,
          QRCodeID: response.data.data.uuid,
        }));
        uploadBrandPhoto(response.data.data.activation_code);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation error
        } else {
        }
      });
  };

  const editTurnKeyAccount = () => {
    const data = { account_id: turnKeyAccountId, payload: turnKeyObject };

    TurnKeyBusinesses.updateTurnKeyPayload(data)
      .then((response) => {
        const code = response.data.code;
        if (code === 2) {
          setIsAccountActivated(true);
          setProcessingStatus(false);
        } else {
          const accountActivationCode = response.data.activation_code;

          if (imageUploadFile[0]) {
            uploadBrandPhoto(accountActivationCode);
          } else {
            setProcessingStatus(false);
          }
        }
      })
      .catch((error) => {
        console.log("error updating");
      });
  };

  return (
    <div className={` ${turnKeyAccountId ? "hidden" : ""}container md:mt-10`}>
      <div className="flex flex-col items-center">
        <div className="wrapper">
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>

        <div className="flex flex-row mt-3 text-xl font-semibold uppercase text-primary-500 pb-10">
          {turnKeyAccountId ? (
            isAccountActivated ? (
              <div>
                Turnkey Account already{" "}
                <span className="text-orange-600 transition-all animate-fade-in-from-top-1s">
                  activated
                </span>
                . Edits Discarded
              </div>
            ) : processingStatus ? (
              <div>
                Looking for changes and updating . . .
                <div
                  class="inline-block ml-5 h-10 w-10 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                ></div>
              </div>
            ) : (
              <div className="flex flex-row">
                <div className="pr-3 animate-pulse">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#088F8F"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg>
                </div>
                <div>
                  Turnkey Account updated{" "}
                  <span className="text-emerald-600 transition-all animate-fade-in-from-top-1s">
                    successfully
                  </span>
                </div>
              </div>
            )
          ) : (
            <div>Turnkey Account has been created successfully</div>
          )}
        </div>

        {!turnKeyAccountId && (
          <>
            <QRCodeDisplayModal
              header={company.companyName}
              title=""
              QRLink={QRURL + activationCode}
            />
            <PinCodeField codeLength={6} values={"" + activationCode} />
            <div className="text-md font-semibold text-gray-500 pt-10">
              Copy the above code{" "}
              <span className="text-lg pl-2 text-primary-500">
                {activationCode}
              </span>
            </div>
          </>
        )}

        <p class="text-gray-500 dark:text-gray-400 pt-3">
          The easy-login for this turnkey account{" "}
          <a
            href={`/easy-login/${activationCode}`}
            class="inline-flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline"
            target="_blank"
            rel="noreferrer noopener"
          >
            easy-login url
            <svg
              aria-hidden="true"
              class="w-5 h-5 ml-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a>
        </p>
        <a className="mt-10" href="/settings">
          {!processingStatus && (
            <button className="h-10 px-5 text-primary-500 font-bold transition-colors duration-150 border border-primary-500 rounded-lg focus:shadow-outline hover:bg-primary-700 hover:text-white">
              Start Over
            </button>
          )}
        </a>
      </div>
    </div>
  );
}
