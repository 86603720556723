import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { DashboardLayout } from "../../components/organisms/Layout";
import Input from "../../components/form/Input";
import TextArea from "../../components/form/TextArea";
import { toast } from "react-hot-toast";
import ReactImageUploading from "react-images-uploading";
import Select from "../../components/form/Select";
import BrandApi from "../../api/Brand";
import Company from "../../api/Company";
import { decodeIds } from "../../utils";

const EditBrand = () => {
  const [edit, setEdit] = useState(false);
  const params = useParams();
  const { t } = useTranslation();
  const [externalName, setExternalName] = useState("");
  const [description, setDescription] = useState("");
  const [urlSlug, setUrlSlug] = useState("");
  const [brand, setBrand] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [company, setCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [facebook, setFacebook] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [images, setImages] = useState([]);
  const [coverImages, setCoverImages] = useState([]);
  const [decodedId, setDecodedId] = useState(decodeIds(params?.brandId)[0]);
  const domainUrl =
    process.env.REACT_APP_API_BASE_URL ===
    "https://stagingapi.heyhire.net/api/v1/"
      ? "https://staging.heyhire.app"
      : "https://heyhire.app";

  useEffect(() => {
    document.title = "HeyHire Portal-Edit-Brand";
    loadCompanies();
  }, []);

  const loadBrand = () => {
    BrandApi.getById(decodedId)
      .then((response) => {
        const brandFromResponse = response.data.data;
        setBrand(brandFromResponse);
        setName(brandFromResponse.name);
        setEmail(brandFromResponse.email);
        setWebsite(brandFromResponse.website);
        setCompany(brandFromResponse.company_id);
        setDescription(brandFromResponse.description);
        setExternalName(brandFromResponse.external_name);
        setFacebook(brandFromResponse.facebook);
        setInstagram(brandFromResponse.instagram);
        setLinkedin(brandFromResponse.linkedin);
        setTwitter(brandFromResponse.twitter);
        setUrlSlug(brandFromResponse.url_slug);
        setYoutube(brandFromResponse.youtube);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Something went wrong.");
        }
      })
      .finally(() => setLoadingCompany(false));
  };

  const loadCompanies = () => {
    setLoadingCompany(true);
    Company.getAll()
      .then((response) => {
        setCompanies(response.data.data);
      })
      .catch()
      .finally(() => loadBrand());
  };

  const updateBrand = () => {
    setUpdateLoading(true);
    setValidationErrors(null);

    let body = {
      id: decodedId,
      name: name,
      company_id: company,
      website: website,
      facebook: facebook,
      twitter: twitter,
      url_slug: urlSlug,
      description: description,
      external_name: externalName,
      instagram: instagram,
      linkedin: linkedin,
      youtube: youtube,
    };

    /*
    if (website !== "" || website !== null && website !== brand.url_slug) {
      body.url_slug = website;
    }
    */
    BrandApi.update(decodedId, body)
      .then(() => {
        toast.success("Changes Saved");
        if (images.length > 0) {
          uploadBrandPhoto();
        }

        if (coverImages.length > 0) {
          uploadBrandCoverPhoto();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error("Something Went Wrong. Please Try Again.");
          }
        }
      })
      .then(() => setUpdateLoading(false));
  };

  const uploadBrandPhoto = () => {
    setUpdateLoading(true);
    BrandApi.uploadImage(decodedId, images[0].file).finally(() =>
      setUpdateLoading(false)
    );
  };

  const uploadBrandCoverPhoto = () => {
    setUpdateLoading(true);
    BrandApi.uploadCoverImage(decodedId, coverImages[0].file).finally(() =>
      setUpdateLoading(false)
    );
  };

  return (
    <DashboardLayout darmo={true}>
      <div>
        <div className="flex justify-between">
          <Link to="/companies/brands" className="font-bold text-2xl">
            <i className="far fa-chevron-left text-xl mr-2" /> Edit Brand
          </Link>

          <button
            onClick={() => setEdit(!edit)}
            className="p-2 px-12 rounded-full border-2 border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-white disabled:border-primary-200 disabled:text-primary-200"
            disabled={loading || updateLoading}
          >
            {t("common.edit")}{" "}
            {edit ? (
              <i className="fad fa-lock-open ml-1" />
            ) : (
              <i className="fad fa-lock ml-1" />
            )}
          </button>
        </div>
        <hr className="my-2 border-gray-300" />

        <div>
          {loadingCompany && (
            <i className="flex justify-center items-center w-full fad fa-circle-notch fa-spin ml-2 text-2xl text-warmgray-400" />
          )}

          {brand && (
            <div className="px-5 mt-6">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8">
                <Input
                  name="twitter"
                  label="Company name"
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                  error={
                    validationErrors && validationErrors.twitter
                      ? validationErrors.twitter[0]
                      : null
                  }
                  disabled={!edit}
                  darmo={true}
                />

                <Input
                  name="external_name"
                  label="External Business Name"
                  value={externalName}
                  onChange={(e) => setExternalName(e.target.value)}
                  error={
                    validationErrors && validationErrors.external_name
                      ? validationErrors.external_name[0]
                      : null
                  }
                  disabled={!edit}
                  darmo={true}
                />
                <div>
                  <TextArea
                    label="Brand Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    error={
                      validationErrors && validationErrors.description
                        ? validationErrors.description[0]
                        : null
                    }
                    disabled={!edit}
                    darmo={true}
                  />

                  <div className="self-center">
                    <input type="checkbox" /> Apply External Business Name to
                    children
                  </div>
                </div>
                <div>
                  <Input
                    name="url_slug"
                    label="Custom URL"
                    slug={domainUrl}
                    placeholder="business-name"
                    value={urlSlug}
                    onChange={(e) => setUrlSlug(e.target.value)}
                    error={
                      validationErrors && validationErrors.url_slug
                        ? validationErrors.url_slug[0]
                        : null
                    }
                    disabled={!edit}
                    darmo={true}
                  />
                  <div>
                    <ReactImageUploading
                      value={images}
                      onChange={(imageList, index) => {
                        setImages(imageList);
                      }}
                    >
                      {({ imageList, onImageUpload }) => (
                        <div className="flex mt-4">
                          <div className="h-28 w-28 bg-warmgray-200 selfcenter flex justify-center mb-3">
                            {imageList && imageList.length > 0 ? (
                              <img
                                className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                                src={imageList[0]?.dataURL}
                                alt=""
                              />
                            ) : brand.photo ? (
                              <img
                                className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                                src={brand?.photo?.thumb_url}
                                alt=""
                              />
                            ) : (
                              <i
                                className="text-white text-3xl self-center fa fa-plus cursor-pointer"
                                onClick={onImageUpload}
                              />
                            )}
                          </div>
                          <button
                            className="ml-4 rounded-full px-4 py-2 bg-primary-500 hover:bg-primary-300 text-white cursor-pointer self-center disabled:bg-primary-300"
                            onClick={onImageUpload}
                            disabled={!edit}
                          >
                            Upload Brand Logo
                          </button>
                        </div>
                      )}
                    </ReactImageUploading>
                  </div>

                  <div>
                    <ReactImageUploading
                      value={coverImages}
                      onChange={(imageList, index) => {
                        setCoverImages(imageList);
                      }}
                    >
                      {({ imageList, onImageUpload }) => (
                        <div className="flex">
                          <div className="h-28 w-28 bg-warmgray-200 selfcenter flex justify-center mb-3">
                            {imageList && imageList.length > 0 ? (
                              <img
                                className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                                src={imageList[0]?.dataURL}
                                alt=""
                              />
                            ) : brand.photo ? (
                              <img
                                className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                                src={brand?.photo?.thumb_url}
                                alt=""
                              />
                            ) : (
                              <i
                                className="text-white text-3xl self-center fa fa-plus cursor-pointer"
                                onClick={onImageUpload}
                              />
                            )}
                          </div>
                          <button
                            className="ml-4 rounded-full px-4 py-2 bg-primary-500 hover:bg-primary-300 text-white cursor-pointer self-center disabled:bg-primary-300"
                            onClick={onImageUpload}
                            disabled={!edit}
                          >
                            Upload Cover Banner
                          </button>
                        </div>
                      )}
                    </ReactImageUploading>
                  </div>
                </div>
              </div>
              <>
                {/* <span className="block font-bold mb-2">Basic Information</span>
                <hr /> */}

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <Input
                    name="name"
                    label="Brand Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={
                      validationErrors && validationErrors.name
                        ? validationErrors.name[0]
                        : null
                    }
                    disabled={!edit}
                    darmo={true}
                  />

                  <Select
                    label="Company"
                    value={company}
                    name="company"
                    onChange={(e) => {
                      setCompany(companies[e.target.selectedIndex].id);
                    }}
                    disabled={!edit}
                    darmo={true}
                  >
                    {companies.map((company) => {
                      return (
                        <option
                          className="p-5"
                          key={company.id}
                          value={company.id}
                        >
                          {company.name}
                        </option>
                      );
                    })}
                  </Select>

                  {/*<Input
                    className="mt-0"
                    name="website"
                    label="Website"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    error={
                      validationErrors && validationErrors.url_slug
                        ? validationErrors.url_slug[0]
                        : null
                    }
                    disabled={!edit}
                  />*/}

                  <Input
                    className="mt-0"
                    name="email"
                    label="Email"
                    value={email}
                    disabled={true}
                    darmo={true}
                  />
                </div>
              </>

              <div className={"mt-8"}>
                {/* <span className={"block font-bold mb-1"}>Social Media</span>
                <hr /> */}

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <Input
                    name="website"
                    label="Website"
                    slug="http://"
                    placeholder="www.example.com"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    error={
                      validationErrors && validationErrors.website
                        ? validationErrors.website[0]
                        : null
                    }
                    disabled={!edit}
                    darmo={true}
                  />
                  <Input
                    name="facebook"
                    label="Facebook Page"
                    slug="facebook.com/"
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                    error={
                      validationErrors && validationErrors.facebook
                        ? validationErrors.facebook[0]
                        : null
                    }
                    disabled={!edit}
                    darmo={true}
                  />

                  <Input
                    name="linkedin"
                    label="Linkedn Page"
                    slug="linkedin.com/"
                    value={linkedin}
                    onChange={(e) => setLinkedin(e.target.value)}
                    error={
                      validationErrors && validationErrors.linkedin
                        ? validationErrors.linkedin[0]
                        : null
                    }
                    disabled={!edit}
                    darmo={true}
                  />

                  <Input
                    name="instagram"
                    label="Instagram Page"
                    slug="instagram.com/"
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                    error={
                      validationErrors && validationErrors.instagram
                        ? validationErrors.instagram[0]
                        : null
                    }
                    disabled={!edit}
                    darmo={true}
                  />

                  <Input
                    name="twitter"
                    label="Twitter Page"
                    slug="twitter.com/"
                    value={twitter}
                    onChange={(e) => setTwitter(e.target.value)}
                    error={
                      validationErrors && validationErrors.twitter
                        ? validationErrors.twitter[0]
                        : null
                    }
                    disabled={!edit}
                    darmo={true}
                  />

                  <Input
                    name="youtube"
                    label="Youtube Page"
                    slug="youtube.com/"
                    value={youtube}
                    onChange={(e) => setYoutube(e.target.value)}
                    error={
                      validationErrors && validationErrors.youtube
                        ? validationErrors.youtube[0]
                        : null
                    }
                    disabled={!edit}
                    darmo={true}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex w-full py-10">
          <button
            className="flex justify-center items-center rounded-full bg-primary-500 px-12 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
            onClick={(e) => {
              e.preventDefault();
              updateBrand();
            }}
            disabled={updateLoading || loading}
          >
            {t("common.save")}
            {loading || updateLoading ? (
              <i className="fad fa-circle-notch fa-spin ml-2" />
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default EditBrand;
