import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ArrowsUpDownIcon,
} from "@heroicons/react/24/outline";
import "./AdminNavBar.scss";
import { useDispatch, useSelector } from "react-redux";
import { setBackgroundcolor, darkmode } from "../../../store/authSlice";
import {
  logout,
  setSelectedLocation,
  selectRoles,
  selectSelectedLocation,
  selectUser,
} from "../../../store/authSlice";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Location from "../../../api/Location";
import User from "../../../api/User";
import { Dropdown } from "antd";
import DropdownMenuItem from "../../DropdownMenuItem";
import { defaultUserImage, heyhireIcon } from "../../../assets/images";
import { FaAdjust } from "react-icons/fa";
import { toast } from "react-hot-toast";

export default function AdminNavBar({ isEnterprise = true }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mode = useSelector(darkmode);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const location = useLocation();
  const roles = useSelector(selectRoles);
  const user = useSelector(selectUser);
  const selectedLocation = useSelector(selectSelectedLocation);
  const [displayAddress, setDisplayAddress] = useState(true);
  const isSuper = !pathname.includes("/business");
  // const domainFLag = process.env.REACT_APP_API_BASE_URL.search("stagingapi");
  const [locations, setLocations] = useState([]);
  const showAddress = pathname === "/business/dashboard";
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem("theme") || (mode ? "light" : "dark");
  });

  useEffect(() => {
    const root = window.document.documentElement;
    if (theme === "dark") {
      root.classList.add("dark");
    } else {
      root.classList.remove("dark");
    }
    localStorage.setItem("theme", theme);
    dispatch(setBackgroundcolor(theme === "light"));
  }, [theme]);

  const superUserItems = [
    {
      label: t("sidebar.dashboard"),
      icon: "far fa-home",
      link: "/dashboard",
    },
    {
      label: "Businesses",
      icon: "far fa-briefcase",
      permissions: ["company.view"],
      subItems: [
        {
          label: t("sidebar.companies"),
          link: "/companies",
          permissions: ["company.view"],
        },
        {
          label: t("sidebar.regions"),
          link: "/companies/regions",
          permissions: ["region.view"],
        },
        {
          label: t("sidebar.locations"),
          link: "/companies/locations",
          permissions: ["location.view"],
        },
        {
          label: t("sidebar.brands"),
          link: "/companies/brands",
          permissions: ["brand.view"],
        },
        { label: t("sidebar.address-book"), link: "/admin/address-book" },
        {
          label: t("sidebar.Settings"),
          link: "/settings",
          permissions: ["company.view"],
        },
      ],
    },
    {
      label: "Billing",
      icon: "far fa-money-check",
      permissions: ["job_seeker.view"],
      subItems: [
        { label: "Activity", link: "/payments/activity" },
        { label: "Discounts", link: "/payments/discounts" },
        { label: "Free Accounts", link: "/payments/free-accounts" },
        { label: "Trial Accounts", link: "/payments/trial-accounts" },
      ],
    },
    {
      label: "Users",
      icon: "far fa-user",
      subItems: [
        {
          label: t("sidebar.manage-business-users"),
          link: "/admin/manage-business-users",
        },
        {
          label: t("sidebar.job-seekers"),
          link: "/job-seekers",
          permissions: ["job_seeker.view"],
        },
      ],
    },
    {
      label: "Miscellaneous",
      icon: "far fa-cogs",
      subItems: [
        {
          label: t("sidebar.predefined-jobs"),
          link: "/predefined-jobs",
          permissions: ["predefined_job.view"],
        },
        {
          label: t("sidebar.pre-canned-messages"),
          link: "/messages/canned-messages",
        },
        {
          label: t("sidebar.general-settings"),
          link: "/admin/general-settings",
        },
        { label: t("sidebar.certifications"), link: "/admin/certifications" },
      ],
    },
    {
      label: "Control Panel",
      icon: "far fa-wrench",
      link: "/control-panel",
    },
  ];

  const showLocationDropdown =
    (isEnterprise && roles[0] !== "employee") ||
    roles[0] === "super-user" ||
    roles[0] === "regional_manager";

  const showExitLocation =
    (isEnterprise && roles[0] === "owner") || roles[0] === "regional_manager";

  useEffect(() => {
    loadLocations();
  }, []);

  const loadLocations = async () => {
    const _selectedLocation = await JSON.parse(
      localStorage.getItem("selectedLocation")
    );
    Location.getAll().then((response) => {
      if (response.data.data) {
        const resLocations = response.data.data;
        setLocations(resLocations);
        if (resLocations.length > 0 && !selectedLocation) {
          if (showLocationDropdown) {
            if (_selectedLocation) {
              dispatch(setSelectedLocation(_selectedLocation));
            } else {
              if (roles[0] === "turnkey_admin") {
              } else {
                dispatch(setSelectedLocation({ name: "Select a location" }));
              }
            }
          } else {
            dispatch(setSelectedLocation(resLocations[0]));
          }
        }
      }
    });
  };

  const changeLocation = async (id) => {
    try {
      await User.updateLocation(id);
      toast.success("Location Changed!");
    } catch (error) {
      toast.error("Error Occurred during Location switching. Try Again!");
    }
  };

  const UserInfo = () => {
    return (
      <>
        <div className={"flex justify-center items-center"}>
          <div className="flex flex-col justify-between text-black">
            <span className={"block font-semibold mb-0 text-center"}>
              Switch Business
            </span>
          </div>
        </div>
        {showAddress &&
          displayAddress &&
          roles[0] === "super-user" &&
          selectedLocation?.id && (
            <div className="my-4  rounded-md p-4">
              <p className="w-48 font-semibold text-black text-sm">
                {selectedLocation?.address?.address +
                  ", " +
                  selectedLocation?.address?.city +
                  ", " +
                  selectedLocation?.address?.state || " "}
              </p>
            </div>
          )}
      </>
    );
  };

  const menu = (
    <div
      className="z-10 origin-top-right w-[260px] h-auto md:max-h-[600px] lg:max-h-[600px] max-h-[400px] 
              rounded-lg shadow-lg bg-white focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div
        className="py-1 h-auto md:max-h-[600px] lg:max-h-[600px] max-h-[400px] overflow-auto"
        role="none"
      >
        <p className="text-sm font-bold pl-5">Location:</p>
        {roles[0] !== "turnkey_admin" &&
          locations.map((location, index) => (
            <div className="font-bold text-sm">
              <DropdownMenuItem
                key={location?.id}
                onClick={() => {
                  dispatch(setSelectedLocation(location));
                  localStorage.setItem(
                    "selectedLocation",
                    JSON.stringify(location)
                  );
                  changeLocation(location?.id);
                  if (roles[0] !== "super-user") {
                    setDisplayAddress(false);
                  }
                  navigate(`/business/dashboard?location=${location?.id}`);
                }}
                label={location?.name}
                icon="far fa-map-marker-alt"
              />
            </div>
          ))}
      </div>
    </div>
  );

  const BusinessLocation = ({ className }) => {
    if (roles[0] !== "super-user" && selectedLocation?.id) return <></>;
    return (
      <div className={className}>
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          placement="topRight"
          overlayClassName={""}
        >
          <a
            href="#href"
            className="ant-dropdown-link hover:text-primary-500"
            onClick={(e) => e.preventDefault()}
          >
            <div className="flex cursor-pointer hover:ring2 hover:ring-violet-200">
              <div
                className={
                  "p-3 w-full rounded flex justify-center text-white cursor-pointer"
                }
              >
                <button className="rounded-md bg-primary-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  {locations && locations.length > 0 && selectedLocation && (
                    <span>{selectedLocation?.name}</span>
                  )}
                </button>
              </div>
            </div>
          </a>
        </Dropdown>
      </div>
    );
  };

  const handleClickMenu = (path) => {
    navigate(path);
  };
  return (
    <Disclosure
      as="nav"
      className="bg-primary-500 dark:bg-[#242526] shadow relative"
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-full px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-white hover:text-secondary focus:outline-none">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-8 w-auto"
                    src={heyhireIcon}
                    alt="Your Company"
                  />
                  <p className="text-[9px] pl-1 font-extrabold pt-2 text-white">
                    Admin
                  </p>
                </div>

                <div className="hidden sm:ml-6 sm:flex md:space-x-5 text-[13px] font-medium text-white">
                  {superUserItems.map((item, _x) => (
                    <Menu as="div" key={_x} className="relative text-left mt-5">
                      <div>
                        <Menu.Button
                          onClick={(e) => handleClickMenu(item.link)}
                          className="inline-flex text-[10px] md:text-[12px] items-center px-1 pt-1 hover:text-secondary"
                        >
                          {item.label}
                        </Menu.Button>
                      </div>
                      {item.subItems && item.subItems.length && (
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                          className="bg-white z-50"
                        >
                          <Menu.Items
                            key={_x}
                            className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white dark:bg-[#3A3B3D] ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <div className="py-1">
                              {item.subItems.map((subItem, _j) => (
                                <Menu.Item key={_j}>
                                  {({ active }) => (
                                    <div
                                      className={`${
                                        active
                                          ? " text-primary-700 hover:bg-primary-500 hover:text-white dark:text-gray-300"
                                          : " text-gray-900 dark:text-gray-300"
                                      } block px-4 py-2 text-sm`}
                                    >
                                      <Link to={subItem.link} className="block">
                                        {subItem.label}
                                      </Link>
                                    </div>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Transition>
                      )}
                    </Menu>
                  ))}
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <Menu as="div" className="relative ml-3">
                  <div className="admin-switch">
                    <Menu.Button className="flex ">
                      {selectedLocation?.brand?.photo?.thumb_url ? (
                        <div
                          className="relative rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500
                           focus:ring-offset-2  justify-center p-[5px] min-w-[30px] min-h-[30px]"
                        >
                          <img
                            width={30}
                            height={30}
                            src={
                              selectedLocation?.brand?.photo?.thumb_url ||
                              defaultUserImage
                            }
                            alt={user?.company?.name}
                            className="object-fill rounded-full"
                          />

                          <ArrowsUpDownIcon
                            className="absolute h-4 w-4 mr-1 bottom-7 left-7 text-green-500"
                            aria-hidden="true"
                          />
                        </div>
                      ) : (
                        <div className="flex flex-row text-[12px] font-bold text-white">
                          <div>
                            <ArrowsUpDownIcon
                              className="block h-4 w-4 mr-1"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="">Switch Business</div>
                        </div>
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className={`absolute right-0 z-10 mt-2 w-70 origin-top-right rounded-md ${
                        mode ? "bg-white" : "bg-white opacity-100"
                      } py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                    >
                      <Menu.Item>
                        <div className="flex flex-col mb-5">
                          <div className="flex flex-col flex-1">
                            <div className="flex-1 flex flex-col pt-12 pb-4 px-6 scroll-custom min-w-[260px] max-w-[260px]">
                              <UserInfo />
                            </div>
                            {showLocationDropdown && (
                              <BusinessLocation
                                className={"relative mt-2 w-full"}
                              />
                            )}
                          </div>
                        </div>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>

                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={defaultUserImage}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md text-gray-900 dark:text-gray-300 text-[12px] font-medium bg-white dark:bg-[#3A3B3D] py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        <DropdownMenuItem
                          onClick={() => navigate("/business/my-account")}
                          label="Profile"
                          icon="far fa-user"
                        />
                      </Menu.Item>
                      <Menu.Item>
                        <DropdownMenuItem
                          label="Switch Accounts"
                          icon="far fa-repeat"
                          onClick={() =>
                            navigate(
                              isSuper ? "/business/dashboard" : "/dashboard"
                            )
                          }
                        />
                      </Menu.Item>

                      {/* Theme Menu with Submenu */}
                      <Menu.Item>
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex items-center justify-between w-full px-3.5 py-2 text-[12px] font-medium hover:bg-primary-500 hover:text-white">
                                <span className="flex items-center">
                                  <i className="mr-2 shrink-0 flex items-center justify-center">
                                    <FaAdjust className="text-base" />
                                  </i>
                                  Theme
                                </span>
                                <i
                                  className={`fas fa-chevron-${
                                    open ? "up" : "down"
                                  }`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="pl-8 py-2 space-y-2">
                                <div
                                  className="cursor-pointer text-[12px] hover:text-gray-400 dark:hover:text-gray-500"
                                  onClick={() => setTheme("light")}
                                >
                                  Light
                                </div>
                                <div
                                  className="cursor-pointer text-[12px] hover:text-gray-400 dark:hover:text-gray-500"
                                  onClick={() => setTheme("dark")}
                                >
                                  Dark
                                </div>
                                <div
                                  className="cursor-pointer text-[12px] hover:text-gray-400 dark:hover:text-gray-500"
                                  onClick={() => setTheme("device")}
                                >
                                  System
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      </Menu.Item>

                      <Menu.Item>
                        <DropdownMenuItem
                          onClick={() => dispatch(logout())}
                          label="Logout"
                          icon="far fa-sign-out"
                        />
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-4 pt-2 text-white">
              {/* Dashboard (Left-aligned with border-l-4) */}
              <Disclosure.Button
                as={Link}
                to="/admin/dashboard"
                className={`block py-2 pl-3 pr-4 text-base font-medium ${
                  location.pathname === "/admin/dashboard"
                    ? "border-l-4 border-gray-300 text-purple-300" // Active state
                    : "border-l-4 border-transparent hover:border-gray-300 hover:text-secondary"
                }`}
              >
                Dashboard
              </Disclosure.Button>

              {/* Business Section */}
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="block w-full py-2 pl-3 pr-4 text-base font-medium border-l-4 border-transparent hover:border-gray-300 hover:text-secondary">
                      <span className="float-left">Business</span>
                      <span className="float-right">{open ? "-" : "+"}</span>
                    </Disclosure.Button>
                    {/* Submenus (No border-l-4 for submenu items) */}
                    <Disclosure.Panel className="flex justify-center">
                      <div className="text-center w-full">
                        <Disclosure.Button
                          as={Link}
                          to="/companies"
                          className={`block py-2 text-base font-medium ${
                            location.pathname === "/companies"
                              ? "text-secondary" // Highlight active submenu
                              : "hover:bg-gray-600 hover:text-secondary"
                          }`}
                        >
                          Companies
                        </Disclosure.Button>
                        <Disclosure.Button
                          as={Link}
                          to="/companies/locations"
                          className={`block py-2 text-base font-medium ${
                            location.pathname === "/companies/locations"
                              ? "text-secondary"
                              : "hover:bg-gray-600 hover:text-secondary"
                          }`}
                        >
                          Locations
                        </Disclosure.Button>
                        <Disclosure.Button
                          as={Link}
                          to="/companies/brands"
                          className={`block py-2 text-base font-medium ${
                            location.pathname === "/companies/brands"
                              ? "text-secondary"
                              : "hover:bg-gray-600 hover:text-secondary"
                          }`}
                        >
                          Brands
                        </Disclosure.Button>
                        <Disclosure.Button
                          as={Link}
                          to="/companies/regions"
                          className={`block py-2 text-base font-medium ${
                            location.pathname === "/companies/regions"
                              ? "text-secondary"
                              : "hover:bg-gray-600 hover:text-secondary"
                          }`}
                        >
                          Regions
                        </Disclosure.Button>
                        <Disclosure.Button
                          as={Link}
                          to="/admin/address-book"
                          className={`block py-2 text-base font-medium ${
                            location.pathname === "/admin/address-book"
                              ? "text-secondary"
                              : "hover:bg-gray-600 hover:text-secondary"
                          }`}
                        >
                          Address Book
                        </Disclosure.Button>
                        <Disclosure.Button
                          as={Link}
                          to="/settings"
                          className={`block py-2 text-base font-medium ${
                            location.pathname === "/settings"
                              ? "text-secondary"
                              : "hover:bg-gray-600 hover:text-secondary"
                          }`}
                        >
                          Settings
                        </Disclosure.Button>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              {/* Users Section */}
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="block w-full py-2 pl-3 pr-4 text-base font-medium border-l-4 border-transparent hover:border-gray-300 hover:text-secondary">
                      <span className="float-left">Users</span>
                      <span className="float-right">{open ? "-" : "+"}</span>
                    </Disclosure.Button>
                    {/* Submenus (No border-l-4 for submenu items) */}
                    <Disclosure.Panel className="pl-6 flex justify-center">
                      <div className="text-center w-full">
                        <Disclosure.Button
                          as={Link}
                          to="/admin/manage-business-users"
                          className={`block py-2 text-base font-medium ${
                            location.pathname === "/admin/manage-business-users"
                              ? "text-secondary"
                              : "hover:bg-gray-600 hover:text-secondary"
                          }`}
                        >
                          Manage Users
                        </Disclosure.Button>
                        <Disclosure.Button
                          as={Link}
                          to="/job-seekers"
                          className={`block py-2 text-base font-medium ${
                            location.pathname === "/job-seekers"
                              ? "text-secondary"
                              : "hover:bg-gray-600 hover:text-secondary"
                          }`}
                        >
                          Manage Jobseekers
                        </Disclosure.Button>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              {/* Miscellaneous Section */}
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="block w-full py-2 pl-3 pr-4 text-base font-medium border-l-4 border-transparent hover:border-gray-300 hover:text-secondary">
                      <span className="float-left">Miscellaneous</span>
                      <span className="float-right">{open ? "-" : "+"}</span>
                    </Disclosure.Button>
                    {/* Submenus (No border-l-4 for submenu items) */}
                    <Disclosure.Panel className="pl-6 flex justify-center">
                      <div className="text-center w-full">
                        <Disclosure.Button
                          as={Link}
                          to="/predefined-jobs"
                          className={`block py-2 text-base font-medium ${
                            location.pathname === "/predefined-jobs"
                              ? "text-secondary"
                              : "hover:bg-gray-600 hover:text-secondary"
                          }`}
                        >
                          Predefined Jobs
                        </Disclosure.Button>
                        <Disclosure.Button
                          as={Link}
                          to="/messages/canned-messages"
                          className={`block py-2 text-base font-medium ${
                            location.pathname === "/messages/canned-messages"
                              ? "text-secondary"
                              : "hover:bg-gray-600 hover:text-secondary"
                          }`}
                        >
                          Precanned Messages
                        </Disclosure.Button>
                        <Disclosure.Button
                          as={Link}
                          to="/admin/general-settings"
                          className={`block py-2 text-base font-medium ${
                            location.pathname === "/admin/general-settings"
                              ? "text-secondary"
                              : "hover:bg-gray-600 hover:text-secondary"
                          }`}
                        >
                          General Settings
                        </Disclosure.Button>
                        <Disclosure.Button
                          as={Link}
                          to="/admin/certifications"
                          className={`block py-2 text-base font-medium ${
                            location.pathname === "/admin/certifications"
                              ? "text-secondary"
                              : "hover:bg-gray-600 hover:text-secondary"
                          }`}
                        >
                          Certifications
                        </Disclosure.Button>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              {/* Control Panel (Left-aligned with border-l-4) */}
              <Disclosure.Button
                as={Link}
                to="/control-panel"
                className={`block py-2 pl-3 pr-4 text-base font-medium ${
                  location.pathname === "/control-panel"
                    ? "border-l-4 border-gray-300 text-purple-300"
                    : "border-l-4 border-transparent hover:border-gray-300 hover:text-secondary"
                }`}
              >
                Control Panel
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
