import React, { useCallback, useRef } from "react";
import { Modal } from "antd";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useSelector } from "react-redux";
import { darkmode } from "../../store/authSlice";

const ImageCropModal = ({
  showCropModal,
  handleCropSave,
  handleCropCancel,
  imageToCrop,
  crop,
  setCrop,
  completedCrop,
  setCompletedCrop,
}) => {
  const mode = useSelector(darkmode);
  const imageRef = useRef(null);

  const onImageLoad = useCallback((img) => {
    imageRef.current = img;
  }, []);

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  const getCroppedImg = () => {
    if (!imageRef.current || !completedCrop) return;

    const canvas = document.createElement("canvas");
    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
    const ctx = canvas.getContext("2d");

    canvas.width = completedCrop.width;
    canvas.height = completedCrop.height;

    ctx.drawImage(
      imageRef.current,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        const croppedImageUrl = URL.createObjectURL(blob);
        resolve({
          url: croppedImageUrl,
          file: new File([blob], "cropped-image.jpg", { type: "image/jpeg" }),
        });
      }, "image/jpeg");
    });
  };

  const onSave = async () => {
    try {
      const croppedResult = await getCroppedImg();
      if (croppedResult) {
        handleCropSave(croppedResult);
      }
    } catch (e) {
      console.error("Error cropping image:", e);
    }
  };

  return (
    <Modal
      title="Crop Image"
      open={showCropModal}
      onOk={onSave}
      onCancel={handleCropCancel}
      width={700}
      style={{
        maxHeight: "90vh",
        overflow: "auto",
      }}
      bodyStyle={{
        height: "500px",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      footer={[
        <button
          key="cancel"
          onClick={handleCropCancel}
          className="font-bold text-sm mr-2 px-6 py-2 rounded-full bg-gray-300 dark:bg-gray-700 text-gray-900 hover:opacity-80 transition-all"
        >
          Cancel
        </button>,
        <button
          key="submit"
          onClick={onSave}
          className="font-bold text-sm mr-2 px-6 py-2 rounded-full bg-primary-500 dark:bg-darkGray text-white hover:bg-primary-400 transition-all hover:opacity-80"
        >
          Crop & Save
        </button>,
      ]}
    >
      <div
        className={`${
          mode ? "" : "text-white"
        } max-w-full max-h-full overflow-hidden`}
      >
        {imageToCrop && (
          <ReactCrop
            src={imageToCrop}
            crop={crop}
            onChange={(newCrop) => {
              setCrop(newCrop);
            }}
            onComplete={handleCropComplete}
            aspect={1}
            minWidth={50}
            keepSelection={true}
          >
            <img
              ref={onImageLoad}
              src={imageToCrop}
              alt="Crop"
              style={{
                maxWidth: "100%",
                maxHeight: "450px",
                objectFit: "contain",
              }}
              onLoad={(e) => {
                onImageLoad(e.currentTarget);
                const img = e.currentTarget;
                const smallerDimension = Math.min(img.width, img.height) * 0.8;

                setCrop({
                  unit: "px",
                  width: smallerDimension,
                  height: smallerDimension,
                  x: (img.width - smallerDimension) / 2,
                  y: (img.height - smallerDimension) / 2,
                  aspect: 1,
                });
              }}
            />
          </ReactCrop>
        )}
      </div>
    </Modal>
  );
};

export default ImageCropModal;
