import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../components/form/Input";
import Select from "../components/form/Select";
import { Link, useParams } from "react-router-dom";
import PredefinedJob from "../api/PredefinedJob";
import Category from "../api/Category";
import { toast } from "react-hot-toast";
import { DashboardLayout } from "../components/organisms/Layout";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../pages/JobPosition.scss";
import { useSelector } from "react-redux";
import { darkmode } from "../store/authSlice";

const PredefinedJobTemplate = () => {
  const { t } = useTranslation();
  const params = useParams();
  const mode = useSelector(darkmode);

  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const [categories, setCategories] = useState([]);
  const [availability, setAvailability] = useState("");
  const [description, setDescription] = useState("");
  const [experience, setExperience] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    document.title = "HeyHire Portal-Edit Position Template";
    loadCategories();
    loadJob();
  }, []);

  const loadJob = () => {
    setLoading(true);

    PredefinedJob.getById(params.jobId)
      .then((response) => {
        response = response?.data?.data;
        setTitle(response?.title);
        setStartDate(response?.start_date);
        setDescription(response?.description);
        setExperience(response?.experience);
        setAvailability(response?.availability);
        setSelectedCategory(response?.category_id);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(false));
  };

  const loadCategories = () => {
    Category.getAll().then((response) => {
      setCategories(response.data.data);
    });
  };

  const clearFields = () => {
    setTitle("");
    setStartDate(null);
    setDescription("");
    setExperience("");
    setAvailability("");
    setSelectedCategory(-1);
  };

  const updateJob = () => {
    setLoading(true);
    setValidationErrors([]);

    PredefinedJob.update(params.jobId, {
      title: title,
      start_date: startDate,
      availability: availability,
      description: description,
      experience: experience,
      category_id: selectedCategory,
    })
      .then(() => toast.success(t("pre-defined-jobs.pre-defined-job-updated")))
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      })
      .then(() => setLoading(false));
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="flex justify-between items-center">
        <h2 className="font-bold text-xl md:text-2xl">
          <Link to={"/predefined-jobs"}>
            <i className="far fa-chevron-left text-xl mr-2" />
          </Link>{" "}
          {t("pre-defined-jobs.pre-defined-job-template")}
        </h2>

        <button
          onClick={() => {
            setEdit((e) => !e);
          }}
          className="p-2 px-4 md:px-16 rounded-full border-2 border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-white disabled:border-primary-200 disabled:text-primary-200 flex items-center"
        >
          {t("common.edit")}{" "}
          {edit ? (
            <i className="fad fa-lock-open ml-1" />
          ) : (
            <i className="fad fa-lock ml-1" />
          )}
        </button>
      </div>
      <hr className="my-2 border-gray-300" />

      <div className="mt-5">
        <h3 className="font-bold text-base">
          {t("pre-defined-jobs.basic-information")}
        </h3>
        <hr className="mt-2 mb-3" />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <Input
            name="title"
            type="text"
            label={t("pre-defined-jobs.job-title")}
            placeholder={t("pre-defined-jobs.job-title")}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={
              validationErrors && validationErrors.title
                ? validationErrors.title[0]
                : null
            }
            disabled={!edit}
            darmo={true}
          />

          <div>
            <Select
              label={t("common.category")}
              value={selectedCategory}
              name="start_date"
              error={
                validationErrors && validationErrors.category_id
                  ? validationErrors.category_id[0]
                  : null
              }
              onChange={(e) => {
                setSelectedCategory(e.target.value);
              }}
              disabled={!edit}
            >
              {categories.map((category) => {
                return (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                );
              })}
            </Select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-3">
          <div>
            <label className="font-bold text-black dark:text-white">
              Description
            </label>
            <div className="pt-2">
              <ReactQuill
                className={`${mode ? "quill-dark" : "quill-light"}`}
                theme="snow"
                label={t("pre-defined-jobs.description")}
                value={description}
                onChange={setDescription}
                placeholder="Enter position description"
              />
              {validationErrors && validationErrors.description && (
                <div className="text-red-400 text-xs font-bold my-2">
                  {validationErrors.description[0]}
                </div>
              )}
            </div>
          </div>

          <div>
            <label className="font-bold text-gray-900 dark:text-white">
              Requirements
            </label>
            <div className="pt-2">
              <ReactQuill
                className={`${mode ? "quill-dark" : "quill-light"}`}
                theme="snow"
                label="Requirements"
                value={experience}
                onChange={setExperience}
                darmo={mode}
                placeholder="Requirements"
              />
              {validationErrors && validationErrors.experience && (
                <div className="text-red-400 text-xs font-bold my-2">
                  {validationErrors.experience[0]}
                </div>
              )}
            </div>
          </div>
        </div>

        {/*<h3 className="font-bold text-sm mt-4">
          {t("pre-defined-jobs.experience")}
          </h3>*/}
        <hr className="mt-2" />
      </div>

      <div className="mt-12 flex flex-row gap-3">
        <button
          className="w-40 flex justify-center items-center rounded-full bg-white border-2 text-black border-primary-500 py-2 text-sm font-semibold leading-6 hover:text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
          onClick={() => clearFields()}
          disabled={!edit}
        >
          {t("common.clear")}
        </button>

        <button
          className="w-40 flex justify-center items-center rounded-full bg-primary-500 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50 cursor-pointer"
          onClick={updateJob}
          disabled={loading && !edit}
        >
          {t("common.save")}{" "}
          {loading && <i className="fad fa-circle-notch fa-spin ml-2" />}
        </button>
      </div>
    </DashboardLayout>
  );
};

export default PredefinedJobTemplate;
