import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../../../../components/organisms/Layout/Authlayout";
import { useSelector } from "react-redux";
import { darkmode } from "../../../../store/authSlice";

const HiringPlan = () => {
  const navigate = useNavigate();
  const mode = useSelector(darkmode);
  const [isEnabledInfinity, setEnabledInfinity] = useState(true);

  const handleSelectedPlan = (planName) => {
    let plan_id;
    if (planName === "go") {
      plan_id = 1;
    } else if (planName === "unlimited") {
      plan_id = isEnabledInfinity ? 3 : 2;
    }
    const planJson = JSON.stringify({ planName, plan_id });
    localStorage.setItem("selectedPlan", planJson);
    localStorage.removeItem("registerStep0");
    localStorage.removeItem("registerStep1");
    localStorage.removeItem("registerStep2");
    navigate("/register?step=1");
  };

  const heyhireLiteList = [
    "1 Position",
    "1 User",
    "Unlimited Applicants",
    "Unlimited Hires",
    "Calendar Integration",
  ];

  const heyhireUnlimitedList = [
    "Unlimited Positions",
    "Unlimited Applicants",
    "Custom Jobs Page",
    "Calendar Integration",
    "Customer Success Manager",
    "Unlimited Users",
    "Unlimited Hires",
    "Map Placement",
    "Onboarding Integration",
    "White Glove Onboarding*",
  ];

  return (
    <AuthLayout restrictSwitch={false}>
      <div className="flex flex-1 flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full rounded mx-4 h-full">
          <div className="w-full h-full">
            <div
              className={`sm:mx-auto sm:w-full flex flex-col justify-center h-full ${
                mode ? "text-black" : "text-white"
              }`}
            >
              <div className="sm:mx-auto w-full">
                <div className="flex flex-col md:flex-row gap-4 lg:gap-6 justify-center sm:rounded-lg lg:px-6">
                  {/* Unlimited Plan */}
                  <div
                    className={`w-full md:w-[600px] mt-4 border-2 md:border-0 dark:border-darkGray rounded-md md:rounded-none md:shadow-none ${
                      mode ? "bg-[#F6F6F6]" : "bg-slate-800"
                    }`}
                  >
                    <div className="m-4 lg:m-6 min-w-[180px] lg:min-w-[230px] flex flex-col h-full">
                      <div>
                        <div className="flex flex-col">
                          <p className="font-arial text-sm mb-3 text-gray-400 dark:text-gray-500">
                            Unlimited Plan
                          </p>

                          <div className="flex justify-between w-full">
                            <div>
                              <p className="text-xl md:text-4xl lg:text-5xl my-1 lg:my-2 flex">
                                <span className="font-arial">
                                  {isEnabledInfinity ? "$119" : "$149"}
                                </span>
                                <span className="text-xs md:text-sm lg:text-2xl font-arial mt-auto ml-2">
                                  /mo per location
                                </span>
                              </p>
                              <div className="text-xs md:text-sm w-fit my-3 text-gray-400 font-italic">
                                ($149 if billed monthly)
                              </div>
                            </div>
                            <div className="flex items-start">
                              <button
                                onClick={() => setEnabledInfinity(false)}
                                className={`${
                                  !isEnabledInfinity
                                    ? "border border-primary-500 bg-primary-500 dark:bg-warmgray-400 text-white dark:text-black"
                                    : "text-black dark:text-white border border-primary-500 dark:border-warmgray-400"
                                } w-fit lg:w-1/2 rounded-full px-6 py-2`}
                              >
                                Monthly
                              </button>
                              <button
                                onClick={() => setEnabledInfinity(true)}
                                className={`${
                                  isEnabledInfinity
                                    ? "border border-primary-500 bg-primary-500 dark:bg-warmgray-400 text-white dark:text-black"
                                    : "text-black dark:text-white border border-primary-500 dark:border-warmgray-400"
                                } w-fit lg:w-1/2 rounded-full px-6 py-2`}
                              >
                                Annual
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="h-auto flex flex-col w-full my-4 overflow-y-auto">
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              {heyhireUnlimitedList
                                ?.slice(0, 5)
                                .map((item, index) => (
                                  <React.Fragment key={index}>
                                    <div className="w-full my-2.5">
                                      <i className="fas fa-check-circle text-[#AA73A6] mr-1" />
                                      <span className="font-arial text-sm lg:text-[14px] dark:text-gray-500">
                                        {item}
                                      </span>
                                    </div>
                                    {index < 4 && (
                                      <hr className="w-full border-1 border-black dark:border-warmgray-500" />
                                    )}
                                  </React.Fragment>
                                ))}
                            </div>
                            <div>
                              {heyhireUnlimitedList
                                ?.slice(5, 10)
                                .map((item, index) => (
                                  <React.Fragment key={index}>
                                    <div className="w-full my-2.5">
                                      <i className="fas fa-check-circle text-[#AA73A6] mr-1" />
                                      <span className="font-arial text-sm lg:text-[14px] dark:text-gray-500">
                                        {item}
                                      </span>
                                    </div>
                                    {index < 4 && (
                                      <hr className="w-full border-1 border-black dark:border-warmgray-500" />
                                    )}
                                  </React.Fragment>
                                ))}
                            </div>
                          </div>
                        </div>
                        <div className="h-24 text-gray-400">
                          *For annual billing
                        </div>
                      </div>
                      <div className="mt-4">
                        <button
                          className="border dark:border-warmgray-500 rounded-full cursor-pointer bg-primary-500 dark:bg-transparent text-white py-4 w-full hover:bg-white dark:hover:bg-warmgray-400 hover:text-black hover:border-black"
                          onClick={() => handleSelectedPlan("unlimited")}
                        >
                          Choose plan
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Lite Plan */}
                  <div
                    className={`w-full md:w-[340px] mt-4 border-2 dark:border-darkGray md:border-0 rounded-md md:rounded-none md:shadow-none ${
                      mode ? "bg-[#F6F6F6]" : "bg-slate-800"
                    }`}
                  >
                    <div className="m-4 lg:m-6 min-w-[180px] lg:min-w-[230px] flex flex-col h-full">
                      <div>
                        <div className="flex flex-col">
                          <p className="font-arial text-sm mb-3 text-gray-400 dark:text-gray-500">
                            Lite Plan
                          </p>
                          <div>
                            <p className="text-xl md:text-4xl lg:text-5xl my-1 lg:my-2 flex">
                              <span className="font-arial">$69</span>
                              <span className="text-xs md:text-sm lg:text-2xl font-arial mt-auto ml-2">
                                /mo per location
                              </span>
                            </p>
                            <div className="text-xs md:text-sm w-fit my-3 text-gray-400 font-italic">
                              Month-to-month pricing only
                            </div>
                          </div>
                        </div>
                        <div className="h-auto flex flex-col w-full my-4 overflow-y-auto">
                          {heyhireLiteList?.map((item, index) => (
                            <React.Fragment key={index}>
                              <div className="w-full my-2.5">
                                <i className="fas fa-check-circle text-[#AA73A6] mr-1" />
                                <span className="font-arial text-sm lg:text-[14px] dark:text-gray-500">
                                  {item}
                                </span>
                              </div>
                              {index < heyhireLiteList?.length - 1 && (
                                <hr className="w-full border-1 border-black dark:border-warmgray-500" />
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                        <div className="h-24"></div>
                      </div>

                      <div className="mt-4">
                        <button
                          className="border dark:border-warmgray-500 rounded-full cursor-pointer bg-primary-500 dark:bg-transparent text-white py-4 w-full hover:bg-white dark:hover:bg-warmgray-400 hover:text-black hover:border-black"
                          onClick={() => handleSelectedPlan("go")}
                        >
                          Choose plan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center mt-8">
                <div
                  className={`title text-sm text-center ${
                    mode ? "text-black" : "text-primary-100"
                  }`}
                >
                  By registering, you are agreeing to the
                  <Link
                    to="https://www.heyhire.com/terms-conditions"
                    target="_blank"
                    className={`${
                      mode
                        ? "text-indigo-600 hover:text-indigo-500 "
                        : "text-secondary hover:text-white"
                    } font-semibold ml-1`}
                  >
                    Terms and Conditions
                  </Link>{" "}
                  and
                  <Link
                    to="https://www.heyhire.com/privacy"
                    target="_blank"
                    className={`ml-1 ${
                      mode
                        ? "text-indigo-600 hover:text-indigo-500 "
                        : "text-secondary hover:text-white"
                    } font-semibold`}
                  >
                    Privacy Policy
                  </Link>
                </div>

                <div
                  className={`text-sm text-center mt-2 mb-8 ${
                    mode ? "text-black" : "text-white"
                  }`}
                >
                  Already registered?
                  <Link
                    to="/login"
                    className={`${
                      mode
                        ? "text-indigo-600 hover:text-indigo-500 "
                        : "text-secondary hover:text-white"
                    } font-semibold ml-1`}
                  >
                    Click here
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default HiringPlan;
