import React from "react";
import { t } from "i18next";
import FormTextarea from "../../../../components/atoms/FormTextarea/FormTextarea";
import { useEffect } from "react";
import { useSettingsContext } from "../SettingsContext";
import ReactImageUploading from "react-images-uploading";
import { useState } from "react";
import FloatingInputText from "../../../../components/form/FloatingInputText";
import GoogleLocations from "../../../../components/google/GoogleLocations/GoogleLocations";
import MultiSelector from "../../../../components/atoms/Selector/MultiSelector";
import Category from "../../../../api/Category";
import PredefinedJob from "../../../../api/PredefinedJob";
import QRCodeDisplayModal from "../../../../components/molecules/ORCodeDisplayModal/QRCodeDisplayModal";
import { saveAs } from "file-saver";
import FormWarningWithButton from "../../../../components/atoms/Notification/FormWarningWithButton";
import FormSuccessWithButton from "../../../../components/atoms/Notification/FormSuccessWithButton";
import GradientPrimaryButton from "../../../../components/atoms/Buttons/GradientPrimaryButton";

const TurnKeyDetails = ({ setTurnKeyObjectEntry, error = false }) => {
  const {
    company,
    setCompanyName,
    businessType,
    turnKeyObject,
    setTurnKeyObject,
    scrollUpRef,
    imageUploadFile,
    setImageUploadFile,
    existingTurnKeyAccount,
    turnKeyAccountId,
    setTurnKeyAccountId,
  } = useSettingsContext();
  const [images, setImages] = useState([]);
  // const [brand, setBrand] = useState(null);
  const brand = null;
  const [turnKeyCompanyName, setTurnKeyCompanyName] = useState(
    company.companyName
  );
  const [turnKeyLocationName, setTurnKeyLocationName] = useState([]);
  const [turnKeyLocationCategory, setTurnKeyLocationCategory] = useState([]);
  const [turnKeyLocationUrlSlug, setTurnKeyLocationUrlSlug] = useState([]);
  const [turnKeyBrandUrlSlug, setTurnKeyBrandUrlSlug] = useState([]);
  const [turnKeyBrandName, setTurnKeyBrandName] = useState([]);
  const [turnKeyWebsite, setTurnKeyWebsite] = useState([]);
  const [turnKeyBrandDescription, setTurnKeyBrandDescription] = useState([]);
  const [turnKeyPositions, setTurnKeyPositions] = useState([]);
  const [turnKeyCity, setTurnKeyCity] = useState([]);
  const [turnKeyState, setTurnKeyState] = useState([]);
  const [turnKeyZip, setTurnKeyZip] = useState([]);
  // const [turnKeyLat, setTurnKeyLat] = useState([]);
  // const [turnKeyLng, setTurnKeyLng] = useState([]);
  const [turnKeyPredefinedTitle, setTurnKeyPredefinedTitle] = useState([]);
  const [turnKeyPredefinedDescription, setTurnKeyPredefinedDescription] =
    useState([]);
  const [turnKeyPredefinedExperience, setTurnKeyPredefinedExperience] =
    useState([]);
  const [manualAddress, setManualAddress] = useState(false);
  const [isAddressDescription, setAddressDescription] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [uploadExists, setUploadExists] = useState(false);
  const [isQuickAddPositionVisible, setIsQuickAddPositionVisible] =
    useState(false);
  const [positionList, setPositionList] = useState([]);
  const [accountStatus, setAccountStatus] = useState(0);
  const [statusText, setStatusText] = useState("Not Yet Activated");
  const [statusStyle, setStatusStyle] = useState([]);
  const [email, setEmail] = useState("");
  const [activationCode, setActivationCode] = useState("");
  const [showAddPositionButton, setShowAddPositionButton] = useState(false);
  const [positionRefresh, setPositionRefresh] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [predefinedValidation, setPredefinedValidation] = useState(false);
  const [positionAddedNotification, setPositionAddedNotification] =
    useState(false);
  const [selectedCategory, setSelectedCategory] = useState(true);
  const [selectedPositions, setSelectedPositions] = useState(true);
  const [brandLogoUUID, setBrandLogoUUID] = useState(false);

  const QRURL =
    "https://api.qrserver.com/v1/create-qr-code/?size=600x600&&color=000000&data=" +
    process.env.REACT_APP_URL +
    "/easy-login/";

  useEffect(() => {
    scrollUpRef.current.scrollIntoView();
    getLocationCategory();
    getPositionList();
    setCompanyName({ companyName: company.companyName });
    setTurnKeyObject((prevState) => ({
      ...prevState,
      turnKeyCompanyName: company.companyName,
    }));

    setAccountStatus(0);
    setStatusText("Not Yet Activated");
    setStatusStyle({
      style: "border-red-500 hover:border-red-600",
      background: "bg-red-500",
    });

    if (!turnKeyAccountId) {
      setTurnKeyObjectEntry(turnKeyObject, imageUploadFile, brandLogoUUID);
    }

    if (turnKeyAccountId) {
      setTurnKeyAccountId(turnKeyAccountId);
      const parseAccountDetails = existingTurnKeyAccount;
      Object.keys(parseAccountDetails).map((keyName, i) => {
        const payload = JSON.parse(parseAccountDetails[i].payload);
        if (parseAccountDetails[i].id === turnKeyAccountId) {
          setTurnKeyCompanyName(payload["turnKeyCompanyName"]);
          collectTurnKeyData(
            "turnKeyCompanyName",
            payload["turnKeyCompanyName"]
          );

          setTurnKeyLocationName(payload["turnKeyLocationName"]);
          collectTurnKeyData(
            "turnKeyLocationName",
            payload["turnKeyLocationName"]
          );
          recordDataToTurnKeyObject("turnKeyLat", payload["turnKeyLat"]);
          recordDataToTurnKeyObject("turnKeyLng", payload["turnKeyLng"]);

          setTurnKeyLocationCategory(payload["turnKeyLocationCategory"]);
          captureBusinessCategory(payload["turnKeyLocationCategory"], true);

          setTurnKeyLocationUrlSlug(payload["turnKeyLocationUrlSlug"]);
          collectTurnKeyData(
            "turnKeyLocationUrlSlug",
            payload["turnKeyLocationUrlSlug"]
          );

          setTurnKeyBrandName(payload["turnKeyBrandName"]);
          collectTurnKeyData("turnKeyBrandName", payload["turnKeyBrandName"]);

          setTurnKeyPositions(payload["positions"]);
          recordDataToTurnKeyObject("positions", payload["positions"]);

          setTurnKeyBrandUrlSlug(payload["turnKeyBrandUrlSlug"]);
          collectTurnKeyData(
            "turnKeyBrandUrlSlug",
            payload["turnKeyBrandUrlSlug"]
          );

          setTurnKeyWebsite(payload["turnKeyWebsite"]);
          collectTurnKeyData("turnKeyWebsite", payload["turnKeyWebsite"]);

          captureBrandDescription(payload["turnKeyBrandDescription"]);

          setTurnKeyCity(payload["turnKeyCity"]);
          collectTurnKeyData("turnKeyCity", payload["turnKeyCity"]);

          setTurnKeyState(payload["turnKeyState"]);
          collectTurnKeyData("turnKeyState", payload["turnKeyState"]);

          setTurnKeyZip(payload["turnKeyZip"]);
          collectTurnKeyData("turnKeyZip", payload["turnKeyZip"]);

          setTurnKeyPredefinedTitle(payload["turnKeyPredefinedTitle"]);
          setTurnKeyPredefinedExperience(
            payload["turnKeyPredefinedExperience"]
          );
          setTurnKeyPredefinedDescription(
            payload["turnKeyPredefinedDescription"]
          );
          setImages(payload["brandLogoUrl"]);
          setActivationCode(parseAccountDetails[i].activation_code);

          if (payload["status"] === 2) {
            setAccountStatus(2);
            setStatusText("Account Activated");
            setStatusStyle({
              style: "border-emerald-500 hover:border-emerald-600",
              background: "bg-emerald-500",
            });
            setEmail(payload["email"]);
          } else if (payload["status"] === 1) {
            setAccountStatus(1);
            setStatusText("Account Pending");
            setStatusStyle({
              style: "border-orange-500 hover:border-orange-600",
              background: "bg-orange-500",
            });
            setEmail(payload["email"]);
          }
          setBrandLogoUUID(true);
          setTurnKeyObjectEntry(turnKeyObject, images, true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (images && images.length === 0) {
      setUploadExists(false);
    } else {
      setUploadExists(true);
    }
    setTurnKeyObjectEntry(turnKeyObject, imageUploadFile, false);
  }, [images]);

  useEffect(() => {
    getPositionList();
    setTurnKeyObjectEntry(turnKeyObject, imageUploadFile, brandLogoUUID);
  }, [refresh, positionRefresh]);

  // useEffect(() => {
  //   console.log("OBJECT : " + JSON.stringify(turnKeyObject));
  //   console.log("Images Files :" + JSON.stringify(imageUploadFile));
  // }, [turnKeyObject, imageUploadFile]);

  useEffect(() => {
    if (
      turnKeyPredefinedDescription !== "" &&
      turnKeyPredefinedTitle !== "" &&
      turnKeyPredefinedExperience !== ""
    ) {
      setPredefinedValidation(true);
    } else {
      setPredefinedValidation(false);
    }
  }, [
    turnKeyPredefinedTitle,
    turnKeyPredefinedExperience,
    turnKeyPredefinedDescription,
  ]);

  const getLocationCategory = async () => {
    let catData = [];
    const categories = await Category.getAll();
    const c = categories.data.data;
    for (let i = 0; i < c.length; i++) {
      const el = c[i];
      catData.push({
        label: el.name,
        value: el.id,
      });
    }
    setCategoriesList(catData);
    setRefresh(true);
  };

  const getPositionList = async () => {
    let positionData = [];
    const allPreDefinedPositions = await PredefinedJob.getAllTitle();

    const c = allPreDefinedPositions.data.data;
    for (let i = 0; i < c.length; i++) {
      const el = c[i];
      positionData.push({
        label: el.title,
        value: el.id,
      });
    }
    setPositionList(positionData);
    setPositionRefresh(true);
  };

  const collectTurnKeyData = (key, value) => {
    if (key === "turnKeyCompanyName") {
      setTurnKeyCompanyName(value);
      setCompanyName({ companyName: value });
    }

    if (key === "turnKeyLocationName") {
      const splitValue = value.split(",");
      setTurnKeyLocationName(splitValue[0]);
    }

    if (key === "turnKeyLocationCategory") {
      setTurnKeyLocationCategory(value);
    }

    if (key === "turnKeyLocationUrlSlug") {
      setTurnKeyLocationUrlSlug(value);
    }

    if (key === "turnKeyBrandName") {
      setTurnKeyBrandName(value);
    }

    if (key === "turnKeyBrandUrlSlug") {
      setTurnKeyBrandUrlSlug(value);
    }

    if (key === "turnKeyWebsite") {
      setTurnKeyWebsite(value);
    }

    if (key === "turnKeyCity") {
      setTurnKeyCity(value);
    }
    if (key === "turnKeyState") {
      setTurnKeyState(value);
    }
    if (key === "turnKeyZip") {
      setTurnKeyZip(value);
    }

    if (key === "turnKeyPredefinedTitle") {
      setTurnKeyPredefinedTitle(value);
    }

    if (key === "turnKeyLocationName") {
      const splitValue = value.split(",");
      recordDataToTurnKeyObject(key, splitValue[0]);
    } else {
      recordDataToTurnKeyObject(key, value);
    }
  };

  const positionSelectionHandler = (event, flag) => {
    let fetchPositionDetailsFromId = "";
    let length = event.length;

    setSelectedPositions(false);

    if (length === 0) {
      setTurnKeyPredefinedTitle("");
      setTurnKeyPredefinedDescription("");
      setTurnKeyPredefinedExperience("");
      recordDataToTurnKeyObject("positions", event);
      return;
    } else {
      fetchPositionDetailsFromId = event[length - 1].value;
    }

    recordDataToTurnKeyObject("positions", event);

    PredefinedJob.getById(fetchPositionDetailsFromId)
      .then((response) => {
        setTurnKeyPredefinedTitle(response.data.data.title);
        setTurnKeyPredefinedDescription(response.data.data.description);
        setTurnKeyPredefinedExperience(response.data.data.experience);
      })
      .catch((error) => {
        console.log("cannot fetch the predefined job with id:" + event);
      });

    setTurnKeyObjectEntry(turnKeyObject, imageUploadFile, brandLogoUUID);
  };

  const addNewPredefinedPositions = () => {
    const newPredefinedPositionData = {
      location_id: 0,
      category_id: 0,
      title: turnKeyPredefinedTitle,
      description: turnKeyPredefinedDescription,
      experience: turnKeyPredefinedExperience,
      availability: "full-time",
    };

    PredefinedJob.add(newPredefinedPositionData)
      .then((response) => {
        setTurnKeyPredefinedTitle("");
        setTurnKeyPredefinedDescription("");
        setTurnKeyPredefinedExperience("");
        setPositionRefresh(!positionRefresh);
        setPositionAddedNotification(true);
      })
      .catch((error) => {
        console.log("cannot fetch the predefined job with id:" + error);
      });
  };

  const captureBrandDescription = (description) => {
    setTurnKeyBrandDescription(description);
    recordDataToTurnKeyObject("turnKeyBrandDescription", description);
  };

  const recordDataToTurnKeyObject = (key, value) => {
    setTurnKeyObject((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const collectImage = (imageList) => {
    setImageUploadFile(imageList);
    setTurnKeyObjectEntry(turnKeyObject, imageList, brandLogoUUID);
  };

  const locationCheck = (e) => {
    if (e.target.value === "") {
      setAddressDescription(false);
    }
  };

  const captureManualEntry = (LocationCapture) => {
    const splitLocationName = LocationCapture.split(",");
    setTurnKeyLocationName(splitLocationName[0]);
    recordDataToTurnKeyObject("turnKeyLocationName", splitLocationName[0]);
  };

  const handleLocationInput = (addressLine, location) => {
    let addressComponents = location[0].address_components;
    const locationSpecifics = location[0].geometry.location;

    // setTurnKeyLat(locationSpecifics.lat());
    // setTurnKeyLng(locationSpecifics.lng());

    const collectData = addressComponents.map((locationComponent) => {
      const types = locationComponent.types;

      if (types[0] === "locality") {
        setTurnKeyCity(locationComponent.long_name);
        recordDataToTurnKeyObject("turnKeyCity", locationComponent.long_name);
      }

      if (types[0] === "administrative_area_level_1") {
        setTurnKeyState(locationComponent.long_name);
        recordDataToTurnKeyObject("turnKeyState", locationComponent.long_name);
      }

      if (types[0] === "postal_code") {
        setTurnKeyZip(locationComponent.long_name);
        recordDataToTurnKeyObject("turnKeyZip", locationComponent.long_name);
      }
    });

    recordDataToTurnKeyObject("turnKeyLat", locationSpecifics.lat());
    recordDataToTurnKeyObject("turnKeyLng", locationSpecifics.lng());

    const splitAddress = addressLine.split(",");
    recordDataToTurnKeyObject("turnKeyLocationName", splitAddress[0]);
  };

  const showQuickAddPositionForm = () => {
    setShowAddPositionButton(false);
    setPositionAddedNotification(false);
    setIsQuickAddPositionVisible(!isQuickAddPositionVisible);
  };

  const enterNewPosition = () => {
    setShowAddPositionButton(true);
    setIsQuickAddPositionVisible(false);
    setTurnKeyPredefinedTitle("");
    setTurnKeyPredefinedDescription("");
    setTurnKeyPredefinedExperience("");
  };

  const handleSavePredefinedPosition = () => {
    if (
      turnKeyBrandDescription !== "" &&
      turnKeyPredefinedTitle !== "" &&
      turnKeyPredefinedExperience !== ""
    ) {
      addNewPredefinedPositions();
    }
  };

  const captureBusinessCategory = (id, flag) => {
    setTurnKeyLocationCategory(id);
    recordDataToTurnKeyObject("turnKeyLocationCategory", id);

    if (flag !== true) {
      setSelectedCategory(false);
    }
  };

  const capturePositionDescription = (description) => {
    setTurnKeyPredefinedDescription(description);
    recordDataToTurnKeyObject("turnKeyPredefinedDescription", description);
  };

  const capturePositionExperience = (experience) => {
    setTurnKeyPredefinedExperience(experience);
    recordDataToTurnKeyObject("turnKeyPredefinedExperience", experience);
  };

  const downloadQR = (imageUrl) => {
    saveAs(QRURL + activationCode, turnKeyCompanyName + "qr_code.jpg");
  };

  const handleAnswerChange = (e) => {
    setTurnKeyObjectEntry(turnKeyObject, imageUploadFile, brandLogoUUID);
  };

  return (
    <div
      onKeyUp={handleAnswerChange}
      onClick={handleAnswerChange}
      className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2 bg-gradient-to-b from-warmgray-100 to-white"
    >
      <FormSuccessWithButton
        status={!turnKeyAccountId && !error}
        title="Nice!"
        content="Data is sufficient"
      />

      <FormWarningWithButton
        status={!turnKeyAccountId && error}
        title="Info required"
        content="This notification will go away once the data looks good."
      />
      <h4 class="font-semibold text-gray-500 dark:text-gray-400 pb-3 pt-10">
        {businessType.businessName} Details
        {/* <span>
          <small class="ml-2 font-semibold text-gray-500 dark:text-gray-400">
            {turnKeyCompanyName && <span> - {turnKeyCompanyName}</span>}
          </small>
        </span> */}
      </h4>
      {turnKeyAccountId && (
        <p className="text-emerald-600 font-bold text-xs pb-5">
          NOTE: If the account is already activated. Edit will not be applied.
        </p>
      )}
      {!turnKeyAccountId && (
        <p className="text-red-500 font-bold text-xs pb-5 notes">
          NOTE: All fields are required.
        </p>
      )}
      {email && (
        <div className="font-bold text-primary-500">Email: {email}</div>
      )}

      {turnKeyAccountId && (
        <div className="-mx-3 md:flex mb-6">
          <div className="relative left-[5%] md:left-[40%] lg:left-[50%] md:w-1/2 px-3 mb-6 md:mb-0">
            <button
              class={`px-6 py-3 w-[150px] h-[40px] 
                disabled text-[10px] float-right font-bold transition-colors duration-300  rounded-md 
                text-black border-2 
                ${statusStyle.style}`}
            >
              {statusText}
              <span class="relative bottom-9 left-[115px] z-10 flex h-3 w-3">
                <span
                  class={`animate-ping absolute inline-flex h-full w-full rounded-full ${statusStyle.background}  opacity-75`}
                ></span>
                <span
                  class={`relative inline-flex rounded-full h-3 w-3 ${statusStyle.background} `}
                ></span>
              </span>
            </button>
          </div>
        </div>
      )}

      <div className="-mx-3 md:flex mb-6">
        <div className="md:w-1/2 px-3 mb-6 md:mb-0">
          <FloatingInputText
            className="my-3"
            id="companyName"
            name="name"
            value={turnKeyCompanyName}
            label={t("settings.company-name")}
            placeholder="Enter name"
            type="text"
            onChange={(e) =>
              collectTurnKeyData("turnKeyCompanyName", e.target.value)
            }
          />
        </div>
        <div className="md:w-1/2 px-3">
          <FloatingInputText
            id="website"
            value={turnKeyWebsite}
            className="my-3"
            name="name"
            label="Website"
            placeholder="Example https://www.heyhire.com/"
            type="text"
            onChange={(e) =>
              collectTurnKeyData("turnKeyWebsite", e.target.value)
            }
          />
        </div>
      </div>
      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

      <div className="-mx-3 md:flex mb-6">
        <div className="md:w-1/2 px-3 mb-6 md:mb-0">
          <GoogleLocations
            label={`Location ${manualAddress ? t("common.address") : "Name"}`}
            placeholder={
              !manualAddress && "Type in to see some suggestions . . ."
            }
            isAddressSearch={manualAddress}
            setAddressCheck={locationCheck}
            getCompleteLocation={handleLocationInput}
            captureManualEntry={captureManualEntry}
            pValue={turnKeyLocationName}
          />
        </div>

        <div className="md:w-1/2 px-3 pt-10">
          <MultiSelector
            selectedValues={
              turnKeyAccountId && selectedCategory
                ? turnKeyLocationCategory
                : false
            }
            isSearchable={true}
            refresh={refresh}
            placeholder="Select Business Catergory"
            dropDownData={categoriesList}
            disabled={""}
            callBackHandler={captureBusinessCategory}
          />
        </div>
      </div>

      <div className="-mx-3 md:flex mb-6">
        <div className="md:w-1/2 px-3">
          <FloatingInputText
            className="my-3"
            id="LocationSlug"
            placeholder="Enter url"
            name="name"
            value={turnKeyLocationUrlSlug}
            label="Location url slug"
            type="text"
            onChange={(e) =>
              collectTurnKeyData("turnKeyLocationUrlSlug", e.target.value)
            }
          />
        </div>
      </div>
      <div className="-mx-3 md:flex mb-2">
        <div className="md:w-1/2 px-3 mb-6 md:mb-0">
          <FloatingInputText
            className="my-3"
            id="city"
            placeholder="City"
            name="name"
            value={turnKeyCity}
            label="City"
            type="text"
            onChange={(e) => collectTurnKeyData("turnKeyCity", e.target.value)}
          />
        </div>
        <div className="md:w-1/2 px-3">
          <FloatingInputText
            className="my-3"
            id="state"
            placeholder="State"
            value={turnKeyState}
            name="name"
            label="State"
            type="text"
            onChange={(e) => collectTurnKeyData("turnKeyState", e.target.value)}
          />
        </div>
        <div class="md:w-1/2 px-3">
          <FloatingInputText
            className="my-3"
            id="Zip"
            placeholder="Zip"
            value={turnKeyZip}
            name="name"
            label="Zip code"
            type="text"
            onChange={(e) => collectTurnKeyData("turnKeyZip", e.target.value)}
          />
        </div>
      </div>
      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

      <div className="-mx-3 md:flex mb-6">
        <div className="md:w-1/2 px-3 mb-6 md:mb-0">
          <FloatingInputText
            className="my-3"
            id="brandName"
            name="name"
            label="Brand Name"
            value={turnKeyBrandName}
            placeholder="Enter Brand Name"
            type="text"
            onChange={(e) =>
              collectTurnKeyData("turnKeyBrandName", e.target.value)
            }
          />
        </div>
        <div className="md:w-1/2 px-3">
          <FloatingInputText
            className="my-3"
            id="brandSlug"
            value={turnKeyBrandUrlSlug}
            placeholder="Enter Brand"
            name="name"
            label="Brand url slug"
            type="text"
            onChange={(e) =>
              collectTurnKeyData("turnKeyBrandUrlSlug", e.target.value)
            }
          />
        </div>
      </div>
      <div className="-mx-3 md:flex mb-6">
        <div className="md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            className="block font-semibold text-gray-400 mb-2"
            htmlFor="grid-city"
          >
            Brand Description
          </label>
          <FormTextarea
            placeholder="Brief description of your brand . . ."
            callBack={captureBrandDescription}
            value={turnKeyBrandDescription}
          />
        </div>
      </div>

      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

      <div className="-mx-3 md:flex mb-6 pl-3">
        <button
          className="xs:pb-3 sm:mr-5 md:mr-5 lg:mr-5 w-fit rounded-md  px-1.5 pt-[10px] pb-[9px] cursor-pointer  py-2 border-primary-400 border-2 font-semibold text-black transition duration-200 ease-in-out hover:-translate-y-1 hover:scale-105 hover:bg-primary-400 disabled:opacity-70 shadow-md hover:text-white"
          onClick={showQuickAddPositionForm}
        >
          Quick Menu: Add Position
        </button>
        <button
          className="w-fit rounded-md  px-1.5 pt-[10px] pb-[9px] cursor-pointer  py-2 border-primary-400 border-2 font-semibold text-black transition duration-200 ease-in-out hover:-translate-y-1 hover:scale-105 hover:bg-primary-400 disabled:opacity-70 shadow-md hover:text-white"
          onClick={enterNewPosition}
        >
          Enter New Position
        </button>
      </div>

      <div className="-mx-3 md:flex mb-6">
        <div
          className={`md:w-1/2 h-full px-3 mb-6 md:mb-0 ${
            isQuickAddPositionVisible ? "" : "hidden"
          } animate-fade-in-from-top-.5s`}
        >
          <MultiSelector
            selectedValues={
              turnKeyAccountId && selectedPositions ? turnKeyPositions : false
            }
            isMultiSelect={true}
            refresh={positionRefresh}
            placeholder="Select position title"
            dropDownData={positionList}
            disabled={""}
            callBackHandler={positionSelectionHandler}
          />
        </div>
      </div>
      <div className="-mx-3 md:flex mb-6">
        <div className="md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            className={`
            ${showAddPositionButton ? "" : "hidden"}
            relative bottom-5 text-primary-500 font-semibold
            transition-all animate-fade-in-from-top-.5s`}
          >
            <span className="font-extrabold text-black">Instructions</span>:
            Fill in the position title, description and experience. Press 'add
            position' button to add to the quick menu drop down.
          </label>
          <FormSuccessWithButton
            status={positionAddedNotification}
            title="Position Added!"
            content="Check the quick menu"
          />
          <FloatingInputText
            disabled={!showAddPositionButton}
            className="my-3"
            id="PositionTitle"
            name="name"
            value={turnKeyPredefinedTitle}
            label="Position Title"
            placeholder="Enter title"
            type="text"
            onChange={(e) =>
              collectTurnKeyData("turnKeyPredefinedTitle", e.target.value)
            }
          />
        </div>
      </div>
      <div className="-mx-3 md:flex mb-6">
        <div className="md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            className="block font-semibold text-gray-400 mb-2"
            htmlFor="grid-city"
          >
            Position Description
          </label>
          <FormTextarea
            disabled={!showAddPositionButton}
            placeholder="Brief description about the position. . ."
            callBack={capturePositionDescription}
            value={turnKeyPredefinedDescription}
          />
        </div>
        <div className="md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            className="block font-semibold text-gray-400 mb-2"
            htmlFor="grid-city"
          >
            Experience
          </label>

          <FormTextarea
            disabled={!showAddPositionButton}
            placeholder="Experience . . ."
            callBack={capturePositionExperience}
            value={turnKeyPredefinedExperience}
          />
        </div>
      </div>
      <div className="-mx-3 md:flex mb-6">
        <div
          className={` 
        ${showAddPositionButton ? "" : "hidden"}
        md:w-1/2 px-3 mb-6 md:mb-0 `}
        >
          <GradientPrimaryButton
            grayScale={!predefinedValidation}
            disabled={!predefinedValidation}
            name="Add Position"
            onClick={handleSavePredefinedPosition}
          />
        </div>
      </div>
      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

      <div className="-mx-3 md:flex mb-6">
        <div className="md:w-1/2 px-3 mb-6 md:mb-0 border-2 border-dashed border-gray-500">
          <ReactImageUploading
            value={images}
            onChange={(imageList, index) => {
              setImages(imageList);
              collectImage(imageList);
            }}
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              <div className="flex mt-4">
                <div className="h-28 w-28 bg-warmgray-200 selfcenter flex justify-center mb-3">
                  {turnKeyAccountId && (
                    <img
                      alt=""
                      className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                      src={images}
                    />
                  )}
                  {images && imageList && imageList.length > 0 ? (
                    <img
                      className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                      src={imageList[0]?.dataURL}
                      alt=""
                    />
                  ) : brand && brand.photo ? (
                    <img
                      className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                      src={brand?.photo?.thumb_url}
                      alt=""
                    />
                  ) : (
                    <i
                      className="text-white text-3xl self-center fa fa-plus cursor-pointer"
                      onClick={onImageUpload}
                    />
                  )}
                </div>

                {!uploadExists ? (
                  <button
                    className="ml-4 rounded px-4 py-2 border-2 border-primary-400 hover:border-primary-400 hover:bg-primary-400 transition duration-200 ease-in-out hover:-translate-y-1 hover:scale-105 hover:text-white text-black font-semibold cursor-pointer self-center disabled:bg-primary-300"
                    onClick={onImageUpload}
                  >
                    Upload Brand Logo
                  </button>
                ) : (
                  <button
                    className="ml-4 rounded px-4 py-2 border-2 border-primary-400 hover:border-primary-400 hover:bg-primary-100 transition duration-200 ease-in-out hover:-translate-y-1 hover:scale-105 hover:text-gray-800 text-black font-semibold cursor-pointer self-center disabled:bg-primary-300"
                    onClick={onImageRemoveAll}
                  >
                    Delete Logo
                  </button>
                )}
              </div>
            )}
          </ReactImageUploading>
        </div>
      </div>

      <FormSuccessWithButton
        status={!turnKeyAccountId && !error}
        title="Nice!"
        content="Data is sufficient"
      />
      <FormWarningWithButton
        status={!turnKeyAccountId && error}
        title="Info required"
        content="This notification will go away once the data looks good."
      />
      {turnKeyAccountId && (
        <div className="-mx-3 md:flex mb-6 h-full w-full">
          <div className="px-3 mb-6 md:mb-0 border-2 border-dashed border-gray-500 w-full">
            <div className="relative ml-0 pb-10 ">
              <div className="container py-10 px-10 mx-0 min-w-full flex flex-col items-center">
                <h2 className="text-5xl mb-3 text-black">QR Code</h2>
                <p className="text-black">with the activation link</p>
                <QRCodeDisplayModal
                  header={company.companyName}
                  title=""
                  buttonDisplay={false}
                  QRLink={QRURL + activationCode}
                />
                <p className="pb-10 font-bold text-lg">
                  Activation Code: {activationCode}
                </p>
                <button
                  className="w-[150px] rounded-lg px-1.5 pt-[10px] pb-[9px]
                text-white font-bold bg-gradient-to-b from-primary-500
                to-primary-400 hover:bg-primary-700 shadow-md"
                  onClick={downloadQR}
                >
                  Download QR
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TurnKeyDetails;
