import React, { useEffect, useState } from "react";
import Category from "../../api/Category";
import { toast } from "react-hot-toast";
import Certification from "../../api/Certification";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../components/form/Input";
import Select from "../../components/form/Select";
import { DashboardLayout } from "../../components/organisms/Layout";

const AddCertification = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(false);
  const [name, setName] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);

  const loadCategories = () => {
    setLoading(true);

    Category.getAll()
      .then((response) => {
        setCategories(response.data.data);

        if (response.data.data.length > 0) {
          setCategory(response.data.data[0].id);
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Something Went Wrong");
        }
      })
      .then(() => setLoading(false));
  };

  const addCertification = () => {
    setLoading(true);
    setValidationErrors([]);

    Certification.add({
      category_id: category,
      name: name,
    })
      .then(() => {
        // Show success toast
        toast.success(t("certifications.certification-added"));
        // Redirect back to certifications page
        navigate("/admin/certifications");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Create New Certificate";
    loadCategories();
  }, []);

  return (
    <DashboardLayout darmo={true}>
      <div className="w-full">
        <h2 className={"text-2xl font-bold"}>
          <Link to={"/admin/certifications"}>
            <i className="far fa-chevron-left text-xl mr-2" />
          </Link>{" "}
          {t("certifications.create-certification")}
        </h2>
        <hr className="border border-primary-100 mt-1" />

        <div className="text-left snap-start pt-6 pb-8 mb-4 w-full">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            <Input
              className="my-1"
              name="name"
              label={t("common.name")}
              type="text"
              value={name}
              placeholder={t("common.name")}
              error={
                validationErrors && validationErrors.name
                  ? validationErrors.name[0]
                  : null
              }
              onChange={(e) => setName(e.target.value)}
              darmo={true}
            />

            <Select
              className="my-1"
              label={t("common.category")}
              value={category}
              name="category"
              onChange={(e) => {
                setCategory(categories[e.target.selectedIndex].id);
              }}
              error={
                validationErrors && validationErrors.category_id
                  ? validationErrors.category_id[0]
                  : null
              }
            >
              {categories.map((category) => {
                return (
                  <option className="p-5" key={category.id} value={category.id}>
                    {category.name}
                  </option>
                );
              })}
            </Select>
          </div>
        </div>

        <div className="flex w-full md:w-1/5">
          <button
            className="w-full flex justify-center items-center rounded-full bg-primary-500 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
            onClick={(e) => {
              e.preventDefault();
              addCertification();
            }}
            disabled={loading}
          >
            {t("common.add")}
            {loading ? <i className="fad fa-circle-notch fa-spin ml-2" /> : ""}
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddCertification;
