import React, { useEffect, useState } from "react";
import { Select } from "antd";
import Dashboard from "../../../../api/Dashboard";
import ApplicationsView from "./ApplicationsView";
import RegistrationsView from "./RegistrationsView";

const JobSeekerDashboard = ({ callback = false }) => {
  const [activeView, setActiveView] = useState("applications");
  const [applicationsData, setApplicationsData] = useState([]);
  const [registrationsData, setRegistrationsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statsData, setStatsData] = useState({
    last24Hours: 0,
    last3Days: 0,
    last7Days: 0,
    last30Days: 0,
  });
  const [registrationStats, setRegistrationStats] = useState({
    last24Hours: 0,
    last3Days: 0,
    last7Days: 0,
    last30Days: 0,
    total: 0,
  });

  useEffect(() => {
    if (activeView === "applications") {
      fetchApplicationsData();
    } else {
      fetchRegistrationsData();
    }
    if (callback) {
      callback("Job Seeker Analytics");
    }
  }, [activeView]); // Refetch data when `activeView` changes

  const fetchApplicationsData = async () => {
    setIsLoading(true);
    try {
      const response = await Dashboard.getRecentSeekerApplications();
      const allApplications = response.data.data;

      // Filter applications for the last 14 days
      const now = new Date();
      const last14Days = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000);
      const filteredApplications = allApplications.filter(
        (app) => new Date(app.created_at) >= last14Days
      );

      const transformedApplications = filteredApplications.map((app) => ({
        id: app.id,
        applicant_name: `${app.seeker.first_name} ${app.seeker.last_name}`,
        applicant_email: app.seeker.email,
        applicant_phone: app.seeker.phone,
        city: app.seeker.city || "N/A",
        state: app.seeker.state || "N/A",
        business_name: app.location.name,
        position_title: app.position.title,
        availability: app.interest
          ? JSON.parse(app.interest).join(", ")
          : "N/A",
        status: app.status,
        created_at: app.created_at,
        resume_url:
          app.seeker.file && app.seeker.file.length > 0
            ? app.seeker.file[0].resume_url
            : null,
        skills: app.seeker.skill ? app.seeker.skill.split(",") : [],
        position_interests: app.seeker.position_interests
          ? JSON.parse(app.seeker.position_interests)
          : [],
      }));

      setApplicationsData(transformedApplications);
      calculateApplicationStats(allApplications); // Note: Still using all applications for stats
    } catch (error) {
      console.error("Error fetching applications:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRegistrationsData = async () => {
    setIsLoading(true);
    try {
      const response = await Dashboard.getRecentSeekerRegistrations();
      const allRegistrations = response.data.data;

      // Filter registrations for the last 14 days
      const now = new Date();
      const last30Days = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
      const filteredRegistrations = allRegistrations.filter(
        (reg) => new Date(reg.created_at) >= last30Days
      );

      // Transform data for the Registrations View
      const transformedRegistrations = filteredRegistrations.map((reg) => ({
        id: reg.id,
        applicant_name: `${reg.first_name} ${reg.last_name}`,
        applicant_email: reg.email,
        applicant_phone: reg.phone || "N/A",
        city: reg.city || "N/A",
        state: reg.state || "N/A",
        registration_date: reg.created_at,
        completed_profile: reg.skill !== null && reg.education !== null,
        skills: reg.skill ? reg.skill.split(",") : [],
        position_interests: reg.position_interests
          ? JSON.parse(reg.position_interests)
          : [],
      }));

      setRegistrationsData(transformedRegistrations);
      calculateRegistrationStats(allRegistrations); // Note: Still using all registrations for stats
    } catch (error) {
      console.error("Error fetching registrations:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to calculate registration statistics
  const calculateRegistrationStats = (registrations) => {
    const now = new Date();
    const last24Hours = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    const last3Days = new Date(now.getTime() - 3 * 24 * 60 * 60 * 1000);
    const last7Days = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    const last30Days = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

    const stats = {
      last24Hours: 0,
      last3Days: 0,
      last7Days: 0,
      last30Days: 0,
      total: registrations.length, // Total count of registrations
    };

    registrations.forEach((reg) => {
      const regDate = new Date(reg.created_at);
      if (regDate >= last24Hours) stats.last24Hours++;
      if (regDate >= last3Days) stats.last3Days++;
      if (regDate >= last7Days) stats.last7Days++;
      if (regDate >= last30Days) stats.last30Days++;
    });

    setRegistrationStats(stats);
  };

  const calculateApplicationStats = (applications) => {
    const now = new Date();
    const last24Hours = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    const last3Days = new Date(now.getTime() - 3 * 24 * 60 * 60 * 1000);
    const last7Days = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    const last30Days = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

    const stats = {
      last24Hours: 0,
      last3Days: 0,
      last7Days: 0,
      last30Days: 0,
    };

    applications.forEach((app) => {
      const appDate = new Date(app.created_at);
      if (appDate >= last24Hours) stats.last24Hours++;
      if (appDate >= last3Days) stats.last3Days++;
      if (appDate >= last7Days) stats.last7Days++;
      if (appDate >= last30Days) stats.last30Days++;
    });

    setStatsData(stats);
  };

  const handleViewChange = (value) => {
    setActiveView(value);
  };

  return (
    <div>
      <div className="divide-y divide-warmgray-300">
        <div className="flex flex-col">
          <div>
            <div className="flex justify-between items-center pt-4 pb-2">
              <h3 className="text-xl font-medium leading-6 text-gray-900">
                Job Seeker Analytics
              </h3>

              {/* Dropdown to switch between views */}
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-500">View:</span>
                <Select
                  defaultValue="applications"
                  value={activeView}
                  onChange={handleViewChange}
                  style={{ width: 180 }}
                  options={[
                    { value: "applications", label: "Applications" },
                    { value: "registrations", label: "Registrations" },
                  ]}
                />
              </div>
            </div>

            {/* Render active view */}
            {activeView === "applications" ? (
              <ApplicationsView
                isLoading={isLoading}
                dashboardData={applicationsData}
                statsData={statsData}
              />
            ) : (
              <RegistrationsView
                isLoading={isLoading}
                dashboardData={registrationsData}
                statsData={registrationStats}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSeekerDashboard;
