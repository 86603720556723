import React from "react";
import moment from "moment";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import ApplicationActions from "../../../components/molecules/Application/ApplicationActions";
import dayjs from "dayjs";

const SelectedApplication = ({
  disabled,
  selectedApplication,
  selectedPosition,
  onNavigate,
  onMessage,
  onInterview,
  onNotes,
  onHire,
  onReject,
  isMobileView,
  totalApplications = 0,
}) => {
  const availabilityList = [
    { value: "full-time", label: "Full Time" },
    { value: "part-time", label: "Part Time" },
  ];
  const formatDateTime = (timestamp, includeDate = true) => {
    if (includeDate) {
      return dayjs.unix(timestamp).format("MMM DD, YYYY hh:mm A");
    }
    return dayjs.unix(timestamp).format("hh:mm A");
  };

  const parseAvailability = () => {
    try {
      const parsed =
        typeof selectedApplication?.interest === "string"
          ? JSON.parse(selectedApplication?.interest)
          : selectedApplication?.interest;

      return Array.isArray(parsed)
        ? parsed
            .map((value) =>
              availabilityList.find((item) => item.value === value)
            )
            .filter(Boolean)
        : [availabilityList.find((item) => item.value === parsed)].filter(
            Boolean
          );
    } catch {
      return [
        availabilityList.find(
          (item) => item.value === selectedApplication?.interest
        ),
      ].filter(Boolean);
    }
  };

  if (!selectedApplication) return null;

  const showNavigation = totalApplications > 1;

  return (
    <div
      className={`rounded-xl border-2 border-gray-300 dark:border-darkGray shadow-md bg-white dark:bg-[#242526] ${
        isMobileView ? "mb-6" : "mb-6 shadow-md"
      }`}
    >
      <div
        className={`flex items-center justify-between ${
          isMobileView ? "p-2" : "px-2 py-6"
        }`}
      >
        {showNavigation ? (
          <button
            onClick={() => onNavigate("prev")}
            className="p-2 hover:bg-gray-100 dark:hover:bg-darkGray-700 rounded-full transition-colors group"
          >
            <FiChevronLeft className="text-gray-600 dark:text-gray-300 w-6 h-6 group-hover:text-primary-500 transition-transform group-hover:-translate-x-1" />
          </button>
        ) : (
          <div className="w-10" />
        )}

        <div
          className={`flex ${
            isMobileView ? "flex-col md:flex-row" : "flex-row"
          } flex-1 items-center mx-2`}
        >
          <div
            className={`flex ${
              isMobileView
                ? "flex-col md:flex-row items-center text-center md:text-left"
                : "flex-row items-center"
            } flex-1`}
          >
            <div
              className={`${
                isMobileView ? "w-32 h-32 md:w-16 md:h-16" : "w-16 h-16"
              } flex-shrink-0`}
            >
              {selectedApplication?.seeker?.photo ? (
                <img
                  src={selectedApplication.seeker.photo.thumb_url}
                  alt={`${selectedApplication?.seeker?.first_name}'s profile`}
                  className="w-full h-full rounded-full object-cover border-2 border-primary-100 shadow-sm"
                />
              ) : (
                <div className="w-full h-full bg-primary-100 text-primary-500 rounded-full flex items-center justify-center text-2xl font-bold">
                  {selectedApplication?.seeker?.first_name[0]?.toUpperCase()}
                </div>
              )}
            </div>
            <div
              className={`${isMobileView ? "mt-4 md:mt-0 md:ml-4" : "ml-4"}`}
            >
              <h2 className="text-lg text-primary-500 dark:text-primary-300 font-bold mb-1">
                {selectedApplication?.seeker?.first_name}{" "}
                {selectedApplication?.seeker?.last_name}
              </h2>
              {selectedApplication?.seeker?.city &&
                selectedApplication?.seeker?.state && (
                  <p className="text-xs text-gray-900 dark:text-gray-300 mb-1">
                    {selectedApplication?.seeker?.city},{" "}
                    {selectedApplication?.seeker?.state}
                  </p>
                )}
              <p className="text-xs text-gray-900 dark:text-gray-300 mb-1">
                {selectedPosition?.label}
                {selectedApplication?.interest && (
                  <>
                    {" - "}
                    <div className="inline-flex">
                      {parseAvailability()
                        ?.map((item) => item.label)
                        .join(" / ")}
                    </div>
                  </>
                )}
              </p>
              <p className="text-xs text-gray-900 dark:text-gray-300">
                Applied{" "}
                {moment(selectedApplication?.created_at).format("MMM D, YYYY")}
              </p>
              {selectedApplication?.interview === "interview scheduled" && (
                <p className="text-xs text-gray-900 dark:text-gray-300 mt-1">
                  Interview{" "}
                  {formatDateTime(selectedApplication?.interview_start_time)} -{" "}
                  {formatDateTime(
                    selectedApplication?.interview_end_time,
                    false
                  )}
                </p>
              )}
            </div>
          </div>

          <div
            className={`${
              isMobileView
                ? "w-full md:w-auto flex justify-center md:justify-end mt-3 md:mt-0"
                : "ml-auto"
            }`}
          >
            <ApplicationActions
              onMessage={() => onMessage(selectedApplication)}
              onInterview={onInterview}
              onNotes={onNotes}
              onHire={onHire}
              onReject={onReject}
              disabled={disabled}
              selectedApplication={selectedApplication}
            />
          </div>
        </div>

        {showNavigation ? (
          <button
            onClick={() => onNavigate("next")}
            className="p-2 hover:bg-gray-100 dark:hover:bg-darkGray-700 rounded-full transition-colors group"
          >
            <FiChevronRight className="text-gray-600 dark:text-gray-300 w-6 h-6 group-hover:text-primary-500 transition-transform group-hover:translate-x-1" />
          </button>
        ) : (
          <div className="w-10" />
        )}
      </div>
    </div>
  );
};

export default SelectedApplication;
