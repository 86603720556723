import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Navbar.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { emitCustomEvent } from "react-custom-events";
import {
  chatList,
  logout,
  setChatListOpen,
  setNotificationCount,
  darkmode,
  selectUser,
  notificationCount,
  setReadNotification,
  readNotify,
  selectSelectedLocation,
  // setUnreadData,
  // selectRoles,
  // readNotify,
  // selectSelectedLocation,
  // sidebar,
  // setBackgroundcolor,
} from "../../../store/authSlice";
import { FaBars } from "react-icons/fa";
import {
  chatListIcon,
  defaultUserImage,
  heyhireBlackIcon,
  heyhireBlueIcon,
  heyhireIcon,
  notifyBellIcon,
  // defaultUserImage,
} from "../../../assets/images";
import { StackedList } from "../../atoms/StackedList";
import Notification from "../../../api/Notification";
import { useEffect } from "react";
import BusinessBreadCrumbs from "../BreadCrumb/BusinessBreadCrumbs";
import { Dropdown } from "antd";
// import JobSeeker from "../../../api/JobSeeker";
// import DropdownMenuItem from "../../DropdownMenuItem";
// import { useTranslation } from "react-i18next";

// const notAllowedroutes = ["dashboard"];

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useSelector(selectUser);
  const selectedLocation = useSelector(selectSelectedLocation);
  const [notificationStackData, setNotificationStackData] = useState([]);
  const [notificationMobileOpen, setNotificationMobileOpen] = useState(false);
  const [notificationDesktopOpen, setNotificationDesktopOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const readNotification = useSelector(readNotify);
  const notifyCount = useSelector(notificationCount);
  const openChatList = useSelector(chatList);
  const mode = useSelector(darkmode);
  // const isSuper = !pathname.includes("/business");
  // const roles = useSelector(selectRoles);
  // const open = useSelector(sidebar);
  // const location = useLocation();
  // const selectedLocation = useSelector(selectSelectedLocation);
  // const allowed = !notAllowedroutes.includes(pathname.split("/")[1]) || false;
  // const langs = {
  //   en: { nativeName: "English" },
  //   es: { nativeName: "Spanish" },
  // };
  // const [days, setDays] = useState(0);
  // const [hours, setHours] = useState(0);
  // const [menuOpen, setMenuOpen] = useState(false);

  const HandleChatList = () => {
    dispatch(setChatListOpen(!openChatList));
    // setMenuOpen(false);
    // navigate("/subscription");
  };

  // const HandleMenuChatList = (chat) => {
  //   dispatch(setChatListOpen(false));
  //   setMenuOpen(!chat);
  // };

  const handleNotificationClose = () => {
    setNotificationDesktopOpen(false);
    setNotificationMobileOpen(false);
  };

  useEffect(() => {
    getNotifications();
  }, [selectedLocation]);

  const getTimeDifference = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    const timeDifference = Math.abs(date2 - date1);
    const secondsAgo = Math.floor(timeDifference / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
    return { secondsAgo, minutesAgo, hoursAgo, daysAgo };
  };

  // const { i18n } = useTranslation();
  // const menu = (
  //   <div
  //     className="origin-top-right w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
  //     role="menu"
  //     aria-orientation="vertical"
  //     aria-labelledby="menu-button"
  //     tabIndex="-1"
  //   >
  //     <div className="py-1" role="none">
  //       <DropdownMenuItem
  //         onClick={() => navigate("/business/my-account")}
  //         label="Profile"
  //         icon="far fa-user"
  //       />
  //       {roles[0] === "super-user" && (
  //         <DropdownMenuItem
  //           label="Switch Accounts"
  //           icon="far fa-repeat"
  //           onClick={() =>
  //             navigate(isSuper ? "/business/dashboard" : "/dashboard")
  //           }
  //         />
  //       )}
  //       <DropdownMenuItem
  //         onClick={() => {
  //           navigate("/login");
  //           dispatch(logout());
  //         }}
  //         label="Logout"
  //         icon="far fa-sign-out"
  //       />
  //     </div>
  //   </div>
  // );

  useEffect(() => {
    if (readNotification) {
      getNotifications();
      dispatch(setReadNotification(false));
    }
  }, [readNotification]);

  const getNotifications = async () => {
    setLoading(true);
    try {
      const response = await Notification.getAllNotifications(
        user?.id,
        selectedLocation?.name !== "Select A Location"
          ? selectedLocation?.id
          : undefined
      );
      let notifications = response?.data?.data || [];
      notifications = notifications.sort((a, b) => {
        const dateA = new Date(a.last_conversation?.createdAt || a.created_at);
        const dateB = new Date(b.last_conversation?.createdAt || b.created_at);
        return dateB - dateA;
      });

      const unreadCount = notifications.filter((n) => n?.is_read === 0)?.length;
      dispatch(setNotificationCount(unreadCount));
      setNotificationStackData([]);
      notifications?.forEach((item) => {
        const { secondsAgo, minutesAgo, hoursAgo, daysAgo } = getTimeDifference(
          item.created_at,
          new Date().toUTCString()
        );
        const conversation_sid =
          item.conversation_sid || item.conversation_id || null;
        setNotificationStackData((prev) => [
          ...prev,
          {
            id: item.id,
            name: item.seeker?.first_name + " " + item.seeker?.last_name || "",
            conversation_sid: conversation_sid,
            status: item.is_read ? "read" : null, // null for unread notifications
            message: item.last_conversation?.text || item.message || "",
            imageUrl: item.seeker?.photo?.thumb_url || defaultUserImage, // Add default image
            seconds: secondsAgo,
            minutes: minutesAgo,
            hours: hoursAgo,
            days: daysAgo,
            details: item,
            body: item.position?.title || "",
          },
        ]);
      });
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  const notifications = (
    <div
      className="w-full rounded-md shadow-xl bg-white dark:bg-darkGray ring-1 ring-black ring-opacity-5 focus:outline-none border border-warmgray-200 dark:border-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div className="py-2" role="none">
        <StackedList
          data={notificationStackData}
          showButton={true}
          selectedLocation={selectedLocation}
          onClose={handleNotificationClose}
          loading={loading}
          open={notificationDesktopOpen || notificationMobileOpen}
        />
      </div>
    </div>
  );

  // const menuLang = (
  //   <div
  //     className="origin-top-right w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden"
  //     role="menu"
  //     aria-orientation="vertical"
  //     aria-labelledby="menu-button"
  //     tabIndex="-1"
  //   >
  //     {Object.keys(langs).map((lang) => (
  //       <li key={lang}>
  //         <button
  //           className={
  //             "block w-full px-4 py-2 " +
  //             (i18n.resolvedLanguage === lang &&
  //               " bg-primary-400 text-white hover:bg-primary-500 hover:text-white")
  //           }
  //           onClick={() => {
  //             i18n.changeLanguage(lang);
  //           }}
  //         >
  //           {langs[lang].nativeName}
  //         </button>
  //       </li>
  //     ))}
  //   </div>
  // );

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 1024;
      if (isMobile) {
        setNotificationDesktopOpen(false);
      } else {
        setNotificationMobileOpen(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        id="navbar"
        className="navbar sticky top-0 w-full bg-white dark:bg-[#242526] flex justify-between align-middle px-4 md:px-6 z-[1] border-b border-warmgray-400 lg:hidden"
      >
        <div className="flex lg:hidden flex-col justify-center">
          <button
            onClick={() => {
              emitCustomEvent("toggle-sidebar");
            }}
          >
            <FaBars className="block lg:hidden text-gray-900 dark:text-white" />
          </button>
        </div>

        <div className="flex w-full">
          <div
            className="flex flex-col lg:hidden justify-center items-start cursor-pointer ml-4"
            onClick={() => navigate("/dashboard")}
          >
            <img
              width={100}
              src={mode ? heyhireBlackIcon : heyhireIcon}
              alt="heyhire"
            />
          </div>
          <div className="flex justify-end ml-auto">
            <div className="flex justify-center cursor-pointer ml-5 mr-0">
              <Dropdown
                overlay={notifications}
                trigger={["click"]}
                placement="bottomRight"
                overlayClassName="overflow-hidden !top-[70px] md:!top-[65px] w-80 rounded-xl border border-gray-50 dark:border-darkGray shadow-lg"
                getPopupContainer={() => document.body}
                onOpenChange={(open) => setNotificationMobileOpen(open)}
                open={notificationMobileOpen}
              >
                <button
                  type="button"
                  className="notification-button relative inline-flex items-center py-2 px-1 text-sm font-medium text-center text-white rounded-lg"
                >
                  <img src={notifyBellIcon} alt="notify-logo" width={36} />
                  {notifyCount !== 0 && (
                    <div className="absolute flex items-center justify-center w-4 h-4 text-[9px] font-bold text-white bg-red-600 rounded-full right-1 top-2">
                      {notifyCount > 9 ? "9+" : notifyCount}
                    </div>
                  )}
                </button>
              </Dropdown>
              <button
                type="button"
                className="relative inline-flex items-center py-2 pl-2 text-sm font-medium text-center text-white cursor-pointer"
                onClick={HandleChatList}
              >
                <img src={chatListIcon} alt="chat-logo" width={36} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        {pathname === "/subscription" && (
          <div className="bg-red-600 text-center py-4 text-white text-sm font-bold flex">
            <div className="mx-auto">
              {pathname === "/subscription" &&
                "Please select a subscription plan"}
            </div>
            <div className="text-center">
              <div
                onClick={() => {
                  navigate("/login");
                  dispatch(logout());
                }}
                className="text-white px-4 rounded-full cursor-pointer"
              >
                <i className="far fa-sign-out mr-2" />
              </div>
            </div>
          </div>
        )}
      </div>

      {pathname !== "/subscription" && (
        <div
          id="navbarWeb"
          className="w-full bg-white hidden align-middle lg:block dark:bg-[#18191A]"
        >
          <div
            className="flex justify-between navbar sticky top-0 bg-white dark:bg-[#242526] dark:border-b dark:border-darkGray border-b border-warmgray-300 py-4 text-white text-sm font-bold rounded-br-3xl pr-4"
            style={{ filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15))" }}
          >
            <div className="px-4 md:px-4 lg:px-8 2xl:px-12 my-auto">
              <img
                src={mode ? heyhireBlueIcon : heyhireIcon}
                alt="logo"
                className="h-7"
              />
            </div>
            <div className="flex justify-center cursor-pointer ml-5 mr-0">
              <Dropdown
                overlay={notifications}
                trigger={["click"]}
                placement="bottomRight"
                overlayClassName="overflow-hidden !top-[70px] md:!top-[60px] w-80 lg:w-96 rounded-xl border border-gray-50 dark:border-darkGray shadow-lg"
                getPopupContainer={() => document.body}
                onOpenChange={(open) => setNotificationDesktopOpen(open)}
                open={notificationDesktopOpen}
              >
                <button
                  type="button"
                  className="notification-button relative inline-flex items-center py-2 px-2 text-sm font-medium text-center text-white rounded-lg cursor-pointer"
                >
                  <img src={notifyBellIcon} alt="notify-logo" width={36} />
                  {notifyCount !== 0 && (
                    <div className="absolute flex items-center justify-center w-4 h-4 text-[9px] font-bold text-white bg-red-600 rounded-full right-2 -top-1">
                      {notifyCount > 9 ? "9+" : notifyCount}
                    </div>
                  )}
                </button>
              </Dropdown>
              <button
                type="button"
                className="relative inline-flex items-center py-2 px-2 text-sm font-medium text-center text-white cursor-pointer"
                onClick={HandleChatList}
              >
                <img src={chatListIcon} alt="chat-logo" width={36} />
              </button>
            </div>
          </div>
          <div className="sticky top-0 flex w-full justify-between px-4 md:px-4 lg:px-8 my-4 2xl:pl-12 2xl:pr-32">
            <div className="my-auto">
              <BusinessBreadCrumbs darmo={mode} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
