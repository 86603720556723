import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { useCustomEventListener } from "react-custom-events";
import AddressBookApi from "../../../api/AddressBook";
import AddAddressModal from "../../../components/modals/AddAddressModal";
import Loading from "../../../components/Loading";
import { DashboardLayout } from "../../../components/organisms/Layout";
// import { ModalDeleteWrapper } from "../../../components/organisms/ModalDeleteWrapper";
import { FormModal } from "../../../components/organisms/FormModal";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/modals/DeleteModal";
// import LocationApi from "../../../api/Location";
import { addIcon } from "../../../assets/images";
import Table from "../../../components/atoms/Tables/Table";
import SearchBar from "../../../components/atoms/SearchBar";

const AddressBook = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sorted, setSorted] = useState([]);
  // const [addressBook, setAddressBook] = useState([]);
  const [addressToDelete, setAddressToDelete] = useState(null);
  const [addressToEdit, setAddressToEdit] = useState(null);
  const [showModal, setShowModal] = useState(false);
  // const [showModalDelete, setShowModalDelete] = useState(false);
  const [loading, setLoading] = useState();
  const [searchAddress, setSearchAddress] = useState("");
  const [filteredAddress, setFilteredAddress] = useState([]);

  useEffect(() => {
    document.title = "HeyHire Portal-Address Book";
    loadAddressBook();
  }, []);

  useCustomEventListener("closeModal", () => {
    setShowModal(false);
    setAddressToEdit(null);
    setAddressToDelete(null);
  });

  useCustomEventListener("dataChanged", () => {
    loadAddressBook();
  });

  const searchAddressName = (txt) => {
    setSearchAddress(txt);
    let text = txt.toLowerCase();
    if (text === "") {
      setFilteredAddress(sorted);
    } else {
      let _companies = sorted.filter((j) =>
        j.name.toLowerCase().includes(text)
      );
      setFilteredAddress(_companies);
    }
  };

  const loadAddressBook = () => {
    setLoading(true);
    AddressBookApi.getAll()
      .then((response) => {
        // setAddressBook(response.data.data);
        setFilteredAddress(response.data.data);
        setSorted(response.data.data);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(false));
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="flex flex-col md:flex-row justify-between mt-6">
        <div className="w-full lg:w-3/5 xl:w-1/3">
          <SearchBar
            searchValue={searchAddress}
            placeholder="Search Address by Name"
            searchFunction={(value) => searchAddressName(value)}
          />
        </div>
      </div>

      <div className="h-full overflow-x-auto mt-3 pb-12">
        {loading ? (
          <Loading count={8} />
        ) : (
          <Table
            mainHeader={t("address-book.address-book")}
            headers={
              !loading && filteredAddress?.length > 0
                ? [
                    t("common.name"),
                    t("common.address"),
                    t("common.address2"),
                    t("common.city"),
                    t("common.state"),
                    t("common.zip"),
                    "Delete",
                  ]
                : ["No data available"]
            }
            data={
              !loading &&
              filteredAddress?.map((address, index) => (
                <tr key={index}>
                  {console.log(address)}
                  <td
                    className="whitespace-nowrap py-3 pr-3 text-sm md:text-base pl-1"
                    onClick={() => navigate(`${address.id}/edit`)}
                  >
                    {address?.name}
                  </td>
                  <td className="whitespace-nowrap xl:whitespace-normal pr-3 py-3 text-sm md:text-base text-gray-500">
                    <div className="text-gray-900">{address?.address}</div>
                  </td>
                  <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                    <div className="text-gray-900">
                      {address?.address2 || "-"}
                    </div>
                  </td>
                  <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                    <div className="text-gray-900">{address?.city}</div>
                  </td>
                  <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                    <div className="text-gray-900">{address?.state}</div>
                  </td>
                  <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                    <div className="text-gray-900">{address?.zip}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                    <div
                      className="text-indigo-600 hover:text-indigo-900 hover:underline cursor-pointer"
                      onClick={() => setAddressToDelete(address.id)}
                    >
                      Delete
                    </div>
                  </td>
                </tr>
              ))
            }
            buttonData={
              <button
                onClick={() => navigate("add")}
                className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 px-6 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
              >
                <div className="flex">
                  <img
                    src={addIcon}
                    alt="logo"
                    className="mr-2 my-auto w-4 h-4"
                  />
                  Add
                </div>
              </button>
            }
          />
        )}
      </div>

      {showModal && <AddAddressModal />}
      <FormModal
        visible={false}
        onClose={() => setShowModal(false)}
        title={
          addressToEdit
            ? t("address-book.edit-address")
            : t("address-book.add-address")
        }
        //   onSubmit={addPredefinedJob}
      >
        <AddAddressModal />
      </FormModal>

      {addressToDelete && (
        <DeleteModal
          title="Delete Address"
          name="Address"
          deleteRequest={() => AddressBookApi.delete(addressToDelete)}
          onSuccess={() => {
            setAddressToDelete(null);
            loadAddressBook();
          }}
        />
      )}
    </DashboardLayout>
  );
};

export default AddressBook;
