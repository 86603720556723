import React from "react";
import queryString from "query-string";
import "./PublicProfile.scss";
// import OpenPositionCard from "../../../components/molecules/OpenPositionCard/OpenPositionCard";
// import ImgAvatarArrow from "../../../assets/images/profile-round.svg";
// import TwoHandsImage from "../../../assets/images/two-hands.png";
// import { FiChevronDown } from "react-icons/fi";
import { PoweredByWhite } from "../../../assets/images";
import { useLocation } from "react-router-dom";
import OpenPositionCardEmbed from "../../../components/molecules/OpenPositionCardEmbed/OpenPositionCardEmbed";

const DarkMode = {
  backgroundColor: "bg-warmgray-1000",
  headerBgColor: "bg-warmgray-1100",
  footerBgColor: "bg-gray-600",
  positonColor: "bg-wartmgray-1100",
  textColor: "text-white",
  textDColor: "text-[#E5E5E5]",
};
const LightMode = {
  backgroundColor: "bg-white",
  headerBgColor: "bg-gray-50",
  footerBgColor: "bg-primary-500",
  positonColor: "bg-wartmgray-1100",
  textColor: "text-black",
  textDColor: "text-black",
};

const EmbededProfileView = () => {
  const location = useLocation();
  const value = queryString.parse(location.search);
  const selectedMode = value.mode || "light";
  const mode = selectedMode !== "dark" ? LightMode : DarkMode;
  // const business = {
  //   name: "Two Hands - ATX",
  //   location: "1011 South Congress Avenue, Austin, TX, United State 78704",
  //   website: "twohandsnyc.com",
  //   description:
  //     "Two Hands is a community-focused cafe that strives to create nutritious, simple and delicious food paired with exceptional coffee. Taking inspiration from Australia's forward-thinking culinary scene and relaxed beachside lifestyle, Two Hands aims to be a place for the community near and far to come unwind in the light-filled sp...",
  //   image: TwoHandsImage,
  // };

  const openPositions = [
    {
      position: "Busser/Porter",
      description:
        "We are seeking team players with high volume experience, great personnalities and a smile to carry on the hospitality of Juliet. ",
    },
  ];

  return (
    <div className={"page-public-profile h-full " + mode.backgroundColor}>
      {/* top bar / welcome bar */}
      <div className={"h-48 md:h-40 " + mode.headerBgColor + " header"}>
        <div className="max-w-[1280px] w-full h-full mx-auto pt-8">
          <div className="h-full text-center justify-center items-center">
            <h3 className={"font-bold text-2xl px-16 " + mode.textColor}>
              We’d love to have you join our team!{" "}
            </h3>
            <br />
            <p className={"px-8 " + mode.textDColor}>
              <strong className="font-bold">
                Please use the form below to apply
              </strong>{" "}
              <br />
              Feel free to send in your resume even if you don't see the
              position you're looking for listed.
            </p>
          </div>
        </div>
      </div>
      <div className="text-center items-center py-8 px-8 md:px-32">
        <OpenPositionCardEmbed
          position={openPositions[0]}
          mode={selectedMode}
        />
        <OpenPositionCardEmbed
          position={openPositions[0]}
          mode={selectedMode}
        />
        <OpenPositionCardEmbed
          position={openPositions[0]}
          mode={selectedMode}
        />
        <OpenPositionCardEmbed
          position={openPositions[0]}
          mode={selectedMode}
        />
      </div>
      {/* footer bottom */}
      <div className={"fixed w-full bottom-0"}>
        <div
          className={
            "px-12 md:px-40 py-5 " +
            mode.footerBgColor +
            " flex flex-col md:flex-row justify-between items-center shadow-[3px_0_0_2px_rgba(0,0,0,0.1)]"
          }
        >
          <>
            <span className="flex font-semibold text-white self-center mr-2 my-4">
              Powered by{" "}
              <img
                className="ml-2"
                height={10}
                width={120}
                alt="logo"
                src={PoweredByWhite}
              />{" "}
            </span>
          </>
          <span className="flex">
            <img
              width={150}
              className="ml-4"
              src="/images/play_store_badge.png"
              alt="App Store"
            />
            <img
              width={150}
              className="ml-4"
              src="/images/app_store_badge.png"
              alt="App Store"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default EmbededProfileView;
