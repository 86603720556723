import React from "react";
import { Tag } from "antd";
import { formatDate } from "../../../../utils/dateConversion";
import Table from "../../../../components/atoms/Tables/Table";
import TableLoading from "../../../../components/Loading/TableLoading";

const ApplicationsView = ({ isLoading, dashboardData, statsData }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "applied":
        return "blue";
      case "viewed":
        return "purple";
      case "hired":
        return "green";
      case "cancelled":
        return "red";
      default:
        return "gray";
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 pt-4">
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
          <dt className="truncate text-sm font-medium text-gray-500">
            Last 24 Hours
          </dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-indigo-600">
            {statsData.last24Hours} Applications
          </dd>
        </div>
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
          <dt className="truncate text-sm font-medium text-gray-500">
            Last 3 Days
          </dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-blue-600">
            {statsData.last3Days} Applications
          </dd>
        </div>
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
          <dt className="truncate text-sm font-medium text-gray-500">
            Last 7 Days
          </dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-teal-600">
            {statsData.last7Days} Applications
          </dd>
        </div>
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
          <dt className="truncate text-sm font-medium text-gray-500">
            Last 30 Days
          </dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-emerald-600">
            {statsData.last30Days} Applications
          </dd>
        </div>
      </div>

      <p className="my-6 font-bold text-lg text-gray-500">
        Applications from last 14 days
      </p>

      <div>
        {isLoading ? (
          <TableLoading Columns={6} />
        ) : (
          <Table
            headers={
              dashboardData?.length > 0
                ? [
                    "Job Seeker",
                    "Location",
                    "Position",
                    "Availability",
                    "Status",
                    "Applied On",
                  ]
                : ["No applications found in the last 14 days"]
            }
            data={dashboardData?.map((application, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="whitespace-nowrap py-3 pr-3 text-sm">
                  <div className="font-medium text-gray-900">
                    {application.applicant_name}
                  </div>
                  <div className="text-gray-500">
                    {application.applicant_email}
                  </div>
                  <div className="text-gray-500">
                    {application.applicant_phone}
                  </div>
                </td>
                <td className="whitespace-nowrap py-3 pr-3 text-sm">
                  <div className="text-gray-900">
                    {application.business_name}
                  </div>
                  <div className="text-gray-500">
                    {application.city}, {application.state}
                  </div>
                </td>
                <td className="whitespace-nowrap py-3 pr-3 text-sm text-gray-900">
                  {application.position_title}
                </td>
                <td className="whitespace-nowrap py-3 pr-3 text-sm text-gray-500">
                  {application.availability}
                </td>
                <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm">
                  <Tag color={getStatusColor(application.status)}>
                    {application.status.charAt(0).toUpperCase() +
                      application.status.slice(1)}
                  </Tag>
                </td>
                <td className="whitespace-nowrap py-3 pr-3 text-sm text-gray-500">
                  {formatDate(application.created_at, "MM-DD-YYYY HH:mm")}
                </td>
              </tr>
            ))}
          />
        )}
      </div>
    </>
  );
};

export default ApplicationsView;
