import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Input from "../form/Input";
import RBAC from "../../api/RBAC";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setRefreshFeature } from "../../store/authSlice";

export default function AddFeatureModal({
  open,
  setOpen,
  editPermission,
  setEditPermission,
}) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [operation, setOperation] = useState("");
  const [nameError, setNameError] = useState(false);
  const [operationError, setOperationError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAddOrEditFeature = async () => {
    if (name === "" && operation === "") {
      setNameError(true);
      setOperationError(true);
    } else if (name === "") {
      setNameError(true);
    } else if (operation === "") {
      setOperationError(true);
    } else {
      setLoading(true);
      try {
        const payload = {
          feature: name.toLowerCase(),
          operations: operation.toLowerCase(),
        };
        const response = editPermission
          ? await RBAC.update(editPermission?.id, payload)
          : await RBAC.add(payload);
        if (response) {
          editPermission
            ? toast.success("Edited Successfully")
            : toast.success("New Feature Added");
          dispatch(setRefreshFeature(true));
          setEditPermission(null);
          setOpen(false);
        }
      } catch (error) {
        console.error("Error editing feature:", error);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editPermission) {
      setName(editPermission?.item);
      setOperation(editPermission?.operations);
    }
  }, [editPermission]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-100" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg p-8 text-center shadow-xl transition-all sm:my-8 sm:p-6 w-fit md:w-[600px] bg-white">
                <div className="mt-2">
                  <h4 className="font-bold text-primary-500 mt-4 mx-6 text-2xl">
                    {editPermission ? "Edit Feature" : "Add New Feature"}
                  </h4>
                  <div className="text-left snap-start sm:rounded-lg sm:px-12 lg:px-6 my-4 flex flex-col gap-2">
                    <Input
                      className="my-3"
                      name="name"
                      label="Feature Name"
                      type="text"
                      value={name}
                      error={nameError && "Name is required"}
                      placeholder="Feature Name"
                      darmo={true}
                      onChange={(e) => setName(e.target.value)}
                    />

                    <Input
                      name="operation"
                      label="Operation"
                      type="text"
                      value={operation}
                      error={operationError && "Operation is required"}
                      placeholder="Operation"
                      darmo={true}
                      onChange={(e) => setOperation(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  className={`mt-5 md:mt-12 flex items-center sm:px-12 lg:px-6 mb-6 gap-2`}
                >
                  <button
                    type="button"
                    className={`flex w-full md:w-1/2 justify-center rounded-xl bg-primary-500 hover:bg-primary-400 px-6 py-2 text-sm font-bold text-white shadow-sm  transition-all hover:opacity-50`}
                    onClick={() => handleAddOrEditFeature()}
                    disabled={loading}
                  >
                    {editPermission ? "Edit" : "Add"}
                  </button>
                  <button
                    type="button"
                    className={`flex w-full md:w-1/2 justify-center rounded-xl bg-primary-500 hover:bg-primary-400
                     px-6 py-2 text-sm font-bold text-white shadow-sm transition-all hover:opacity-50`}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(false);
                    }}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
