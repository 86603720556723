import React, { useEffect, useState } from "react";
// import Button from "../../../components/form/Button";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import User from "../../../api/User";
import Auth from "../../../api/auth";
import { toast } from "react-hot-toast";
// import { DropActionDropdown } from "../../../components/TableActionDropdown";
import Loading from "../../../components/Loading";
import { DashboardLayout } from "../../../components/organisms/Layout";
import useFormatPhoneNumber from "../../../hooks/FormatPhoneNumber";
import { selectUser } from "../../../store/authSlice";
import useQuery from "../../../hooks/useQuery";
import PaginationView from "../../../components/molecules/Pagination";
import SuperUser from "../../../api/SuperUser";
import Table from "../../../components/atoms/Tables/Table";
import { addIcon } from "../../../assets/images";
import IconSelector from "../../../components/atoms/Selector/IconSelector";

const ManageBusinessUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formatPhoneNumber = useFormatPhoneNumber;
  const query = useQuery();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectUser);
  const [pageLimit, setPagelimit] = useState(query.get("pageSize") || 10);
  const [metaData, setMetaData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    document.title = "HeyHire Portal-Users & Permissions";
  }, []);

  useEffect(() => {
    loadUsers();
  }, [query]);

  const loadUsers = () => {
    setLoading(true);
    const page = query.get("page") || 1;
    const sort = query.get("sort") || "";
    const sortData = sort ? `sort=${sort}&` : "";
    const pageData = page ? `page=${page}` : "";
    const limit = query.get("limit") || pageLimit;
    const queryData = sortData + pageData + `&limit=${limit}`;
    User.getAll(queryData)
      .then((response) => {
        const _users = response.data.data?.filter(
          (i) => i?.id !== currentUser?.id
        );
        setUsers(_users);
        setMetaData(response.data.meta);
      })
      .catch(() => {
        toast.error("Error trying to load users. Please try again.");
      })
      .then(() => setLoading(false));
  };

  const deleteUser = (userId) => {
    setLoading(true);
    User.deleteUser(userId)
      .then(() => {
        const dataCopy = users.slice();
        dataCopy.splice(
          users.findIndex((item) => item?.id === userId),
          1
        );
        setUsers(dataCopy);
        setLoading(false);
        toast.success("User Deleted.");
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error trying to delete users. Please try again.");
      })
      .then(() => setLoading(false));
  };

  const suspendUser = (userId) => {
    setLoading(true);
    User.suspendUser(userId)
      .then(() => {
        const dataCopy = [...users];
        dataCopy.map((item) => {
          if (item?.id === userId) {
            item.suspended_at = new Date();
          }
        });
        setUsers(dataCopy);
        toast.success("User Suspended.");
        setLoading(false);
      })
      .catch(() => {
        toast.error("Error trying to suspend users. Please try again.");
        setLoading(false);
      })
      .then(() => setLoading(false));
  };

  const unSuspendUser = (userId) => {
    setLoading(true);
    User.unSuspendUser(userId)
      .then(() => {
        const dataCopy = [...users];
        dataCopy.map((item) => {
          if (item?.id === userId) {
            item.suspended_at = null;
          }
        });
        setUsers(dataCopy);
        toast.success("User UnSuspended.");
        setLoading(false);
      })
      .catch(() => {
        toast.error("Error trying to unsuspend users. Please try again.");
        setLoading(false);
      })
      .then(() => setLoading(false));
  };

  const requestResetPassword = (email) => {
    const body = {
      email: email,
    };
    Auth.ResetPasswordRequest(body)
      .then(() => {
        toast.success("Password reset email has been sent.");
      })
      .catch((error) => {
        toast.error("Something Went wrong. Please try again Later.");
      });
  };

  const resendWelcomeEmail = (userId) => {
    setLoading(true);
    SuperUser.resendWelcomeEmail(userId)
      .then(() => {
        toast.success("Email Sent.");
        setLoading(false);
      })
      .catch(() => {
        toast.error("Error trying to resend welcome email. Please try again.");
        setLoading(false);
      })
      .then(() => setLoading(false));
  };

  const handlecPageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({ ...searchParams, page: currentPage, pageSize: pageSize });
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="overflow-x-auto h-full mt-3 pb-12">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Table
              mainHeader={t("business-users.business-users")}
              headers={
                !loading && users?.length > 0
                  ? [
                      t("common.full-name"),
                      t("common.user-type"),
                      t("common.email"),
                      t("common.phone"),
                      t("common.company"),
                      t("common.last-login"),
                      t("common.status"),
                      t("common.action"),
                    ]
                  : ["No data available"]
              }
              data={
                !loading &&
                users?.map((user, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-3 pr-3 text-sm md:text-base pl-1">
                      {user.first_name} {user.last_name}
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">
                        {user.roles &&
                          user.roles.length > 0 &&
                          user.roles[0].name.replace("_", " ")}
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">{user.email}</div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">
                        {formatPhoneNumber(user.phone) || "-"}
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">{user.company.name}</div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">
                        {user.last_login || "-"}
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base">
                      {user?.suspended_at ? (
                        <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-sm font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                          Suspended
                        </span>
                      ) : (
                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-sm font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          Active
                        </span>
                      )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                      <IconSelector
                        menus={
                          user.id === currentUser.id
                            ? [
                                {
                                  label: "Edit",
                                  action: () =>
                                    navigate(
                                      `/admin/manage-business-users/edit/${user?.id}`
                                    ),
                                },
                              ]
                            : [
                                {
                                  label: "Edit",
                                  action: () =>
                                    navigate(
                                      `/admin/manage-business-users/edit/${user?.id}`
                                    ),
                                },
                                {
                                  label: "Delete",
                                  action: () => deleteUser(user?.id),
                                },
                                {
                                  label: "Suspend",
                                  action: () => suspendUser(user?.id),
                                  disabled: !!user?.suspended_at,
                                },
                                {
                                  label: "Unsuspend",
                                  action: () => unSuspendUser(user?.id),
                                  disabled: !user?.suspended_at,
                                },
                                {
                                  label: "Send Reset Password Link",
                                  action: () =>
                                    requestResetPassword(user?.email),
                                },
                                {
                                  label: "Resend Welcome Email",
                                  action: () => resendWelcomeEmail(user?.id),
                                },
                                {
                                  label: t("general-settings.edit-password"),
                                  action: () =>
                                    navigate(
                                      `/admin/manage-business-users/password-edit/${user?.id}`
                                    ),
                                },
                              ]
                        }
                      />
                    </td>
                  </tr>
                ))
              }
              buttonData={
                <button
                  onClick={() => navigate("add")}
                  className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 px-6 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                >
                  <div className="flex">
                    <img
                      src={addIcon}
                      alt="logo"
                      className="mr-2 my-auto w-4 h-4"
                    />
                    Add
                  </div>
                </button>
              }
            />
            <div className="flex w-full h-20 mt-2 justify-end items-center">
              {!loading && (
                <PaginationView
                  current={metaData?.current_page}
                  next={metaData?.to}
                  pageSize={metaData?.per_page}
                  total={metaData?.total}
                  onChange={handlecPageChange}
                />
              )}
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default ManageBusinessUsers;
