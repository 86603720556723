import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";

const PopupNotification = ({
  title = "Title",
  description = "description",
  confirmText = "Ok",
  cancelText = "Cancel",
  selectedId = null,
  openTrigger = false,
  closeTrigger = false,
  confirm = false,
  showCancelButton = true,
  showConfirmButton = true,
  type = "error",
  width = "",
  height = "",
  content = "",
}) => {
  const [show, setShow] = useState(false);
  // const background = {
  //   success: "bg-green-600",
  //   error: "bg-red-600",
  //   warning: "bg-orange-600",
  // };
  // const hoverColor = {
  //   success: "bg-green-500",
  //   error: "bg-red-500",
  //   warning: "bg-orange-500",
  // };

  useEffect(() => {
    setShow(openTrigger);
  }, [openTrigger, closeTrigger]);

  // const handleConfirm = () => {
  //   confirm(selectedId);
  // };

  const handleClose = () => {
    setShow(false);
    closeTrigger();
  };

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {type === "error" && (
                      <ExclamationCircleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    )}
                    {type === "success" && (
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400 mb-3"
                        aria-hidden="true"
                      />
                    )}
                    {type === "warning" && (
                      <ShieldCheckIcon
                        className="h-6 w-6 text-orange-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{title}</p>
                    <p className="mt-1 text-sm text-gray-500">{description}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default PopupNotification;
