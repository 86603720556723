import { Input } from "antd";
import React from "react";

const FormTextarea = ({
  name,
  type,
  placeholder,
  disabled = false,
  value,
  label,
  callBack,
  darmo,
  ...rest
}) => {
  return (
    <>
      <label
        className={`title font-bold ${darmo ? "text-gray-900" : "text-white"}`}
      >
        {label}
      </label>
      <Input.TextArea
        disabled={disabled}
        className={`mt-2 h-12 p-2 disabled:text-black border-gray-100 dark:border-darkGray border-[1px] rounded-[4px] focus:shadow-none ${
          darmo
            ? "bg-white focus:border-primary-500 placeholder:text-gray-400 hover:border-primary-500 "
            : "bg-slate-800 text-white focus:border-white placeholder:text-gray-500"
        }`}
        placeholder={placeholder}
        value={value}
        rows={4}
        onChange={(e) => callBack(e.target.value)}
        {...rest}
      />
    </>
  );
};

export default FormTextarea;
