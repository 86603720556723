import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import Button from "../../../components/form/Button";
// import { toast } from "react-hot-toast";
// import Modal from "../../../components/Modal";
// import { DropActionDropdown } from "../../../components/TableActionDropdown";
import AddCompanyModal from "../../../components/modals/AddCompanyModal";
import ChangeBrandModal from "../../../components/modals/ChangeBrandModal";
// import SearchBar from "../../../components/atoms/SearchBar";
// import { useCustomEventListener } from "react-custom-events";
import { useTranslation } from "react-i18next";
import {
  // useLocation,
  useNavigate,
  // useParams,
  // useSearchParams,
} from "react-router-dom";
import Loading from "../../../components/Loading";
import { DashboardLayout } from "../../../components/organisms/Layout";
// import PaginationView from "../../../components/molecules/Pagination";
// import useQuery from "../../../hooks/useQuery";
// import { FaArrowAltCircleUp } from "react-icons/fa";
import {
  selectRoles,
  // selectUser,
  // selectPermissions
} from "../../../store/authSlice";
import Stepper from "../../../components/molecules/FormStepper/Stepper";
import StepperControl from "../../../components/molecules/FormStepper/StepperControl";
import { UseContextProvider } from "../../../components/molecules/FormStepper/StepperContext";
import Payment from "../../admin/Settings/Steps/Payments";
import Final from "../../admin/Settings/Steps/Final";
import CompanyDetails from "../../admin/Settings/Steps/CompanyDetails";
import RegionDetails from "../../admin/Settings/Steps/RegionDetails";
import BrandDetails from "../../admin/Settings/Steps/BrandDetails";
import LocationDetails from "../../admin/Settings/Steps/LocationDetails";
import { SettingsContextProvider } from "./SettingsContext";
// import {useContext} from "react";
// import HButton from "../../../components/atoms/HButton";
// import PrimaryButton from "../../../components/atoms/Buttons/PrimaryButton";
import SlideOverPanel from "../../../components/atoms/SildeOverPanel/SlideOverPanel";
import FinalTurnKeyConfirmation from "./Steps/FinalTurnKeyConfirmation";
import TurnKeyDetails from "./Steps/TurnKeyDetails";
// import TurnKeyConfirmation from "./Steps/TurnKeyConfirmation";
import FormWarning from "../../../components/atoms/Notification/FormWarning";
import Preview from "./Steps/Preview";
// import {userTypesSuper} from "../../../utils/permission";
import "./Settings.scss";
// import FormWarningWithButton from "../../../components/atoms/Notification/FormWarningWithButton";
import FormErrorWithButton from "../../../components/atoms/Notification/FormErrorWithButton";

const Settings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();
  const [company, setCompany] = useState({ companyName: "", companyId: "" });
  // const [region, setRegion] = useState([]);
  // const [sorted, setSorted] = useState([]);
  // const [metaData, setMetaData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editCompany, setEditCompany] = useState(null);
  const [showChangeBrandModal, setShowChangeBrandModal] = useState(false);
  // const [sortByColumn, setSortByColumn] = useState(null);
  // const [sortByType, setSortByType] = useState(0); // 0: off, 1: asc, 2: dsc
  // const params = useParams();
  // const [pageLimit, setPagelimit] = useState(50);
  // const query = useQuery();
  // const [searchCompany, setSearchCompany] = useState("");
  // const [filteredCompanies, setFilteredCompanies] = useState([]);
  // const user = useSelector(selectUser);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isTurnKeyAccount, setIsTurnKeyAccount] = useState(null);
  const [warningStatus, setWarningStatus] = useState(false);
  const formTopRef = React.createRef();
  const [currentStep, setCurrentStep] = useState(1);
  const [entryValidity, setEnterValidity] = useState(true);
  // const permissions = useSelector(selectPermissions);
  const roles = useSelector(selectRoles);

  const stepsInitial = [
    "Business Details",
    "Brand",
    "Region",
    "Location",
    "Public Profile",
    "Complete",
  ];

  const [steps, setSteps] = useState(stepsInitial);

  useEffect(() => {
    document.title = "HeyHire Portal-Settings";
    if (roles[0] !== "turnkey_admin" && roles[0] !== "super-user") {
      navigate("/dashboard");
    }
  }, []);

  const handleBusinessTypeUpdate = (businessTypeId) => {
    if (businessTypeId === null) {
      setIsTurnKeyAccount(null);
    } else {
      if (businessTypeId === 3) {
        const stepsTurnKey = [
          "TurnKey Account",
          "Account Details",
          "Confirmation",
        ];

        setIsTurnKeyAccount(businessTypeId);
        setSteps(stepsTurnKey);
      } else {
        setIsTurnKeyAccount(null);
        setSteps(stepsInitial);
      }
    }
  };

  const companyValidationCheck = () => {
    if (isTurnKeyAccount === null) {
      return false;
    } else {
      return true;
    }
  };

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return (
          <CompanyDetails
            handleBusinessTypeUpdate={handleBusinessTypeUpdate.bind(this)}
          />
        );
      case 2:
        if (isTurnKeyAccount) {
          return (
            <TurnKeyDetails
              setTurnKeyObjectEntry={getTurnKeyObjectEntry}
              error={!entryValidity}
            />
          );
        }

        return <BrandDetails />;

      case 3:
        if (!entryValidity) {
          setCurrentStep(2);

          return;
        }

        if (isTurnKeyAccount) {
          return <FinalTurnKeyConfirmation />;
        }
        return <RegionDetails company={company} />;
      case 4:
        return <LocationDetails />;
      case 5:
        return <Payment />;
      case 6:
        return <Final />;
      default:
    }
  };

  const getTurnKeyObjectEntry = (
    object,
    imageUploadFile = false,
    brandLogoUUID = false
  ) => {
    if (
      object.turnKeyLocationName &&
      object.turnKeyLocationCategory &&
      object.turnKeyLocationUrlSlug &&
      object.turnKeyBrandName &&
      object.turnKeyBrandUrlSlug &&
      object.turnKeyWebsite &&
      object.turnKeyBrandDescription &&
      object.turnKeyCity &&
      object.turnKeyState &&
      object.turnKeyZip &&
      (brandLogoUUID || (imageUploadFile.length !== 0 && imageUploadFile))
    ) {
      setEnterValidity(true);
    } else {
      setEnterValidity(false);
    }
  };

  const handleClick = (direction) => {
    if (!companyValidationCheck()) {
      setWarningStatus(true);
      formTopRef.current.scrollIntoView();
    } else {
      setWarningStatus(false);
      let newStep = currentStep;
      direction === "next" ? newStep++ : newStep--;
      newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
    }
  };

  const openSidePanel = () => {
    setIsPanelOpen(true);
  };

  const getTurnKeyCompanyDetails = () => {};

  return (
    <DashboardLayout darmo={true}>
      {showModal && (
        <AddCompanyModal
          company={editCompany}
          title={
            editCompany
              ? t("companies.edit-company")
              : t("companies.add-a-company")
          }
          onClose={() => setShowModal(!showModal)}
          visible={showModal}
        />
      )}
      {showChangeBrandModal && (
        <ChangeBrandModal
          company={editCompany}
          title="Change Brand"
          onClose={() => setShowChangeBrandModal(!showChangeBrandModal)}
          visible={showChangeBrandModal}
        />
      )}
      <div className="flex justify-between">
        <h2 className="font-bold text-2xl" ref={formTopRef}>
          Settings
        </h2>

        <div>
          {/* <PrimaryButton
            customIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
            }
            label="Preview"
            style=" w-[120px] rounded-full px-1.5 pt-[10px] pb-[9px] text-white font-bold bg-gradient-to-b from-primary-500 to-primary-400 hover:bg-primary-500 shadow-md"
            onClick={openSidePanel}
          /> */}
        </div>
      </div>
      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

      <div className="relative left-0 rounded-1xl bg-white pb-5 min-h-auto shadow-xl md:w-full lg:w-full">
        <SettingsContextProvider>
          {/* Stepper */}
          <div className="horizontal container mt-5">
            <FormWarning
              status={warningStatus}
              title="Selection Required"
              content="Select turnkey account or Create a new one"
              note="NOTE: Only turnkey feature is enabled...."
            />
            <Stepper
              key={isTurnKeyAccount}
              steps={steps}
              currentStep={currentStep}
            />

            <UseContextProvider>
              <div className="my-10 p-10">{displayStep(currentStep)}</div>
            </UseContextProvider>

            {isPanelOpen && (
              <SlideOverPanel
                title="Business Details"
                isOpen={isPanelOpen}
                onClose={() => setIsPanelOpen(false)}
              >
                <Preview />
              </SlideOverPanel>
            )}
          </div>

          {/* navigation button */}
          {currentStep !== steps.length && (
            <StepperControl
              handleClick={handleClick}
              currentStep={currentStep}
              steps={steps}
            />
          )}
        </SettingsContextProvider>
        <div className="p-5">
          <FormErrorWithButton
            status={!entryValidity}
            title="Incomplete Form :"
            content="Except position all other fields are required"
          />
        </div>
      </div>

      <div className="h-full">
        {loading && <Loading count={8} />}
        <div className="flex w-full h-20 mt-2 justify-end items-center"></div>
      </div>
    </DashboardLayout>
  );
};

export default Settings;
