import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCustomEventListener } from "react-custom-events";
import { toast } from "react-hot-toast";
import PaginationView from "../../components/molecules/Pagination";
import useQuery from "../../hooks/useQuery";
import { addIcon, defaultUserImage } from "../../assets/images";
import Brand from "../../api/Brand";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import { DashboardLayout } from "../../components/organisms/Layout";
import { encodeIds } from "../../utils";
import SearchBar from "../../components/atoms/SearchBar";
import Table from "../../components/atoms/Tables/Table";

const Brands = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [metaData, setMetaData] = useState({});
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [showAddModal, setShowAddModal] = useState(false);
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [deleteLoading, setDeleteLoading] = useState(false);
  const [pageLimit, setPagelimit] = useState(50);
  const [searchBrand, setSearchBrand] = useState("");
  const [filteredBrands, setFilteredBrands] = useState([]);
  const query = useQuery();

  useEffect(() => {
    document.title = "HeyHire Portal-Brands";
  }, []);

  useEffect(() => {
    loadBrands();
  }, [query]);

  // useCustomEventListener("closeModal", () => {
  //   setShowDeleteModal(false);
  //   setShowAddModal(false);
  // });

  useCustomEventListener("brandAdded", () => {
    loadBrands();
    // setShowAddModal(false);
  });

  const searchBrandName = (txt) => {
    setSearchBrand(txt);
    let text = txt.toLowerCase();
    if (text === "") {
      setFilteredBrands(brands);
    } else {
      let _brands = brands.filter((j) => j?.name.toLowerCase().includes(text));
      setFilteredBrands(_brands);
    }
  };

  const loadBrands = () => {
    setLoading(true);
    const page = query.get("page") || 1;
    const limit = query.get("limit") || pageLimit;
    const pageData = page ? `page=${page}` : "";
    const queryData = pageData + `&limit=${limit}`;

    Brand.getAll(queryData)
      .then((response) => {
        setBrands(response.data.data);
        setMetaData(response.data.meta);
        setFilteredBrands(response.data.data);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Something went wrong. Please Try Again.");
        }
      })
      .then(() => setLoading(false));
  };

  const handlecPageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({ ...searchParams, page: currentPage, pageSize: pageSize });
  };

  // const addBrand = (values) => {
  //   Brand.add(values)
  //     .then(() => {
  //       toast.success(t("brands.brand-added"));
  //       emitCustomEvent("brandAdded");
  //     })
  //     .catch((error) => {
  //       if (error.response && error.response.status === 422) {
  //         // Validation error
  //       } else {
  //         if (error?.response?.status !== 403) {
  //           toast.error(t("common.something-went-wrong-please-try-again"));
  //         }
  //       }
  //     });
  // };

  const deleteBrand = (brandId) => {
    // setDeleteLoading(true);
    Brand.deleteById(brandId)
      .then(() => {
        const dataCopy = brands.slice();
        dataCopy.splice(
          brands.findIndex((item) => item.id === brandId),
          1
        );
        setBrands(dataCopy);
        setFilteredBrands(dataCopy);
        // setShowDeleteModal(false);
        toast.success("Brand Deleted.");
      })
      .catch();
    // .then(() => setDeleteLoading(false));
  };
  return (
    <DashboardLayout darmo={true}>
      <div className="flex flex-col md:flex-row justify-between mt-6">
        <div className="w-full lg:w-3/5 xl:w-1/3">
          <SearchBar
            searchValue={searchBrand}
            placeholder="Search Brand By Name"
            searchFunction={(value) => searchBrandName(value)}
          />
        </div>
      </div>

      <div className="h-full overflow-x-auto mt-3 pb-12">
        {loading ? (
          <Loading count={8} />
        ) : (
          <>
            <Table
              mainHeader={t("brands.brands")}
              headers={
                !loading && filteredBrands?.length > 0
                  ? [
                      t("brands.brand-name"),
                      t("common.company-name"),
                      t("common.website"),
                      "Edit",
                      "Delete",
                    ]
                  : ["No data available"]
              }
              data={
                !loading &&
                filteredBrands?.map((brand, index) => (
                  <tr key={index}>
                    <td
                      className="whitespace-nowrap py-3 pr-3 text-sm md:text-base pl-1"
                      onClick={() =>
                        navigate(`/companies/brands/${encodeIds(brand.id)}`)
                      }
                    >
                      <div className="flex items-center">
                        <div className="h-9 w-9 flex-shrink-0 transform transition duration-200 ease-in-out hover:scale-110">
                          <img
                            alt=""
                            src={
                              brand?.photo?.tiny_url ||
                              brand?.photo?.thumb_url ||
                              defaultUserImage
                            }
                            className="h-9 w-9 rounded-full"
                          />
                        </div>
                        <div className="ml-2 md:ml-4">
                          <div className="font-medium text-gray-900 hover:underline">
                            {brand?.name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">
                        {brand?.company?.name}
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">
                        {brand?.website || "-"}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                      <div
                        className="text-indigo-600 hover:text-indigo-900 hover:underline cursor-pointer"
                        onClick={() =>
                          navigate(`/companies/brands/${encodeIds(brand?.id)}`)
                        }
                      >
                        Edit
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                      <div
                        className="text-indigo-600 hover:text-indigo-900 hover:underline cursor-pointer"
                        onClickDelete={() => {
                          deleteBrand(brand?.id);
                        }}
                      >
                        Delete
                      </div>
                    </td>
                  </tr>
                ))
              }
              buttonData={
                <button
                  onClick={() => navigate("add")}
                  className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 px-6 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                >
                  <div className="flex">
                    <img
                      src={addIcon}
                      alt="logo"
                      className="mr-2 my-auto w-4 h-4"
                    />
                    Add
                  </div>
                </button>
              }
            />
            <div className="flex w-full h-20 mt-2 justify-end items-center">
              {!loading && (
                <PaginationView
                  current={metaData?.current_page}
                  next={metaData?.to}
                  pageSize={metaData?.per_page}
                  total={metaData?.total}
                  onChange={handlecPageChange}
                />
              )}
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Brands;
