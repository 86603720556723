import React from "react";
import { useState } from "react";
import AuthLayout from "../../components/organisms/Layout/Authlayout";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import FloatingInputText from "../../components/form/FloatingInputText";
import Auth from "../../api/auth.js";
import TurnKeyBusinesses from "../../api/TurnKeyBusinesses";
import FormPasswordInput from "../../components/atoms/FormPasswordInput/FormPasswordInput";
import FormWarningWithButton from "../../components/atoms/Notification/FormWarningWithButton";
import FormPhoneFloatingInput from "../../components/atoms/FormPhoneFloatingInput/FormPhoneFloatingInput";
import Payment from "../../api/Payment";
import Subscription from "../../api/Subscription";
import { useSelector } from "react-redux";
import { darkmode } from "../../store/authSlice.js";

const AccountRegistration = () => {
  const [activationCode, setActivationCode] = useState([]);
  const [companyId, setCompanyId] = useState([]);
  const [brandId, setBrandId] = useState([]);
  // const [email, setEmail] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [positionAtBusiness, setPositionAtBusiness] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  // const [loading, setLoading] = useState(false);
  // const [validationErrors, setValidationErrors] = useState(null);
  // const [errorMessage, setErrorMessage] = useState("");
  const [turnKeyData, setTurnKeyData] = useState([]);
  const [logoURL, setLogoURL] = useState([]);
  // const [logoUUID, setLogoUUID] = useState([]);
  const [lock, setLock] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [formObject, setFormObject] = useState([]);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const mode = useSelector(darkmode);

  const navigate = useNavigate();
  const { state } = useLocation();
  // const {turnKeyData } = state; // Read values passed on state

  useEffect(() => {
    document.title = "HeyHire Portal-Easy-Registration";
    if (state === null) {
      navigate("/login");
    } else {
      const { turnKeyData } = state;
      setTurnKeyData(turnKeyData);
      setActivationCode(turnKeyData.activation_code);

      let payload = JSON.parse(turnKeyData.payload);
      setLogoURL(payload["brandLogoUrl"]);
      // setLogoUUID(payload["brandLogoUUID"]);
      // setLogoURL(turn)
      //console.log("new page :" + JSON.stringify(logoUUID));
    }
  }, []);

  useEffect(() => {
    formObject.firstName = firstName;
    formObject.lastName = lastName;
    formObject.phoneNumber = phoneNumber;
    formObject.positionAtBusiness = positionAtBusiness;
    formObject.password = password;
    formObject.confirmPassword = confirmPassword;

    if (
      formObject.firstName &&
      formObject.lastName &&
      formObject.phoneNumber &&
      formObject.positionAtBusiness &&
      formObject.password &&
      formObject.confirmPassword
    ) {
      setButtonDisabled(false);
    }
  }, [
    firstName,
    lastName,
    phoneNumber,
    positionAtBusiness,
    password,
    confirmPassword,
  ]);

  const handleClick = () => {
    localStorage.removeItem("token");
    createBusiness();
  };

  const updateBusinessPhotoDetails = (companyId, brandId) => {
    const data = {
      activation_code: activationCode,
      company_id: companyId,
      brand_id: brandId,
    };

    TurnKeyBusinesses.updatePhoto(data)
      .then((response) => {
        console.log("photo is updated successfully");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation error
        } else {
        }
      });
  };

  const addPositions = (locationId) => {
    TurnKeyBusinesses.addPositions(locationId, activationCode)
      .then(() => {
        console.log("Position is added successfully");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation error
        } else {
        }
      });
  };

  const updateTurnKeyPayLoad = async () => {
    TurnKeyBusinesses.updateStatus(activationCode)
      .then(() => {
        console.log("status is updated successfully");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation error
        } else {
        }
      });
  };

  const addCompanyIdToTurnKeyPayLoad = async (companyId) => {
    const data = {
      activation_code: activationCode,
      company_id: companyId,
    };

    TurnKeyBusinesses.addCompanyId(data)
      .then(() => {
        console.log("status is updated successfully");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation error
        } else {
        }
      });
  };

  const createBusiness = () => {
    let payload = JSON.parse(turnKeyData.payload);

    const addressData = {
      address: payload["turnKeyLocationName"],
      city: payload["turnKeyCity"],
      state: payload["turnKeyState"],
      zip: payload["turnKeyZip"],
      lat: payload["turnKeyLat"],
      lng: payload["turnKeyLng"],
    };
    const APIData = {
      address: addressData,
      location: {
        name: payload["turnKeyLocationName"]
          ? payload["turnKeyLocationName"]
          : "please update",
        url_slug: payload["turnKeyLocationUrlSlug"]
          ? payload["turnKeyLocationUrlSlug"]
          : "please update",
        category_id: payload["turnKeyLocationCategory"]
          ? payload["turnKeyLocationCategory"]
          : "1",
        description: payload["turnKeyLocationName"]
          ? payload["turnKeyLocationName"]
          : "please update",
        business_phone: phoneNumber ? phoneNumber : "",
      },
      company: {
        name: payload["turnKeyCompanyName"]
          ? payload["turnKeyCompanyName"]
          : "please update",
        how_did_you_hear: "please update",
      },
      user: {
        first_name: firstName,
        last_name: lastName,
        email: payload["email"] ? payload["email"] : "please update",
        phone: phoneNumber,
        password: password,
      },
    };

    RegisterBusiness(APIData);
  };

  const RegisterBusiness = async (dataAPi) => {
    try {
      const reqData = await Auth.Register(dataAPi);
      setCompanyId(reqData.data.user.company_id);
      setBrandId(reqData.data.location.brand_id);
      localStorage.setItem("token", reqData.data.token);

      updateBusinessPhotoDetails(
        reqData.data.user.company_id,
        reqData.data.location.brand_id
      );
      addPositions(reqData.data.location.id);
      addCompanyIdToTurnKeyPayLoad(reqData.data.user.company_id);

      Payment.addAccount(reqData.data).then((response) => {
        Subscription.subscribe(response.data);
      });

      updateTurnKeyPayLoad();

      //Navigate once the token is set
      navigate("/easy-confirmation", {
        state: {
          turnKeyData: turnKeyData,
        },
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
      } else {
        // toast.error("Api Error!");
      }
    }
  };

  // const lockClick = () => {
  //   setLock(!lock);
  // };

  const getPassword = (password) => {
    setPassword(password);
  };

  const checkConfirmPassword = (e) => {
    if (password === e.target.value) {
      setConfirmPassword(e.target.value);
      setConfirmPasswordError(false);
    } else {
      setConfirmPasswordError(true);
    }
  };

  const collectPhoneNumber = (value) => {
    setPhoneNumber(value);
  };

  return (
    <AuthLayout showLink title="Account Activation" customLogo={logoURL}>
      <div className="flex flex-col justify-center items-center px-4 md:min-h-full my-4 md:my-0 w-full">
        <div
          className={`w-full flex flex-row justify-center text-center font-bold align-middle pb-10 ${
            mode ? "text-gray-600" : "text-gray-400"
          } animate-fade-in-from-top-.5s`}
        >
          <div className="pr-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#088F8F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="animate-bounce"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
              <polyline points="22 4 12 14.01 9 11.01"></polyline>
            </svg>
          </div>
          Quick info and we are all set!
        </div>
        <div className="w-full flex flex-col justify-center items-center">
          <div class="w-full max-w-lg" autocomplete="off">
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <div class="relative animate-fade-in-from-top-.5s">
                  <FloatingInputText
                    type="text"
                    id="FirstName"
                    label="First Name"
                    placeholder="Enter First Name"
                    className="my-3"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 px-3">
                <div class="relative animate-fade-in-from-top-.5s">
                  <FloatingInputText
                    type="text"
                    id="LastName"
                    label="Last Name"
                    placeholder="Enter Last Name"
                    className="my-3"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <div class="relative animate-fade-in-from-top-.5s">
                  <FormPhoneFloatingInput
                    className="my-3"
                    label="Phone Number"
                    value={phoneNumber}
                    onChange={collectPhoneNumber}
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 px-3">
                <div class="relative animate-fade-in-from-top-.5s">
                  <FloatingInputText
                    type="text"
                    id="PositionBusiness"
                    label="Position At Business"
                    autoComplete="off"
                    placeholder="Position At Business"
                    className="my-3"
                    onChange={(e) => setPositionAtBusiness(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <div class="relative animate-fade-in-from-top-.5s">
                  <FormPasswordInput handleChange={getPassword} mode={mode} />
                </div>

                <h6
                  class={`font-bold text-[10px] ${
                    mode ? "text-gray-900" : "text-gray-400"
                  } p-2`}
                >
                  {confirmPasswordError
                    ? " Password do not match yet"
                    : "HINT: Password should be at least 8 characters long"}
                </h6>
              </div>
              <div class="w-full md:w-1/2 px-3">
                <div class="relative animate-fade-in-from-top-.5s">
                  <FloatingInputText
                    type="password"
                    id="confirm"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    className="my-3"
                    onChange={(e) => checkConfirmPassword(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pb-5">
            <FormWarningWithButton
              status={confirmPasswordError}
              title="HINT"
              content="passwords do not match yet"
            />
          </div>
          <div className="text-center">
            <button
              disabled={buttonDisabled}
              className="h-[45px] w-full md:w-fit rounded-full px-3.5 pt-[10px] pb-[9px] text-white text-lg font-bold bg-gradient-to-b from-primary-500 to-primary-400 hover:bg-primary-500 disabled:opacity-70 shadow-md"
              onClick={handleClick}
            >
              Complete Activation
            </button>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default AccountRegistration;
