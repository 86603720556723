import React from "react";

function SlideOverPanel({ title, children, isOpen, onClose }) {
  const backdropClasses = `inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${
    isOpen ? "opacity-100 ease-in-out duration-500" : "opacity-0"
  }`;
  const slideOverClasses = `pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 ${
    isOpen
      ? "transform transition ease-in-out duration-500 sm:duration-700 translate-x-0"
      : "transform transition ease-in-out duration-500 sm:duration-700 translate-x-full"
  }`;
  const closeButtonClasses = `absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4 ${
    isOpen ? "opacity-100 ease-in-out duration-500" : "opacity-0"
  }`;
  const panelClasses = `border-solid border-2  shadow-2xl shadow-primary-500 pointer-events-auto relative w-screen max-w-md bg-white py-6 shadow-xl ${
    isOpen ? "" : "opacity-0 pointer-events-none"
  }`;

  return (
    <div
      className="relative z-10"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <div className={backdropClasses} onClick={onClose}></div>
      <div className="inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className={slideOverClasses}>
            <div className={panelClasses}>
              <div className={closeButtonClasses}>
                <button
                  type="button"
                  className="rounded-md text-primary-500 hover:text-black focus:outline-none focus:ring-2 focus:ring-gray"
                  onClick={onClose}
                >
                  <span className="sr-only">Close panel</span>
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex h-full flex-col overflow-hidden">
                <div className="px-4 sm:px-6">
                  <h2
                    className="text-base ml-10 pl-16 items-center font-semibold leading-6 text-gray-900"
                    id="slide-over-title"
                  >
                    {title}
                  </h2>
                </div>
                <div className="relative flex-1 px-4">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SlideOverPanel;
