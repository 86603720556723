import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { emitCustomEvent } from "react-custom-events";

import Modal from "../Modal";

const DeleteModal = ({ name, title, deleteRequest, onSuccess = () => {} }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const deleteItem = () => {
    setLoading(true);

    deleteRequest()
      .then(() => {
        toast.success(`${name} ${t("common.deleted")}`);
        emitCustomEvent("closeModal");
        onSuccess();
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(false));
  };

  return (
    <Modal
      title={title}
      subTitle={
        <span>
          <p>{t("common.are-you-sure-you-want-to-delete-this-item")}</p>
          <p className="font-normal">
            {t("common.this-action-cant-be-undone")}
          </p>
        </span>
      }
      headerBg="bg-red-500"
    >
      <div className="w-full">
        <div className="text-left snap-start px-8 pt-6 pb-8 mb-4 w-full">
          <div className="mt-8 flex">
            <button
              className="w-full rounded-xl p-4 m-1 text-lg border-2 hover:text-white hover:bg-midnight border-black text-black"
              onClick={(e) => {
                e.preventDefault();
                emitCustomEvent("closeModal");
              }}
            >
              {t("common.cancel")}
            </button>
            <button
              className="w-full rounded-xl p-4 m-1 text-white text-lg bg-red-500 hover:bg-red-600 disabled:bg-red-200"
              onClick={() => deleteItem()}
              disabled={loading}
            >
              {t("common.delete")}
              {loading && <i className="fad fa-circle-notch fa-spin ml-2" />}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
