import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../../components/organisms/Layout";
import {
  OpenJobs,
  applicantBlueIcon,
  hiredBlueIcon,
} from "../../../assets/images";
import "../ViewJob/ViewJob.scss";
import FormSelect from "../../../components/atoms/FormSelect";
import {
  darkmode,
  locations,
  selectRoles,
  selectSelectedLocation,
  selectUser,
  setInitialChatFetch,
  setMinimizedPersons,
  setSelectedLocation,
  setSelectedPersons,
  setVisiblePersons,
} from "../../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import ChatApi from "../../../api/Chat";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import BusinessAnalyticsDashboard from "../../../api/BusinessAnalyticsDashboad";
import JobApplicationsChart from "../../../components/molecules/Chart/JobApplicationsChart";
import JobPieChart from "../../../components/molecules/Chart/JobPieChart";
import User from "../../../api/User";
import { encodeIds } from "../../../utils";

const BusinessAnalyticDashboard = () => {
  const dispatch = useDispatch();
  const mode = useSelector(darkmode);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const roles = useSelector(selectRoles);
  const selectedLocation = useSelector(selectSelectedLocation);
  const allLocations = useSelector(locations);
  const isEnterprise = user?.company?.is_enterprise === 1;
  const currentRole = roles?.[0] || "";
  const showLocationDropdown =
    (isEnterprise && currentRole === "owner") ||
    (isEnterprise && currentRole !== "employee") ||
    currentRole === "super-user" ||
    currentRole === "regional_manager";
  const [selectedOption, setSelectedOption] = useState(null);
  const [isInView, setIsInView] = useState(false);
  const [chatInfo, setChatInfo] = useState([]);
  const [statCount, setStatCount] = useState([]);
  const [applicantsData, setApplicantsData] = useState([]);
  const [allApplications, setAllApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [applicantsLoading, setApplicantsLoading] = useState(false);
  const [applicationsChartLoading, setApplicationsChartLoading] =
    useState(true);

  const loadStatisticsCount = async () => {
    try {
      setLoading(true);
      let locationId =
        user?.company?.is_enterprise === 0
          ? user?.location?.id
          : selectedOption?.value?.id;

      if (locationId) {
        const response = await BusinessAnalyticsDashboard.getAnalyticsCount(
          locationId
        );
        setStatCount(response?.data?.data || []);
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error loading statistics:", error);
      if (error?.response?.status !== 403) {
        toast.error("That didn't work. Try refreshing the page.");
      }
      return false;
    } finally {
      setLoading(false);
    }
  };

  const loadApplicantsData = async () => {
    try {
      setApplicantsLoading(true);
      setApplicationsChartLoading(true);

      const locationId =
        user?.company?.is_enterprise === 0
          ? user?.location?.id
          : selectedOption?.value?.id;

      if (!locationId) {
        setApplicantsData([]);
        setAllApplications([]);
        return false;
      }

      const response = await BusinessAnalyticsDashboard.getApplicantDetails(
        locationId
      );

      // Safely handle the response data
      const rawData = response?.data?.data || [];
      setAllApplications(rawData);

      // Process applications only if we have valid data
      if (Array.isArray(rawData) && rawData.length > 0) {
        const processedApplications = rawData
          .filter(
            (jobPosition) =>
              jobPosition &&
              jobPosition.application &&
              Array.isArray(jobPosition.application)
          )
          .flatMap((jobPosition) =>
            jobPosition.application
              .filter((app) => app && app.job_seeker)
              .map((app) => ({
                ...app,
                job_title:
                  jobPosition?.job_details?.title || "Untitled Position",
              }))
          )
          .sort((a, b) => {
            const dateA = new Date(a.job_seeker?.created_at || 0);
            const dateB = new Date(b.job_seeker?.created_at || 0);
            return dateB - dateA;
          });

        setApplicantsData(processedApplications);
      } else {
        setApplicantsData([]);
      }

      return true;
    } catch (error) {
      console.error("Error loading applicants:", error);
      if (error?.response?.status !== 403) {
        toast.error("That didn't work. Try refreshing the page.");
      }
      setApplicantsData([]);
      setAllApplications([]); // Make sure to clear all applications on error
      return false;
    } finally {
      setApplicantsLoading(false);
      setApplicationsChartLoading(false);
    }
  };

  const loadChatInfo = async () => {
    try {
      setConversationLoading(true);
      let locationId =
        user?.company?.is_enterprise === 0
          ? user?.location?.id
          : selectedOption?.value?.id;

      if (locationId) {
        const response = await ChatApi.getChatsByLocation(locationId);
        setChatInfo(response?.data?.data || []);
      }
    } catch (error) {
      console.error("Error loading chat info:", error);
      if (error?.response?.status !== 403) {
        toast.error("That didn't work. Try refreshing the page.");
      }
      setChatInfo([]);
    } finally {
      setConversationLoading(false);
    }
  };

  useEffect(() => {
    if (selectedOption) {
      const initializeData = async () => {
        setChatInfo([]);
        setConversationLoading(true);
        setAllApplications([]);

        try {
          await Promise.all([
            loadStatisticsCount().catch((error) => {
              console.error("Error loading statistics:", error);
              return false;
            }),
            loadApplicantsData().catch((error) => {
              console.error("Error loading applicants:", error);
              return false;
            }),
          ]);

          await loadChatInfo().catch((error) => {
            console.error("Error loading chat info:", error);
          });
        } catch (error) {
          console.error("Error initializing data:", error);
          toast.error(
            "An error occurred while loading data. Please try again."
          );
        } finally {
          setConversationLoading(false);
        }
      };

      initializeData();
    }
  }, [selectedOption]);

  const formatDateWithTime = (date) => {
    if (!date) return "-";

    const dateObj = dayjs(date);
    const now = dayjs();

    const yearsAgo = now.diff(dateObj, "year");
    if (yearsAgo >= 1) {
      return `${dateObj.format("MMM D, YYYY h:mm A")} - ${yearsAgo} ${
        yearsAgo === 1 ? "year" : "years"
      } ago`;
    }

    const monthsAgo = now.diff(dateObj, "month");
    if (monthsAgo >= 1) {
      return `${dateObj.format("MMM D, YYYY h:mm A")} - ${monthsAgo} ${
        monthsAgo === 1 ? "month" : "months"
      } ago`;
    }

    const daysAgo = now.diff(dateObj, "day");
    if (daysAgo >= 1) {
      return `${dateObj.format("MMM D, YYYY h:mm A")} - ${daysAgo} ${
        daysAgo === 1 ? "day" : "days"
      } ago`;
    }

    const hoursAgo = now.diff(dateObj, "hour");
    if (hoursAgo >= 1) {
      return `${dateObj.format("MMM D, YYYY h:mm A")} - ${hoursAgo} ${
        hoursAgo === 1 ? "hour" : "hours"
      } ago`;
    }

    const minutesAgo = now.diff(dateObj, "minute");
    if (minutesAgo >= 1) {
      return `${minutesAgo} ${minutesAgo === 1 ? "minute" : "minutes"} ago`;
    }

    return "a minute ago";
  };

  const renderLocationDropdown = () => {
    if (
      !allLocations?.length ||
      !showLocationDropdown ||
      currentRole === "turnkey_admin"
    ) {
      return null;
    }

    return (
      <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mt-4 md:mt-0">
        <FormSelect
          type="add-job"
          label="Select Location"
          placeholder="Select Location"
          classNames="font-bold"
          options={allLocations.map((info) => ({
            value: info,
            label: info?.name || "Unnamed Location",
          }))}
          onChange={(selectedOption) => {
            setSelectedOption(selectedOption);
          }}
          value={
            selectedOption
              ? {
                  label: selectedOption?.label,
                  value: selectedOption?.value,
                }
              : allLocations[0]
              ? {
                  value: allLocations[0],
                  label: allLocations[0]?.name,
                }
              : null
          }
          isDisabled={loading || applicantsLoading || conversationLoading}
          darmo={mode}
        />
      </div>
    );
  };

  useEffect(() => {
    if (selectedLocation && allLocations?.length > 0) {
      const defaultLocation = allLocations[0];
      if (defaultLocation) {
        setSelectedOption({
          value: defaultLocation,
          label: defaultLocation?.name,
        });
      }
    }
  }, [selectedLocation, allLocations]);

  const changeLocation = async (id) => {
    try {
      await User.updateLocation(id);
      toast.success("Location Changed!");
    } catch (error) {
      console.error("Error changing location:", error);
      toast.error("Error occurred during location switching. Try again!");
    }
  };

  const handleApplicantClick = (jobId, applicantId, navigateCondition) => {
    if (selectedOption) {
      dispatch(setSelectedLocation(selectedOption?.value));
      localStorage.setItem(
        "selectedLocation",
        JSON.stringify(selectedOption?.value)
      );
      if (selectedOption?.value?.name !== selectedLocation?.name) {
        changeLocation(selectedOption?.value?.id);
      }
      if (navigateCondition === "applicant") {
        navigate(
          `/business/applicant/${encodeIds(jobId)}/${encodeIds(applicantId)}`,
          {
            state: {
              isSearchingSpecificApplication: true,
            },
          }
        );
      } else {
        navigate(`/business/applicant/${encodeIds(jobId)}`);
      }
      dispatch(setInitialChatFetch(false));
      dispatch(setVisiblePersons([]));
      dispatch(setMinimizedPersons([]));
      dispatch(setSelectedPersons([]));
    }
  };

  const renderApplicantRow = (application) => {
    if (!application?.job_seeker) return null;

    const isRejectedOrCanceled =
      application?.job_seeker?.status === "rejected" ||
      application?.job_seeker?.status === "canceled";

    const statusColorClass = isRejectedOrCanceled
      ? "text-red-600 dark:text-red-400 bg-red-50 dark:bg-red-950/30"
      : "text-green-600 dark:text-green-400 bg-green-50 dark:bg-green-950/30";

    const statusText =
      application?.job_seeker?.status === "applied"
        ? "Pending"
        : application?.job_seeker?.status || "Unknown";

    const firstName = application?.job_seeker?.first_name || "";
    const lastName = application?.job_seeker?.last_name || "";

    return (
      <div
        key={application.job_seeker_id}
        className="flex items-center justify-between transition-colors bg-gray-50 dark:bg-opacity-5 hover:bg-gray-50/45 dark:hover:bg-darkGray p-3 rounded-lg cursor-pointer"
        onClick={() =>
          handleApplicantClick(
            application?.job_seeker?.job_id,
            application?.job_seeker?.job_seeker_id,
            "applicant"
          )
        }
      >
        <div className="flex items-center space-x-3 flex-1 min-w-0">
          {application?.photo?.thumb_url ? (
            <img
              alt=""
              src={application.photo.thumb_url}
              className="h-9 w-9 rounded-full transition-transform duration-200 hover:scale-110"
            />
          ) : (
            <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center flex-shrink-0 transition-transform duration-200 hover:scale-110">
              {firstName[0]?.toUpperCase() || "?"}
            </div>
          )}
          <div className="min-w-0 flex-1">
            <p className="text-gray-600 dark:text-gray-200 font-medium truncate">
              {`${firstName} ${lastName}`.trim() || "Unknown Applicant"}
            </p>
            <p className="text-gray-500 text-sm truncate">
              {application.job_title || "Untitled Position"}
            </p>
            {application?.job_seeker?.created_at && (
              <p className="text-gray-400 dark:text-gray-500 text-xs mt-1 truncate">
                Applied on{" "}
                {moment(application.job_seeker.created_at).format(
                  "MMM DD, YYYY"
                )}
              </p>
            )}
          </div>
        </div>
        <span
          className={`text-sm ${statusColorClass} px-2 py-1 rounded-full whitespace-nowrap ml-2 flex-shrink-0`}
        >
          {statusText}
        </span>
      </div>
    );
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="flex flex-col md:flex-row justify-between items-start mb-6">
        <h1 className="text-xl xl:text-2xl font-bold text-gray-900 dark:text-gray-300">
          Analytics Dashboard
        </h1>
        {renderLocationDropdown()}
      </div>

      <div className="pb-5 mx-auto">
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
            <div className="relative overflow-hidden rounded-lg px-4 py-5 dark:bg-[#242526] border border-warmgray-200 dark:border-darkGray shadow-md shadow-gray-500 dark:shadow-none sm:px-6 sm:py-6 bg-transparent">
              <dt>
                <div className="absolute rounded-lg bg-gray-50 p-3">
                  <img src={OpenJobs} alt="logo" className="h-6 w-6" />
                </div>
                <p className="ml-16 truncate font-bold text-sm text-[#333333] dark:text-gray-300">
                  Open Jobs
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline">
                <p className="text-2xl font-semibold text-gray-900 dark:text-gray-300">
                  {loading ? "-" : statCount?.total_job}
                </p>
              </dd>
            </div>
            <div className="relative overflow-hidden rounded-lg bg-transparent dark:bg-[#242526] border border-warmgray-200 dark:border-darkGray px-4 py-5 shadow-md shadow-gray-500 dark:shadow-none sm:px-6 sm:py-6">
              <dt>
                <div className="absolute rounded-lg bg-gray-50 p-3">
                  <img src={applicantBlueIcon} alt="logo" className="h-6 w-6" />
                </div>
                <p className="ml-16 truncate text-sm font-bold text-[#333333] dark:text-gray-300">
                  Open Applicants
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline">
                <p className="text-2xl font-semibold text-gray-900 dark:text-gray-300">
                  {loading ? "-" : statCount?.total_applied}
                </p>
              </dd>
            </div>
            <div className="relative overflow-hidden rounded-lg bg-transparent dark:bg-[#242526] border border-warmgray-200 dark:border-darkGray px-4 py-5 shadow-md dark:shadow-none shadow-gray-500 sm:px-6 sm:py-6">
              <dt>
                <div className="absolute rounded-md bg-gray-50 p-3">
                  <img src={hiredBlueIcon} alt="logo" className="h-6 w-6" />
                </div>
                <p className="ml-16 truncate text-sm font-bold text-[#333333] dark:text-gray-300">
                  Total Hired
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline">
                <p className="text-2xl font-semibold text-gray-900 dark:text-gray-300">
                  {loading ? "-" : statCount?.total_hired}
                </p>
              </dd>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6 text-[#333333]">
            {/* Ongoing Conversation Table */}
            <div className="rounded-lg overflow-hidden shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1),0_2px_4px_-1px_rgba(0,0,0,0.06)] border border-warmgray-200 dark:border-darkGray dark:shadow-none">
              <div className="flex justify-between px-6 py-4 border-b border-warmgray-200 dark:border-darkGray">
                <div className="font-bold text-base dark:text-gray-300">
                  Ongoing Conversation
                </div>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full min-w-[540px]">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-primary-500 dark:bg-darkGray text-white uppercase text-xs font-semibold text-left tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 bg-primary-500 dark:bg-darkGray text-white uppercase text-xs font-semibold text-left tracking-wider">
                        Position
                      </th>
                      <th className="px-6 py-3 bg-primary-500 dark:bg-darkGray text-white uppercase text-xs font-semibold text-left tracking-wider">
                        Date / Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {conversationLoading ? (
                      <tr>
                        <td
                          colSpan="3"
                          className="text-center py-4 text-gray-900 dark:text-gray-500"
                        >
                          <div className="animate-pulse">Loading...</div>
                        </td>
                      </tr>
                    ) : chatInfo?.length > 0 ? (
                      chatInfo?.slice(0, 5)?.map((chat, index) => (
                        <tr
                          key={index}
                          className="border-b last:border-b-0 hover:bg-gray-50/50 dark:hover:bg-transparent transition-colors"
                        >
                          <td className="px-6 py-3">
                            <div className="flex items-center">
                              <div
                                onClick={() =>
                                  handleApplicantClick(
                                    chat?.job_id,
                                    chat?.job_seeker_id,
                                    "applicant"
                                  )
                                }
                                className="text-gray-600 dark:text-gray-300 text-sm font-medium hover:text-blue-500 truncate cursor-pointer"
                              >
                                {chat?.seeker?.first_name || ""}{" "}
                                {chat?.seeker?.last_name || ""}
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-3">
                            <span
                              className="text-[13px] font-medium text-gray-400 dark:text-gray-300 whitespace-nowrap cursor-pointer"
                              onClick={() =>
                                handleApplicantClick(chat?.job_id, "job")
                              }
                            >
                              {chat?.position?.title || "Untitled Position"}
                            </span>
                          </td>
                          <td className="px-6 py-3">
                            <span className="text-[13px] font-medium text-gray-400 dark:text-gray-300 whitespace-nowrap">
                              {chat?.last_conversation?.results?.[0]?.updatedAt
                                ? formatDateWithTime(
                                    chat?.last_conversation?.results?.[0]
                                      ?.updatedAt
                                  )
                                : "-"}
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center py-4">
                          {!conversationLoading && (
                            <span className="text-gray-400 dark:text-gray-500">
                              No conversations
                            </span>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Applicants Table */}
            <div className="rounded-lg overflow-hidden shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1),0_2px_4px_-1px_rgba(0,0,0,0.06)] border border-warmgray-200 dark:border-darkGray dark:shadow-none">
              <div className="flex justify-between px-6 py-4 border-b border-warmgray-200 dark:border-darkGray">
                <div className="font-bold text-base dark:text-gray-300">
                  Applicants
                </div>
                <button
                  className="text-sm text-blue-600 dark:text-warmgray-400 hover:underline"
                  onClick={() =>
                    navigate("/analytics/applicants", {
                      state: { applicantsData, selectedOption },
                    })
                  }
                >
                  View All
                </button>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-primary-500 dark:bg-darkGray text-white uppercase text-xs font-semibold text-left tracking-wider whitespace-nowrap">
                        Name
                      </th>
                      <th className="px-6 py-3 bg-primary-500 dark:bg-darkGray text-white uppercase text-xs font-semibold text-left tracking-wider whitespace-nowrap">
                        Position Applied
                      </th>
                      <th className="px-6 py-3 bg-primary-500 dark:bg-darkGray text-white uppercase text-xs font-semibold text-left tracking-wider whitespace-nowrap">
                        Response Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {applicantsLoading ? (
                      <tr>
                        <td
                          colSpan="3"
                          className="text-center py-4 text-gray-900 dark:text-gray-500"
                        >
                          <div className="animate-pulse">Loading...</div>
                        </td>
                      </tr>
                    ) : applicantsData?.length > 0 ? (
                      applicantsData?.slice(0, 5)?.map((applicant, index) => (
                        <tr
                          key={index}
                          className="border-b last:border-b-0 hover:bg-gray-50/50 dark:hover:bg-transparent transition-colors"
                        >
                          <td
                            className="px-6 py-3 text-left text-sm font-medium whitespace-nowrap dark:text-gray-300 cursor-pointer hover:underline"
                            onClick={() =>
                              handleApplicantClick(
                                applicant?.job_seeker?.job_id,
                                applicant?.job_seeker?.job_seeker_id,
                                "applicant"
                              )
                            }
                          >
                            {applicant?.job_seeker?.first_name || ""}{" "}
                            {applicant?.job_seeker?.last_name || ""}
                          </td>
                          <td
                            className="px-6 py-3 whitespace-nowrap dark:text-gray-300 cursor-pointer hover:underline"
                            onClick={() =>
                              handleApplicantClick(
                                applicant?.job_seeker?.job_id,
                                "job"
                              )
                            }
                          >
                            {applicant?.job_title || "Untitled Position"}
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap dark:text-gray-300">
                            {(() => {
                              const responseTime =
                                applicant?.response_time?.toLowerCase() || "";
                              let tagColor = "bg-green-100 text-green-800";

                              if (
                                responseTime?.includes("year") ||
                                responseTime?.includes("1+ years")
                              ) {
                                tagColor =
                                  "text-red-600 dark:text-red-400 bg-red-50 dark:bg-red-500/20";
                              } else if (responseTime?.includes("month")) {
                                tagColor =
                                  "text-orange-600 dark:text-orange-400 bg-orange-50 dark:bg-orange-500/20";
                              } else if (responseTime?.includes("day")) {
                                tagColor =
                                  "text-yellow-600 dark:text-yellow-400 bg-yellow-50 dark:bg-yellow-500/20";
                              }

                              return (
                                <span
                                  className={`px-2 py-1 rounded-full font-medium ${tagColor}`}
                                >
                                  {applicant?.response_time || "N/A"}
                                </span>
                              );
                            })()}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center py-4">
                          {!applicantsLoading && (
                            <span className="text-gray-400 dark:text-gray-500">
                              No applicants
                            </span>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
            {applicantsData && statCount && chatInfo && (
              <JobPieChart
                data={applicantsData}
                statCount={statCount}
                mode={mode}
                isInView={isInView}
                setIsInView={setIsInView}
                ongoingChats={chatInfo}
                conversationLoading={conversationLoading || false}
              />
            )}
            <div className="bg-white dark:bg-[#242526] shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1),0_2px_4px_-1px_rgba(0,0,0,0.06)] border border-warmgray-200 dark:border-darkGray dark:shadow-none rounded-lg p-5">
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-bold text-base dark:text-gray-300">
                  Recent Applicants
                </h3>
              </div>
              <div className="overflow-x-auto">
                {applicantsLoading ? (
                  <div className="flex justify-center items-center py-6">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-500"></div>
                  </div>
                ) : !applicantsData || applicantsData.length === 0 ? (
                  <div className="text-center text-gray-500 py-6">
                    No recent applicants
                  </div>
                ) : (
                  <div className="space-y-4 min-w-[350px]">
                    {applicantsData
                      .slice(0, 5)
                      .map((application, index) =>
                        renderApplicantRow(application)
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="p-6 lg:col-span-2 relative overflow-hidden rounded-lg px-4 pb-12 pt-5 dark:bg-[#242526] shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1),0_2px_4px_-1px_rgba(0,0,0,0.06)] border border-warmgray-200 dark:border-darkGray dark:shadow-none sm:px-6 sm:pt-6 bg-transparent">
            <div className="flex justify-between mb-4 items-start">
              <div className="font-bold text-base dark:text-gray-300">
                Applications by Position
              </div>
            </div>
            {applicationsChartLoading ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              allApplications &&
              allApplications.length > 0 && (
                <JobApplicationsChart
                  data={allApplications}
                  mode={mode}
                  selectedOption={selectedOption}
                />
              )
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default BusinessAnalyticDashboard;
