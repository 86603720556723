import React from "react";
import { useEffect } from "react";
// import { useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
// import {
//   useLocation,
//   useNavigate,
//   useParams,
//   useSearchParams,
// } from "react-router-dom";
// import {
//   selectRoles,
//   selectUser,
//   selectPermissions,
// } from "../../../../store/authSlice";
import { useState } from "react";
import { HiOutlineUpload } from "react-icons/hi";
// import Company from "../../../../api/Company";
// import useQuery from "../../../../hooks/useQuery";
// import { Tooltip } from "antd";
// import DropDown from "../../../../components/atoms/Selector/DropDown";
// import TurnKeyBusinesses from "../../../../api/TurnKeyBusinesses";
import ControlPanel from "../../../../api/ControlPanel";
// import { saveAs } from "file-saver";
// import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
// import { toast } from "react-hot-toast";
// import PopupNotification from "../../../../components/atoms/Notification/PopupNotification";
import { DropFileSection } from "../../../../components/molecules/DropFileSection";
import Feeds from "../../../../components/molecules/Feeds/Feeds";
import Divider from "../../../../components/atoms/Divider/Divider";

const BulkTurnkeyImport = ({ callback = false }) => {
  // const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] =
  //   useState(true);
  // const [autoUpdateApplicantDataEnabled, setAutoUpdateApplicantDataEnabled] =
  //   useState(false);
  // const { t } = useTranslation();
  // const roles = useSelector(selectRoles);
  // const [businessNameShowPanel, setBusinessNameShowPanel] = useState(false);
  // const query = useQuery();
  // const [turnkeyAccountList, setTurnkeyAccountList] = useState(false);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [sortCriteria, setSortCriteria] = useState("");
  // const [pageSize, setPageSize] = useState(50);
  // const [searchInput, setSearchInput] = useState("");
  // const [totalRecords, setTotalRecords] = useState(0);
  // const [openExportModal, setOpenExportModal] = useState(false);
  // const [exportOption, setExportOption] = useState(1);
  // const [allTurnkeyAccount, setAllTurnkeyAccount] = useState(false);
  // const [activatedTurnkeyAccounts, setActivatedTurnkeyAccounts] =
  //   useState(false);
  // const [notActivatedTurnkeyAccounts, setNotActivatedTurnkeyAccounts] =
  //   useState(false);
  // const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  // const [turnkeyAccountId, setTurnkeyAccountId] = useState([]);
  // const [showNotification, setShowNotification] = useState(false);
  const [logs, setLogs] = useState([]);
  const [file, setFile] = useState([]);
  const [validation, setValidation] = useState(false);
  // const [validationMessage, setValidationMessage] = useState(false);
  const [fileLabel, setFileLabel] = useState("XLSX File (.xlsx)");
  const [uploadStatus, setUploadStatus] = useState("no-file");
  const [fileKey, setFileKey] = useState("");
  // const [processingFileIndex, setProcessingFileIndex] = useState(2);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);

  // let link = process.env.REACT_APP_URL + "/easy-login/";

  useEffect(() => {
    callback("Turnkey Bulk Import");
  }, []);

  const handleFileUpload = (event) => {
    const fileSource = event.target.files[0];
    const formData = new FormData();
    formData.append("file", fileSource);
    setFile(fileSource);
    setFileLabel(fileSource.name);

    ControlPanel.validateUpload(formData)
      .then((response) => {
        const status = response.data.status;
        const message = response.data.message;
        const logs = response.data.logs;
        const fileKeyValue = response.data.file_key;
        const rows = response.data.row_count;

        if (status === 1) {
          setUploadStatus("success");
          setFileKey(fileKeyValue);
          setValidation(true);
          setTotalRows(rows);
        } else {
          setUploadStatus("error");
          setValidation(false);
        }

        const validationData = {
          id: 1,
          type: status === 1 ? "success" : "error",
          person: {
            name: message,
            comment: logs.length === 0 ? "" : JSON.stringify(logs),
          },
          date: new Date().toLocaleString() + "",
          dateTime: "2023-01-24T09:20",
        };
        setLogs((logs) => [...logs, validationData]);
      })
      .catch((error) => {
        const validationData = {
          id: 1,
          type: "error",
          person: {
            name: "Invalid or empty file. Please upload excel files only.",
          },
          date: new Date().toLocaleString() + "",
          dateTime: "2023-01-24T09:20",
        };
        setLogs((logs) => [...logs, validationData]);
      });
  };

  const processFile = () => {
    setLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("file_key", fileKey);
      let index = 2;
      let processedIndex = 0;

      const interval = setInterval(() => {
        if (processedIndex !== index && index <= totalRows) {
          ControlPanel.getLogs(fileKey, index)
            .then((response) => {
              const status = response.data.status;

              const validationData = {
                id: 1,
                type: status === 1 ? "success" : "warning",
                person: {
                  name:
                    status === 1
                      ? "row " + index + " created successfully!"
                      : "row " + index + " processing...",
                },
                date: new Date().toLocaleString() + "",
                dateTime: "2023-01-24T09:20",
              };
              setLogs((logs) => [...logs, validationData]);

              if (status === 1) {
                processedIndex = index;
                index++;
              }
            })
            .catch((error) => {
              const validationData = {
                id: 1,
                type: "error",
                person: {
                  name: "Error processing the file.",
                },
                date: new Date().toLocaleString() + "",
                dateTime: "2023-01-24T09:20",
              };
              setLogs((logs) => [...logs, validationData]);
            });
        }
      }, 500);

      ControlPanel.processAccountCreation(formData)
        .then((response) => {
          const timeoutId = setTimeout(() => {
            const validationData = {
              id: 1,
              type: "success",
              person: {
                name: "Account creation Complete!",
              },
              date: new Date().toLocaleString() + "",
              dateTime: "2023-01-24T09:20",
            };
            setLogs((logs) => [...logs, validationData]);
            clearInterval(interval);
            clearTimeout(timeoutId);
            setLoading(false);
            setValidation(false);
          }, 1000);
        })
        .catch((e) => {
          console.log("response: ", e);
        });
    }
  };

  return (
    <div>
      <div className="divide-y divide-warmgray-300">
        <div className="flex flex-col">
          {!loading && (
            <div>
              <div className="space-y-5 pb-10 relative top-10">
                <h3 className="text-md font-medium leading-6 text-gray-900 pb-2">
                  Import Excel Sheet
                </h3>
              </div>
              <div className="w-full border-10 shadow-sm border-black pl-1 pr-1">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div>
                      <DropFileSection
                        status={uploadStatus}
                        label={fileLabel}
                        handleFileUpload={handleFileUpload}
                        icon={
                          <HiOutlineUpload className="mx-auto h-10 w-10 text-gray-400" />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {validation && (
            <div className="pt-5 pb-5">
              <div className="pt-2 pb-2"></div>
              {loading ? (
                <div className="flex flex-row">
                  <h5 className="text-md font-medium leading-6 text-gray-900 pb-2 pt-2">
                    Creating Account ......
                  </h5>
                  <div className="flex items-left pl-3 pt-2">
                    <span className="animate-spin rounded-full h-5 w-5 border-t-1 border-b-2 border-primary-700"></span>
                  </div>
                </div>
              ) : (
                <div>
                  <Divider name="Upload Complete ! File is ready" />
                  <h5 className="text-md font-medium leading-6 text-gray-900 pb-2 pt-2">
                    Action
                  </h5>
                  <button
                    type="button"
                    className="block rounded-xl bg-primary-500 px-3 py-2 text-center text-sm font-semibold text-white 
                    shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                     focus-visible:outline-primary-700"
                    onClick={processFile}
                  >
                    Run - Creating Turnkey Accounts
                  </button>
                </div>
              )}
            </div>
          )}
          <div className="pt-2 pb-2">
            <Divider name="Logs" />
          </div>
          <div className="min-h-[500px] overflow-auto border-2 border-dotted border-gray-300 p-5">
            <h5 className="text-md font-medium leading-6 text-gray-900 pb-2">
              Activity Logs
            </h5>
            <Feeds data={logs} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkTurnkeyImport;
