import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../components/organisms/Layout";
import { Space } from "antd";
import AccountView from "./AccountView";
import NotificationView from "./NotificatonView";
import { useNavigate } from "react-router-dom";

const tabItems = [
  {
    title: "Account",
    content: <AccountView />,
  },
  {
    title: "Notifications & Alerts",
    content: <NotificationView />,
  },
  {
    title: "Payments",
    content: "/payments",
    type: "link",
    to: "/payments",
  },
];

const MyAccount = () => {
  const [active, setActive] = useState(0);
  const [content, setContent] = useState(tabItems[0].content);
  const changeContent = (value, i) => {
    setContent(value);
    setActive(i);
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Account Information";
  }, []);

  return (
    <DashboardLayout darmo={true}>
      <div className="flex flex-row py-4 overflow-x-auto overflow-hidden">
        {tabItems.map((item, i) => (
          <TabNav
            key={i}
            title={item.title}
            index={i}
            content={item.content}
            active={active === i}
            type={item.type}
            onClick={changeContent}
          />
        ))}
      </div>
      <div className="flex ">{content}</div>
    </DashboardLayout>
  );
};

export default MyAccount;

const TabNav = ({ index, title, content, onClick, active, type }) => {
  const navigate = useNavigate();
  const ClickHandler = () => {
    if (type !== "link") {
      onClick(content, index);
    } else {
      return navigate(content);
    }
  };
  return (
    <div className="h-16 pr-6 cursor-pointer" onClick={ClickHandler}>
      <Space size={17} align="center">
        <div className="">
          <h2
            className={`text-2xl whitespace-nowrap ${
              active
                ? "text-black dark:text-gray-300"
                : "text-gray-500 opacity-50"
            }`}
          >
            {title}
          </h2>
          {active && (
            <hr className="mt-0 h-0.5 bg-primary-500 dark:bg-darkGray" />
          )}
        </div>
      </Space>
    </div>
  );
};
