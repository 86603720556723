import React from "react";

const FloatingInputText = ({
  label,
  name,
  slug,
  className,
  icon,
  onChange,
  type,
  value,
  placeholder,
  disabled,
  error,
  id,
}) => {
  // const [passwordVisible, setPasswordVisible] = useState(false);
  // const passwordToggle = !passwordVisible ? (
  //   <i className="h-full text-gray-400 far fa-eye" />
  // ) : (
  //   <i className="h-full text-gray-400 far fa-eye-slash" />
  // );

  return (
    <div className={className}>
      <label className="block tracking-wide font-semibold text-gray-400 text-md mb-2">
        {label}
      </label>

      <div className="relative flex flex-row shadow-sm hover:border-primary-400 hover:border-1 shadow-gray-500 rounded-md w-full">
        <input
          type={type}
          autoComplete="new-password"
          value={value}
          id={`floating_filled ${id}`}
          className="flex rounded-md px-2.5 focus:outline-none pb-2.5 pt-5 w-full text-md peer"
          placeholder=" "
          disabled={disabled ? disabled : ""}
          tabindex="-1"
          onChange={(event) => onChange(event)}
        />
        {icon === "email" && (
          <div className="mx-2 my-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 1 24 24"
              stroke-width="2"
              stroke="#7D66D3"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z"
              />
            </svg>
          </div>
        )}
        <label
          for={`floating_filled ${id}`}
          class="absolute text-md text-gray-100 font-[60px] dark:text-gray-400  duration-300 transform -translate-y-4 scale-75 top-4 focus:top-5 z-10 origin-[0] left-2.5 peer-focus:text-primary-500 peer-focus:dark:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
        >
          {placeholder}
        </label>
      </div>

      {error && (
        <span className="block text-red-500">
          <small>{error}</small>
        </span>
      )}
    </div>
  );
};

export default FloatingInputText;
