import React, { useState } from "react";
import AuthLayout from "../../../components/organisms/Layout/Authlayout";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Subscription = () => {
  const featureList = [
    "Unlimited hires",
    "Unlimited Positions",
    "Chat-to-SMS",
    "QR Banner Designer",
    "Sub-Users",
    "Job Templates",
    "Hiring Form Templates",
    "Payroll/Onboarding Integration*",
    "Calendar Integration",
    "Customer Success Manager",
    "Multi-Location Management",
  ];
  const [enabled, setEnabled] = useState(true);

  return (
    <AuthLayout restrictSwitch={true}>
      <div className="flex flex-1 flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full rounded mx-4 h-full">
          <div className="w-full h-full">
            <div className="sm:mx-auto sm:w-full flex flex-col justify-center h-full">
              <div className="mb-8 sm:mx-auto sm:w-full lg:max-w-lg sm:max-w-[600px] h-full">
                <div className="flex flex-col h-full items-center justify-center sm:rounded-lg sm:px-12 lg:px-6">
                  <div className="flex">
                    <p
                      className={`text-[14px] font-bold mt-1 ${
                        enabled ? "text-gray-500" : "text-primary-500"
                      }`}
                    >
                      Billed Monthly
                    </p>
                    <Switch
                      checked={enabled}
                      onChange={setEnabled}
                      className="mx-3 bg-primary-500 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          enabled ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                    <p
                      className={`text-[14px] font-bold mt-1 ${
                        enabled ? "text-primary-500" : "text-gray-500"
                      }`}
                    >
                      Billed Yearly (save{" "}
                      <span className="tracking-tighter">33</span>%)
                    </p>
                  </div>
                  <div className="mt-4 border-2 rounded-md shadow-md shadow-primary-300">
                    <div className="m-6">
                      <div className="flex flex-col gap-2">
                        <p className="font-bold text-2xl text-primary-500">
                          {enabled ? "Annual" : "Monthly"}{" "}
                          <span className="text-black">Subscription</span>
                        </p>
                        <p className="font-bold text-2xl">
                          <span className="tracking-tighter">
                            {enabled ? "$99.99" : "$149.99"}
                          </span>
                          <span className="text-[14px] font-normal">
                            /month
                          </span>
                        </p>
                        <p className="font-normal text-primary-500">
                          {enabled ? (
                            <span>
                              Yearly subscription{" "}
                              <span className="text-green-500 font-bold">
                                Save 33%
                              </span>
                            </span>
                          ) : (
                            "Month to month. Cancel anytime!"
                          )}
                        </p>
                      </div>
                      <hr className="border-gray-300 my-6" />
                      <div className="flex flex-col gap-1">
                        <p className="font-normal mb-2 mr-0 md:mr-6">
                          Enjoy all these features and hire at ease!
                        </p>

                        {featureList?.map((item) => (
                          <div className="flex items-center gap-0.5">
                            <div className="relative flex h-5 w-5 items-center justify-center rounded-full bg-primary-500 mr-2">
                              <CheckIcon
                                className="h-3 w-3 text-white"
                                aria-hidden="true"
                              />
                            </div>
                            <span className="font-bold text-[15px] mt-1">
                              {item}
                            </span>
                          </div>
                        ))}
                      </div>

                      <div className="flex justify-center mt-6">
                        <button
                          className="font-bold text-sm mr-2 px-6 py-2 w-32 rounded-xl bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                          type="button"
                          // onClick={() => navigate("/register")}
                        >
                          Checkout
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Subscription;
