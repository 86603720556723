import React, { useEffect } from "react";
import { NylasSchedulerEditor } from "@nylas/react";
import { DashboardLayout } from "../../../components/organisms/Layout";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/authSlice";
import Scheduler from "../../../api/Scheduler";
import { toast } from "react-hot-toast";

export default function ScheduleEditorPage() {
  const user = useSelector(selectUser);

  // Function to store user mapping when logged in to Nylas
  const storeUserMapping = (nylasUserData) => {
    try {
      const mapping = {
        email: user?.email,
        at_hash: nylasUserData.at_hash,
        updated_at: new Date().toISOString(),
      };
      localStorage.setItem("nylas_user_mappings", JSON.stringify(mapping));
    } catch (error) {
      console.error("Error storing user mapping:", error);
    }
  };

  // Function to check if current session is valid
  const checkNylasSession = () => {
    try {
      const nylasUsersData = localStorage.getItem("user");
      const mappingData = localStorage.getItem("nylas_user_mappings");

      if (!nylasUsersData || !mappingData) return;

      const nylasUsers = JSON.parse(nylasUsersData);
      const currentNylasUser = nylasUsers[0];
      const storedMapping = JSON.parse(mappingData);

      // Clear data if either email or hash doesn't match
      if (
        storedMapping.email !== user?.email ||
        storedMapping.at_hash !== currentNylasUser?.at_hash
      ) {
        clearNylasData();
      }
    } catch (error) {
      console.error("Error checking Nylas session:", error);
    }
  };

  // Function to clear both Nylas storages
  const clearNylasData = () => {
    try {
      localStorage.removeItem("user");
      localStorage.removeItem("nylas_user_mappings");
      window.location.reload();
    } catch (error) {
      console.error("Error clearing Nylas data:", error);
    }
  };

  // Effect to check session when component mounts
  useEffect(() => {
    if (user?.email) {
      checkNylasSession();
    }
  }, [user?.email]);

  const handleChangeConfig = async (event) => {
    try {
      const payload = {
        user_id: user?.id,
        company_id: user?.company_id,
        config_id: event?.detail?.config?.id,
        email: event?.detail?.config?.participants[0]?.email,
        event_booking: JSON.stringify(event?.detail?.config?.event_booking),
        availability: JSON.stringify({
          duration_minutes:
            event?.detail?.config?.availability?.duration_minutes,
          interval_minutes:
            event?.detail?.config?.availability?.interval_minutes,
        }),
        timestamp: event?.timeStamp,
      };
      if (event?.detail?.config?.id) {
        await Scheduler.addSchedulerConfig(payload);
      }
      toast.success("Changes saved!");
    } catch (error) {
      toast.error("Error saving the configuration.");
      console.error(error);
    }
  };

  const handleDeleteConfig = async (event) => {
    try {
      await Scheduler.deleteById(event?.detail?.configuration?.id);
      toast.success("Deleted successfully!");
    } catch (error) {
      toast.error("Error deleting the configuration.");
      console.error(error);
    }
  };

  return (
    <DashboardLayout>
      <div className="px-4 py-5 sm:p-6">
        <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-300 mb-6">
          Scheduler Availability Settings
        </h1>
        <div className="relative">
          <NylasSchedulerEditor
            onLoggedIn={() => {
              const nylasUsersData = localStorage.getItem("user");
              if (nylasUsersData) {
                const nylasUsers = JSON.parse(nylasUsersData);
                if (nylasUsers[0]) {
                  storeUserMapping(nylasUsers[0]);
                }
              }
            }}
            onSchedulerConfigCloseClicked={() => {
              clearNylasData();
            }}
            eventOverrides={{
              schedulerConfigChanged: async (event) => {
                if (event?.type === "schedulerConfigChanged") {
                  handleChangeConfig(event);
                }
              },
              schedulerConfigCreated: async (event) => {
                if (event?.type === "schedulerConfigCreated") {
                  handleChangeConfig(event);
                }
              },
              deleteButtonClick: async (event) => {
                handleDeleteConfig(event);
              },
            }}
            schedulerPreviewLink={`${window.location.origin}/preview?config_id={config.id}`}
            nylasSessionsConfig={{
              clientId: process.env.REACT_APP_NYLAS_CLIENT_ID,
              redirectUri: `${window.location.origin}/scheduler-editor`,
              domain: "https://api.us.nylas.com/v3",
              hosted: true,
              accessType: "offline",
            }}
            defaultSchedulerConfigState={{
              selectedConfiguration: {
                requires_session_auth: false,
                scheduler: {
                  email_template: {
                    logo: "https://s3.us-east-2.amazonaws.com/assets.heyhire.com/img/HH-Logo-P.png",
                    booking_confirmed: {
                      title: `Upcoming interview with ${user?.company?.name}`,
                      body: `Your interview with ${user?.first_name} ${user?.last_name} from ${user?.company?.name} is scheduled successfully.\nPlease make sure you add it to your calendar. You got this!\n-The HeyHire Team`,
                    },
                  },
                  rescheduling_url: `${window.location.origin}/reschedule/:booking_ref`,
                  cancellation_url: `${window.location.origin}/cancel/:booking_ref`,
                },
              },
            }}
            hideEditorTabs={["communications", "pageStyles"]}
            enableUserFeedback={false}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}
