import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  locations,
  setChatApplicant,
  setInitialChatFetch,
  setMinimizedPersons,
  setSelectedLocation,
  setSelectedPersons,
  setVisiblePersons,
} from "../../../store/authSlice";
import User from "../../../api/User";
import { toast } from "react-hot-toast";

export default function StackedList({
  data = [],
  showButton = false,
  selectedLocation,
  onClose,
  loading,
  open,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allLocations = useSelector(locations);
  const hasData = data?.length > 0;
  const [filter, setFilter] = useState("all");
  const unreadMessages = data.filter((item) => item.status === null);
  const filteredData =
    filter === "unread" ? data.filter((item) => item.status === null) : data;

  useEffect(() => {
    if (open) setFilter("all");
  }, [open]);

  const changeLocation = async (id) => {
    try {
      await User.updateLocation(id);
      toast.success("Location Changed!");
    } catch (error) {
      toast.error("Error Occurred during Location switching. Try Again!");
    }
  };

  const handleNotification = (applicant) => {
    if (selectedLocation?.name === "Select A Location") {
      const matchingLocation = allLocations?.find(
        (location) => location.id === applicant?.location_id
      );
      if (matchingLocation) {
        dispatch(setSelectedLocation(matchingLocation));
        localStorage.setItem(
          "selectedLocation",
          JSON.stringify(matchingLocation)
        );
        changeLocation(matchingLocation.id);
        navigate(`/business/dashboard?location=${matchingLocation.id}`);
        dispatch(setInitialChatFetch(false));
        dispatch(setVisiblePersons([]));
        dispatch(setMinimizedPersons([]));
        dispatch(setSelectedPersons([]));
        dispatch(setChatApplicant(applicant));
      }
    } else {
      dispatch(setChatApplicant(applicant));
    }
    onClose();
  };

  const handleViewAll = (e) => {
    e.stopPropagation();
    if (onClose) onClose();
    navigate("/notifications");
  };

  return (
    <div
      id="notifications-dropdown"
      className="overflow-y-auto overflow-x-hidden h-full max-h-[calc(100vh-125px)]"
    >
      <div className="px-4 py-1 border-b dark:border-gray-400 bg-white dark:bg-darkGray">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-300">
          Notifications
        </h2>
        {hasData && (
          <div className="flex space-x-2 mt-2 mb-1">
            <button
              className={`px-4 py-2 text-xs font-semibold rounded-lg ${
                filter === "all"
                  ? "bg-primary-500 dark:bg-warmgray-500 text-white"
                  : "bg-gray-300 dark:bg-gray-600 text-gray-900 dark:text-gray-300"
              }`}
              onClick={() => setFilter("all")}
              disabled={loading}
            >
              All
            </button>
            <button
              className={`px-4 py-2 text-xs font-semibold rounded-lg ${
                filter === "unread"
                  ? "bg-primary-500 dark:bg-warmgray-500 text-white"
                  : "bg-gray-300 dark:bg-gray-600 text-gray-900 dark:text-gray-300 disabled:text-gray-400"
              }`}
              onClick={() => setFilter("unread")}
              disabled={unreadMessages.length === 0 || loading}
            >
              Unread
            </button>
          </div>
        )}
      </div>
      {loading ? (
        <div className="p-4">
          <div className="animate-pulse flex flex-col gap-4">
            <div>
              <div className="h-2 bg-gray-300 rounded w-full mb-2"></div>{" "}
              <div className="h-2 bg-gray-300 rounded w-1/2"></div>{" "}
            </div>
            <div>
              <div className="h-2 bg-gray-300 rounded w-full mb-2"></div>{" "}
              <div className="h-2 bg-gray-300 rounded w-1/2"></div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <>
          <ul className="divide-y divide-primary-200 dark:divide-gray-400">
            {!hasData && (
              <li className="flex items-center justify-center cursor-pointer font-bold shadow-lg gap-x-2 py-3 p-3 hover:bg-primary-100 dark:hover:bg-darkGray dark:text-gray-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                </svg>{" "}
                You are up to date!
              </li>
            )}
            {filteredData?.map((item) => (
              <li
                key={item.id}
                className={`flex items-center justify-between gap-x-6 py-3 p-3 shadow-sm ${
                  item.status
                    ? "hover:bg-primary-100 dark:hover:bg-warmgray-700"
                    : "bg-primary-100 dark:bg-warmgray-800 hover:bg-primary-200 dark:hover:bg-warmgray-600"
                }`}
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNotification(item?.details);
                  }}
                  className="flex gap-x-3 cursor-pointer w-full relative"
                >
                  <img
                    className="h-10 w-10 mt-1 flex-none rounded-full bg-gray-50"
                    src={item.imageUrl}
                    alt=""
                  />
                  <div className="min-w-0 flex-1 pr-5">
                    <p className="text-gray-900 text-[12px]">
                      <span className="text-primary-500 dark:text-gray-300 font-bold">
                        {item.name}{" "}
                      </span>
                      <span className="font-semibold text-warmgray-600 dark:text-warmgray-300">
                        sent you a message:{" "}
                      </span>
                      <span className="font-bold dark:text-gray-300">
                        {item?.message?.length > 30
                          ? `${item?.message?.substring(0, 30)}...`
                          : item?.message}
                      </span>
                    </p>
                    <p className="mt-1 truncate text-xs font-bold text-gray-500">
                      {item?.body}
                    </p>
                    {item.days === 0 ? (
                      item.hours === 0 ? (
                        item.minutes === 0 ? (
                          <p className="mt-1 truncate text-[10px] font-bold text-gray-500">
                            {item.seconds} seconds ago
                          </p>
                        ) : (
                          <p className="mt-1 truncate text-[10px] font-bold text-gray-500">
                            {item.minutes} minutes ago
                          </p>
                        )
                      ) : (
                        <p className="mt-1 truncate text-[10px] font-bold text-gray-500">
                          {item.hours} hours ago
                        </p>
                      )
                    ) : (
                      <p className="mt-1 truncate text-[10px] font-bold text-gray-500">
                        {item.days} days ago
                      </p>
                    )}
                  </div>
                  {item.status === null && (
                    <div className="rounded-full w-3 h-3 bg-primary-500 dark:bg-gray-300 absolute right-0 top-1/2 transform -translate-y-1/2"></div>
                  )}
                </div>
              </li>
            ))}
          </ul>
          {showButton && hasData && (
            <div className="px-2 pt-2 dark:border-t dark:border-gray-400">
              <div
                onClick={(e) => {
                  handleViewAll(e);
                }}
                className="flex w-full cursor-pointer items-center justify-center rounded-xl bg-primary-500 dark:bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 dark:ring-0 ring-inset ring-gray-300 hover:bg-primary-400 hover:text-white focus-visible:outline-offset-0"
              >
                View all
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
