import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../components/organisms/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  locations,
  readNotify,
  selectSelectedLocation,
  selectUser,
  setChatApplicant,
  setInitialChatFetch,
  setMinimizedPersons,
  setReadNotification,
  setSelectedLocation,
  setSelectedPersons,
  setVisiblePersons,
} from "../../store/authSlice";
import Notification from "../../api/Notification";
import { useNavigate } from "react-router-dom";
import User from "../../api/User";
import { toast } from "react-hot-toast";

const Notifications = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const readNotification = useSelector(readNotify);
  const selectedLocation = useSelector(selectSelectedLocation);
  const allLocations = useSelector(locations);

  useEffect(() => {
    document.title = "HeyHire Portal - Notifications";
    getNotifications();
  }, []);

  useEffect(() => {
    if (readNotification) {
      getNotifications();
      dispatch(setReadNotification(false));
    }
  }, [readNotification]);

  const getTimeDifference = (timestamp) => {
    const date1 = new Date(timestamp);
    const date2 = new Date();
    const timeDifference = Math.abs(date2 - date1);
    const secondsAgo = Math.floor(timeDifference / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
    if (daysAgo > 0) {
      return `${daysAgo}d ago`;
    } else if (hoursAgo > 0) {
      return `${hoursAgo}h ago`;
    } else if (minutesAgo > 0) {
      return `${minutesAgo}m ago`;
    } else {
      return `${secondsAgo}s ago`;
    }
  };

  const changeLocation = async (id) => {
    try {
      await User.updateLocation(id);
      toast.success("Location Changed!");
    } catch (error) {
      toast.error("Error Occurred during Location switching. Try Again!");
    }
  };

  const getNotifications = async () => {
    try {
      setLoading(true);
      const response = await Notification.getAllNotifications(
        user?.id,
        selectedLocation?.name !== "Select A Location"
          ? selectedLocation?.id
          : undefined
      );
      const sortedNotifications = response.data.data.sort((a, b) => {
        const dateA = new Date(a.last_conversation?.createdAt);
        const dateB = new Date(b.last_conversation?.createdAt);
        return dateB - dateA;
      });
      setNotifications(sortedNotifications);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setNotifications([]);
    } finally {
      setLoading(false);
    }
  };

  const handleNotificationClick = (applicant) => {
    if (selectedLocation?.name === "Select A Location") {
      const matchingLocation = allLocations?.find(
        (location) => location.id === applicant?.location_id
      );
      if (matchingLocation) {
        dispatch(setSelectedLocation(matchingLocation));
        localStorage.setItem(
          "selectedLocation",
          JSON.stringify(matchingLocation)
        );
        changeLocation(matchingLocation.id);
        navigate(`/business/dashboard?location=${matchingLocation.id}`);
        dispatch(setInitialChatFetch(false));
        dispatch(setVisiblePersons([]));
        dispatch(setMinimizedPersons([]));
        dispatch(setSelectedPersons([]));
        dispatch(setChatApplicant(applicant));
      }
    } else {
      dispatch(setChatApplicant(applicant));
    }
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="sm:flex sm:items-center mb-6">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-semibold leading-6 text-gray-900 dark:text-gray-300 border-b border-warmgray-300 dark:border-warmgray-500 pb-2">
            Notifications
          </h1>
          <p className="mt-2 text-sm text-gray-600 dark:text-warmgray-400">
            Recent messages and notifications
          </p>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center py-6">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-500"></div>
        </div>
      ) : (
        <div className="flex flex-col gap-1">
          {notifications?.length > 0 ? (
            notifications?.map((notification, index) => (
              <div
                key={index}
                onClick={() => handleNotificationClick(notification)}
                className={`flex items-center py-2 px-2 rounded-xl cursor-pointer 
                  ${
                    notification?.is_read === 0
                      ? "bg-warmgray-100 dark:bg-darkGray"
                      : "bg-warmgray-50 dark:bg-gray-600"
                  }`}
              >
                <div className="flex-shrink-0 mr-2.5">
                  <div className="h-9 w-9 rounded-full bg-gray-200 flex items-center justify-center">
                    {notification?.seeker?.photo ? (
                      <div className="h-9 w-9">
                        <img
                          alt={`${notification?.seeker?.first_name}'s profile`}
                          src={notification?.seeker?.photo?.thumb_url}
                          className="w-full h-full rounded-full object-cover border-2 border-purple-100"
                        />
                      </div>
                    ) : (
                      <div className="h-9 w-9 bg-blue-100 rounded-full flex items-center justify-center flex-shrink-0 text-lg">
                        {notification?.seeker?.first_name[0]?.toUpperCase()}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-1 flex items-center justify-between min-w-0">
                  <p
                    className={`text-sm ${
                      notification?.is_read === 0 ? "font-medium" : ""
                    } text-gray-900 dark:text-gray-300 truncate`}
                  >
                    <span className="font-bold">
                      {notification?.seeker?.first_name +
                        " " +
                        notification?.seeker?.last_name}{" "}
                      :
                    </span>{" "}
                    <span className="text-gray-600 dark:text-warmgray-400">
                      {notification?.last_conversation?.text}
                      {notification?.conversation_count > 1 &&
                        ` + ${
                          notification.conversation_count - 1
                        } other message${
                          notification.conversation_count - 1 > 1 ? "s" : ""
                        }`}
                    </span>
                  </p>
                  <div className="flex items-center ml-2 flex-shrink-0">
                    <p className="text-xs text-gray-900 dark:text-gray-300">
                      {getTimeDifference(
                        notification?.last_conversation?.createdAt
                      )}
                    </p>
                    {notification?.is_read === 0 && (
                      <div className="ml-2 h-3 w-3 rounded-full bg-primary-500 dark:bg-gray-300"></div>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-10 text-gray-500 dark:text-gray-400">
              No notifications found
            </div>
          )}
        </div>
      )}
    </DashboardLayout>
  );
};

export default Notifications;
