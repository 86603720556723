import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../components/organisms/Layout";
import Input from "../../components/form/Input";
import { addIcon } from "../../assets/images";
import TextArea from "../../components/form/TextArea";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";
import "./rangepicker.scss";
import ShiftAddOrEdit from "../../components/molecules/ShiftAddOrEdit/ShiftAddOrEdit";
import ShiftJobCard from "../../components/molecules/ShiftJobCard/ShiftJobCard";
import { useDispatch, useSelector } from "react-redux";
import {
  darkmode,
  eventDetails,
  isEventPage,
  nextShiftPage,
  setEventDetails,
  setEventPage,
  setNextShiftPage,
  shiftsArray,
  sidebar,
} from "../../store/authSlice";

const AddEvent = () => {
  const dispatch = useDispatch();
  const mode = useSelector(darkmode);
  const open = useSelector(sidebar);
  const newShift = useSelector(nextShiftPage);
  const [formData, setFormData] = useState({
    eventName: "",
    eventAddress: "",
    isRevealEvent: false,
    eventDetails: "",
    dressCode: "",
    selectedRange: "",
  });
  const [errors, setErrors] = useState({});
  // const [loading, setLoading] = useState(false);
  const [editShift, setEditShift] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const existingShifts = useSelector(shiftsArray);
  const isNextStep = useSelector(isEventPage);
  const eventData = useSelector(eventDetails);
  let now = new Date();
  let starts = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
  );
  let ends = moment(starts).add(1, "days").subtract(1, "seconds");
  let [start, setstart] = useState(starts);
  let [end, setend] = useState(ends);
  let local = {
    format: "DD-MM-YYYY HH:mm",
    sundayFirst: false,
  };
  let applyCallback = (startDate, endDate) => {
    setstart(startDate);
    setend(endDate);

    const formattedStartDate = moment(startDate).format("MMM DD YYYY HH:mm:ss");
    const formattedEndDate = moment(endDate).format("MMM DD YYYY HH:mm:ss");
    const selectedRange = `${formattedStartDate} - ${formattedEndDate}`;

    setFormData((prevData) => ({ ...prevData, selectedRange }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.eventName) newErrors.eventName = "Event name is required";
    if (!formData.eventAddress)
      newErrors.eventAddress = "Event address is required";
    if (!formData.eventDetails)
      newErrors.eventDetails = "Event details are required";
    if (!formData.dressCode) newErrors.dressCode = "Dress code is required";
    if (!formData.selectedRange)
      newErrors.selectedRange = "Date range is required";
    return newErrors;
  };

  const handleNextPage = () => {
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      dispatch(setEventDetails(formData));
      dispatch(setEventPage(true));
    }
  };

  const addEvent = () => {
    if (eventData && existingShifts && existingShifts.length > 0) {
      const payload = {
        event: eventData,
        shifts: existingShifts,
      };
      console.log(payload);
    }
  };

  useEffect(() => {
    if (existingShifts && existingShifts.length > 0) {
      dispatch(setNextShiftPage(true));
    }
  }, []);

  useEffect(() => {
    if (eventData) {
      setFormData(eventData);
    }
  }, [eventData]);

  const handleEditShift = (index) => {
    if (existingShifts) {
      dispatch(setNextShiftPage(false));
      setEditShift(existingShifts[index]);
      setEditIndex(index);
    }
  };

  return (
    <DashboardLayout darmo={true}>
      {!isNextStep && (
        <div className="mt-4 w-full max-w-7xl">
          <div className="flex flex-col gap-1 xl:gap-3">
            <h2 className="text-xl xl:text-2xl font-bold text-gray-900 dark:text-gray-300">
              Where is the shift?
            </h2>
          </div>

          <div className="text-left snap-start pt-6 pb-8 mb-4 w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:w-3/4">
              <div>
                <Input
                  name="eventName"
                  label="Event Name"
                  type="text"
                  value={formData.eventName}
                  placeholder="Event Name"
                  onChange={handleChange}
                  darmo={mode}
                />
                {errors.eventName && (
                  <span className="text-red-400 font-bold text-xs">
                    {errors.eventName}
                  </span>
                )}

                <div>
                  <Input
                    className="mt-6"
                    name="eventAddress"
                    label="Event Address"
                    type="text"
                    value={formData.eventAddress}
                    placeholder="Event Address"
                    onChange={handleChange}
                    darmo={mode}
                  />
                  {errors.eventAddress && (
                    <span className="text-red-400 font-bold text-xs">
                      {errors.eventAddress}
                    </span>
                  )}

                  <div className="flex mt-2">
                    <input
                      className="mr-2 cursor-pointer my-auto"
                      type="checkbox"
                      name="isRevealEvent"
                      checked={formData.isRevealEvent}
                      onChange={handleChange}
                    />
                    <span className="text-gray-900 dark:text-gray-300">
                      Only reveal event location after accepting shifter.
                    </span>
                  </div>

                  <div className="mt-5">
                    <TextArea
                      name="eventDetails"
                      label="Event Details"
                      value={formData.eventDetails}
                      placeholder={
                        "You can talk about your culture, service, mission or anything that  best describes your business"
                      }
                      onChange={handleChange}
                      darmo={mode}
                    />
                    {errors.eventDetails && (
                      <span className="text-red-400 font-bold text-xs">
                        {errors.eventDetails}
                      </span>
                    )}
                  </div>

                  <div className="mt-4">
                    <TextArea
                      name="dressCode"
                      label="Dress Code"
                      value={formData.dressCode}
                      placeholder={
                        "You can talk about your culture, service, mission or anything that  best describes your business"
                      }
                      onChange={handleChange}
                      darmo={mode}
                    />
                    {errors.dressCode && (
                      <span className="text-red-400 font-bold text-xs">
                        {errors.dressCode}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <DateTimeRangeContainer
                  // ranges={ranges}
                  start={start}
                  end={end}
                  local={local}
                  applyCallback={applyCallback}
                >
                  <Input
                    name="selectedRange"
                    label="Select Date"
                    type="text"
                    value={formData.selectedRange}
                    placeholder="Select Range"
                    darmo={mode}
                    onChange={handleChange}
                  />
                </DateTimeRangeContainer>
                {errors.selectedRange && (
                  <span className="text-red-400 font-bold text-xs">
                    {errors.selectedRange}
                  </span>
                )}
              </div>
            </div>

            <div className="flex w-full lg:w-3/4 mt-12">
              <button
                className="cursor-pointer flex justify-center items-center rounded-full bg-orange-500 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-orange-400 transition-all hover:opacity-50"
                onClick={(e) => {
                  e.preventDefault();
                  handleNextPage();
                }}
              >
                <img
                  src={addIcon}
                  alt="logo"
                  className="mr-2 my-auto w-4 h-4"
                />
                Add Shifts
              </button>
            </div>
          </div>
        </div>
      )}

      {isNextStep &&
        (existingShifts === null ||
          existingShifts?.length === 0 ||
          !newShift) && (
          <div className="mt-4 w-full max-w-7xl">
            <div className="flex flex-col gap-1 xl:gap-3">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-300">
                {editShift
                  ? `Edit a Shift to ${formData?.eventName}`
                  : `Add a shift to ${formData?.eventName}`}
              </h2>
            </div>
            <ShiftAddOrEdit
              type="event"
              editMode={editShift}
              editIndex={editIndex}
            />
          </div>
        )}

      {isNextStep && existingShifts?.length > 0 && newShift && (
        <div className="mt-4 w-full">
          <div className="flex flex-col gap-1 xl:gap-3">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-300">
              Add a shift to {formData?.eventName}
            </h2>
          </div>
          <div
            className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ${
              open ? "xl:grid-cols-3" : "xl:grid-cols-4"
            } w-full gap-4 mt-8`}
          >
            {existingShifts?.map((jb, index) => (
              <ShiftJobCard
                index={index}
                onClick={() => handleEditShift(index)}
                type="event"
                jobPosition={jb}
                darmo={true}
              />
            ))}
            <button
              className="my-auto w-36 cursor-pointer flex justify-center items-center rounded-full bg-orange-500 dark:bg-white/20 px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-orange-400 transition-all hover:opacity-50"
              onClick={(e) => {
                e.preventDefault();
                dispatch(setNextShiftPage(false));
              }}
            >
              <img src={addIcon} alt="logo" className="mr-2 my-auto w-4 h-4" />
              Add a Shift
            </button>
          </div>
          <div className="flex justify-center lg:justify-start mt-8 lg:mt-12 gap-4">
            <button
              className="w-36 cursor-pointer flex justify-center items-center rounded-full bg-primary-500 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-primary-400 transition-all hover:opacity-50"
              onClick={(e) => {
                e.preventDefault();
                dispatch(setEventPage(false));
              }}
            >
              Previous
            </button>

            <button
              className="w-36 cursor-pointer flex justify-center items-center rounded-full bg-primary-500 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-primary-400 transition-all hover:opacity-50"
              onClick={(e) => {
                e.preventDefault();
                addEvent();
              }}
              // disabled={loading}
            >
              <img src={addIcon} alt="logo" className="mr-2 my-auto w-4 h-4" />
              Post Event
            </button>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default AddEvent;
