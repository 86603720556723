import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Input from "../form/Input";
import { toast } from "react-hot-toast";
import Alert from "../../api/Alert";
import ConfirmationModal from "./ConfirmationModal";

export default function BroadcastModal({ open, setOpen }) {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAlert, setLoadingAlert] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);

  const handleAlert = async () => {
    if (name === "" && message === "") {
      setNameError(true);
      setMessageError(true);
    } else if (name === "") {
      setNameError(true);
    } else if (message === "") {
      setMessageError(true);
    } else {
      setLoading(true);
      try {
        const payload = {
          Name: name,
          Message: message,
        };
        const response = editAlert
          ? await Alert.update(0, payload)
          : await Alert.addBroadcast(payload);
        if (response) {
          toast.success("Broadcast updated.");
          setOpen(false);
        }
      } catch (error) {
        console.error("Error editing feature:", error);
      }
      setLoading(false);
    }
  };

  const deleteAttachedAlert = async (item) => {
    setOpenDeleteConfirmationModal(true);
  };

  const handleRemoveAttachedAlert = async () => {
    try {
      await Alert.delete(0).then((response) => {
        toast.success("Deleted Successfully");
        setName("");
        setMessage("");
        setDeleteAlert(true);
      });
    } catch (err) {
      console.error(err);
    }
    setEditAlert(false);
    setOpenDeleteConfirmationModal(false);
  };

  const getBroadcastAlert = async () => {
    setLoadingAlert(true);
    try {
      const response = await Alert.getById(0);
      if (response?.data?.data) {
        setEditAlert(true);
        setName(response?.data?.data?.name);
        setMessage(response?.data?.data?.message);
      }
    } catch (error) {
      console.error("Error getting alert:", error);
    }
    setLoadingAlert(false);
  };

  useEffect(() => {
    getBroadcastAlert();
  }, [deleteAlert]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg p-8 text-center shadow-xl transition-all sm:my-8 sm:p-6 w-fit md:w-[600px] bg-white">
                <ConfirmationModal
                  openTrigger={openDeleteConfirmationModal}
                  closeTrigger={() => setOpenDeleteConfirmationModal(false)}
                  type="error"
                  title="Confirm Action"
                  confirmText="Delete"
                  description="Are you sure you want to delete this alert?"
                  confirm={handleRemoveAttachedAlert}
                />

                {loadingAlert ? (
                  <div>
                    <p className="text-lg font-bold text-primary-500">
                      Broadcast Alert
                    </p>
                    <div className="h-36">
                      <i className="fad fa-circle-notch fa-spin ml-2 mt-6 text-xl" />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="mt-2">
                      <div className="flex justify-between items-center sm:px-12 lg:px-6">
                        <div className="flex flex-col items-center mx-auto">
                          <h4 className="font-bold text-primary-500 mt-4 text-2xl">
                            {editAlert
                              ? "Edit Broadcast Alert"
                              : "Add Broadcast Alert"}
                          </h4>
                          {editAlert && (
                            <p className="mt-2 text-sm">
                              Note: broadcast alert already exists
                            </p>
                          )}
                        </div>
                        {editAlert && (
                          <button
                            className="btn btn-link"
                            aria-label="Delete"
                            onClick={() => deleteAttachedAlert()}
                          >
                            <i className="fa fa-trash text-lg text-primary-500"></i>
                          </button>
                        )}
                      </div>

                      <div className="text-left snap-start sm:rounded-lg sm:px-12 lg:px-6 my-6 flex flex-col gap-4">
                        <div className="flex flex-col justify-start">
                          <Input
                            name="name"
                            label="Alert Name"
                            type="text"
                            value={name}
                            error={nameError && "Alert Name is required"}
                            placeholder="Alert Name"
                            darmo={true}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>

                        <div className="flex flex-col justify-start">
                          <Input
                            name="message"
                            label="Alert Message"
                            type="text"
                            value={message}
                            error={messageError && "Alert Message is required"}
                            placeholder="Alert Message"
                            darmo={true}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`mt-5 md:mt-12 flex items-center sm:px-12 lg:px-6 mb-6 gap-2`}
                    >
                      <button
                        type="button"
                        className={`flex w-full md:w-1/2 justify-center rounded-xl bg-primary-500 hover:bg-primary-400 px-6 py-2 text-sm font-bold text-white shadow-sm  transition-all hover:opacity-50`}
                        onClick={() => handleAlert()}
                        disabled={loading}
                      >
                        {editAlert ? "Edit" : "Add"}
                        {loading && (
                          <i className="fad fa-circle-notch fa-spin my-auto ml-2" />
                        )}
                      </button>

                      <button
                        type="button"
                        className={`flex w-full md:w-1/2 justify-center rounded-xl bg-primary-500 hover:bg-primary-400
                     px-6 py-2 text-sm font-bold text-white shadow-sm transition-all hover:opacity-50`}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpen(false);
                        }}
                        disabled={loading}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
