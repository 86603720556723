import React, { useState, useRef, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { defaultUserImage } from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedLocation,
  setInitialChatFetch,
  setMinimizedPersons,
  setSelectedLocation,
  setSelectedPersons,
  setVisiblePersons,
} from "../../../store/authSlice";
import { useNavigate } from "react-router-dom";
import { encodeIds } from "../../../utils";
import User from "../../../api/User";
import { toast } from "react-hot-toast";

const JobApplicationsChart = ({ data, selectedOption, mode = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedLocation = useSelector(selectSelectedLocation);
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [isChartReady, setIsChartReady] = useState(false);
  const [zoomState, setZoomState] = useState(null);

  const resetZoom = () => {
    setZoomState(null);
    if (chartInstanceRef.current) {
      chartInstanceRef.current.zoomX(
        chartData.timeRange.min,
        chartData.timeRange.max
      );
    }
  };

  const handleTooltipPosition = (event, chartElement) => {
    if (!isChartReady || !chartRef.current) return;

    try {
      const rect = chartRef.current?.getBoundingClientRect();
      const tooltipWidth = 288;
      const tooltipHeight = 320;
      const padding = 20;

      let clientX, clientY;

      if (event?.target?.getBoundingClientRect) {
        const markerRect = event?.target?.getBoundingClientRect();
        clientX = markerRect.left + markerRect.width / 2;
        clientY = markerRect.top + markerRect.height / 2;
      } else if (event?.clientX) {
        clientX = event.clientX;
        clientY = event.clientY;
      } else {
        clientX = rect.left + rect.width / 2;
        clientY = rect.top + rect.height / 2;
      }

      let x = clientX - rect.left;
      let y = clientY - rect.top;

      x = Math.max(tooltipWidth / 2 + padding, x);
      x = Math.min(rect.width - tooltipWidth / 2 - padding, x);
      y = Math.max(tooltipHeight / 2 + padding, y);
      y = Math.min(rect.height - padding, y);

      setTooltipPosition({ x, y });
    } catch (error) {
      console.error("Error calculating tooltip position:", error);
      const rect = chartRef?.current?.getBoundingClientRect();
      setTooltipPosition({
        x: rect.width / 2,
        y: rect.height / 2,
      });
    }
  };

  const handleDataPointInteraction = (event, chartContext, config) => {
    if (!isChartReady || !config) return;

    if (config.dataPointIndex > -1) {
      const { seriesIndex, dataPointIndex } = config;
      const series = chartData.series[seriesIndex];

      // Prevent event propagation to avoid zooming interference
      if (event && event.stopPropagation) {
        event.stopPropagation();
      }

      setTooltipData({
        seriesIndex,
        dataPointIndex,
        seriesName: series.name,
        data: series.data[dataPointIndex],
      });
      handleTooltipPosition(event, config);
    } else {
      setTooltipData(null);
    }
  };

  const handleZoomEvents = {
    beforeZoom: (chartContext, opts) => {
      // Preserve the current zoom state
      return opts;
    },
    zoomed: (chartContext, opts) => {
      // Store the zoom state to prevent unintended resets
      setZoomState({
        xaxis: {
          min: opts.xaxis.min,
          max: opts.xaxis.max,
        },
      });
    },
  };

  const chartData = useMemo(() => {
    // Add null/undefined check for data
    if (!data || !Array.isArray(data) || data.length === 0) {
      return {
        series: [],
        timeRange: { min: Date.now(), max: Date.now() },
      };
    }

    // Add null check for job_details
    const jobs = [
      ...new Set(
        data
          .filter((job) => job?.job_details?.title) // Filter out items without title
          .map((job) => job.job_details.title)
      ),
    ];

    const series = jobs.map((jobTitle) => {
      const jobApplications = data
        .filter(
          (job) =>
            job?.job_details?.title === jobTitle &&
            Array.isArray(job?.application)
        )
        .flatMap((job) => job.application || []);

      const dailyApplications = jobApplications.reduce((acc, app) => {
        // Add null checks for nested properties
        if (!app?.job_seeker?.created_at) return acc;

        const date = new Date(app.job_seeker.created_at);
        date.setHours(0, 0, 0, 0);
        const timestamp = date.getTime();

        if (!acc[timestamp]) {
          acc[timestamp] = { count: 0, details: [] };
        }
        acc[timestamp].count++;
        acc[timestamp].details.push({
          name:
            `${app?.job_seeker?.first_name || ""} ${
              app?.job_seeker?.last_name || ""
            }`.trim() || "Unknown",
          time: new Date(app.job_seeker.created_at).toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
          profileIcon: app?.photo?.thumb_url || defaultUserImage,
          job_id: app?.job_seeker?.job_id,
          job_seeker_id: app?.job_seeker?.job_seeker_id,
        });

        return acc;
      }, {});

      const dataPoints = Object.entries(dailyApplications)
        .map(([timestamp, data]) => ({
          x: parseInt(timestamp),
          y: data.count,
          details: data.details,
        }))
        .sort((a, b) => a.x - b.x);

      return { name: jobTitle, data: dataPoints };
    });

    const timestamps = series.flatMap((s) => s.data.map((d) => d.x));
    const now = Date.now();

    return {
      series,
      timeRange: {
        min:
          timestamps.length > 0
            ? Math.min(...timestamps)
            : now - 30 * 24 * 60 * 60 * 1000, // 30 days ago if no data
        max: timestamps.length > 0 ? Math.max(...timestamps) : now,
      },
    };
  }, [data]);

  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
      background: mode ? "transparent" : "#242526",
      events: {
        mounted: (chartContext) => {
          setIsChartReady(true);
          chartInstanceRef.current = chartContext;
        },
        mouseMove: (event, chartContext, config) => {
          if (config.dataPointIndex > -1 && isChartReady) {
            handleDataPointInteraction(event, chartContext, config);
          }
        },
        mouseLeave: () => {
          setTooltipData(null);
        },
        touchstart: (event, chartContext, config) => {
          if (config.dataPointIndex > -1 && isChartReady) {
            handleDataPointInteraction(event, chartContext, config);
          }
        },
        touchend: (event) => {
          if (event.target === chartRef.current) {
            setTooltipData(null);
          }
        },
        click: (event, chartContext, config) => {
          if (config.dataPointIndex > -1 && isChartReady) {
            handleDataPointInteraction(event, chartContext, config);
          }
        },
        beforeZoom: handleZoomEvents.beforeZoom,
        zoomed: handleZoomEvents.zoomed,
        beforeResetZoom: () => {
          resetZoom();
          return false; // Prevent default reset behavior
        },
      },
      toolbar: {
        show: true,
        tools: {
          zoomIn: true,
          zoomOut: true,
          pan: true,
          reset: true,
          download: true,
        },
        autoSelected: "zoom",
      },
      zoom: {
        enabled: true,
        type: "xy",
        autoScaleYaxis: false,
      },
    },
    colors: ["#77B6EA", "#545454", "#1E40AF", "#374151", "#DC2626"],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    markers: {
      size: 5,
      hover: {
        size: 8,
        sizeOffset: 3,
      },
      style: "hollow",
      strokeWidth: 2,
      strokeColors: ["#77B6EA", "#545454", "#1E40AF", "#374151", "#DC2626"],
    },
    xaxis: {
      type: "datetime",
      min: zoomState?.xaxis?.min || chartData.timeRange.min,
      max: zoomState?.xaxis?.max || chartData.timeRange.max,
      labels: {
        format: "dd MMM yyyy",
        style: { colors: mode ? "#000" : "#fff" },
      },
    },
    yaxis: {
      title: {
        text: "Number of Applications",
        style: { color: mode ? "#000" : "#fff" },
      },
      labels: {
        style: { colors: mode ? "#000" : "#fff" },
      },
    },
    tooltip: {
      enabled: true,
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        const event = window.event || {};
        handleDataPointInteraction(event, w.config, {
          seriesIndex,
          dataPointIndex,
        });
        return "Tooltip Content";
      },
      intersect: true,
      shared: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      labels: { colors: mode ? "#000" : "#fff" },
    },
    grid: {
      borderColor: mode ? "#e5e7eb" : "#374151",
      strokeDashArray: 4,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        filter: {
          type: "none",
        },
      },
    },
  };

  if (chartData.series.length === 0) {
    return (
      <div className="text-center py-4 text-gray-400 dark:text-gray-500">
        No data available
      </div>
    );
  }

  const changeLocation = async (id) => {
    try {
      await User.updateLocation(id);
      toast.success("Location Changed!");
    } catch (error) {
      toast.success("Error Occurred during Location switching. Try Again!");
    }
  };

  const handleApplicantClick = (jobId, applicantId, navigateCondition) => {
    if (selectedOption) {
      dispatch(setSelectedLocation(selectedOption?.value));
      localStorage.setItem(
        "selectedLocation",
        JSON.stringify(selectedOption?.value)
      );
      if (selectedOption?.value?.name !== selectedLocation?.name) {
        changeLocation(selectedOption?.value?.id);
      }
      if (navigateCondition === "applicant") {
        navigate(
          `/business/applicant/${encodeIds(jobId)}/${encodeIds(applicantId)}`,
          {
            state: {
              isSearchingSpecificApplication: true,
            },
          }
        );
      } else {
        navigate(`/business/applicant/${encodeIds(jobId)}`);
      }
      dispatch(setInitialChatFetch(false));
      dispatch(setVisiblePersons([]));
      dispatch(setMinimizedPersons([]));
      dispatch(setSelectedPersons([]));
    }
  };

  return (
    <div className="relative w-full p-0 lg:p-4" ref={chartRef}>
      <div className="relative">
        <ReactApexChart
          options={chartOptions}
          series={chartData?.series}
          type="line"
          height={350}
          className="cursor-pointer"
        />

        {tooltipData && isChartReady && (
          <div
            className={`absolute z-[100] w-64 xl:w-72 p-2 rounded-lg shadow-lg ${
              mode
                ? "bg-[#f3f4f6] border-gray-200"
                : "bg-[#374151] border-gray-600"
            } border`}
            style={{
              left: `${tooltipPosition?.x}px`,
              top: `${tooltipPosition?.y}px`,
              transform: "translate(-50%, -50%)",
              maxHeight: "320px",
              overflowY: "auto",
              pointerEvents: "auto",
            }}
          >
            <div
              className={`font-bold mb-2 pb-2 border-b ${
                mode
                  ? "text-gray-900 border-gray-200"
                  : "text-white border-gray-600"
              }`}
            >
              {new Date(tooltipData?.data?.x).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </div>

            <div
              className={`text-sm mb-4 ${
                mode ? "text-gray-900" : "text-gray-300"
              }`}
            >
              {tooltipData?.seriesName}: {tooltipData?.data?.y}{" "}
              {tooltipData?.data?.y === 1 ? "applicant" : "applicants"}
            </div>

            <div className="max-h-48 overflow-y-auto space-y-2">
              {tooltipData?.data?.details?.map((detail, index) => (
                <div
                  key={index}
                  className={`flex items-center justify-between p-2 rounded ${
                    mode ? "bg-[#fff]" : "bg-[#1F2937]"
                  }`}
                >
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() =>
                      handleApplicantClick(
                        detail?.job_id,
                        detail?.job_seeker_id,
                        "applicant"
                      )
                    }
                  >
                    <img
                      src={detail?.profileIcon}
                      alt="Profile"
                      className="w-7 h-7 rounded-full mr-2 object-cover"
                    />
                    <span
                      className={`text-xs xl:text-sm ${
                        mode ? "text-gray-900" : "text-white"
                      }`}
                    >
                      {detail?.name}
                    </span>
                  </div>
                  <span
                    className={`ml-2 text-xs xl:text-sm whitespace-nowrap ${
                      mode ? "text-gray-600" : "text-gray-300"
                    }`}
                  >
                    {detail?.time}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobApplicationsChart;
