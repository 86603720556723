import React, { useState, useEffect, useRef, useCallback } from "react";
import { Col, Row } from "antd";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  darkmode,
  mobileView,
  selectUser,
  setChatApplicant,
  setJobApplicantTitle,
  setJobPositionTitle,
  setMobileView,
} from "../../../store/authSlice";
import { DashboardLayout } from "../../../components/organisms/Layout";
import Application from "../../../api/Application";
import JobPosition from "../../../api/JobPosition";
import { encodeIds, decodeIds } from "../../../utils";
import { useTranslation } from "react-i18next";
import JobPositionSelect from "../../../components/atoms/Selector/JobPositionSelect";
import JobSeeker from "../../../api/JobSeeker";
import ApplicantInfo from "../ViewApplicant/ApplicantInfo";
import ApplicationCardSkeleton from "../../../components/Loading/ApplicationCardSkeleton";
import NotesModal from "../../../components/modals/NotesModal";
import NewConfirmationModal from "../../../components/modals/NewConfirmationModal";
import SchedulingOptionsModal from "../Scheduler/SchedulingOptionsModal";
import {
  DesktopApplicationCard,
  MobileApplicationCard,
} from "../../../components/molecules/Application/ApplicationCard";
import SelectedApplication from "../ViewApplicant/SelectedApplication";
import ApplicationLoadingSkeleton from "../../../components/Loading/ApplicationLoadingSkeleton";
import { PositionHeader } from "../ViewApplicant/PositionHeader";
import RecruitmentTools from "../ViewApplicant/RecruitmentTools";

const PAGE_SIZE = 10;

const ViewPositions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mode = useSelector(darkmode);
  const user = useSelector(selectUser);
  const isMobileView = useSelector(mobileView);
  const location = useLocation();
  const applicationsListRef = useRef(null);
  const applicationRefsMap = useRef({});
  const previousPositionRef = useRef(null);

  // URL Parsing
  const urlParams = location.pathname.split("/");
  const positionId = decodeIds(urlParams[3]);
  const seekerId = urlParams.length > 4 ? decodeIds(urlParams[4]) : null;
  const archivedPositionTitle = location?.state?.archivedPositionTitle || "";
  const isArchivedPosition = location?.state?.isArchivedPosition || false;

  // State Management
  const [loading, setLoading] = useState(true);
  const [loadingApplications, setLoadingApplications] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [activeSegment, setActiveSegment] = useState("Applicants");
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [jobPositions, setJobPositions] = useState([]);
  const [applicantsCount, setApplicantsCount] = useState(0);
  const [interviewsCount, setInterviewsCount] = useState(0);
  const [declinedCount, setDeclinedCount] = useState(0);
  const [hiredCount, setHiredCount] = useState(0);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [applicant, setApplicant] = useState(null);
  const [loadingApplicant, setLoadingApplicant] = useState(false);
  const [apiAvailabilityData, setApiAvailabilityData] = useState([]);
  const [activityLogs, setActivityLogs] = useState([]);
  const [showApplicantDetail, setShowApplicantDetail] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [type, setType] = useState("");
  const [isSchedulingOpen, setIsSchedulingOpen] = useState(false);
  const [loadingEvent, setLoadingEvent] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isApplicationTransitioning, setIsApplicationTransitioning] =
    useState(false);
  const [detailsLoaded, setDetailsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [applications, setApplications] = useState([]);
  const [loadingType, setLoadingType] = useState(null);
  const [isSearchingSpecificApplication, setIsSearchingSpecificApplication] =
    useState(location.state?.isSearchingSpecificApplication || false);
  const [isSearchingSingleApplication, setIsSearchingSingleApplication] =
    useState(false);
  const [currentSegmentCount, setCurrentSegmentCount] = useState(0);

  useEffect(() => {
    const checkMobileView = () => {
      dispatch(setMobileView(window.innerWidth < 1280));
    };
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => window.removeEventListener("resize", checkMobileView);
  }, [dispatch]);

  const fetchSpecificApplication = async (positionId, seekerId) => {
    try {
      setLoadingApplications(true);
      const response = await Application.getApplicationByPositionAndSeeker(
        positionId,
        seekerId
      );
      const application = response?.data?.data;

      if (application[0]) {
        let segmentName = "Applicants";

        // Directly fetch counts from API for this position
        const countResponse = await JobPosition.getById(positionId);
        const position = countResponse?.data?.data;

        // Get hired count separately
        const hiredResponse = await JobPosition.getHiredEmployees(positionId);
        const hiredEmployees = hiredResponse?.data?.data || [];

        // Extract counts directly from API responses
        const newApplicantsCount = position?.total_applied || 0;
        const newInterviewsCount = position?.total_interviews || 0;
        const newDeclinedCount = position?.total_declined || 0;
        const newHiredCount = hiredEmployees.length;

        // Update state with these counts
        setApplicantsCount(newApplicantsCount);
        setInterviewsCount(newInterviewsCount);
        setDeclinedCount(newDeclinedCount);
        setHiredCount(newHiredCount);

        // Determine segment and count
        let segmentCount = 0;

        if (application[0].segment === "hired") {
          segmentName = "Hired";
          segmentCount = newHiredCount;
        } else if (application[0].segment === "interviews") {
          segmentName = "Interviews";
          segmentCount = newInterviewsCount;
        } else if (application[0].segment === "declined") {
          segmentName = "Declined";
          segmentCount = newDeclinedCount;
        } else {
          segmentCount = newApplicantsCount;
        }

        setActiveSegment(segmentName);
        setApplications([application[0]]);
        setSelectedApplication(application[0]);
        setHasMore(false);
        setIsSearchingSingleApplication(true);
        setCurrentSegmentCount(segmentCount);

        // Force detail view for mobile
        if (isMobileView) {
          setShowApplicantDetail(true);
        }

        // Load applicant details
        await loadApplicationData(application[0]);

        return true;
      } else {
        toast.error("Application not found");
        navigate(`/business/applicant/${encodeIds(positionId)}`);
        return false;
      }
    } catch (error) {
      console.error("Error loading specific application:", error);
      toast.error("Failed to load application. Please try again.");
      return false;
    } finally {
      setLoadingApplications(false);
      setIsSearchingSpecificApplication(false);
    }
  };

  // URL Management
  const updateURL = (position, application) => {
    if (!position?.value?.id) return;

    const basePath = "/business/applicant";
    const encodedPositionId = encodeIds(position.value.id.toString());
    let newUrl = `${basePath}/${encodedPositionId}`;

    if (application?.seeker?.id) {
      const encodedSeekerId = encodeIds(application.seeker.id.toString());
      newUrl += `/${encodedSeekerId}`;
    }

    // Use navigate instead of directly manipulating history
    navigate(newUrl, { replace: true });
  };

  // Activity Logs with error handling
  const getActivity = async (id) => {
    if (!id) return;

    try {
      const response = await Application.getApplicationLogs(id);
      setActivityLogs(response.data.data);
      return response.data.data;
    } catch (error) {
      console.error("Error fetching activity logs:", error);
      return [];
    }
  };

  // Availability Data with error handling
  const loadAvailability = async (id) => {
    if (!id) return [];

    try {
      const data = await Application.getByIdForAvailability(id);
      const availability = data?.data?.availability || [];
      setApiAvailabilityData(availability);
      return availability;
    } catch (error) {
      console.error("Error fetching availability:", error);
      return [];
    }
  };

  // Comprehensive Application Data Loading
  const loadApplicationData = async (application) => {
    if (!application?.seeker?.id || !selectedPosition?.value?.id) {
      setDetailsLoaded(true);
      return false;
    }

    try {
      setLoadingApplicant(true);
      setDetailsLoaded(false);

      // Load seeker details and availability but do not refetch applications
      const [seekerResponse] = await Promise.all([
        JobSeeker.getById(application?.seeker?.id),
        loadAvailability(application?.seeker?.id),
        getActivity(application?.id),
      ]);

      if (seekerResponse?.data?.data) {
        setApplicant(seekerResponse.data.data);

        // Update Redux store
        dispatch(
          setJobApplicantTitle(
            seekerResponse.data.data.first_name +
              " " +
              seekerResponse.data.data.last_name
          )
        );

        return true;
      }
      return false;
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/subscription");
      } else {
        toast.error("Failed to load applicant details. Please try again.");
      }
      return false;
    } finally {
      setLoadingApplicant(false);
      setDetailsLoaded(true);
    }
  };

  // Load counts from the position data with error handling
  const loadCounts = async (positionId) => {
    if (!positionId) return;

    try {
      const response = await JobPosition.getById(positionId);
      const position = response?.data?.data;

      if (position) {
        setApplicantsCount(position.total_applied || 0);
        setInterviewsCount(position.total_interviews || 0);
        setDeclinedCount(position.total_declined || 0);

        // Load hired count separately
        const hiredResponse = await JobPosition.getHiredEmployees(positionId);
        const hiredEmployees = hiredResponse?.data?.data || [];
        setHiredCount(hiredEmployees.length);
      } else {
        resetCounts();
      }
    } catch (error) {
      console.error("Error loading counts:", error);
      resetCounts();
    }
  };

  // Reset counts to zero
  const resetCounts = () => {
    setApplicantsCount(0);
    setInterviewsCount(0);
    setDeclinedCount(0);
    setHiredCount(0);
  };

  // Job Positions Loading
  const loadJobPositions = async () => {
    try {
      setLoading(true);
      if (isArchivedPosition && positionId) {
        // Get position details directly for accurate counts
        const countResponse = await JobPosition.getById(positionId);
        const position = countResponse?.data?.data;

        // Create default option with archived tag
        const defaultOption = {
          value: position,
          label: `${archivedPositionTitle} [Archived]`,
          isArchived: true,
        };

        setSelectedPosition(defaultOption);
        dispatch(setJobPositionTitle(defaultOption));

        // Get counts directly
        setApplicantsCount(position?.total_applied || 0);
        setInterviewsCount(position?.total_interviews || 0);
        setDeclinedCount(position?.total_declined || 0);

        // Get hired employees separately
        const hiredResponse = await JobPosition.getHiredEmployees(positionId);
        const hiredEmployees = hiredResponse?.data?.data || [];
        setHiredCount(hiredEmployees.length);

        return [position];
      }

      const response = await JobPosition.getAll();
      const sortedJobs = response.data.data.filter(
        (j) => j?.archived_at == null && j?.deleted_at == null
      );
      setJobPositions(sortedJobs);

      let defaultOption;
      const matchedPosition = sortedJobs.find(
        (job) => job.id === Number(positionId)
      );

      if (matchedPosition) {
        defaultOption = {
          value: matchedPosition,
          label: matchedPosition?.title,
        };
      } else if (sortedJobs.length > 0) {
        defaultOption = {
          value: sortedJobs[0],
          label: sortedJobs[0]?.title,
        };
      }

      if (defaultOption) {
        setSelectedPosition(defaultOption);
        dispatch(setJobPositionTitle(defaultOption));

        // Get position details directly for accurate counts
        const countResponse = await JobPosition.getById(defaultOption.value.id);
        const position = countResponse?.data?.data;

        // Get hired employees separately
        const hiredResponse = await JobPosition.getHiredEmployees(
          defaultOption.value.id
        );
        const hiredEmployees = hiredResponse?.data?.data || [];

        // Set all count values directly
        setApplicantsCount(position?.total_applied || 0);
        setInterviewsCount(position?.total_interviews || 0);
        setDeclinedCount(position?.total_declined || 0);
        setHiredCount(hiredEmployees.length);
      }

      return sortedJobs;
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/subscription");
      } else {
        toast.error(t("common.something-went-wrong-please-try-again"));
      }
      return [];
    } finally {
      setLoading(false);
    }
  };

  // Load hired employees with error handling
  const loadEmployees = async (positionId) => {
    if (!positionId) return [];
    try {
      const tempUsers = await JobPosition.getHiredEmployees(positionId);
      const hiredEmployees = tempUsers.data.data || [];
      setHiredCount(hiredEmployees.length);
      return hiredEmployees;
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/subscription");
      } else {
        console.error("Failed to load Hired Employees:", error);
      }
      setHiredCount(0);
      return [];
    }
  };

  // Applications Loading
  const loadApplications = async (positionId, segment, page = 1) => {
    if (!positionId) return [];

    try {
      if (page === 1) {
        setLoadingApplications(true);
        setLoadingMore(false);
        if (!isSearchingSpecificApplication) {
          setIsSearchingSingleApplication(false);
        }
      } else {
        setLoadingMore(true);
      }

      document.title = `HeyHire Portal-Viewing-${selectedPosition?.label}`;

      if (segment.toLowerCase() === "hired") {
        const hiredEmployees = await loadEmployees(positionId);
        if (page === 1) {
          setApplications(hiredEmployees);
        }
        setHasMore(false);
        return hiredEmployees;
      } else if (segment.toLowerCase() === "interviews") {
        const response = await Application.getByPositionId(
          positionId,
          0,
          segment.toLowerCase()
        );

        const newApplications = response?.data?.data || [];
        setApplications(newApplications);
        setHasMore(false);
        return newApplications;
      } else {
        // For other segments, use the original API call with pagination
        const response = await Application.getByPositionId(
          positionId,
          0,
          segment.toLowerCase(),
          page,
          PAGE_SIZE
        );

        const newApplications = response?.data?.data || [];
        const countResponse = await JobPosition.getById(
          selectedPosition.value.id
        );
        const position = countResponse?.data?.data;
        const newApplicantsCount = position?.total_applied || 0;
        const newDeclinedCount = position?.total_declined || 0;

        // Determine if there are more applications by comparing with total count
        let totalCount = 0;
        switch (segment.toLowerCase()) {
          case "applicants":
            totalCount = newApplicantsCount;
            break;
          case "declined":
            totalCount = newDeclinedCount;
            break;
          default:
            totalCount = 0;
        }

        // Set hasMore based on current loaded count vs total count
        const currentTotal = (page - 1) * PAGE_SIZE + newApplications.length;
        setHasMore(currentTotal < totalCount);

        if (page === 1) {
          setApplications(newApplications);
        } else {
          setApplications((prev) => [...prev, ...newApplications]);
        }

        return newApplications;
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/subscription");
      } else {
        toast.error("Failed to load applications. Please try again.");
      }
      return [];
    } finally {
      setLoadingApplications(false);
      setLoadingMore(false);
      setLoadingType(null);
    }
  };

  // Handle scroll to load more applications
  const handleScroll = useCallback(() => {
    if (
      !hasMore ||
      isApplicationTransitioning ||
      loadingApplications ||
      loadingMore ||
      loadingType
    )
      return;

    const list = applicationsListRef.current;
    if (list) {
      const { scrollTop, scrollHeight, clientHeight } = list;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        setLoadingType("pagination");
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }, [
    hasMore,
    isApplicationTransitioning,
    loadingApplications,
    loadingMore,
    loadingType,
  ]);

  // Fetch new applications when `currentPage` changes
  useEffect(() => {
    if (selectedPosition?.value?.id && currentPage > 1) {
      const listElement = applicationsListRef.current;
      let previousScrollHeight = 0;
      let previousScrollTop = 0;

      if (listElement) {
        previousScrollHeight = listElement.scrollHeight;
        previousScrollTop = listElement.scrollTop;
      }

      // Save the current selected application to restore it later
      const currentSelectedApp = selectedApplication;

      // Load the new applications
      loadApplications(
        selectedPosition.value.id,
        activeSegment.toLowerCase(),
        currentPage
      ).then(() => {
        // After loading completes, ensure we keep the current selection
        if (currentSelectedApp) {
          setSelectedApplication(currentSelectedApp);
        }

        // After loading completes and DOM updates, restore relative scroll position
        setTimeout(() => {
          if (listElement) {
            const newScrollHeight = listElement.scrollHeight;
            listElement.scrollTop =
              previousScrollTop +
              (newScrollHeight - previousScrollHeight) -
              200;
          }
        }, 100); // Small delay to ensure DOM has updated
      });
    }
  }, [currentPage]);

  // Reset pagination when changing positions or tabs
  useEffect(() => {
    setCurrentPage(1);
    setHasMore(true);

    // Only load applications when activeSegment changes but selectedPosition remains the same
    if (
      selectedPosition?.value?.id &&
      !isInitialLoad &&
      previousPositionRef.current === selectedPosition?.value?.id
    ) {
      loadApplications(
        selectedPosition.value.id,
        activeSegment.toLowerCase(),
        1
      );
    }
  }, [selectedPosition, activeSegment]);

  // Debounced application selection to prevent rapid state changes
  const handleApplicationSelection = async (
    application,
    isPositionChange = false
  ) => {
    if (
      !application ||
      (isApplicationTransitioning && !isPositionChange) ||
      (selectedApplication?.id === application?.id && !isPositionChange)
    ) {
      return;
    }

    try {
      setIsApplicationTransitioning(true);
      setSelectedApplication(application);
      setDetailsLoaded(false);

      // Load data asynchronously but don't wait for it to complete
      const dataPromise = loadApplicationData(application);

      // Update URL and position title immediately
      updateURL(selectedPosition, application);

      if (selectedPosition) {
        dispatch(setJobPositionTitle(selectedPosition));
      }

      // Scroll to selected application
      scrollToSelectedApplication(application);

      // Wait for data to finish loading
      await dataPromise;
    } catch (error) {
      console.error("Error selecting application:", error);
      toast.error("Failed to load application details");
    } finally {
      setIsApplicationTransitioning(false);
    }
  };

  // Navigation with debounce
  const navigateApplication = async (direction) => {
    if (isApplicationTransitioning) return;

    const currentApplications = applications;
    if (!selectedApplication || currentApplications.length === 0) return;

    const currentIndex = currentApplications.findIndex(
      (app) => app.id === selectedApplication.id
    );

    let newIndex;
    if (direction === "next") {
      newIndex = (currentIndex + 1) % currentApplications.length;
    } else {
      newIndex =
        (currentIndex - 1 + currentApplications.length) %
        currentApplications.length;
    }

    const newSelectedApplication = currentApplications[newIndex];
    await handleApplicationSelection(newSelectedApplication);
  };

  // Improved scrolling with error handling
  const scrollToSelectedApplication = (application) => {
    if (
      !applicationRefsMap.current[application?.id] ||
      !applicationsListRef.current
    )
      return;

    try {
      const selectedApplicationElement =
        applicationRefsMap.current[application?.id];
      const containerElement = applicationsListRef.current;

      const containerRect = containerElement?.getBoundingClientRect();
      const elementRect = selectedApplicationElement?.getBoundingClientRect();

      const scrollTop =
        selectedApplicationElement.offsetTop -
        containerElement.offsetTop -
        (containerRect.height / 2 - elementRect.height / 2);

      containerElement.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    } catch (error) {
      console.error("Error scrolling to application:", error);
    }
  };

  // First Application Selection with safeguards
  const selectFirstApplication = async (applications) => {
    if (isApplicationTransitioning) return;

    if (applications && applications.length > 0) {
      const firstApplication = applications[0];
      await handleApplicationSelection(firstApplication);
    } else {
      setSelectedApplication(null);
      setApplicant(null);
      dispatch(setJobApplicantTitle(null));
    }
  };

  useEffect(() => {
    // Only run this effect when switching from mobile to desktop
    if (!isMobileView && applications.length > 0 && !selectedApplication) {
      handleApplicationSelection(applications[0]);
    }
  }, [isMobileView, applications, selectedApplication]);

  useEffect(() => {
    if (
      !isApplicationTransitioning &&
      !isMobileView &&
      (!isInitialLoad || applications.length > 0)
    ) {
      selectFirstApplication(applications);
    }
  }, [activeSegment, applications, isMobileView]);

  // Initial Load
  useEffect(() => {
    if (user) {
      loadJobPositions();
    }
  }, [user]);

  useEffect(() => {
    const loadInitialData = async () => {
      if (selectedPosition && isInitialLoad) {
        try {
          setLoadingApplications(true);
          dispatch(setJobApplicantTitle(null));

          if (isSearchingSpecificApplication && seekerId) {
            const success = await fetchSpecificApplication(
              selectedPosition?.value?.id,
              seekerId
            );
            if (success && isMobileView) {
              setShowApplicantDetail(true);
            }
          } else {
            // Load applications for the selected segment
            const applications = await loadApplications(
              selectedPosition.value.id,
              activeSegment.toLowerCase(),
              1
            );

            // Handle specific seekerId if provided
            if (seekerId) {
              const targetApplication = applications?.find(
                (app) => app.seeker?.id === Number(seekerId)
              );

              if (targetApplication) {
                // Force detail view for mobile
                if (isMobileView) {
                  setShowApplicantDetail(true);
                }

                // Select the application
                await handleApplicationSelection(targetApplication);
              } else {
                toast.error("Application not found");
                // Reset URL if application not found
                const basePath = "/business/applicant";
                const encodedPositionId = encodeIds(
                  selectedPosition?.value?.id?.toString()
                );
                navigate(`${basePath}/${encodedPositionId}`);
              }
            } else if (applications.length > 0) {
              // No seekerId - select first available application
              await handleApplicationSelection(applications[0]);
            }
          }
        } catch (error) {
          console.error("Error in initial load:", error);
          toast.error("Failed to load data. Please try again.");
          setApplications([]);
        } finally {
          setLoadingApplications(false);
          setIsInitialLoad(false);
          previousPositionRef.current = selectedPosition?.value?.id;
        }
      }
    };

    loadInitialData();
  }, [selectedPosition, isInitialLoad]);

  useEffect(() => {
    const handlePositionChange = async () => {
      if (
        !selectedPosition?.value?.id ||
        selectedPosition?.value?.id === previousPositionRef.current ||
        isInitialLoad
      ) {
        return;
      }

      try {
        // Set ALL loading states at once before any async operations
        setLoadingApplications(true);
        setIsApplicationTransitioning(true);
        setLoadingType("position");

        // Clear all data states at once
        setSelectedApplication(null);
        setApplicant(null);
        setActivityLogs([]);
        setApiAvailabilityData([]);
        resetCounts();
        setIsSearchingSingleApplication(false);

        // Get counts directly from API responses
        const countResponse = await JobPosition.getById(
          selectedPosition.value.id
        );
        const position = countResponse?.data?.data;

        // Get the hired count separately
        const hiredResponse = await JobPosition.getHiredEmployees(
          selectedPosition.value.id
        );
        const hiredEmployees = hiredResponse?.data?.data || [];

        // Use direct values from API responses
        const newApplicantsCount = position?.total_applied || 0;
        const newInterviewsCount = position?.total_interviews || 0;
        const newDeclinedCount = position?.total_declined || 0;
        const newHiredCount = hiredEmployees.length;

        // Update state
        setApplicantsCount(newApplicantsCount);
        setInterviewsCount(newInterviewsCount);
        setDeclinedCount(newDeclinedCount);
        setHiredCount(newHiredCount);

        // Determine which segment to show based on fresh count values, not state
        let segmentToShow = "Applicants"; // Default

        // Check which segment has applications and prioritize in this order
        if (newApplicantsCount > 0) {
          segmentToShow = "Applicants";
        } else if (newInterviewsCount > 0) {
          segmentToShow = "Interviews";
        } else if (newHiredCount > 0) {
          segmentToShow = "Hired";
        } else if (newDeclinedCount > 0) {
          segmentToShow = "Declined";
        }

        // Set the active segment to the one with applications
        setActiveSegment(segmentToShow);

        // Only place where loadApplications is called when position changes
        const applications = await loadApplications(
          selectedPosition.value.id,
          segmentToShow.toLowerCase(),
          1
        );

        updateURL(selectedPosition, null);

        // Only after ALL data is loaded, update the UI
        if (applications.length > 0 && !isMobileView) {
          await handleApplicationSelection(applications[0], true);
        }
      } catch (error) {
        console.error("Error handling position change:", error);
        toast.error("Failed to load position data");
        setApplications([]);
      } finally {
        // Clear ALL loading states at once after everything is done
        setLoadingApplications(false);
        setIsApplicationTransitioning(false);
        setLoadingType(null);
        previousPositionRef.current = selectedPosition?.value?.id;
      }
    };

    if (selectedPosition && !isInitialLoad) {
      handlePositionChange();
    }
  }, [selectedPosition]);

  const handleChatApplicant = (applicant) => {
    dispatch(setChatApplicant(applicant));
  };

  const handleApplicationClick = (application) => {
    setSelectedApplication(application);
    if (isMobileView) {
      setShowApplicantDetail(true);
    }
    handleApplicationSelection(application);
  };

  const handleOpenNotesModal = () => {
    setIsNotesModalOpen(true);
  };

  const handleCloseNotesModal = () => {
    setIsNotesModalOpen(false);
  };

  const handleSegmentChange = (segment) => {
    if (segment !== activeSegment) {
      setActiveSegment(segment);
      setLoadingType("segment");
      setIsSearchingSingleApplication(false);
      if (isMobileView) {
        setShowApplicantDetail(false);
      }
    }
  };

  const hireApplicant = async (applicationId) => {
    if (!applicationId) return;

    try {
      setLoadingEvent(true);
      await Application.hireByApplicationId(applicationId);
      toast.success("Applicant Hired successfully");
      setIsModalVisible(false);

      // Update counts and reload applications
      await loadCounts(selectedPosition?.value?.id);
      await loadApplications(selectedPosition?.value?.id, "hired", 1);

      // Switch to hired segment
      setActiveSegment("Hired");
    } catch (error) {
      console.error("Error hiring applicant:", error);
      setIsModalVisible(false);
      toast.error("An error occurred while hiring the applicant");
    } finally {
      setLoadingEvent(false);
    }
  };

  const rejectApplication = async (declineReason) => {
    if (!selectedApplication?.id) return;

    try {
      setLoadingEvent(true);
      const payload = {
        id: selectedApplication.id,
        reason: declineReason,
      };

      await Application.rejectByApplicationId(payload);
      toast.success("Applicant Rejected");
      setIsModalVisible(false);

      // Update counts and reload applications
      await loadCounts(selectedPosition?.value?.id);
      await loadApplications(selectedPosition?.value?.id, "declined", 1);

      // Switch to declined segment
      setActiveSegment("Declined");
    } catch (error) {
      console.error("Error rejecting applicant:", error);
      setIsModalVisible(false);
      toast.error("An error occurred while rejecting the applicant");
    } finally {
      setLoadingEvent(false);
    }
  };

  const handleHireApplicant = () => {
    setType("hire");
    setIsModalVisible(true);
  };

  const handleRejectApplication = () => {
    setType("decline");
    setIsModalVisible(true);
  };

  const handleInterview = () => {
    setIsSchedulingOpen(true);
  };

  const renderLoadMoreButton = () => {
    if (isSearchingSingleApplication) {
      if (currentSegmentCount > 1) {
        return (
          <div className="w-full py-4 flex justify-center">
            <button
              onClick={() => {
                setIsSearchingSingleApplication(false);
                loadApplications(
                  selectedPosition.value.id,
                  activeSegment.toLowerCase(),
                  1
                );
              }}
              className="bg-primary-500 dark:bg-darkGray dark:hover:bg-gray-600 hover:bg-primary-400 text-white dark:bg-primary-600 py-2 px-4 rounded-lg flex items-center justify-center"
            >
              <i className="far fa-list mr-2" />
              View All Applicants
            </button>
          </div>
        );
      }
      return null;
    }

    if (!hasMore || loadingMore) return null;
    return (
      <div className="w-full py-4 flex justify-center">
        <button
          onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
          disabled={loadingMore}
          className="bg-primary-500 dark:bg-darkGray dark:hover:bg-gray-600 hover:bg-primary-400 text-white dark:bg-primary-600 py-2 px-4 rounded-lg flex items-center justify-center"
        >
          <i className="far fa-arrow-down mr-2" />
          Load More
        </button>
      </div>
    );
  };

  const renderApplicationContent = () => {
    if (isApplicationTransitioning) {
      return (
        <div className="w-full text-center py-4 dark:text-gray-300">
          Loading application...
        </div>
      );
    }

    if (loadingApplicant) {
      return (
        <div className="w-full text-center py-4 dark:text-gray-300">
          Loading applicant details...
        </div>
      );
    }

    return (
      applicant &&
      detailsLoaded && (
        <ApplicantInfo
          mode={mode}
          applicant={applicant}
          availability={apiAvailabilityData}
          application={selectedApplication}
          activityLogs={activityLogs}
        />
      )
    );
  };

  const renderSegments = () => {
    const segments = [
      { label: "Applicants", count: applicantsCount },
      { label: "Interviews", count: interviewsCount },
      { label: "Hired", count: hiredCount },
      { label: "Declined", count: declinedCount },
    ];

    return segments?.map((segment, index) => (
      <button
        key={segment.label}
        onClick={() => segment.count > 0 && handleSegmentChange(segment.label)}
        disabled={
          segment.count === 0 ||
          isApplicationTransitioning ||
          loadingApplications
        }
        className={`flex flex-1 items-center justify-center py-2 px-4 bg-warmgray-200 dark:bg-darkGray text-gray-900 dark:text-gray-300 text-sm 
          ${index === 3 ? "border-none" : "border-r border-warmgray-400"}
          ${index === 0 && "border-l border-warmgray-400"}
          ${activeSegment === segment.label ? "font-bold" : ""}
          ${segment.count === 0 ? "opacity-50 cursor-not-allowed" : ""}
          ${
            activeSegment === segment.label && segment.count > 0
              ? "font-bold"
              : ""
          }`}
      >
        <span>{segment.label}</span>
        <span className="ml-1 text-xs">({segment.count})</span>
      </button>
    ));
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="flex flex-col h-full">
        {isMobileView ? (
          <div className="flex flex-col h-full">
            {/* Force detail view if seekerId exists, otherwise check showApplicantDetail */}
            {!seekerId && !showApplicantDetail ? (
              // List View
              <>
                {!loading && (
                  <div className="flex items-center justify-center">
                    {isArchivedPosition ? (
                      <div className="text-gray-900 dark:text-gray-300 font-medium">
                        {selectedPosition?.label}
                      </div>
                    ) : (
                      <div className="mt-4 bg-white dark:bg-[#242526] border border-warmgray-200 dark:border-darkGray px-4 py-2 rounded-xl">
                        <JobPositionSelect
                          jobPositions={jobPositions}
                          selectedPosition={selectedPosition}
                          setSelectedPosition={setSelectedPosition}
                          mode={mode}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div className="flex whitespace-nowrap overflow-x-auto px-4 py-1.5 rounded-xl border-2 border-warmgray-200 dark:border-darkGray bg-warmgray-200 dark:bg-[#242526] my-3">
                  {renderSegments()}
                </div>
                <div
                  className="flex-1 h-full overflow-y-auto mb-3"
                  ref={applicationsListRef}
                  onScroll={handleScroll}
                >
                  {loadingApplications || loading ? (
                    <>
                      {loadingType === "segment" && (
                        <PositionHeader selectedPosition={selectedPosition} />
                      )}
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        {Array.from({ length: 5 }).map((_, index) => (
                          <ApplicationCardSkeleton key={index} />
                        ))}
                      </div>
                    </>
                  ) : applications?.length === 0 ? (
                    <div>
                      <PositionHeader selectedPosition={selectedPosition} />
                      <div className="mt-2 border border-primary-500 dark:border-darkGray text-gray-900 dark:text-gray-300 p-4 rounded-xl text-sm text-center">
                        Once you have applicants they will appear here
                      </div>
                    </div>
                  ) : (
                    <>
                      <PositionHeader selectedPosition={selectedPosition} />
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        {applications?.map((application) => (
                          <MobileApplicationCard
                            key={application.id}
                            application={application}
                            onClick={() => handleApplicationClick(application)}
                          />
                        ))}
                      </div>
                      {loadingMore ? (
                        <div className="text-center py-2">
                          <i className="fad fa-circle-notch fa-spin text-primary-500 dark:text-primary-300 text-lg" />
                        </div>
                      ) : (
                        renderLoadMoreButton()
                      )}
                    </>
                  )}
                </div>
              </>
            ) : (
              // Detail View
              <div className="flex-1 overflow-y-auto">
                <div className="flex items-center mb-4">
                  <button
                    onClick={() => {
                      const basePath = "/business/applicant";
                      const encodedPositionId = encodeIds(
                        selectedPosition?.value?.id?.toString()
                      );
                      navigate(`${basePath}/${encodedPositionId}`);
                      setShowApplicantDetail(false);
                      setIsSearchingSingleApplication(false);
                      loadApplications(
                        selectedPosition.value.id,
                        activeSegment.toLowerCase(),
                        1
                      );
                    }}
                    className="font-bold text-primary-500 dark:text-primary-300 hover:text-primary-600 dark:hover:text-primary-400 pr-4 rounded-lg"
                  >
                    ← Back to Applications
                  </button>
                </div>
                {loading || loadingApplications ? (
                  <ApplicationLoadingSkeleton isMobileView={true} />
                ) : (
                  <>
                    <SelectedApplication
                      disabled={
                        isApplicationTransitioning || loadingApplications
                      }
                      selectedApplication={selectedApplication}
                      selectedPosition={selectedPosition}
                      onNavigate={navigateApplication}
                      onMessage={handleChatApplicant}
                      onInterview={handleInterview}
                      onNotes={handleOpenNotesModal}
                      onHire={handleHireApplicant}
                      onReject={handleRejectApplication}
                      isMobileView={true}
                      totalApplications={applications.length}
                    />
                    {renderApplicationContent()}
                  </>
                )}
              </div>
            )}
          </div>
        ) : (
          <Row gutter={[16, 16]}>
            <Col
              xs={24}
              lg={7}
              className={`${
                applications?.length === 0 ? "h-auto" : "h-[calc(100vh-150px)]"
              } sticky top-0`}
            >
              <div
                className="h-full overflow-y-auto 2xl:pr-2"
                ref={applicationsListRef}
                onScroll={handleScroll}
              >
                {loadingApplications || loading ? (
                  <>
                    {loadingType === "segment" && (
                      <PositionHeader selectedPosition={selectedPosition} />
                    )}
                    {Array.from({ length: 5 }).map((_, index) => (
                      <ApplicationCardSkeleton key={index} />
                    ))}
                  </>
                ) : applications?.length === 0 ? (
                  <div>
                    <PositionHeader selectedPosition={selectedPosition} />
                    <div className="mt-2 border border-primary-500 dark:border-darkGray text-gray-900 dark:text-gray-300 p-4 rounded-xl text-sm text-center">
                      Once you have applicants they will appear here
                    </div>
                  </div>
                ) : (
                  <>
                    <PositionHeader selectedPosition={selectedPosition} />
                    {applications?.map((application) => (
                      <DesktopApplicationCard
                        key={application.id}
                        application={application}
                        isSelected={selectedApplication?.id === application?.id}
                        onClick={() => handleApplicationSelection(application)}
                        ref={(el) => {
                          applicationRefsMap.current[application.id] = el;
                        }}
                      />
                    ))}
                    {loadingMore ? (
                      <div className="text-center py-2">
                        <i className="fad fa-circle-notch fa-spin text-primary-500 dark:text-primary-300 text-lg" />
                      </div>
                    ) : (
                      renderLoadMoreButton()
                    )}
                  </>
                )}
              </div>
            </Col>

            <Col xs={24} lg={17}>
              {loadingApplications || loading ? (
                <>
                  {loadingType === "segment" && (
                    <div className="flex items-center w-full rounded-xl border-2 border-warmgray-200 dark:border-darkGray dark:bg-darkGray mb-6 whitespace-nowrap overflow-x-auto">
                      <div className="flex flex-shrink-0">
                        {isArchivedPosition ? (
                          <div className="px-4 py-2 text-gray-900 dark:text-gray-300 font-medium">
                            {selectedPosition?.label}
                          </div>
                        ) : (
                          <JobPositionSelect
                            jobPositions={jobPositions}
                            selectedPosition={selectedPosition}
                            setSelectedPosition={setSelectedPosition}
                            mode={mode}
                          />
                        )}
                      </div>
                      {renderSegments()}
                    </div>
                  )}
                  <ApplicationLoadingSkeleton loadingType="segment" />
                </>
              ) : (
                <>
                  <div className="flex items-center w-full rounded-xl border-2 border-warmgray-200 dark:border-darkGray dark:bg-darkGray mb-6 whitespace-nowrap overflow-x-auto">
                    <div className="flex flex-shrink-0">
                      {isArchivedPosition ? (
                        <div className="px-4 py-2 text-gray-900 dark:text-gray-300 font-medium">
                          {selectedPosition?.label}
                        </div>
                      ) : (
                        <JobPositionSelect
                          jobPositions={jobPositions}
                          selectedPosition={selectedPosition}
                          setSelectedPosition={setSelectedPosition}
                          mode={mode}
                        />
                      )}
                    </div>
                    {renderSegments()}
                  </div>
                  {applications?.length > 0 && (
                    <>
                      <SelectedApplication
                        disabled={
                          isApplicationTransitioning || loadingApplications
                        }
                        selectedApplication={selectedApplication}
                        selectedPosition={selectedPosition}
                        onNavigate={navigateApplication}
                        onMessage={handleChatApplicant}
                        onInterview={handleInterview}
                        onNotes={handleOpenNotesModal}
                        onHire={handleHireApplicant}
                        onReject={handleRejectApplication}
                        isMobileView={false}
                        totalApplications={applications.length}
                      />
                      {renderApplicationContent()}
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
        )}
        {!loadingApplications && !loading && applications?.length === 0 && (
          <RecruitmentTools />
        )}

        <NotesModal
          isOpen={isNotesModalOpen}
          onClose={handleCloseNotesModal}
          application={selectedApplication}
        />

        <NewConfirmationModal
          open={isModalVisible}
          setOpen={setIsModalVisible}
          type={type}
          onClick={(declineReason) => {
            if (type === "hire") {
              hireApplicant(selectedApplication?.id);
            } else {
              rejectApplication(declineReason);
            }
          }}
          applicant={selectedApplication?.seeker}
          positionTitle={selectedPosition?.label}
          loading={loadingEvent}
        />

        <SchedulingOptionsModal
          open={isSchedulingOpen}
          setOpen={() => setIsSchedulingOpen(false)}
          applicant={selectedApplication?.seeker}
          positionId={selectedPosition?.value?.id}
        />
      </div>
    </DashboardLayout>
  );
};

export default ViewPositions;
