import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Form, Space, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import Input from "../../../components/form/Input";
import useFormatPhoneNumber from "../../../hooks/FormatPhoneNumber";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { generatePassword } from "../../../utils";
import User from "../../../api/User";
import { darkmode, selectUser, setNewSubUser } from "../../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { emitCustomEvent } from "react-custom-events";

export default function AddUserModal({ open, setOpen, data }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const mode = useSelector(darkmode);
  const editMode = false;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userPosition, setUserPosition] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState(null);
  const user = useSelector(selectUser);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const checkConfirmPassword = () => {
    if (confirmPassword === password) {
      return true;
    } else {
      setValidationErrors({
        ...validationErrors,
        confirmPassword: "password do not match!",
      });
      return false;
    }
  };

  const updateUser = async () => {
    setLoading(true);
    setValidationErrors(null);
    const requestBody = {
      role: "employee",
      email: email,
      phone: phone,
      first_name: firstName,
      last_name: lastName,
      company_id: user?.company_id,
    };
    requestBody.location_ids = [user?.location_id];

    User.update(requestBody, params.userId)
      .then(() => {
        toast.success(t("business-users.user-added"));
        emitCustomEvent("closeModal");
        navigate(-1);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
        setLoading(false);
      })
      .then(() => setLoading(false));
  };

  const addUser = async () => {
    const confirmPass = password ? checkConfirmPassword() : true;

    if (!confirmPass) {
      return;
    } else {
      setLoading(true);
      setValidationErrors(null);
      const _password = password ? password : generatePassword();
      const requestBody = {
        role: "employee",
        email: email,
        phone: phone,
        first_name: firstName,
        last_name: lastName,
        company_id: data?.location?.company_id,
        password: _password,
      };
      requestBody.location_ids = [data?.location?.id];
      requestBody.position_name = userPosition;

      User.add(requestBody)
        .then(() => {
          toast.success(t("business-users.user-added"));
          dispatch(setNewSubUser(true));
          setOpen(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            setValidationErrors(error.response.data.errors);
          } else {
            if (error?.response?.status !== 403) {
              toast.error(t("common.something-went-wrong-please-try-again"));
            }
          }
          setLoading(false);
        })
        .then(() => setLoading(false));
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-100" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-black dark:bg-opacity-60 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-[#1F1F1F] px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-[600px]">
                  <h4 className="font-bold text-lg text-primary-500 dark:text-gray-200 mt-4 mb-6 mx-6">
                    {editMode ? "Edit User" : "Create a User"}
                  </h4>
                  <div className="sm:rounded-lg sm:px-12 lg:px-6">
                    <Form form={form} layout="vertical" className="mt-4">
                      <Space size={20} direction="vertical" className="w-full">
                        <Row gutter={[15, 18]}>
                          <Col xs={24} md={12}>
                            <Input
                              name="name"
                              label={t("business-users.first-name")}
                              type="text"
                              value={firstName}
                              error={
                                validationErrors && validationErrors.first_name
                                  ? validationErrors.first_name[0]
                                  : null
                              }
                              onChange={(e) => setFirstName(e.target.value)}
                              darmo={mode}
                            />
                          </Col>

                          <Col xs={24} md={12}>
                            <Input
                              name="name"
                              label={t("business-users.last-name")}
                              type="text"
                              value={lastName}
                              error={
                                validationErrors && validationErrors.last_name
                                  ? validationErrors.last_name[0]
                                  : null
                              }
                              onChange={(e) => setLastName(e.target.value)}
                              darmo={mode}
                            />
                          </Col>
                        </Row>

                        <Row gutter={[15, 18]}>
                          <Col xs={24} md={12}>
                            <Input
                              name="email"
                              label={t("common.email")}
                              type="text"
                              value={email}
                              error={
                                validationErrors && validationErrors.email
                                  ? validationErrors.email[0]
                                  : null
                              }
                              onChange={(e) => setEmail(e.target.value)}
                              darmo={mode}
                            />
                          </Col>

                          <Col xs={24} md={12}>
                            <Input
                              name="phone"
                              label={t("common.phone")}
                              type="text"
                              value={useFormatPhoneNumber(phone)}
                              error={
                                validationErrors && validationErrors.phone
                                  ? validationErrors.phone[0]
                                  : null
                              }
                              onChange={(e) => {
                                if (e.target.value.length < 15) {
                                  setPhone(e.target.value);
                                }
                              }}
                              disabled={false}
                              darmo={mode}
                            />
                          </Col>
                        </Row>

                        <Input
                          className="my-1"
                          name="position"
                          label={t("business-users.position")}
                          type="text"
                          value={userPosition}
                          error={
                            validationErrors && validationErrors.position_name
                              ? validationErrors.position_name[0]
                              : null
                          }
                          onChange={(e) => setUserPosition(e.target.value)}
                          darmo={mode}
                        />

                        <Row gutter={[15, 18]}>
                          <Col xs={24} md={12}>
                            <Input
                              className="my-1"
                              name="password"
                              label={t("common.password")}
                              type="password"
                              value={password}
                              error={
                                validationErrors && validationErrors.password
                                  ? validationErrors.password
                                  : null
                              }
                              onChange={(e) => {
                                setValidationErrors({
                                  ...validationErrors,
                                  password: null,
                                });
                                setPassword(e.target.value);
                              }}
                              darmo={mode}
                            />
                          </Col>

                          <Col xs={24} md={12}>
                            <Input
                              className="my-1"
                              name="confirmPassword"
                              label={"Confirm " + t("common.password")}
                              type="password"
                              value={confirmPassword}
                              error={
                                validationErrors &&
                                validationErrors.confirmPassword
                                  ? validationErrors.confirmPassword
                                  : null
                              }
                              onChange={(e) => {
                                setValidationErrors({
                                  ...validationErrors,
                                  confirmPassword: null,
                                });
                                setConfirmPassword(e.target.value);
                              }}
                              darmo={mode}
                            />
                          </Col>
                        </Row>
                      </Space>
                    </Form>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6  sm:px-12 lg:px-6 mb-6">
                  <button
                    type="button"
                    className="flex w-full justify-center rounded-xl bg-primary-500 dark:bg-darkGray px-6 py-2 text-sm font-bold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                    onClick={(e) => {
                      e.preventDefault();
                      editMode ? updateUser() : addUser();
                    }}
                  >
                    {editMode
                      ? t("business-users.update-user")
                      : t("common.add")}
                    {loading && (
                      <i className="fad fa-circle-notch fa-spin ml-2 my-auto" />
                    )}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
