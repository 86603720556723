import React from "react";
import Select, { components } from "react-select";
import {
  openPositionsBlackIcon,
  openPositionsIcon,
} from "../../../assets/images";

const JobPositionSelect = ({
  jobPositions,
  selectedPosition,
  setSelectedPosition,
  mode,
}) => {
  // Helper function to count 'applied' applications for a position
  const getAppliedCount = (position) => {
    if (!position.applications || !Array.isArray(position.applications)) {
      return 0;
    }

    return position.applications.filter((app) => app.status === "applied")
      .length;
  };

  const CustomSingleValue = (props) => {
    const position = props.data.value;
    return (
      <components.SingleValue {...props}>
        <div className="flex items-center">
          <img
            src={mode ? openPositionsBlackIcon : openPositionsIcon}
            alt="applicant"
            className="w-4 h-4 flex-shrink-0 mr-2"
          />
          <span>{props.data.label}</span>
          <span className="ml-1">({position?.total_applied})</span>
        </div>
      </components.SingleValue>
    );
  };

  // Custom Option component to display the applied count
  const CustomOption = (props) => {
    const position = props.data.value;
    return (
      <components.Option {...props}>
        <div className="flex items-center w-full">
          <span>{props.data.label}</span>
          {position?.total_applied > 0 && (
            <span className="ml-1 text-sm opacity-75">
              ({position?.total_applied})
            </span>
          )}
        </div>
      </components.Option>
    );
  };

  // Format options with the applied count
  const getOptions = () => {
    if (!jobPositions) return [];

    return jobPositions.map((position) => {
      return {
        value: position,
        label: position?.title,
      };
    });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      backgroundColor: "transparent",
      minHeight: "auto",
      height: "auto",
      "&:hover": {
        border: "none",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0",
      display: "flex",
      alignItems: "center",
    }),
    singleValue: (provided) => ({
      ...provided,
      position: "relative",
      transform: "none",
      margin: "0 4px",
      color: mode ? "black" : "white",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: "0",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "0",
      color: mode ? "black" : "white",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      position: "absolute",
      width: "auto",
      minWidth: "200px",
      maxWidth: "400px",
      backgroundColor: mode ? "white" : "black",
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: mode
        ? state.isSelected
          ? "#f3f4f6"
          : "white"
        : state.isSelected
        ? "#374151"
        : "#242526",
      color: mode ? "black" : "white",
      "&:hover": {
        backgroundColor: mode ? "#f3f4f6" : "#374151",
      },
    }),
  };

  return (
    <div className="flex justify-center items-center mx-2 rounded-l-xl whitespace-nowrap cursor-pointer">
      {jobPositions && jobPositions.length > 0 && (
        <Select
          options={getOptions()}
          value={selectedPosition}
          onChange={(selectedOption) => {
            setSelectedPosition(selectedOption);
          }}
          styles={customStyles}
          components={{
            SingleValue: CustomSingleValue,
            Option: CustomOption,
          }}
          className="flex-grow font-bold"
          isSearchable={false}
          menuPlacement="bottom"
          menuPosition="fixed"
        />
      )}
    </div>
  );
};

export default JobPositionSelect;
