import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PredefinedJob from "../api/PredefinedJob";
import { emitCustomEvent } from "react-custom-events";
import { toast } from "react-hot-toast";
import Input from "../components/form/Input";
import Select from "../components/form/Select";
import TextArea from "../components/form/TextArea";
import { Link, useNavigate } from "react-router-dom";
import ReactTags from "react-tag-autocomplete";
import Certification from "../api/Certification";
import { DashboardLayout } from "../components/organisms/Layout";
import Category from "../api/Category";

const AddPredefinedJob = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("2033-12-12"); // TODO: Remove after endpoint removes required property
  const [position, setPosition] = useState("full-time");
  const [description, setDescription] = useState("");
  const [experience, setExperience] = useState("");
  const [certifications, setCertifications] = useState([]);
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const addPredefinedJob = () => {
    setLoading(true);

    PredefinedJob.add({
      title: title,
      start_date: startDate, // TODO: Remove after endpoint removes required property
      availability: position,
      description: description,
      experience: experience,
      category_id: selectedCategory,
    })
      .then(() => {
        emitCustomEvent("closeModal");
        emitCustomEvent("dataChanged");
        toast.success(t("pre-defined-jobs.pre-defined-job-added"));
        navigate("/predefined-jobs");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      })
      .then(() => setLoading(false));
  };

  const loadCertifications = () => {
    setLoading(true);

    Certification.getAll()
      .then((response) => {
        setCertifications(response.data.data);
      })
      .catch()
      .then(() => setLoading(false));
  };

  const loadCategories = () => {
    Category.getAll().then((response) => {
      setCategories(response.data.data);
      setSelectedCategory(response.data.data[0].id);
    });
  };

  const addCertification = (certification) => {
    // Make a request to the api
    Certification.add({
      category_id: 1,
      name: certification.name,
    }).then(() => {
      setSelectedCertifications([...selectedCertifications, certification]);
    });
  };

  const removeCertification = (index) => {
    const copy = selectedCertifications.slice(0);
    copy.splice(index, 1);
    setSelectedCertifications(copy);
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Create New Position Template";
    loadCertifications();
    loadCategories();
  }, []);

  return (
    <DashboardLayout darmo={true}>
      <div className="w-full">
        <h2 className={"text-2xl font-bold"}>
          <Link to={"/predefined-jobs"}>
            <i className="far fa-chevron-left text-xl mr-2" />
          </Link>{" "}
          {t("pre-defined-jobs.pre-defined-jobs")}
        </h2>
        <hr className="my-2 border-gray-300" />

        <div className="text-left snap-start pt-6 pb-8 mb-4 w-full flex flex-col gap-3">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
            <Input
              name="title"
              label={t("pre-defined-jobs.job-title")}
              type="text"
              value={title}
              placeholder={t("pre-defined-jobs.job-title")}
              error={
                validationErrors && validationErrors.title
                  ? validationErrors.title[0]
                  : null
              }
              onChange={(e) => setTitle(e.target.value)}
              darmo={"true"}
            />

            <div>
              <Select
                label={t("common.category")}
                value={selectedCategory}
                name="start_date"
                error={
                  validationErrors && validationErrors.category_id
                    ? validationErrors.category_id[0]
                    : null
                }
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                }}
              >
                {categories.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
              </Select>
            </div>

            <Select
              label={t("pre-defined-jobs.type-of-position")}
              value={position}
              name="company"
              onChange={(e) => {
                setPosition(e.target.value);
              }}
            >
              <option value="full-time">Full Time</option>
              <option value="part-time">Part Time</option>
            </Select>
          </div>

          <div className="grid grid-cols-1 gap-3">
            <div>
              <TextArea
                name="description"
                label={t("pre-defined-jobs.description")}
                value={description}
                placeholder={t("pre-defined-jobs.description")}
                error={
                  validationErrors && validationErrors.description
                    ? validationErrors.description[0]
                    : null
                }
                onChange={(e) => setDescription(e.target.value)}
                darmo={"true"}
              />
            </div>

            <div>
              <TextArea
                name="experience"
                label={t("pre-defined-jobs.required-experience")}
                value={experience}
                placeholder={t("pre-defined-jobs.required-experience")}
                error={
                  validationErrors && validationErrors.experience
                    ? validationErrors.experience[0]
                    : null
                }
                onChange={(e) => setExperience(e.target.value)}
                darmo={"true"}
              />
            </div>

            <div className="mb-2 md:mb-0">
              <label
                //  className={`font-bold ${mode ? "text-black" : "text-white"}`}
                className={`font-bold text-black`}
              >
                Required Certifications
              </label>
              <div className="mt-[3px]">
                <ReactTags
                  tags={selectedCertifications}
                  suggestions={certifications}
                  onDelete={removeCertification}
                  onAddition={addCertification}
                  newTagText={"Add New Certification:"}
                  placeholderText={"Search Certifications or add your own"}
                  allowNew={true}
                  allowBackspace={false}
                  classNames={{
                    // root: `p-3 border-2 rounded-xl border-gray-300 focus-within:border-primary-300 shadow-sm ${mode ? "bg-white" : "bg-slate-800"}`,
                    root: `p-3 border rounded-xl border-gray-300 focus-within:border-primary-300 shadow-sm bg-white`,
                    searchWrapper: "",
                    // searchInput: `focus:outline-0 ${mode ? "bg-white text-black" : "bg-slate-800 text-white"} custom-search-input`,
                    searchInput: `focus:outline-0 bg-white text-black custom-search-input sm:text-sm border border-gray-100`,
                    rootFocused: "focus:bg-blue-500",
                    suggestions:
                      "p-3 min-w-[250px] z-30 bg-white flex shadow-lg cursor-pointer",
                    suggestionActive: "bg-red-500",
                  }}
                  tagComponent={({ tag, removeButtonText, onDelete }) => {
                    return (
                      <button
                        className={
                          "bg-primary-400 hover:bg-primary-500 text-white text-sm rounded-full py-1 pl-3 pr-2 mr-1 mb-2"
                        }
                        onClick={onDelete}
                      >
                        {tag.name} <i className={"fas fa-times-circle ml-1"} />
                      </button>
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <hr className="mt-8" />

          <div className="flex flex-col justify-center items-center w-full md:w-1/5">
            <button
              className="w-full flex justify-center items-center rounded-full bg-primary-500 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
              onClick={(e) => {
                e.preventDefault();
                addPredefinedJob();
              }}
              disabled={loading}
            >
              {t("common.save")}
              {loading && <i className="fad fa-circle-notch fa-spin ml-2" />}
            </button>

            <div className="mt-4">
              <button>{t("click-here-to-save-as-draft")}</button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddPredefinedJob;
