import React, { useState } from "react";
import { emitCustomEvent } from "react-custom-events";
import { Modal } from "antd";
// import { useSelector } from "react-redux";
// import { selectRoles } from "../../store/authSlice";
import Input from "../form/Input";
import Company from "../../api/Company";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const AddCompanyModal = ({ company = null, visible, title, onClose }) => {
  const { t } = useTranslation();
  // const roles = useSelector(selectRoles);
  const [name, setName] = useState(company?.name);
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [isEnterprise, setIsEnterprise] = useState(
    company?.is_enterprise === 1 ? true : false
  );
  // const [selectedBrands, setSelectedBrands] = useState([]);
  // const [brands, setBrands] = useState([]);
  //const [locations, setLocations] = useState([]);
  //const [selectedLocation, setSelectedLocation] = useState([]);
  /*
  useEffect(() => {
    if (company) {
      loadBrands();
      //loadLocations();  
    }
  }, []);

  const loadLocations = () => {
    Location.getAll().then((response) => {
      const locationsResponse = response.data.data;
      setLocations(locationsResponse);
      if (locationsResponse.length > 0) {
        const _selectedLocation = filterSelectedLocations(locationsResponse);
        setSelectedLocation(_selectedLocation);
      }
    });
  };

  const filterSelectedLocations = (locations) => {
    const _selectedLocations = company?.locations.map(loc => {
      const _selectedLocation = locations.find(l => l?.id === loc?.id);
      if (_selectedLocation) {
        return {label: _selectedLocation?.name, value: _selectedLocation?.id};
      } else {
        return;
      }
    });
    const _tempSelectedLoc = _selectedLocations.filter(i => i?.value);
    return _tempSelectedLoc;
  };

  const loadBrands = () => {
    Brand.getAll().then((response) => {
      setBrands(response.data.data);
      if (response.data.data.length > 0) {
        const _selectedBrands = filterSelectedBrands(response.data.data);
        setSelectedBrands(_selectedBrands);
      }
    });
  };

  const filterSelectedBrands = (_brands) => {
    const _selectedbrands = company?.locations.map(loc => {
      const _selectedbrand = _brands.find(b => b?.id === loc?.brand_id);
      if (_selectedbrand) {
        return {label: _selectedbrand?.name, value: _selectedbrand?.id};
      } else {
        return;
      }
    });
    const _tempSelectedBrand = _selectedbrands.filter(i => i?.value);
    return _tempSelectedBrand;
  };

  const updateBrandsCompany = () => {
    const body = {
      company_id: company?.id,
      brand_id: selectedBrands?.value
    };
    SuperUser.updateBrandCompany(body).then((response) => {
      console.log('response', response.data.data);
    }).catch((error) => {
      console.log('error', error.response);
    }).then(() => {
      emitCustomEvent("companyAdded");
    });
  };
*/
  const addCompany = () => {
    setLoading(true);

    const request = company
      ? Company.update(company.id, {
          name: name,
          is_enterprise: isEnterprise ? 1 : 0,
        })
      : Company.add({ name: name, is_enterprise: isEnterprise ? 1 : 0 });

    request
      .then(() => {
        // updateBrandsCompany();
        toast.success(
          company ? t("companies.company-updated") : t("company-added")
        );
        // emitCustomEvent("companyAdded");
      })
      .catch((error) => {
        if (error.response && error.response.status === 428) {
          toast.error(
            "Action discarded! Enterprise should have more than 1 location."
          );
        } else if (error.response && error.response.status === 422) {
          // Validation error
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      })
      .then(() => setLoading(false));
  };

  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={onClose}
      closable={false}
      footer={null}
    >
      <div className="w-full">
        <button
          className="absolute right-5 m-3 p-3 text-white text-opacity-80 hover:text-opacity-100 text-xl"
          onClick={onClose}
        >
          <i className="far fa-times" />
        </button>

        <div className={"w-full text-center py-10 bg-violet-600"}>
          <span className="block text-xl text-white font-normal">{title}</span>
        </div>
        <div className="text-left snap-start px-8 pt-6 pb-8 mb-4 w-full">
          <Input
            className="my-3"
            darmo={true}
            name="name"
            label={t("common.name")}
            type="text"
            value={name}
            placeholder={t("companies.add-a-company")}
            error={
              validationErrors && validationErrors.name
                ? validationErrors.name[0]
                : null
            }
            onChange={(e) => setName(e.target.value)}
          />
          {/*isEnterprise && (
          <div className="my-3">
            <span className={"font-bold block"}>Brands</span>
            <ReactSelect
              value={selectedBrands}
              // isMulti
              theme={(theme) => {
                return {
                  ...theme,
                  borderRadius: 4,
                  spacing: {
                    ...theme.spacing,
                    controlHeight: 47,
                  },
                  colors: {
                    ...theme.colors,
                    primary25: "#A899E2",
                    primary: "#7D66D3",
                  },
                };
              }}
              onChange={(selected) => setSelectedBrands(selected)}
              options={brands.map((brand) => {
                return {
                  value: brand.id,
                  label: brand.name,
                };
              })}
            />
          </div>
            )*/}
          {/*isEnterprise && (
          <div className="my-3">
            <span className={"font-bold block mt--1"}>
              Locations
            </span>
            <ReactSelect
              value={selectedLocation}
              isMulti
              theme={(theme) => {
                return {
                  ...theme,
                  borderRadius: 4,
                  spacing: {
                    ...theme.spacing,
                    controlHeight: 47,
                  },
                  colors: {
                    ...theme.colors,
                    primary25: "#A899E2",
                    primary: "#7D66D3",
                  },
                };
              }}
              onChange={(selected) => setSelectedLocation(selected)}
              options={locations.map((location) => {
                return {
                  value: location.id,
                  label: location.name,
                };
              })}
            />
          </div>
            )*/}
          <div className="self-center font-bold">
            <input
              className="mr-2"
              type="checkbox"
              defaultChecked={isEnterprise}
              value={isEnterprise}
              onChange={() => setIsEnterprise(!isEnterprise)}
            />
            Is an enterprise Company?
          </div>

          <hr className="mt-8" />

          <div className="mt-8 flex">
            <button
              className="w-full rounded-md p-4 m-1 text-lg border-2 hover:text-white hover:bg-violet-500 border-violet-500 text-violet-500"
              onClick={(e) => {
                e.preventDefault();
                emitCustomEvent("closeModal");
              }}
            >
              {t("common.cancel")}
            </button>
            <button
              className="w-full rounded-md p-4 m-1 text-white text-lg bg-violet-500 hover:bg-violet-700 disabled:bg-violet-200"
              onClick={(e) => {
                e.preventDefault();
                addCompany();
              }}
              disabled={loading}
            >
              {t("common.save")}
              {loading ? (
                <i className="fad fa-circle-notch fa-spin ml-2" />
              ) : (
                ""
              )}
            </button>
          </div>

          <div className="text-center mt-5">
            <button>{t("click-here-to-save-as-draft")}</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddCompanyModal;
