import axios from "./api";

const base = "/business/application";
const baseInstagram = "/business/instagram";
const baseLog = "/business/application-log";

const Application = {
  getByLocationId(locationId, withoutChats = 0) {
    return axios.get(
      `${base}/location/${locationId}?withoutChats=${withoutChats}`
    );
  },
  getByPositionId(positionId, withoutChats = 0, type, page, limit) {
    let url = `${base}/position/${positionId}?withoutChats=${withoutChats}`;
    if (type !== undefined) url += `&type=${type}`;
    if (page !== undefined) url += `&page=${page}`;
    if (limit !== undefined) url += `&limit=${limit}`;
    return axios.get(url);
  },
  getApplicationByPositionAndSeeker(positionId, seekerId) {
    return axios.get(`${base}/${positionId}/${seekerId}`);
  },
  getById(Id) {
    return axios.get(`${base}/${Id}`);
  },
  hireByApplicationId(id) {
    return axios.post(`${base}/hire/${id}`);
  },
  rejectByApplicationId(body) {
    return axios.post(`${base}/reject`, body);
  },
  getInstagramProfile(id) {
    return axios.get(`${baseInstagram}/${id}`);
  },
  getApplicationLogs(id) {
    return axios.get(`${baseLog}/${id}`);
  },
  postApplicationView(body) {
    return axios.post(`${base}/view`, body);
  },
  getByIdForAvailability(Id) {
    return axios.get(`/business/calender-availability/${Id}`);
  },
  requestResume(body) {
    return axios.post(`/business/request-resume`, body);
  },
};

export default Application;
