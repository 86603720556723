import React, { useState, useMemo } from "react";
import { Divider } from "antd";
import { formatDate } from "../../../../utils/dateConversion";
import Table from "../../../../components/atoms/Tables/Table";
import TableLoading from "../../../../components/Loading/TableLoading";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";

const RegistrationsView = ({
  isLoading,
  dashboardData = [],
  statsData = {},
}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Pie chart uses full 30 days data
  const cityData = useMemo(
    () =>
      Object.values(
        dashboardData.reduce((acc, { city }) => {
          if (!city) return acc;
          acc[city] = acc[city] || { name: city, value: 0 };
          acc[city].value += 1;
          return acc;
        }, {})
      ),
    [dashboardData]
  );

  // Filter table data to last 14 days
  const tableDashboardData = useMemo(() => {
    const now = new Date();
    const last14Days = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000);
    return dashboardData.filter(
      (reg) => new Date(reg.registration_date) >= last14Days
    );
  }, [dashboardData]);

  const generateVibrantColors = (count) => {
    const baseColors = [
      "hsl(220, 80%, 60%)", // Blue
      "hsl(140, 60%, 50%)", // Green
      "hsl(45, 90%, 55%)", // Yellow
      "hsl(10, 80%, 60%)", // Red
      "hsl(280, 70%, 65%)", // Purple
      "hsl(200, 85%, 50%)", // Cyan
      "hsl(300, 65%, 55%)", // Pink
      "hsl(30, 85%, 55%)", // Orange
    ];

    return Array.from(
      { length: count },
      (_, i) => baseColors[i % baseColors.length]
    );
  };

  const dynamicColors = useMemo(
    () => generateVibrantColors(cityData.length),
    [cityData.length]
  );

  const totalRegistrations = cityData.reduce(
    (sum, item) => sum + item.value,
    0
  );

  return (
    <>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 pt-4">
        {[
          {
            label: "Last 24 Hours",
            value: statsData.last24Hours,
            color: "text-purple-600",
          },
          {
            label: "Last 3 Days",
            value: statsData.last3Days,
            color: "text-cyan-600",
          },
          {
            label: "Last 7 Days",
            value: statsData.last7Days,
            color: "text-pink-600",
          },
          {
            label: "Last 30 Days",
            value: statsData.last30Days,
            color: "text-orange-600",
          },
        ].map((stat, index) => (
          <div
            key={index}
            className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
          >
            <dt className="truncate text-sm font-medium text-gray-500">
              {stat.label}
            </dt>
            <dd
              className={`mt-1 text-3xl font-semibold tracking-tight ${stat.color}`}
            >
              {stat.value} Registrations
            </dd>
          </div>
        ))}
      </div>

      {/* Table Section */}
      <div className="pt-6 pb-4">
        <Divider className="my-0" />
        <p className="mt-4 font-bold text-lg text-gray-500">
          Registrations from last 14 days
        </p>
      </div>

      <div className="mt-4">
        {isLoading ? (
          <TableLoading Columns={5} />
        ) : (
          <Table
            headers={
              tableDashboardData.length > 0
                ? ["User", "Location", "Registered On"]
                : ["No registrations found in the last 14 days"]
            }
            data={tableDashboardData.map((registration, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="whitespace-nowrap py-3 pr-3 text-sm">
                  <div className="font-medium text-gray-900">
                    {registration.applicant_name}
                  </div>
                  <div className="text-gray-500">
                    {registration.applicant_email}
                  </div>
                  <div className="text-gray-500">
                    {registration.applicant_phone}
                  </div>
                </td>
                <td className="whitespace-nowrap py-3 pr-3 text-sm">
                  <div className="text-gray-900">
                    {registration.city}, {registration.state}
                  </div>
                </td>
                <td className="whitespace-nowrap py-3 pr-3 text-sm text-gray-500">
                  {formatDate(
                    registration.registration_date,
                    "MM-DD-YYYY HH:mm"
                  )}
                </td>
              </tr>
            ))}
          />
        )}
      </div>

      {/* Pie Chart Section */}
      <div className="pt-6 pb-4">
        <Divider className="my-0" />
        <p className="mt-4 font-bold text-lg text-gray-500">
          Job Seeker Registrations by City (Last 30 Days)
        </p>
      </div>

      <div className="mt-6 flex justify-center">
        {cityData.length > 0 ? (
          <div className="w-full">
            <ResponsiveContainer width="100%" height={450}>
              <PieChart>
                <Pie
                  data={cityData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius="85%"
                  activeIndex={activeIndex}
                >
                  {cityData.map((city, index) => {
                    const baseColor = dynamicColors[index];
                    const brightenedColor = baseColor.replace("%60%", "%75%"); // Increase brightness on hover

                    return (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          activeIndex === index ? brightenedColor : baseColor
                        }
                        opacity={
                          activeIndex === null || activeIndex === index
                            ? 1
                            : 0.4
                        }
                        onMouseEnter={() => setActiveIndex(index)}
                        onMouseLeave={() => setActiveIndex(null)}
                      />
                    );
                  })}
                </Pie>
                <Tooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      const data = payload[0].payload;
                      const percent = (
                        (data.value / totalRegistrations) *
                        100
                      ).toFixed(1);
                      return (
                        <div className="bg-white shadow-lg rounded-lg p-3 border border-gray-200">
                          <p className="font-semibold text-gray-900">
                            {data.name}
                          </p>
                          <p className="text-sm text-gray-500">
                            Registrations: {data.value} ({percent}%)
                          </p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
              </PieChart>
            </ResponsiveContainer>

            {/* Legend */}
            <div className="flex flex-wrap justify-center gap-3 mt-3">
              {cityData.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center cursor-pointer"
                  onMouseEnter={() => setActiveIndex(index)}
                  onMouseLeave={() => setActiveIndex(null)}
                >
                  <span
                    className="inline-block w-4 h-4 mr-2 rounded-full"
                    style={{
                      backgroundColor: dynamicColors[index],
                    }}
                  />
                  <span className="text-sm text-black">
                    {item.name} ({item.value})
                  </span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p className="text-gray-500 text-center">No city data available</p>
        )}
      </div>
    </>
  );
};

export default RegistrationsView;
