import React from "react";

import "./PublicProfile.scss";
import OpenPositionCard from "../../../components/molecules/OpenPositionCard/OpenPositionCard";
import { Collapse, Space } from "antd";
import ImgAvatarArrow from "../../../assets/images/profile-round.svg";
import TwoHandsImage from "../../../assets/images/two-hands.png";
import { FiChevronDown } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import PublicProfileAPI from "../../../api/PublicProfile";

const { Panel } = Collapse;

const PublicProfile = () => {
  // get parameter query
  const [brand, setBrand] = useState({});
  const [locations, setLocations] = useState({});
  // const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const FetchBrandData = async (slug) => {
    try {
      const respo = await PublicProfileAPI.brandLookUp(slug);
      const { data } = respo.data;

      // set brand data
      setBrand(data);
      if (data?.location_count === 1) {
        const lUrl = data?.locations[0]?.url_slug;
        // call location api
        FetchLocationData(slug, lUrl);
      }
    } catch (error) {
      // setLoading(false);
      navigate("/404");
      toast.error("Brand Not Found");
    }
  };

  const FetchLocationData = async (brandSlug, slug) => {
    try {
      const respo = await PublicProfileAPI.locationLookUp(brandSlug, slug);
      const { data } = respo.data;

      // set brand data
      setLocations(data);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      navigate("/404");
      toast.error("Brand Not Found");
    }
  };

  useEffect(() => {
    const { brand } = params;
    if (brand) {
      return FetchBrandData(brand);
    } else {
      navigate("/404");
      toast.error("Brand Not Found");
    }
  }, [params]);

  const business = {
    name: "Two Hands - ATX",
    location: "1011 South Congress Avenue, Austin, TX, United State 78704",
    website: "twohandsnyc.com",
    description:
      "Two Hands is a community-focused cafe that strives to create nutritious, simple and delicious food paired with exceptional coffee. Taking inspiration from Australia's forward-thinking culinary scene and relaxed beachside lifestyle, Two Hands aims to be a place for the community near and far to come unwind in the light-filled sp...",
    image: TwoHandsImage,
  };

  // const openPositions = [
  //   {
  //     position: "Busser/Porter",
  //     description:
  //       "We are seeking team players with high volume experience, great personnalities and a smile to carry on the hospitality of Juliet. We are seeking team players with high volume experience, great personnalities and a smile to carry on the hospitality of Juliet. We are seeking team players with high volume experience, great personnalities and a smile to carry on the hospitality of Juliet. ",
  //   },
  // ];

  return (
    <div className="page-public-profile h-full">
      <div className="public-profile flex flex-col h-full">
        <div className="h-32 md:h-64 bg-red-400 md:rounded-b-3xl header">
          <div className="max-w-[1280px] w-full h-full mx-auto p-4">
            <div className="h-full flex justify-end items-center md:items-end">
              <button className="hidden md:block px-4 py-2 rounded-md border border-primary-500 bg-white hover:bg-primary-500 text-primary-500 hover:text-white cursor-pointer">
                Share <i className="fas fa-share-all ml-1" />
              </button>
              <button className="md:hidden px-4 py-2 rounded-md border border-warmgray-900 bg-white hover:bg-warmgray-900 text-warmgray-900 hover:text-white cursor-pointer">
                <i className="fas fa-share-all" />
              </button>
            </div>
          </div>
        </div>
        <div className="max-w-[1280px] w-full mx-auto flex-1">
          <div className="flex h-full flex-col md:flex-row">
            <div className="w-full md:w-[383px] bg-primary-400 text-white flex flex-col">
              <div className="relative hidden top-[-70px] md:flex flex-col px-6 h-100">
                <div className="relative flex justify-center bg-white rounded-full p-3 w-[145px] h-[145px] mx-auto">
                  <img
                    className={"rounded-full"}
                    height="125"
                    width="125"
                    src={brand?.photo?.thumb_url || business.image}
                    alt={brand?.name}
                  />
                  <img
                    src={ImgAvatarArrow}
                    alt="avatar"
                    className="absolute bottom-0"
                  />
                </div>

                <span className="block font-bold text-white text-xl mt-7 mb-4">
                  {brand?.name}
                </span>

                <div className="flex items-center mb-4">
                  <div className="text-center min-w-[28px]">
                    <i className="far fa-map-marker-alt col-span-1 text-xl text-white-400 text-center self-center" />
                  </div>
                  <span className="ml-1 col-span-7 font-semibold">
                    {business.location}
                  </span>
                </div>

                <div className="flex items-center mb-6">
                  <div className="text-center min-w-[28px]">
                    <i className="far fa-home col-span-1 text-xl text-white-400 text-center self-center" />
                  </div>
                  <span className="ml-1 col-span-7 font-semibold self-center underline">
                    <a href={brand?.website} target="_blank" rel="noreferrer">
                      {brand?.website}
                    </a>
                  </span>
                </div>

                <span className="block font-semibold text-white-900">
                  {brand?.description}
                </span>
              </div>

              <div className={"hidden md:block mt-auto"}>
                <div className="flex justify-center mb-4">
                  <img
                    width={100}
                    src="/images/play_store_badge.png"
                    alt="Play Store"
                  />
                  <img
                    width={100}
                    className="ml-1"
                    src="/images/app_store_badge.png"
                    alt="App Store"
                  />
                </div>
                <div className="shadow-2xl text-center bg-white text-primary-500 py-4 flex justify-center">
                  <span className="font-semibold self-center mr-2">
                    Powered by{" "}
                  </span>
                  <img
                    height={10}
                    width={120}
                    alt="logo"
                    src="/logo/powered-by.svg"
                  />
                </div>
              </div>

              <Collapse
                className="public-profile-collapse md:hidden"
                ghost
                expandIconPosition="right"
                expandIcon={({ isActive }) => (
                  <FiChevronDown size={24} color="white" />
                )}
              >
                <Panel
                  header={
                    <div className={"flex text-white"}>
                      <div
                        className={"rounded-full border-4 border-white h-min"}
                      >
                        <img
                          className={"rounded"}
                          src={business.image}
                          alt={business.name}
                          width={40}
                          height={40}
                        />
                        {/* <img
                          src={ImgAvatarArrow}
                          alt="avatar"
                          className="absolute left-0 bottom-0"
                        /> */}
                      </div>
                      <div className={"ml-2"}>
                        <span className={"block text-lg font-bold"}>
                          {brand?.name}
                        </span>
                        <span className={"block underline cursor-pointer"}>
                          {brand?.website}
                        </span>
                      </div>
                    </div>
                  }
                  key="1"
                >
                  <div>
                    <p className={"text-white ml-14"}>{brand?.location}</p>
                    <p className={"mt-4 text-white"}>{brand?.description}</p>
                  </div>
                </Panel>
              </Collapse>
            </div>
            <div className="flex-1 md:ml-0 lg:ml-16 md:pt-8 p-4 pb-44 md:pb-6">
              <div className="h-full">
                <div className="flex justify-between bg-white shadow-lg  p-2 px-4 md:p-6 rounded-md">
                  <h6 className="">
                    Open Position ({locations?.positions?.length})
                  </h6>
                  {/* <div className="bg-orange-900 small text-white p-1 rounded-sm font-normal self-center">
                    Immediate Interviewing
                  </div> */}
                </div>

                <div className="mt-3 md:mt-4">
                  <Space direction="vertical" size={8} className="w-full">
                    {locations?.positions &&
                      locations?.positions.map((l, i) => (
                        <OpenPositionCard key={i} position={l} />
                      ))}

                    {/* <OpenPositionCard position={openPositions[0]} />
                    <OpenPositionCard position={openPositions[0]} />
                    <OpenPositionCard position={openPositions[0]} /> */}
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"fixed md:hidden w-full bottom-0"}>
        <div
          className={
            "px-4 py-5 bg-primary-500 flex items-center shadow-[3px_0_0_2px_rgba(0,0,0,0.1)]"
          }
        >
          <span className={"text-white font-thin"}>
            Want to <strong className="font-bold">apply</strong> for this{" "}
            <strong className="font-bold">job?</strong>
          </span>
          <img
            width={150}
            className="ml-4"
            src="/images/app_store_badge.png"
            alt="App Store"
          />
        </div>
        <div className="text-center bg-white text-primary-500 py-4 flex justify-center">
          <span className="font-semibold self-center mr-2">Powered by </span>
          <img height={10} width={120} alt="logo" src="/logo/powered-by.svg" />
        </div>
      </div>
    </div>
  );
};

export default PublicProfile;
