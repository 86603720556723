import axios from "axios";

const myAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

let unauthorizedCallback = null;
let navigateFunction = null;

export const setUnauthorizedCallback = (callback) => {
  unauthorizedCallback = callback;
};

export const setNavigateFunction = (navigate) => {
  navigateFunction = navigate;
};

// Add request interceptor for setting auth token
myAxios.interceptors.request.use((config) => {
  let token = localStorage.getItem("token");
  config.headers.Authorization = token ? "Bearer " + token : null;
  return config;
});

myAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const isChatCreateToken =
      error.config && error.config.url.includes("/business/chat/create-token");

    if (error.response && error.response.status === 403) {
      if (!isChatCreateToken) {
        if (unauthorizedCallback) {
          unauthorizedCallback();
        } else if (navigateFunction) {
          // navigateFunction("/business/billing");
        } else {
          console.log("Unauthorized");
        }
      }
    }
    return Promise.reject(error);
  }
);

export default myAxios;
