import { Form, Space, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Auth from "../../../../api/auth";
import FormSelect from "../../../../components/atoms/FormSelect";
import Input from "../../../../components/form/Input";
import AuthLayout from "../../../../components/organisms/Layout/Authlayout";
import FormInput from "../../../../components/atoms/FormInput";
import FormPhoneInput from "../../../../components/atoms/FormPhoneInput";
import {
  RegisterStep1Schema,
  RegisterStep1InitialValues,
} from "../registerSchema";
import { darkmode } from "../../../../store/authSlice";
import ProgressBar from "../../../../components/atoms/ProgressBar/ProgressBar";

const hearList = [
  { value: "Social Media", label: "Social Media" },
  { value: "Google", label: "Google" },
  { value: "From a Friend", label: "From a Friend" },
  { value: "From an Ad", label: "From an Ad" },
  { value: "Others", label: "Others" },
];

const Step1 = ({ onSubmit, data }) => {
  const [strength, setStrength] = useState(
    JSON.parse(localStorage.getItem("registerStep1"))?.strength || 0
  );
  const [apiError, setApiError] = useState({});
  const registerStep0 = JSON.parse(localStorage.getItem("registerStep0"));
  const defaultBusinessName = registerStep0?.businessName || "";
  const defaultEmail = registerStep0?.email || "";
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const mode = useSelector(darkmode);
  const navigate = useNavigate();

  useEffect(() => {
    if (!registerStep0?.businessName && !registerStep0?.email) {
      navigate("/plans");
    }
  }, []);

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setSubmitting,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      ...RegisterStep1InitialValues,
      email: registerStep0?.email || "",
      businessName: registerStep0?.businessName || "",
    },
    validationSchema: RegisterStep1Schema,
    onSubmit: async (formData) => {
      setSubmitting(true);
      setLoading(true);
      if (strength >= 75) {
        const valid = await validateEmailPhone(formData.email, formData.phone);

        if (valid) {
          const {
            password,
            cpassword,
            email,
            businessName,
            ...formDataWithoutPasswords
          } = formData;

          let dataWithStrength;

          if (
            formDataWithoutPasswords.hearAboutUs !== "Others" &&
            formDataWithoutPasswords.others !== ""
          ) {
            dataWithStrength = { ...formDataWithoutPasswords, others: "" };
          } else {
            dataWithStrength = { ...formDataWithoutPasswords };
          }

          localStorage.setItem(
            "registerStep1",
            JSON.stringify(dataWithStrength)
          );
          onSubmit(formData, "3");
        }
      }
      setLoading(false);
    },
  });

  const onChangePassword = (e) => {
    const value = e.target.value;
    setFieldValue("password", value);
    const counts = value.length > 7;
    const hasNumber = /^(?=.*[0-9])/.test(value);
    const passCount = [counts, hasNumber].filter(Boolean).length;
    setStrength(passCount * 40);
  };

  const validateEmailPhone = async (email, phone) => {
    try {
      await Auth.VerifyUnique({ email, phone });
      setApiError({});
      return true;
    } catch (error) {
      const err = error.response?.data?.errors || {};
      setApiError(err);
      return false;
    }
  };

  const initializeFormValues = () => {
    const storedData = localStorage.getItem("registerStep1");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setValues(parsedData);
      form.setFieldsValue(parsedData);
    }
  };

  useEffect(() => {
    initializeFormValues();
  }, []);

  return (
    <AuthLayout>
      <ProgressBar currentStep={2} />
      <div className="flex flex-1 flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full rounded mx-4 h-full">
          <div className="w-full h-full">
            <div className="sm:mx-auto sm:w-full flex flex-col justify-center h-full">
              <div className="mb-8 sm:mx-auto sm:w-full lg:max-w-lg sm:max-w-[600px] h-full">
                <div className="flex flex-col h-full items-center sm:rounded-lg sm:px-12 lg:px-6">
                  <p
                    className={`text-xl font-bold ${
                      mode ? "text-black" : "text-white"
                    }`}
                  >
                    Tell us about your business
                  </p>
                  <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    className="mt-4"
                  >
                    <Space size={20} direction="vertical" className="w-full">
                      <FormInput
                        id="businessName"
                        name="businessName"
                        label="Business Name"
                        placeholder="XYZ Holdings LLC"
                        value={values.businessName}
                        defaultValue={defaultBusinessName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        darmo={mode}
                        disabled
                      />
                      {touched.businessName && errors.businessName && (
                        <p className="text-red-400 text-xs font-bold mt-[-20px]">
                          {errors.businessName}
                        </p>
                      )}

                      <Row gutter={[15, 18]}>
                        <Col xs={24} md={12}>
                          <FormInput
                            name="firstName"
                            label="First Name"
                            placeholder="John"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            darmo={mode}
                          />
                          {touched.firstName && errors.firstName && (
                            <p className="text-red-400 text-xs font-bold pt-0.5">
                              {errors.firstName}
                            </p>
                          )}
                        </Col>
                        <Col xs={24} md={12}>
                          <FormInput
                            name="lastName"
                            label="Last Name"
                            placeholder="Smith"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            darmo={mode}
                          />
                          {touched.lastName && errors.lastName && (
                            <p className="text-red-400 text-xs font-bold pt-0.5">
                              {errors.lastName}
                            </p>
                          )}
                        </Col>
                      </Row>
                      <Row gutter={[15, 18]}>
                        <Col xs={24} md={12}>
                          <FormPhoneInput
                            id="phone-input"
                            name="phone"
                            darmo={mode}
                            label="Phone Number"
                            value={values.phone}
                            onChange={(e) => {
                              setFieldValue("phone", e);
                            }}
                            onBlur={handleBlur}
                          />
                          {touched?.phone && errors?.phone && (
                            <p className="text-red-400 text-xs font-bold pt-0.5">
                              {errors?.phone}
                            </p>
                          )}
                          {touched?.phone && apiError?.phone && (
                            <p className="text-red-400 text-xs font-bold pt-0.5">
                              {apiError?.phone}
                            </p>
                          )}
                        </Col>
                        <Col xs={24} md={12}>
                          <FormInput
                            name="email"
                            id="email"
                            label="E-mail"
                            value={values.email}
                            defaultValue={defaultEmail}
                            darmo={mode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled
                          />
                          {touched.email && errors.email && (
                            <p className="text-red-400 text-xs font-bold pt-0.5">
                              {errors.email}
                            </p>
                          )}
                          {touched.email && apiError?.email && (
                            <p className="text-red-400 text-xs font-bold pt-0.5">
                              {apiError?.email}
                            </p>
                          )}
                        </Col>
                      </Row>
                      <Input
                        name="password"
                        label="Password"
                        type="password"
                        placeholder="password"
                        value={values.password}
                        onBlur={handleBlur}
                        onChange={onChangePassword}
                        darmo={mode}
                      />
                      {touched.password && errors.password && (
                        <p className="text-red-400 text-xs font-bold mt-[-20px]">
                          {errors.password}
                        </p>
                      )}

                      <Input
                        name="cpassword"
                        label="Confirm Password"
                        type="password"
                        placeholder="confirm password"
                        value={values.cpassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        darmo={mode}
                      />

                      <FormSelect
                        id="hearAboutUs"
                        name="hearAboutUs"
                        label="How did you hear about us?"
                        placeholder="How did you hear about us?"
                        defaultValue={
                          !!values.hearAboutUs && [
                            {
                              label: values.hearAboutUs,
                              value: values.hearAboutUs,
                            },
                          ]
                        }
                        onBlur={handleBlur}
                        onChange={(value) =>
                          setFieldValue("hearAboutUs", value.value)
                        }
                        options={hearList}
                        darmo={mode}
                        value={
                          !!values.hearAboutUs && [
                            {
                              label: values.hearAboutUs,
                              value: values.hearAboutUs,
                            },
                          ]
                        }
                      />
                      {touched.hearAboutUs && errors.hearAboutUs && (
                        <p className="text-red-400 text-xs font-bold mt-[-22px]">
                          {errors.hearAboutUs}
                        </p>
                      )}
                      {values.hearAboutUs === "Others" && (
                        <div>
                          <FormInput
                            id="others"
                            name="others"
                            label="Can you mention what Other Way?"
                            placeholder="What Other source?"
                            value={values.others}
                            darmo={mode}
                            onChange={(e) => {
                              setFieldValue("others", e.target.value);
                            }}
                            onBlur={handleBlur}
                          />
                          {touched.others && errors.others && (
                            <p className="text-red-400 text-xs font-bold mt-1">
                              {errors.others}
                            </p>
                          )}
                        </div>
                      )}
                    </Space>

                    <div
                      className={`title mt-12 text-sm ${
                        mode ? "text-black" : "text-primary-100"
                      }`}
                    >
                      By registering, you are agreeing to the
                      <Link
                        to="https://www.heyhire.com/terms-conditions"
                        target="_blank"
                        className={`${
                          mode
                            ? "text-indigo-600 hover:text-indigo-500 "
                            : "text-secondary hover:text-white"
                        } font-semibold ml-1`}
                      >
                        Terms and Conditions
                      </Link>{" "}
                      and
                      <Link
                        to="https://www.heyhire.com/privacy"
                        target="_blank"
                        className={`ml-1 ${
                          mode
                            ? "text-indigo-600 hover:text-indigo-500 "
                            : "text-secondary hover:text-white"
                        } font-semibold`}
                      >
                        Privacy Policy
                      </Link>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="flex border-t-2 py-4 border-primary-500 dark:border-darkGray justify-center">
                <div className="flex w-full lg:w-1/2 xl:w-1/3 justify-between">
                  <button
                    className="font-bold text-sm mr-2 px-6 py-2 w-32 rounded-full bg-primary-500 dark:bg-darkGray text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                    type="button"
                    onClick={() => {
                      const {
                        password,
                        cpassword,
                        email,
                        businessName,
                        ...valuesWithoutPasswords
                      } = values;
                      localStorage.setItem(
                        "registerStep1",
                        JSON.stringify({ ...valuesWithoutPasswords })
                      );
                      navigate("/register?step=1");
                    }}
                    disabled={loading}
                  >
                    Previous
                  </button>
                  <button
                    className="font-bold text-sm mr-2 px-6 py-2 w-32 rounded-full bg-primary-500 dark:bg-darkGray text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                    type="submit"
                    onClick={() => handleSubmit()}
                    disabled={loading}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Step1;
