import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { emitCustomEvent } from "react-custom-events";
import { toast } from "react-hot-toast";
import Company from "../../api/Company";
import User from "../../api/User";
import useFormatPhoneNumber from "../../hooks/FormatPhoneNumber";
import Input from "../../components/form/Input";
import { DashboardLayout } from "../../components/organisms/Layout";
import ReactSelect from "react-select";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Location from "../../api/Location";
import Region from "../../api/Region";
import { useSelector } from "react-redux";
import { darkmode, selectRoles, selectUser } from "../../store/authSlice";
import { userTypesEnterprise, AddUserTypes } from "../../utils/permission";
import { generatePassword } from "../../utils";
import FormSelect from "../atoms/FormSelect";
import { darkThemeColors, lightThemeColors } from "../../themeColors";

const AllowedUsers = ["owner"];

const AddUserModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [editableUser, setEditableUser] = useState();
  const params = useParams();
  const editMode = location.pathname.search("edit") !== -1;
  const user = useSelector(selectUser);
  const roles = useSelector(selectRoles);
  const [selectedUserType, setSelectedUserType] = useState("owner");
  const [selectedCompany, setSelectedCompany] = useState(2);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userPosition, setUserPosition] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const allowedCompanySelection =
    roles.length > 0 && roles[0] === "super-user"
      ? AllowedUsers.includes(roles[0])
      : false;
  const allowedUserTypeSelection =
    roles.length > 0 && roles[0] === "owner" && !user.company.is_enterprise
      ? false
      : true;
  const userTypes = allowedUserTypeSelection
    ? user.company.is_enterprise & (roles[0] === "owner")
      ? userTypesEnterprise
      : AddUserTypes[roles[0]]
    : AddUserTypes[roles[0]];
  const mode = useSelector(darkmode);

  useEffect(() => {
    if (editMode) {
      document.title = "HeyHire Portal-Edit User";
    } else {
      document.title = "HeyHire Portal-Add New User";
    }
    loadCompanies();
    loadLocations();
    loadRegions();
    if (editMode) {
      loadUser();
    }
  }, []);

  useEffect(() => {
    setSelectedLocation([]);
    if (editableUser?.roles[0]?.name !== "regional_manager") {
      setSelectedRegions([]);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (editMode && editableUser?.roles[0]?.name === "regional_manager") {
      const _tempRegions = regions?.filter(
        (i) => i?.id === editableUser?.location?.region_id
      ); //await filteredRegions();
      let _selectedRegion = [];
      _selectedRegion.push({
        value: _tempRegions[0]?.id,
        label: _tempRegions[0]?.name,
      });
      setSelectedRegions(_selectedRegion);
    }
  }, [regions, editableUser]);

  const loadUser = () => {
    setLoading(true);

    User.getById(params.userId)
      .then(async (response) => {
        const tempUser = response.data.data;
        setEditableUser(tempUser);
        setSelectedCompany(tempUser?.company_id);
        setSelectedUserType(tempUser?.roles[0]?.name);
        setSelectedLocation([
          { value: tempUser?.location_id, label: tempUser?.location?.name },
        ]);
        setFirstName(tempUser?.first_name);
        setLastName(tempUser?.last_name);
        setEmail(tempUser?.email);
        setPhone(tempUser?.phone);
        setUserPosition(tempUser?.position_name);
        setLoading(false);
      })
      .catch((error) => {
        console.log("loadUser -> error", error);
      });
  };

  const loadCompanies = () => {
    Company.getAll().then((response) => {
      setCompanies(response.data.data);

      // Set the default selected company
      if (response.data.data.length > 0 && !editMode) {
        setSelectedCompany(response.data.data[0].id);
      }
    });
  };

  const loadLocations = () => {
    Location.getAll().then((response) => {
      const locationsResponse = response.data.data;
      setLocations(locationsResponse);

      if (locationsResponse.length > 0 && !editMode) {
        setSelectedLocation(locationsResponse[0].id);
      }
    });
  };

  const loadRegions = () => {
    Region.getAll().then((response) => setRegions(response.data.data));
  };

  const updateUser = async () => {
    setLoading(true);
    setValidationErrors(null);
    const requestBody = {
      role: allowedUserTypeSelection ? selectedUserType : "employee",
      email: email,
      phone: phone,
      first_name: firstName,
      last_name: lastName,
      company_id: selectedCompany,
    };

    if (selectedUserType === "regional_manager") {
      requestBody.region_ids = selectedRegions.map((r) => r.value);
      requestBody.location_ids =
        selectedLocation?.length > 0
          ? selectedLocation?.map((sl) => sl.value)
          : !selectedLocation
          ? [selectedLocation]
          : [user.location_id];
    } else {
      requestBody.location_ids =
        selectedLocation?.length > 0
          ? selectedLocation?.map((sl) => sl.value)
          : !selectedLocation
          ? [selectedLocation]
          : [user.location_id];
    }
    User.update(requestBody, params.userId)
      .then(() => {
        toast.success(t("business-users.user-added"));
        emitCustomEvent("closeModal");
        navigate(-1);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
        setLoading(false);
      })
      .then(() => setLoading(false));
  };

  const addUser = async () => {
    const confirmPass = password ? await checkConfirmPassword() : true;

    if (!confirmPass) {
      return;
    } else {
      setLoading(true);
      setValidationErrors(null);
      const _password = password ? password : await generatePassword();
      const requestBody = {
        role: allowedUserTypeSelection ? selectedUserType : "employee",
        email: email,
        phone: phone,
        first_name: firstName,
        last_name: lastName,
        company_id: selectedCompany,
        password: _password,
      };

      if (selectedUserType === "regional_manager") {
        requestBody.region_ids = selectedRegions.map((r) => r.value);
        requestBody.location_ids =
          selectedLocation?.length > 0
            ? selectedLocation?.map((sl) => sl.value)
            : !selectedLocation
            ? [selectedLocation]
            : [user.location_id];
      } else {
        requestBody.location_ids =
          selectedLocation?.length > 0
            ? selectedLocation?.map((sl) => sl.value)
            : !selectedLocation
            ? [selectedLocation]
            : [user.location_id];
      }
      if (selectedUserType === "employee" || !allowedUserTypeSelection) {
        requestBody.position_name = userPosition;
      }
      User.add(requestBody)
        .then(() => {
          toast.success(t("business-users.user-added"));
          emitCustomEvent("closeModal");
          navigate("/business/manage-users");
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            setValidationErrors(error.response.data.errors);
          } else {
            if (error?.response?.status !== 403) {
              toast.error(t("common.something-went-wrong-please-try-again"));
            }
          }
          setLoading(false);
        })
        .then(() => setLoading(false));
    }
  };

  const filteredLocations = () => {
    return locations.filter(
      (location) => location.company.id === selectedCompany
    );
  };

  const checkConfirmPassword = () => {
    if (confirmPassword === password) {
      return true;
    } else {
      setValidationErrors({
        ...validationErrors,
        confirmPassword: "password do not match!",
      });
      return false;
    }
  };

  const filteredRegions = () => {
    return regions.filter((region) => region.company_id === selectedCompany);
  };

  useEffect(() => console.log(selectedCompany, companies), [selectedCompany]);

  return (
    <DashboardLayout darmo={true}>
      <div className="w-full">
        <h2 className="text-2xl font-bold my-4 text-gray-900 dark:text-gray-300">
          <span className="my-auto">
            {editMode ? "Edit User" : "Create User"}
          </span>
          <hr className="lg:w-11/12 2xl:w-4/5 border border-primary-100 dark:border-gray-400 mt-1" />
        </h2>
        <div className="text-left snap-start pt-6 pb-8 mb-4 w-full">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:w-[90%] xl:w-4/5 gap-2">
            {allowedCompanySelection && (
              <div className="mt-1">
                <FormSelect
                  type="add-job"
                  label={t("common.company")}
                  placeholder="Select Company"
                  value={selectedCompany?.label}
                  options={companies.map((job) => ({
                    value: job?.name,
                    label: job?.name,
                  }))}
                  onChange={(e) => setSelectedCompany(e?.value)}
                  darmo={mode}
                />
              </div>
            )}

            {allowedUserTypeSelection && (
              <div className="">
                <FormSelect
                  type="add-job"
                  label={t("business-users.select-user-type")}
                  placeholder={t("business-users.select-user-type")}
                  value={selectedUserType?.label}
                  options={userTypes.map((job) => ({
                    value: job?.value,
                    label: job?.label,
                  }))}
                  onChange={(e) => setSelectedUserType(e?.value)}
                  darmo={mode}
                />
              </div>
            )}
            {selectedUserType === "regional_manager" ? (
              <div>
                <span className="font-bold block mt-1 text-gray-900 dark:text-gray-300">
                  Regions
                </span>
                <ReactSelect
                  value={selectedRegions}
                  isMulti
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    spacing: {
                      ...theme.spacing,
                      controlHeight: 40,
                    },
                    colors: {
                      ...theme.colors,
                      ...(mode ? lightThemeColors : darkThemeColors),
                    },
                  })}
                  onChange={(selected) => setSelectedRegions(selected)}
                  options={filteredRegions().map((region) => ({
                    value: region.id,
                    label: region.name,
                  }))}
                  isDisabled={selectedUserType === "owner"}
                  classNamePrefix="react-select"
                  styles={{
                    menu: (base) => ({
                      ...base,
                      backgroundColor: mode ? "#ffffff" : "#F5F5F5",
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: mode
                        ? state.isFocused
                          ? "#D3D3D3"
                          : "#ffffff"
                        : state.isFocused
                        ? "#D3D3D3"
                        : "#F5F5F5",
                      ":active": {
                        backgroundColor: mode ? "#9CA3AF" : "#A9A9A9",
                      },
                    }),
                    multiValue: (base) => ({
                      ...base,
                      backgroundColor: mode ? "#f3f4f6" : "#374151",
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      color: mode ? "#1f2937" : "#f3f4f6",
                    }),
                    multiValueRemove: (base) => ({
                      ...base,
                      color: mode ? "#6b7280" : "#9ca3af",
                      ":hover": {
                        backgroundColor: mode ? "#e5e7eb" : "#4b5563",
                        color: mode ? "#1f2937" : "#f3f4f6",
                      },
                    }),
                  }}
                />
              </div>
            ) : null}
            {
              <>
                {selectedUserType !== "owner" && (
                  <div>
                    <span className="font-bold block mt-1 text-gray-900 dark:text-gray-300">
                      Locations
                    </span>
                    <ReactSelect
                      value={selectedLocation}
                      isMulti
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        spacing: {
                          ...theme.spacing,
                          controlHeight: 40,
                        },
                        colors: {
                          ...theme.colors,
                          ...(mode ? lightThemeColors : darkThemeColors),
                        },
                      })}
                      onChange={(selected) => setSelectedLocation(selected)}
                      options={filteredLocations().map((location) => {
                        return {
                          value: location.id,
                          label: location.name,
                        };
                      })}
                      isDisabled={selectedUserType === "owner"}
                      styles={{
                        menu: (base) => ({
                          ...base,
                          backgroundColor: mode ? "#ffffff" : "#F5F5F5",
                        }),
                        option: (base, state) => ({
                          ...base,
                          backgroundColor: mode
                            ? state.isFocused
                              ? "#D3D3D3"
                              : "#ffffff"
                            : state.isFocused
                            ? "#D3D3D3"
                            : "#F5F5F5",
                          ":active": {
                            backgroundColor: mode ? "#9CA3AF" : "#A9A9A9",
                          },
                        }),
                        multiValue: (base) => ({
                          ...base,
                          backgroundColor: mode ? "#f3f4f6" : "#374151",
                        }),
                        multiValueLabel: (base) => ({
                          ...base,
                          color: mode ? "#1f2937" : "#f3f4f6",
                        }),
                        multiValueRemove: (base) => ({
                          ...base,
                          color: mode ? "#6b7280" : "#9ca3af",
                          ":hover": {
                            backgroundColor: mode ? "#e5e7eb" : "#4b5563",
                            color: mode ? "#1f2937" : "#f3f4f6",
                          },
                        }),
                      }}
                    />
                  </div>
                )}
              </>
            }
            <Input
              className="my-1"
              name="name"
              label={t("business-users.first-name")}
              type="text"
              value={firstName}
              error={
                validationErrors && validationErrors.first_name
                  ? validationErrors.first_name[0]
                  : null
              }
              onChange={(e) => setFirstName(e.target.value)}
              darmo={mode}
            />
            <Input
              className="my-1"
              name="name"
              label={t("business-users.last-name")}
              type="text"
              value={lastName}
              error={
                validationErrors && validationErrors.last_name
                  ? validationErrors.last_name[0]
                  : null
              }
              onChange={(e) => setLastName(e.target.value)}
              darmo={mode}
            />
            {!allowedUserTypeSelection || selectedUserType === "employee" ? (
              <Input
                className="my-1"
                name="position"
                label={t("business-users.position")}
                type="text"
                value={userPosition}
                error={
                  validationErrors && validationErrors.position_name
                    ? validationErrors.position_name[0]
                    : null
                }
                onChange={(e) => setUserPosition(e.target.value)}
                darmo={mode}
              />
            ) : null}
            <Input
              className="my-1"
              name="email"
              label={t("common.email")}
              type="text"
              value={email}
              error={
                validationErrors && validationErrors.email
                  ? validationErrors.email[0]
                  : null
              }
              onChange={(e) => setEmail(e.target.value)}
              darmo={mode}
            />
            <Input
              className="my-1"
              name="phone"
              label={t("common.phone")}
              type="text"
              value={useFormatPhoneNumber(phone)}
              error={
                validationErrors && validationErrors.phone
                  ? validationErrors.phone[0]
                  : null
              }
              onChange={(e) => {
                if (e.target.value.length < 15) {
                  setPhone(e.target.value);
                }
              }}
              disabled={false}
              darmo={mode}
            />
            {!editMode && (
              <Input
                className="my-1"
                name="password"
                label={t("common.password")}
                type="password"
                value={password}
                error={
                  validationErrors && validationErrors.password
                    ? validationErrors.password
                    : null
                }
                onChange={(e) => {
                  setValidationErrors({ ...validationErrors, password: null });
                  setPassword(e.target.value);
                }}
                darmo={mode}
              />
            )}
            {!editMode && (
              <Input
                className="my-1"
                name="confirmPassword"
                label={"Confirm " + t("common.password")}
                type="password"
                value={confirmPassword}
                icon="far fa-lock"
                error={
                  validationErrors && validationErrors.confirmPassword
                    ? validationErrors.confirmPassword
                    : null
                }
                onChange={(e) => {
                  setValidationErrors({
                    ...validationErrors,
                    confirmPassword: null,
                  });
                  setConfirmPassword(e.target.value);
                }}
                darmo={mode}
              />
            )}
          </div>

          <div className="flex w-full md:w-1/5 mt-6">
            <button
              className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 dark:bg-darkGray px-12 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
              onClick={(e) => {
                e.preventDefault();
                if (editMode) {
                  updateUser();
                } else {
                  addUser();
                }
              }}
              disabled={loading}
            >
              {editMode ? t("business-users.update-user") : t("common.add")}
              {loading && <i className="fad fa-circle-notch fa-spin ml-2" />}
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddUserModal;
