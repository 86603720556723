import React, { useState } from "react";
import { NylasCancelBookingForm, NylasCancelledEventCard } from "@nylas/react";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";

const NylasCancelBooking = ({
  startTime,
  endTime,
  configSettings,
  eventInfo,
  onBack,
  onConfirmClose,
  onCancelSubmit,
  onGoBack,
  configId,
  bookingId,
  organizer,
}) => {
  const [showCancelledCard, setShowCancelledCard] = useState(false);
  const parsedOrganizer = JSON.parse(organizer);

  const handleCancelSubmit = async (event) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NYLAS_API_URL}/scheduling/bookings/${bookingId}?configuration_id=${configId}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cancellation_reason: event.detail.reason,
          }),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(
          errorData.error?.message || "Failed to cancel booking in Nylas"
        );
        return;
      }
      onCancelSubmit("set", event, configId);
      setShowCancelledCard(true);
    } catch (error) {
      console.error("Error canceling booking:", error);
      toast.error("An error occurred while canceling the booking");
    }
  };

  if (showCancelledCard) {
    return (
      <div className="w-full flex flex-col">
        <div className="flex justify-between items-center p-4 border-b dark:border-gray-700">
          <h2 className="text-lg font-semibold dark:text-gray-300">
            Interview Cancelled
          </h2>
        </div>
        <div className="w-full p-4">
          <NylasCancelledEventCard
            cancelledEventInfo={{
              booking_id: bookingId,
              organizer: {
                email: parsedOrganizer?.email,
                name: parsedOrganizer?.name,
                is_organizer: true,
              },
              title: eventInfo?.title,
              description: eventInfo?.description,
              status: "cancelled",
            }}
            onCloseCancelEventCardClicked={(event) => {
              onConfirmClose?.(event);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col">
      <div className="flex justify-between items-center p-4 border-b dark:border-gray-700">
        <h2 className="text-lg font-semibold dark:text-gray-300">
          Cancel Interview
        </h2>
        <div className="flex items-center gap-2">
          <button
            onClick={onBack}
            className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="w-full p-4">
        <NylasCancelBookingForm
          selectedTimeslot={{
            start_time: dayjs.unix(startTime).toDate(),
            end_time: dayjs.unix(endTime).toDate(),
          }}
          cancelBookingId={bookingId}
          configSettings={configSettings}
          eventInfo={eventInfo}
          onCancelBookingFormSubmitted={(event) => {
            handleCancelSubmit(event);
          }}
          onGoBackButtonClicked={(event) => {
            onGoBack?.(event);
          }}
        />
      </div>
    </div>
  );
};

export default NylasCancelBooking;
