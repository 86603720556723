import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PredefinedJob from "../../api/PredefinedJob";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { DashboardLayout } from "../../components/organisms/Layout";
import { addIcon } from "../../assets/images";
import Table from "../../components/atoms/Tables/Table";
import IconSelector from "../../components/atoms/Selector/IconSelector";

const PreDefinedJobs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "HeyHire Portal-Position Templates";
    loadPredefinedJobs();
  }, []);

  const loadPredefinedJobs = () => {
    setLoading(true);
    PredefinedJob.getAll()
      .then((response) => setJobs(response.data.data))
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .finally(() => setLoading(false));
  };

  const deleteJob = (id) => {
    PredefinedJob.delete(id)
      .then(() => {
        const dataCopy = jobs.slice();
        dataCopy.splice(
          jobs.findIndex((item) => item?.id === id),
          1
        );
        setJobs(dataCopy);
        toast.success(t("Job Deleted Successfully"));
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      });
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="overflow-x-auto h-full mt-3 pb-12">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Table
              mainHeader={t("pre-defined-jobs.pre-defined-jobs")}
              headers={
                !loading && jobs?.length > 0
                  ? [
                      t("pre-defined-jobs.position"),
                      t("pre-defined-jobs.type"),
                      t("common.category"),
                      t("common.status"),
                      t("common.action"),
                    ]
                  : ["No data available"]
              }
              data={
                !loading &&
                jobs?.map((job, index) => (
                  <tr key={index}>
                    <td
                      className="whitespace-nowrap py-3 pr-3 text-sm md:text-base pl-1"
                      onClick={() => navigate(`edit/${job.id}`)}
                    >
                      <div className="text-gray-900">{job.title}</div>
                    </td>

                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">{job.availability}</div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">
                        {job?.category?.name || "-"}
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-sm font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        Active
                      </span>
                    </td>

                    <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                      <IconSelector
                        menus={[
                          {
                            label: "Edit",
                            action: () =>
                              navigate(`/predefined-jobs/edit/${job.id}`),
                          },
                          {
                            label: "Delete",
                            action: () => deleteJob(job?.id),
                          },
                          { label: "Resend Welcome Email", action: () => {} },
                        ]}
                      />
                    </td>
                  </tr>
                ))
              }
              buttonData={
                <button
                  onClick={() => navigate("/predefined-jobs/add")}
                  className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 px-6 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                >
                  <div className="flex">
                    <img
                      src={addIcon}
                      alt="logo"
                      className="mr-2 my-auto w-4 h-4"
                    />
                    Add
                  </div>
                </button>
              }
            />
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default PreDefinedJobs;
