import React, { useState } from "react";
import FloatingInputText from "../../form/FloatingInputText";

const FormPasswordInput = ({ handleChange, mode }) => {
  const [password, setPassword] = useState("");
  // const [showStrength, setShowStrength] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    handleChange(event.target.value);
    // setShowStrength(true);
  };

  const passwordStrength = password.length >= 8 ? "strong" : "weak";
  const barStyle = {
    width: `${password.length >= 8 ? 100 : password.length * 12.5}%`,
    height: "8px",
    backgroundColor: passwordStrength === "strong" ? "#009B77" : "#bf360c",
  };

  return (
    <div>
      <FloatingInputText
        type="password"
        id="password"
        label="Password"
        placeholder="Enter Password"
        className="my-3"
        name="password"
        value={password}
        onChange={handlePasswordChange}
      />
      <div
        style={{ marginTop: "10px" }}
        className={`${password ? "" : "hidden"}`}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className={`${mode ? "text-gray-900" : "text-gray-400"}`}
        >
          <span className="font-semibold">Password Strength:</span>
          <span className="font-semibold">{passwordStrength}</span>
        </div>
        <div
          className="mt-2 h-[12px] bg-gray-500 rounded-lg"
          style={barStyle}
        ></div>
      </div>
    </div>
  );
};

export default FormPasswordInput;
