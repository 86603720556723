import Hashids from "hashids";
const hashids = new Hashids("", 10);

export const encodeIds = (id) => {
  return hashids.encode(id);
};

export const decodeIds = (id) => {
  return hashids.decode(id);
};

export const SearchArray = (array, key, value) => {
  return array.filter((r) => r[key].includes(value));
};
export const getFormattedData = (array, key) => {
  const gh = array.filter((fr) => fr.types.includes(key));
  return gh.length > 0 ? gh[0] : { long_name: "" };
};

export const formatPhone = (str) => {
  if (!str) return;
  let cleaned = str.replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d+)$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return str;
};

export const generatePassword = () => {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const distance = (lat1, lon1, lat2, lon2) => {
  var R = 3958.8; // Radius of the Earth in miles
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var rlat1 = lat1 * (Math.PI / 180); // Convert degrees to radians
    var rlat2 = lat2 * (Math.PI / 180); // Convert degrees to radians
    var difflat = rlat2 - rlat1; // Radian difference (latitudes)
    var difflon = (lon2 - lon1) * (Math.PI / 180); // Radian difference (longitudes)

    var d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) *
              Math.cos(rlat2) *
              Math.sin(difflon / 2) *
              Math.sin(difflon / 2)
        )
      );
    return d.toFixed(1);
  }
};

export const getPlatform = () => {
  const isAndroid = navigator.userAgent.match("Android");
  if (isAndroid) {
    return "android";
    // setPlatform("android");
  } else {
    return "ios";
    // setPlatform("ios");
  }
};

export const DateFormat = "MM/DD/YYYY";
export const DateFormatWithTime = "MM/DD/YYYY - hh:mm A";
