import PublicProfile from "./pages/business/PublicProfile/PublicProfile";
import BrandProfile from "./pages/business/PublicProfile/BrandProfile";
import EmbededProfileView from "./pages/business/PublicProfile/EmbededProfileView";
import ManageUsers from "./pages/business/Users";
import Poster from "./pages/Poster";
import CreatePoster from "./pages/Poster/Add";
import QRCode from "./pages/QRcode";
import CreateQRCode from "./pages/QRcode/add";
import OpenPositions from "./pages/OpenPositions";
import Regions from "./pages/Regions";
import Companies from "./pages/Companies";
import Brands from "./pages/Brands";
import PreDefinedJobs from "./pages/PreDefinedJobs";
import AddPredefinedJob from "./pages/AddPredefinedJob";
import PredefinedJobTemplate from "./pages/PredefinedJobTemplate";
import Locations from "./pages/Locations";
import EditBrand from "./pages/Brands/Edit-Brand";
import ArchivedJobs from "./pages/ArchivedJobs";
import EditPublicProfile from "./pages/Brands/Edit-Public-Profile";
import Profile from "./pages/Profile";
import AddCompany from "./pages/AddCompany";
import AddRegion from "./pages/AddRegion";
import AddLocation from "./pages/Locations/AddLocation";
import AddBrand from "./pages/AddBrand";
import Payments from "./pages/Payments/Payments";
import BusinessDashboard from "./pages/business/BusinessDashboard";
import EmbedCode from "./pages/EmbedCode";
import CannedMessages from "./pages/CannedMessages";
import ManageBusinessUsers from "./pages/admin/ManageBusinessUsers";
import AddUserPage from "./pages/admin/AddUserPage";
import EditPasswordPage from "./pages/admin/EditPassword";
import AddUserModal from "./components/modals/AddUserModal";
import AddJobSeeker from "./pages/AddJobSeeker";
import JobSeekers from "./pages/JobSeekers";
import AddPrecannedMessage from "./pages/AddPrecannedMessage";
import GeneralSettings from "./pages/admin/GeneralSettings";
import AddAddress from "./pages/admin/AddAddress";
import AddressBook from "./pages/admin/AddressBook";
import Certifications from "./pages/admin/Certifications";
import AddCertification from "./pages/admin/AddCertification";
import AddJobPosition from "./pages/AddJobPosition";
import JobPositions from "./pages/JobPositions";
import JobSeeker from "./pages/JobSeeker";
import MyAccount from "./pages/Account";
import DraftPositions from "./pages/DraftPositions";
import BusinessShowCase from "./pages/business/PublicProfile/BusinessShowCase";
import Settings from "./pages/admin/Settings/Settings";
import TurnKeyLogin from "./pages/TurnKey/TurnKeyLogin";
import AccountRegistration from "./pages/TurnKey/AccountRegistration";
import AccountConfirmation from "./pages/TurnKey/AccountConfirmation";
import { LoadingScreen } from "./pages/TurnKey/LoadingScreen";
// import PrimarySettings from "./pages/admin/AdminControlPanel/AdminControlPanel";
// import ControlPanel from "./pages/admin/AdminControlPanel/AdminControlPanel";
import AdminControlPanel from "./pages/admin/AdminControlPanel/AdminControlPanel";
import { Support } from "./pages/Support";
import { Notifications } from "./pages/Notifications";
import FileUpload from "./pages/FileUpload";
import Dashboard from "./pages/Dashboard";
import Subscription from "./pages/auth/Subscription";
import AddNewShifts from "./pages/Shifts/AddNewShifts";
import ViewShift from "./pages/Shifts/ViewShift";
import MainShiftsPage from "./pages/Shifts/MainShiftsPage";
import AddEvent from "./pages/Shifts/AddEvent";
import Unauthorized from "./Unauthorized";
import ViewEvent from "./pages/Shifts/ViewEvent";
import NewSubscription from "./pages/auth/Subscription/NewSubscription";
import JobPositionRegister from "./pages/JobPositionRegister";
import JobPositionRegister2 from "./pages/JobPositionRegister2";
import Intergrations from "./pages/Intergrations";
import Shifts7 from "./pages/Intergrations/Shifts7/Shifts7";
import BusinessAnalyticDashboard from "./pages/business/BusinessAnalyticDashboard";
import ViewApplicantsList from "./pages/business/BusinessAnalyticDashboard/ViewApplicantsList";
import ViewPositions from "./pages/business/ViewJob/ViewPositions";
import SchedulePage from "./pages/business/Scheduler/SchedulePage";
import ScheduleEditorPage from "./pages/business/Scheduler/ScheduleEditorPage";
import Interviews from "./pages/business/Scheduler/Interviews";

const RoutesList = [
  // admin routes
  {
    name: "Admin-Control-Panel",
    path: "/control-panel",
    element: <AdminControlPanel />,
    auth: true,
  },
  {
    name: "Manage Business Users",
    path: "/admin/manage-business-users",
    element: <ManageBusinessUsers />,
    auth: true,
  },
  {
    name: "Add Business Users",
    path: "/admin/manage-business-users/add",
    element: <AddUserPage />,
    auth: true,
  },
  {
    name: "Edit Business User",
    path: "/admin/manage-business-users/edit/:userId",
    element: <AddUserPage />,
    auth: true,
  },
  {
    name: "Edit Business User",
    path: "/admin/manage-business-users/password-edit/:userId",
    element: <EditPasswordPage />,
    auth: true,
  },
  {
    name: "General Settings",
    path: "/admin/general-settings",
    element: <GeneralSettings />,
    auth: true,
  },
  {
    name: "Address Book",
    path: "/admin/address-book",
    element: <AddressBook />,
    auth: true,
  },
  {
    name: "Add Address",
    path: "/admin/address-book/add",
    element: <AddAddress />,
    auth: true,
  },
  {
    name: "Edit Address",
    path: "/admin/address-book/:addressId/edit",
    element: <AddAddress />,
    auth: true,
  },
  {
    name: "Certification",
    path: "/admin/certifications",
    element: <Certifications />,
    auth: true,
  },
  {
    name: "Add Certification",
    path: "/admin/certifications/add",
    element: <AddCertification />,
    auth: true,
  },

  // Analytics Dashboard
  {
    name: "Analytics Dashboard",
    path: "/analytics",
    element: <BusinessAnalyticDashboard />,
    auth: true,
  },
  {
    name: "Job Applicants",
    path: "/analytics/applicants",
    element: <ViewApplicantsList />,
    auth: true,
  },

  // job position
  {
    name: "Job Positions",
    path: "/job-positions",
    element: <JobPositions />,
    auth: true,
  },
  {
    name: "Draft Positions",
    path: "/job-positions/draft",
    element: <DraftPositions />,
    auth: true,
  },
  {
    name: "Edit Draft Position",
    path: "/job-positions/draft/edit/:draftId",
    element: <AddJobPosition />,
    auth: true,
  },
  {
    name: "Post Position",
    path: "/job-positions/add",
    element: <AddJobPosition />,
    auth: true,
  },
  {
    name: "Edit Position",
    path: "/job-positions/edit/:jobId",
    element: <AddJobPosition />,
    auth: true,
  },

  // job seeker (rbac not changed)
  {
    name: "Manage Job Seekers",
    path: "/job-seekers",
    element: <JobSeekers />,
    auth: true,
  },
  {
    name: "View Job Seeker",
    path: "/job-seekers/view",
    element: <JobSeeker />,
    auth: true,
  },
  {
    name: "Add Job Seeker",
    path: "/job-seekers/add",
    element: <AddJobSeeker />,
    auth: true,
  },
  {
    name: "Edit Job Seeker",
    path: "/job-seekers/edit/:id",
    element: <AddJobSeeker />,
    auth: true,
  },

  // moving old routes
  {
    name: "Positions",
    path: "/business/view-job/:id",
    element: <ViewPositions />,
    auth: true,
  },
  {
    name: "Business Applications",
    path: "/business/applications",
    element: <Payments />,
    auth: true,
  },
  {
    name: "Payments",
    path: "/business/payments",
    element: <Payments />,
    auth: true,
  },
  {
    name: "Payments",
    path: "/business/billing",
    element: <Payments />,
    auth: true,
  },
  {
    name: "My Company",
    path: "/business/my-company",
    element: <Payments />,
    auth: true,
  },
  {
    name: "Business Dashboard",
    path: "/business/my-company",
    element: <BusinessDashboard />,
    auth: true,
  },
  {
    name: "Positions",
    path: "/business/applicant/:position",
    element: <ViewPositions />,
    auth: true,
  },
  {
    name: "Positions",
    path: "/business/applicant/:position/:id",
    element: <ViewPositions />,
    auth: true,
  },
  {
    name: "Positions",
    path: "/business/hired-applicant/:position/:id",
    element: <ViewPositions />,
    auth: true,
  },
  {
    name: "Canned Messages",
    path: "/business/messages/canned-messages",
    element: <CannedMessages />,
    auth: true,
  },
  {
    name: "Canned Messages",
    path: "/business/messages/canned-messages/:id/edit",
    element: <AddPrecannedMessage />,
    auth: true,
  },
  {
    name: "Embedded Code",
    path: "/business/public-profile/embed-code",
    element: <EmbedCode />,
    auth: true,
  },
  {
    name: "Settings",
    path: "/business/settings",
    element: <Payments />,
    auth: true,
  },
  {
    name: "Dashboard",
    path: "/business/dashboard",
    element: <BusinessDashboard />,
    auth: true,
  },
  {
    name: "Admin Dashboard",
    path: "/admin/dashboard",
    element: <Dashboard />,
    auth: true,
  },
  {
    name: "My Account",
    path: "/business/my-account",
    element: <MyAccount />,
    auth: true,
  },
  {
    name: "Support",
    path: "/support",
    element: <Support />,
    auth: true,
  },

  {
    name: "Canned Messages",
    path: "/messages/canned-messages",
    element: <CannedMessages />,
    auth: true,
  },
  {
    name: "Edit Messages",
    path: "/messages/canned-messages/:id/edit",
    element: <AddPrecannedMessage />,
    auth: true,
  },
  {
    name: "Add Message",
    path: "/messages/canned-messages/add",
    element: <AddPrecannedMessage />,
    auth: true,
  },
  { name: "Payments", path: "/payments", element: <Payments />, auth: true },
  {
    name: "Add Job Seekers",
    path: "/job-seekers/add",
    element: <AddJobSeeker />,
    auth: true,
  },
  {
    name: "Job Seekers",
    path: "/job-seekers",
    element: <JobSeekers />,
    auth: true,
  },

  //Account Notification
  {
    name: "Account Notifications",
    path: "/notifications",
    element: <Notifications />,
    auth: true,
  },

  // companies
  {
    name: "Positions",
    path: "/open-positions",
    element: <OpenPositions />,
    auth: false,
  },
  { name: "Companies", path: "/companies", element: <Companies />, auth: true },
  {
    name: "Add Company",
    path: "/companies/add",
    element: <AddCompany />,
    auth: true,
  },
  {
    name: "Regions",
    path: "/companies/regions",
    element: <Regions />,
    auth: true,
  },
  {
    name: "Add Region",
    path: "/companies/regions/add",
    element: <AddRegion />,
    auth: true,
  },
  {
    name: "Brands",
    path: "/companies/brands",
    element: <Brands />,
    auth: true,
  },
  // { name:"Brands", path: "/companies/brand", element:<Brands />, auth: true},
  {
    name: "Add Brand",
    path: "/companies/brands/add",
    element: <AddBrand />,
    auth: true,
  },
  {
    name: "Edit Brand",
    path: "/companies/brands/:id/edit",
    element: <EditBrand />,
    auth: true,
  },
  {
    name: "Edit Brand",
    path: "/companies/brands/:brandId",
    element: <EditBrand />,
    auth: true,
  },
  {
    name: "Locations",
    path: "/companies/locations",
    element: <Locations />,
    auth: true,
  },
  {
    name: "Add Location",
    path: "/companies/locations/add",
    element: <AddLocation />,
    auth: true,
  },
  {
    name: "Edit Location",
    path: "/companies/locations/:id/edit",
    element: <AddLocation />,
    auth: true,
  },
  {
    name: "Predefined Jobs",
    path: "/predefined-jobs",
    element: <PreDefinedJobs />,
    auth: true,
  },
  {
    name: "Add Predefined",
    path: "/predefined-jobs/add",
    element: <AddPredefinedJob />,
    auth: true,
  },
  {
    name: "Edit Predefined",
    path: "/predefined-jobs/edit/:jobId",
    element: <PredefinedJobTemplate />,
    auth: true,
  },
  // business
  {
    name: "Brand Profile",
    path: "/business/public-profile/brand-profile",
    element: <BrandProfile />,
    auth: false,
  },
  {
    name: "posiMy Profile",
    path: "/business/public-profile/my-profile",
    element: <BrandProfile />,
    auth: false,
  },
  {
    name: "Ecode",
    path: "/business/public-profile/view-embed-code",
    element: <EmbededProfileView />,
    auth: false,
  },
  {
    name: "Edit Business Profile",
    path: "/business/public-profile/edit",
    element: <EditPublicProfile />,
    auth: true,
  },
  {
    name: "QR Code",
    path: "/business/qr-code",
    element: <QRCode />,
    auth: true,
  },
  {
    name: "Create QR Code",
    path: "/business/qr-code/add",
    element: <CreateQRCode />,
    auth: true,
  },
  {
    name: "Edit QR Code",
    path: "/business/qr-code/:id/edit",
    element: <CreateQRCode />,
    auth: true,
  },
  {
    name: "QR Banners",
    path: "/business/posters",
    element: <Poster />,
    auth: true,
  },
  {
    name: "Intergrations",
    path: "/intergrations",
    element: <Intergrations />,
    auth: true,
  },
  {
    name: "7Shifts",
    path: "/intergrations/7Shifts",
    element: <Shifts7 />,
    auth: true,
  },
  {
    name: "Create Banner",
    path: "/business/posters/add",
    element: <CreatePoster />,
    auth: true,
  },
  {
    name: "Edit Banner",
    path: "/business/posters/:id/edit",
    element: <CreatePoster />,
    auth: true,
  },
  {
    name: "Manage Users",
    path: "/business/manage-users",
    element: <ManageUsers />,
    auth: true,
  },
  {
    name: "Manage Regional Managers",
    path: "/business/manage-regional-managers",
    element: <ManageUsers type={"Managers"} />,
    auth: true,
  },
  {
    name: "Add Sub User",
    path: "/business/manage-users/add",
    element: <AddUserModal />,
    auth: true,
  },
  {
    name: "Edit Sub User",
    path: "/business/manage-users/edit/:userId",
    element: <AddUserModal />,
    auth: true,
  },
  {
    name: "Manage Sub Users",
    path: "/business/sub-users",
    element: <ManageUsers type={"Sub Users"} />,
    auth: true,
  },
  {
    name: "Add Sub User",
    path: "/business/sub-users/add",
    element: <AddUserModal />,
    auth: true,
  },
  {
    name: "Edit Sub User",
    path: "/business/sub-users/edit/:userId",
    element: <AddUserModal />,
    auth: true,
  },
  {
    name: "Schedule Editor",
    path: "/scheduler-editor",
    element: <ScheduleEditorPage />,
    auth: true,
  },
  {
    name: "Scheduled Interviews",
    path: "/interviews",
    element: <Interviews />,
    auth: true,
  },
  {
    name: "Preview",
    path: "/preview?",
    element: <SchedulePage />,
    auth: true,
  },
  {
    name: "Cancel Interview",
    path: "/cancel/:bookingRef",
    element: <SchedulePage />,
    auth: true,
  },
  {
    name: "Reschedule Interview",
    path: "/reschedule/:bookingRef",
    element: <SchedulePage />,
    auth: true,
  },

  // public profiles
  {
    name: "Brand",
    path: "/public-profile/brand/:brand",
    element: <PublicProfile />,
    auth: false,
  },
  {
    name: "Archived Jobs",
    path: "/archived-jobs",
    element: <ArchivedJobs />,
    auth: true,
  },
  {
    name: "Profile",
    path: "/profile",
    element: <Profile />,
    auth: true,
  },

  {
    name: "Business Showcase Public Profile",
    path: "/heyhire/:city?",
    element: <BusinessShowCase />,
    auth: false,
  },
  {
    name: "File Upload",
    path: "/file-upload/:userId?",
    element: <FileUpload />,
    auth: false,
  },
  {
    name: "Subscription",
    path: "/subscribe?",
    element: <Subscription />,
    auth: true,
  },
  {
    name: "Select Plan",
    path: "/subscription",
    element: <NewSubscription />,
    auth: true,
  },
  {
    name: "Add Sub User",
    path: "/add-sub-user",
    element: <JobPositionRegister />,
    auth: true,
  },
  {
    name: "Add Job",
    path: "/add-job",
    element: <JobPositionRegister2 />,
    auth: true,
  },

  // shifts
  {
    name: "Shifts",
    path: "/shifts",
    element: <MainShiftsPage />,
    auth: true,
  },
  {
    name: "Post Shift",
    path: "/shifts/add",
    element: <AddNewShifts />,
    auth: true,
  },
  {
    name: "Post Shift",
    path: "/shifts/event/add",
    element: <AddEvent />,
    auth: true,
  },
  {
    name: "Open Shift",
    // path: "/business/open-shift/:id",
    path: "-now-",
    element: <ViewShift />,
    auth: true,
  },
  {
    name: "Event Profile",
    // path: "/business/event/:id",
    path: "/event",
    element: <ViewEvent />,
    auth: true,
  },

  //turnkey routes
  { name: "settings", path: "/settings", element: <Settings />, auth: true },
  {
    name: "TurnKey",
    path: "/easy-login",
    element: <TurnKeyLogin />,
  },
  {
    name: "TurnKey",
    path: "/easy-login/:activationCode",
    element: <TurnKeyLogin />,
  },
  {
    name: "TurnKey",
    path: "/easy-registration",
    element: <AccountRegistration />,
  },
  {
    name: "TurnKey",
    path: "/easy-confirmation",
    element: <AccountConfirmation />,
  },
  {
    name: "TurnKey",
    path: "/loading-profile",
    element: <LoadingScreen />,
  },
  {
    name: "test",
    path: "/test-me",
    element: <TurnKeyLogin />,
  },
  {
    name: "403",
    path: "/403",
    element: <Unauthorized />,
  },
];

export default RoutesList;
