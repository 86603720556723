import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import { toast } from "react-hot-toast";
// import api from "../../api/api.js";
import { darkmode } from "../../store/authSlice";
// import Input from "../../components/form/Input";
import AuthLayout from "../../components/organisms/Layout/Authlayout";
import Button from "../../components/form/Button.js";
// import FormActivationCode from "../../components/atoms/FormActivationCode/FormActivationCode.js";
import { useParams } from "react-router-dom";
import TurnKeyBusinesses from "../../api/TurnKeyBusinesses.js";
// import FormWarning from "../../components/atoms/Notification/FormWarning.js";
import "./TurnKey.scss";
import PinCodeField from "../../components/form/PinCodeField.js";
import FloatingInputText from "../../components/form/FloatingInputText.js";
import { TurnKeyContextProvider } from "./TurnKeyContext.js";
// import AccountRegistration from "./AccountRegistration.js";
import FormWarningWithButton from "../../components/atoms/Notification/FormWarningWithButton.js";

//const pinCodeArray = [];

const TurnKeyLogin = () => {
  // const { t } = useTranslation();
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const pinCodeArrayRef = useRef([]);
  const mode = useSelector(darkmode);
  const [email, setEmail] = useState("");
  const [activationCode, setActivationCode] = useState("");
  const [loading, setLoading] = useState(false);
  // const [validationErrors, setValidationErrors] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [warningStatus, setWarningStatus] = useState(false);
  const [activationCodeWarningStatus, setActivationCodeWarningStatus] =
    useState(false);
  const [isActivationCodeInactive, setIsActivationCodeInactive] =
    useState(false);
  const [isAccountActivated, setIsAccountActivated] = useState(true);
  const [data, setData] = useState([]);
  const params = useParams();

  useEffect(() => {
    document.title = "HeyHire Portal-Easy-Login";

    if (params.activationCode) {
      const activationCodeFromURL = getNumberFromString(params.activationCode);
      isTurnKeyAccountActivated(activationCodeFromURL);
      setActivationCode(activationCodeFromURL);
    } else {
      setIsAccountActivated(false);
    }

    return () => {
      setEmail("");
      setLoading(false);
      // setValidationErrors(null);
      setErrorMessage("");
    };
  }, []);

  const getNumberFromString = (paramCode) => {
    const regex = /(\d+)/;
    const match = paramCode.match(regex);
    return match ? match[0] : null;
  };

  const isTurnKeyAccountActivated = (activationCode) => {
    TurnKeyBusinesses.turnkeyAccountStatus(activationCode)
      .then((response) => {
        if (
          response.data.is_activated === 1 &&
          response.data.business_url !== ""
        ) {
          window.location.href =
            "https://heyhire.app/" + response.data.business_url;
        } else {
          setIsAccountActivated(false);
        }
      })
      .catch((error) => {
        console.log("error");
        setIsActivationCodeInactive(true);
        setIsAccountActivated(false);
      });
  };

  const handleActivate = (e) => {
    e.preventDefault();

    if (!isValidActivationCodeEntry()) {
      setActivationCodeWarningStatus(true);
    } else {
      if (typeof email !== "undefined") {
        var pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );

        if (!pattern.test(email)) {
          setWarningStatus(true);
        } else {
          setWarningStatus(false);
          setLoading(true);
          // setValidationErrors(null);
          setErrorMessage("");

          TurnKeyBusinesses.updateEmail(data)
            .then((response) => {
              setIsActivationCodeInactive(false);
              navigate("/easy-registration", {
                state: {
                  turnKeyData: response.data.data,
                },
              });

              // setActivationCode(response.data.data.activation_code);
            })
            .catch((error) => {
              setIsActivationCodeInactive(true);
              setLoading(false);

              if (error.response && error.response.status === 422) {
                // Validation error
              } else {
              }
            });
        }
      } else {
        setWarningStatus(true);
      }
    }
  };

  const onChangePinHandler = (event) => {
    const target = event.target;
    const value = target.value;
    pinCodeArrayRef.current[target.id] = value;

    const code = getActivationCode();

    setData((prevState) => ({
      ...prevState,
      activationCode: code,
    }));
  };

  const isValidActivationCodeEntry = () => {
    const code = getActivationCode();

    if (code === "" || code.length < 6) {
      return false;
    }
    return true;
  };

  const getActivationCode = () => {
    let code = "";

    if (!params.activationCode) {
      code = pinCodeArrayRef.current.join("");
    } else {
      const activationCodeFromURL = getNumberFromString(params.activationCode);
      code = activationCodeFromURL;
    }

    code = code.replace(/\s/g, "").trim();

    return code;
  };

  const emailFieldChangeHandler = (value) => {
    if (!isValidActivationCodeEntry()) {
      setActivationCodeWarningStatus(true);
    } else {
      setActivationCodeWarningStatus(false);
      setWarningStatus(false);
      setEmail(value);

      const code = getActivationCode();

      setData((prevState) => ({
        ...prevState,
        activationCode: code,
      }));

      setData((prevState) => ({
        ...prevState,
        email: value,
      }));
    }
  };

  return (
    <AuthLayout showLink title="Welcome to HeyHire!">
      <div className="min-h-full flex flex-1 flex-col justify-center px-4 sm:px-6 lg:px-8">
        {!isAccountActivated ? (
          <TurnKeyContextProvider>
            <div className="flex flex-col">
              <div className="flex flex-row w-full justify-center text-center font-bold align-middle pb-10 text-gray-600 animate-fade-in-from-top-.5s">
                <div className="flex">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#088F8F"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="animate-bounce"
                    >
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                      <polyline points="22 4 12 14.01 9 11.01"></polyline>
                    </svg>
                  </div>
                  <span className="transition-all animate-fade-in-from-top-.5s text-gray-500 ml-1">
                    Your account is set up and your positions have been added.
                    Start hiring today!
                  </span>
                </div>
              </div>
              <div
                className={`text-center justify-center pb-4 font-bold ${
                  mode ? "text-gray-600" : "text-white"
                } transition-all animate-fade-in-from-top-.5s`}
              >
                Start hiring today!
              </div>
            </div>
            <div className="sm:mx-auto sm:w-full sm:max-w-[480px] w-full">
              <div className="flex flex-col w-full pb-10 items-center">
                <div>
                  <label
                    class="block uppercase text-center lg:justify-center tracking-wide text-primary-500 text-xs font-bold animate-fade-in-from-top-1s"
                    for="grid-first-name"
                  >
                    {activationCode
                      ? "Your 6 digit activation code"
                      : "Enter your 6 digit activation code"}
                  </label>
                </div>
                <div className="responsive-block sm:ml-0 md:ml-0 lg:ml-0 scale-x-75 md:scale-75 lg:scale-100">
                  <PinCodeField
                    onChange={onChangePinHandler}
                    codeLength={6}
                    values={activationCode}
                  />
                </div>
              </div>
              <div class="relative w-full animate-fade-in-from-top-1s">
                <FloatingInputText
                  type="text"
                  placeholder="Enter Email"
                  className="my-3 font-bold text-gray-500"
                  onChange={(e) => emailFieldChangeHandler(e.target.value)}
                  icon="email"
                />
              </div>
              <FormWarningWithButton
                status={warningStatus}
                title="Invalid"
                content="Please enter a valid email"
              />
              <FormWarningWithButton
                status={activationCodeWarningStatus}
                title="Warning"
                content="Activation Code Needed"
              />
              <FormWarningWithButton
                status={isActivationCodeInactive}
                title="Invalid Activation Code"
                content="Please try again"
              />
              <div class="p-1 mb-4 text-sm dark:text-red-400" role="alert">
                <span class="font-medium">{errorMessage}</span>
              </div>
              <div className="mt-[36px] lg:mb-[81px]">
                <Button
                  label="Activate Account"
                  loading={loading}
                  onClick={handleActivate}
                />
              </div>
            </div>
          </TurnKeyContextProvider>
        ) : (
          <div className="flex justify-center pt-10">
            <span class="inline-block animate-pulse rounded-full p-2 font-bold text-black text-sm">
              Fetching business . . .
            </span>
          </div>
        )}
      </div>
    </AuthLayout>
  );
};

export default TurnKeyLogin;
