// import { t } from "i18next";
import React, { useEffect, useState } from "react";
// import { Link, NavLink, useNavigate } from "react-router-dom";
// import Selector from "../../../../components/atoms/Selector/Selector";
import { useTranslation } from "react-i18next";
// import { toast } from "react-hot-toast";
import Input from "../../../../components/form/Input";
// import Company from "../../../../api/Company";
// import BusinessDropDown from "../../../../components/atoms/BusinessDropDown/BusinessDropDown";
import MultiSelector from "../../../../components/atoms/Selector/MultiSelector";
// import Region from "../../../../api/Region";
import { useSettingsContext } from "../SettingsContext";

const RegionDetails = () => {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const [regionName, setRegionName] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [regionAdded, setRegionAdded] = useState(false);
  const [successAlert, setSuccessAlert] = useState("hidden");
  // const [isEnterprise, setIsEnterprise] = useState(false);
  // const [locations, setLocations] = useState([]);
  // const [selectedLocation, setSelectedLocation] = useState([]);
  const { companyName, setCompanyName } = useSettingsContext();
  const sampleref = React.createRef();

  useEffect(() => {
    sampleref.current.scrollIntoView();

    const timeId = setTimeout(() => {
      setSuccessAlert("hidden");
    }, 5000);
  }, [successAlert]);

  const addEditRegion = () => {
    //  setLoading(true);
    //  let body = {
    //    company_id: company.companyId,
    //    name: regionName,
    //  };
    //  Region.create(body)
    //    .then(() => {
    //     setSuccessAlert("");
    //     // toast.success(t("regions.region-added"));
    //      setRegionAdded(!regionAdded);
    //    })
    //    .catch((error) => {
    //      if (error.response && error.response.status === 422) {
    //        // Validation error
    //        setValidationErrors(error.response.data.errors);
    //      } else {
    //        toast.error(t("common.something-went-wrong-please-try-again"));
    //      }
    //    })
    //    .then(() => setLoading(false));
  };

  return (
    <div>
      <div className="pb-16" ref={sampleref}>
        <h2 className={"text-2xl font-bold"}>{t("settings.attach-regions")}</h2>
        <span className="font-bold text-indigo-500">for {companyName}</span>
        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
        <MultiSelector refresh={regionAdded} />
      </div>
      <div className="inline-flex items-center justify-center w-full pb-16">
        <hr className="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
        <span className="absolute px-3 font-medium-translate-x-1/2 bg-white left-1/2 dark:text-white ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            {" "}
            <circle cx="12" cy="12" r="10" />
            <path d="M16 12l-4 4-4-4M12 8v7" />
          </svg>{" "}
        </span>
      </div>
      <h2 className={"text-2xl font-bold"}>{t("settings.create-region")}</h2>
      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
      <div class="grid gap-6 mb-6 md:grid-cols-2">
        <Input
          className="my-3"
          name="name"
          label={t("settings.region-name")}
          type="text"
          value={regionName}
          placeholder="Enter Here"
          error={
            validationErrors && validationErrors.name
              ? validationErrors.name[0]
              : null
          }
          onChange={(e) => setRegionName(e.target.value)}
        />
        <br></br>

        <div className="flex w-full md:w-1/2 h-full">
          <button
            className="w-full rounded-md p-2 text-white text-lg bg-primary-700 hover:bg-primary-500 disabled:bg-violet-200"
            onClick={(e) => {
              e.preventDefault();
              addEditRegion();
            }}
            disabled={loading}
          >
            {t("settings.add-region")}
            {loading ? <i className="fad fa-circle-notch fa-spin ml-2" /> : ""}
          </button>
        </div>
      </div>
      <div
        className={` ${successAlert} bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md`}
        role="alert"
      >
        <div className="flex">
          <div className="py-1">
            <svg
              className="fill-current h-6 w-6 text-teal-500 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          </div>
          <div>
            <p className="font-bold">
              Region is added to the dropdown menu above
            </p>
            <p className="text-sm">
              You can create more new regions if required.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegionDetails;
