import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "../../../api/api.js";
import { login, darkmode } from "../../../store/authSlice";
import AuthLayout from "../../../components/organisms/Layout/Authlayout";
import { heyhireBlackIcon, heyhireIcon } from "../../../assets/images.js";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { toast } from "react-hot-toast";

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mode = useSelector(darkmode);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const passwordToggle = !passwordVisible ? (
    <i
      className={`h-full ${
        mode ? "text-gray-400" : "text-white"
      } far fa-eye-slash`}
    />
  ) : (
    <i
      className={`h-full ${mode ? "text-gray-400" : "text-white"} far fa-eye`}
    />
  );

  useEffect(() => {
    document.title = "HeyHire Portal-Login";
    return () => {
      setEmail("");
      setPassword("");
      setLoading(false);
      setValidationErrors(null);
      setErrorMessage("");
    };
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    setValidationErrors(null);
    setErrorMessage("");

    api
      .post("business/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data));
        dispatch(login(response.data));
        navigate("/dashboard");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          if (error.response.data.message === "block") {
            setErrorMessage(t("login.location-blocked"));
          } else {
            if (
              (error.response.data.errors.email &&
                error.response.data.errors.email[0] ===
                  t("login.error-message-check-email")) ||
              (error.response.data.errors.password &&
                error.response.data.errors.password[0] ===
                  t("login.error-message-check-password"))
            ) {
              setErrorMessage(t("login.error-message"));
            } else {
              setValidationErrors(error.response.data.errors);
            }
          }
        } else {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target?.value;
    const name = target?.name;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  return (
    <AuthLayout loginpage title={t("login.sign-in-account")}>
      <div className="flex flex-col min-h-full">
        <div className="flex flex-1 flex-col justify-center sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md rounded p-2 mx-2">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img
                className="mx-auto w-[160px] lg:w-[250px] lg:mt-8"
                src={mode ? heyhireBlackIcon : heyhireIcon}
                alt="logo"
              />
              <h2
                className={`mt-6 text-center text-lg lg:text-2xl font-medium leading-9 ${
                  !mode ? "text-white" : "text-gray-900"
                }`}
              >
                {t("login.sign-in-account")}
              </h2>
            </div>
            <div className="flex justify-center">
              {errorMessage && (
                <p className="text-red-400 text-sm font-bold border border-red-400 px-4 py-2 rounded-md mt-4">
                  {errorMessage}
                </p>
              )}
            </div>

            <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
              <div className="p-6 sm:rounded-lg sm:px-12">
                <form className="space-y-6" action="#" method="POST">
                  <div>
                    <label
                      htmlFor="email"
                      className={`block text-sm font-medium leading-6 ${
                        mode ? "text-gray-900" : "text-white"
                      }`}
                    >
                      {t("common.email")}
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <input
                        className={`block w-full rounded-md border-0 py-1.5 pr-10 ${
                          mode
                            ? "text-gray-900 placeholder:text-gray-400 focus:ring-indigo-600 bg-white"
                            : "text-white placeholder:text-gray-300 focus:ring-white bg-slate-800"
                        }  ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6`}
                        name="email"
                        label={t("common.email")}
                        type="email"
                        value={email}
                        placeholder={t("login.email-input-placeholder")}
                        onChange={handleInputChange}
                      />
                      {validationErrors?.email && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-red-500"
                          />
                        </div>
                      )}
                    </div>
                    {validationErrors?.email && (
                      <p className="mt-2 text-xs text-red-400 font-bold">
                        {validationErrors?.email[0]}
                      </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className={`block text-sm font-medium leading-6 ${
                        mode ? "text-gray-900" : "text-white"
                      }`}
                    >
                      {t("common.password")}
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <input
                        className={`block w-full rounded-md border-0 ${
                          mode
                            ? "text-gray-900  placeholder:text-gray-400 focus:ring-indigo-600 bg-white"
                            : "text-white  placeholder:text-gray-300 focus:ring-white bg-slate-800"
                        } py-1.5 pr-8 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                        name="password"
                        label={t("common.password")}
                        placeholder={t("login.password-input-placeholder")}
                        type={passwordVisible ? "text" : "password"}
                        value={password}
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                      <div
                        className={`absolute top-1/2 right-0 transform -translate-y-1/2 cursor-pointer pr-3 ${
                          mode ? "text-gray-600" : "text-white"
                        }`}
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        {passwordToggle}
                      </div>
                    </div>
                    {validationErrors?.password && (
                      <p className="mt-2 text-xs text-red-400 font-bold">
                        {validationErrors?.password[0]}
                      </p>
                    )}
                  </div>

                  <div className="flex items-center justify-between w-full">
                    <div className="ml-auto text-sm leading-4 flex flex-shrink-0">
                      <Link
                        to="/forgot-password"
                        className={`font-medium ${
                          mode
                            ? "text-indigo-600 hover:text-indigo-500"
                            : "text-secondary hover:text-white"
                        }`}
                      >
                        {t("login.forgot-password")}?
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      label={t("login.login")}
                      loading={loading}
                      onClick={handleLogin}
                      type="submit"
                      className="flex w-full justify-center rounded-full bg-primary-500 dark:bg-darkGray px-6 py-2 text-sm font-bold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                    >
                      {t("login.sign-in")}
                      {loading && (
                        <i className="fad fa-circle-notch fa-spin ml-2 my-auto" />
                      )}
                    </button>
                  </div>
                  <p
                    className={`text-center text-sm ${
                      mode ? "text-black" : "text-white"
                    }`}
                  >
                    {t("login.do-not-have-account")}
                    {"   "}
                    <span
                      onClick={() => {
                        localStorage.removeItem("registerStep0");
                        localStorage.removeItem("registerStep1");
                        localStorage.removeItem("registerStep2");
                        localStorage.removeItem("selectedPlan");
                        navigate("/plans");
                      }}
                      className={`cursor-pointer font-semibold leading-6 ${
                        mode
                          ? "text-indigo-600 hover:text-indigo-500 "
                          : "text-secondary hover:text-white"
                      }`}
                    >
                      {t("login.sign-up")}
                    </span>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
