import usePlacesAutocomplete, {
  getGeocode,
  getZipCode,
  getDetails,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import Input from "./Input";
import { emitCustomEvent } from "react-custom-events";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

const defaultTypes = ["address"];
const PlacesAutocomplete = ({
  darmo,
  label,
  placeholder,
  pValue,
  setAddressCheck,
  error,
  isAddressSearch = false,
  type,
}) => {
  const searchPara = isAddressSearch ? defaultTypes : [];
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: { country: "us" },
      types: [...searchPara],
    },
    debounce: 300,
  });

  useEffect(() => {
    setValue(pValue, false);
  }, [pValue]);

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    if (setAddressCheck) {
      setAddressCheck(e);
    }
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description, terms }) =>
    () => {
      let addressLine = "";

      for (let i = 0; i < terms.length - 3; i++) {
        addressLine += terms[i].value + (i !== terms.length - 4 ? ", " : "");
      }
      setValue(addressLine, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => {
          getZipCode(results[0], false).then(async (result) => {
            const parameter = {
              placeId: results[0].place_id,
              fields: ["formatted_phone_number"],
            };
            const number = await getDetails(parameter);
            emitCustomEvent("placeSelected", {
              description: results[0].formatted_address,
              addressLine: addressLine,
              terms: terms,
              zipCode: result,
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
              business_number: number.formatted_phone_number,
            });
          });
        })
        .catch((error) => {
          if (error?.response?.status !== 403) {
            toast.error("Something went wrong!");
          }
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
        terms,
      } = suggestion;

      return (
        <li
          className="p-4 hover:bg-primary-400 hover:text-white cursor-pointer"
          key={place_id}
          onClick={handleSelect(suggestion, terms)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref}>
      <Input
        value={value}
        onChange={handleInput}
        disabled={!ready}
        label={label}
        placeholder={placeholder}
        error={error}
        darmo={darmo}
        type={type}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && (
        <ul className="z-[999] w-full absolute bg-white border-2 border-primary-300">
          {renderSuggestions()}
        </ul>
      )}
    </div>
  );
};

export default PlacesAutocomplete;
