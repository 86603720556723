import "react-phone-number-input/style.css";
import "./FormPhoneInput.css";
import PhoneInput from "react-phone-number-input";
import React from "react";

const FormPhoneFloatingInput = ({
  id,
  className,
  label,
  onChange,
  type = "text",
  placeholder,
}) => {
  return (
    <div className={className}>
      <label className="block tracking-wide font-semibold text-gray-400 text-md mb-2">
        {label}
      </label>
      <div className="relative flex flex-row shadow-sm hover:border-primary-400 hover:border-1 shadow-gray-500 rounded-md w-full">
        <PhoneInput
          defaultCountry="US"
          country="US"
          national
          countryCallingCodeEditable={false}
          className="p-2.5 px-4 font-bold h-[52px] shadow-gray-500 rounded-md w-full"
          type={type}
          placeholder={placeholder}
          onChange={(value) => onChange(value)}
        />
        <label
          for={`floating_filled ${id}`}
          class="absolute text-md text-gray-100 font-[60px] dark:text-gray-400  duration-300 transform -translate-y-4 scale-75 top-4 focus:top-5 z-10 origin-[0] left-2.5 peer-focus:text-primary-500 peer-focus:dark:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
        >
          {placeholder}
        </label>
      </div>
    </div>
  );
};

export default FormPhoneFloatingInput;
