import React from "react";

const GradientPrimaryButton = ({
  name,
  grayScale = false,
  onClick,
  disabled = false,
}) => {
  return (
    <div>
      {grayScale ? (
        <button
          disabled={true}
          className={` w-[150px] text-sm rounded-lg px-1.5 pt-[10px] pb-[9px]
          text-white font-bold bg-gray-500  hover:text-[15px]
          `}
          onClick={onClick}
        >
          {name}
        </button>
      ) : (
        <button
          disabled={disabled}
          className={` w-[150px] text-sm rounded-lg px-1.5 pt-[10px] pb-[9px]
          text-white font-bold bg-primary-500  hover:text-[15px] hover:bg-primary-700 shadow-md active:shadow-md active:shadow-black
          `}
          onClick={onClick}
        >
          {name}
        </button>
      )}
    </div>
  );
};

export default GradientPrimaryButton;
