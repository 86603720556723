import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { getFormattedData } from "../../../utils";

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};
const Laglng = {
  lat: 26.2752941,
  lng: -80.107158,
};
const mapStyles = {
  position: "relative",
  width: "100%",
  height: "100%",
};

const GoogleMapView = ({ location, zoomlevel = 11, onClickAction }) => {
  const getReverseGeocodingData = async (lat, lng) => {
    const google = window.google;
    var latlng = new google.maps.LatLng(lat, lng);
    var geocoder = new google.maps.Geocoder();
    const respo = await geocoder.geocode({ latLng: latlng });
    const { results } = respo;
    if (results.length > 0) {
      var placeDetails = results[0];
      const cityD = getFormattedData(
        placeDetails["address_components"],
        "locality"
      );
      const State = getFormattedData(
        placeDetails["address_components"],
        "administrative_area_level_1"
      );
      const ZipCode = getFormattedData(
        placeDetails["address_components"],
        "postal_code"
      );
      const address = {
        address: placeDetails.formatted_address,
        city: cityD?.long_name,
        state: State?.long_name,
        zip: ZipCode?.long_name,
        lat: placeDetails.geometry.location.lat(),
        lng: placeDetails.geometry.location.lng(),
      };
      return address;
    }
    return {};
  };

  const onMapClicked = async (mapProps, map, clickEvent) => {
    const clickData = {
      lat: clickEvent.latLng.lat(),
      lng: clickEvent.latLng.lng(),
    };
    // setLngLat(clickData)
    const d = await getReverseGeocodingData(clickData.lat, clickData.lng);
    return onClickAction(d);
  };

  return (
    <div className="h-40">
      <Map
        containerStyle={containerStyle}
        google={window.google}
        zoom={zoomlevel}
        style={mapStyles}
        initialCenter={Laglng}
        center={location}
        onClick={onMapClicked}
      >
        <Marker
          title={location.address}
          name={location.address}
          position={location}
        />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLEAPI,
})(GoogleMapView);

// export default GoogleMapView;
