import { useEffect } from "react";
// import { useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
// import {
//   useLocation,
//   useNavigate,
//   useParams,
//   useSearchParams,
// } from "react-router-dom";
// import { selectRoles } from "../../../store/authSlice";
import { DashboardLayout } from "../../../components/organisms/Layout";
import { useState } from "react";
// import ControlPanel from "../../../api/ControlPanel";
import BusinessPanel from "./Business/BusinessPanel";
import PaymentDetailsPanel from "./Payments/PaymentDetailsPanel";
// import SuccessPayments from "./Payments/PaymentLogs";
import Subscriptions from "./Payments/Subscriptions";
import Turnkey from "./Turnkey/Turnkey";
import { BulkTurnkeyImport } from "./BulkTurnkeyImport";
// import LocationPanel from "./Analytics/AnalyticsPanel";
import AnalyticsPanel from "./Analytics/AnalyticsPanel";
import { BriefcaseIcon, ChartBarSquareIcon } from "@heroicons/react/24/outline";
import { FaMoneyCheck } from "react-icons/fa";
import { RiRobotLine } from "react-icons/ri";
import { AiOutlineMonitor } from "react-icons/ai";
import RBACPanel from "./RBAC/RBACPanel";
import Groups from "./Groups/Groups";
import AlertsPanel from "./Alerts/AlertsPanel";
import { MessageBroadcast } from "./MessageBroadcast";
import Notifications from "./Notifications/Notifications";
import JobSeekerDashboard from "./JobSeekerDashboard/JobSeekerDashboard";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [
  {
    name: "Business",
    logo: (
      <BriefcaseIcon
        className="h-5 w-5 flex-shrink-0 self-center text-primary-500"
        aria-hidden="true"
      />
    ),
    href: "#",
    current: true,
  },
  {
    name: "Analytics",
    logo: (
      <ChartBarSquareIcon
        className="h-5 w-5 flex-shrink-0 self-center text-primary-500"
        aria-hidden="true"
      />
    ),
    href: "#",
    current: false,
  },
  {
    name: "Payment",
    logo: (
      <FaMoneyCheck
        className="h-5 w-5 flex-shrink-0 self-center text-primary-500"
        aria-hidden="true"
      />
    ),
    href: "#",
    current: false,
  },
  {
    name: "Turnkey",
    logo: (
      <AiOutlineMonitor
        className="h-5 w-5 flex-shrink-0 self-center text-primary-500"
        aria-hidden="true"
      />
    ),
    href: "#",
    current: false,
  },
  {
    name: "Turnkey Bulk Import",
    logo: (
      <RiRobotLine
        className="h-5 w-5 flex-shrink-0 self-center text-primary-500"
        aria-hidden="true"
      />
    ),
    href: "#",
    current: false,
  },
  {
    name: "RBAC",
    href: "#",
    current: false,
  },
  {
    name: "Job Seeker Analytics",
    href: "#",
    current: false,
  },
  // {
  //   name: "Registrations Analytics",
  //   href: "#",
  //   current: false,
  // },
  {
    name: "Alerts",
    href: "#",
    current: false,
  },
  {
    name: "Notifications",
    href: "#",
    current: false,
  },
  {
    name: "Hospitality Groups",
    href: "#",
    current: false,
  },

  { name: "Message Broadcast", href: "#", current: false },
  // { name: "Payouts", href: "#", current: false },
  // { name: "Refunds & Blocks", href: "#", current: false },
  // { name: "Subscriptions", href: "#", current: false },
];

const AdminControlPanel = ({ defaultTab = "Business" }) => {
  // const [businessList, setBusinessList] = useState([]);
  // const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] =
  //   useState(true);
  // const [autoUpdateApplicantDataEnabled, setAutoUpdateApplicantDataEnabled] =
  //   useState(false);
  // const { t } = useTranslation();
  // const roles = useSelector(selectRoles);
  // const [businessNameShowPanel, setBusinessNameShowPanel] = useState(false);
  const [openTab, setOpenTab] = useState("Business");
  const [tabSelected, setTabSelected] = useState("");

  useEffect(() => {
    document.title = "HeyHire Admin-Control-Panel";
    handleTabClick(defaultTab);
  }, []);

  useEffect(() => {
    setTabSelected(openTab);
  }, [openTab]);

  const handleTabClick = (key) => {
    tabs.map((tab) => {
      if (tab.name === key) {
        tab.current = true;
      } else {
        tab.current = false;
      }
    });
    setOpenTab(key);
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="w-full">
        <div className="mx-auto flex flex-col">
          <main className="flex-1">
            <div className="relative mx-auto">
              <div>
                <div className="px-4 lg:px-0">
                  <h1 className="text-2xl font-bold tracking-tight text-[#2C3E50] ">
                    Control Panel -{" "}
                    <span className="text-[15px]">{tabSelected}</span>
                  </h1>
                </div>

                <div className="px-4 lg:px-0">
                  <div className="py-6">
                    {/* Tabs */}
                    <div className="lg:hidden">
                      <label htmlFor="selected-tab" className="sr-only">
                        Select a tab
                      </label>
                      <select
                        id="selected-tab"
                        name="selected-tab"
                        className="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-purple-500 sm:text-sm sm:leading-6"
                        defaultValue={tabs.find((tab) => tab?.current)?.name}
                        onChange={(e) => handleTabClick(e?.target?.value)}
                      >
                        {tabs.map((tab) => (
                          <option key={tab?.name}>{tab?.name} </option>
                        ))}
                      </select>
                    </div>

                    <div className="hidden lg:block">
                      <div className="border-b border-warmgray-300">
                        <nav className="-mb-px flex space-x-8 overflow-auto">
                          {tabs?.map((tab) => (
                            <a
                              key={tab?.name}
                              href={tab?.href}
                              onClick={() => handleTabClick(tab.name)}
                              className={classNames(
                                tab.current
                                  ? " border-b-4 border-primary-500 text-primary-500 hover:text-primary-500"
                                  : "border-b-2 border-transparent text-gray-500 hover:border-warmgray-300 hover:text-gray-600",
                                "whitespace-nowrap border-b-2 py-4 px-1 text-md font-medium"
                              )}
                            >
                              <div className="grid grid-flow-col space-x-3">
                                <div>{tab?.name}</div>
                                <div>{tab?.logo}</div>
                              </div>
                            </a>
                          ))}
                        </nav>
                      </div>
                    </div>

                    {openTab === "Business" && (
                      <div
                        key="Business"
                        className="transition-opacity duration-150 ease-linear"
                      >
                        <BusinessPanel callback={handleTabClick} />
                      </div>
                    )}

                    {openTab === "Analytics" && (
                      <div
                        key="Analytics"
                        className="transition-opacity duration-150 ease-linear"
                      >
                        <AnalyticsPanel callback={handleTabClick} />
                      </div>
                    )}

                    {openTab === "Payment" && (
                      <div
                        key="Payment"
                        className="transition-opacity duration-150 ease-linear"
                      >
                        <PaymentDetailsPanel callback={handleTabClick} />
                      </div>
                    )}

                    {openTab === "Turnkey" && (
                      <div
                        key="Business"
                        className="transition-opacity duration-150 ease-linear"
                      >
                        <Turnkey callback={handleTabClick} />
                      </div>
                    )}

                    {openTab === "Turnkey Bulk Import" && (
                      <div
                        key="Bulk Turnkey Import"
                        className="transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                      >
                        <BulkTurnkeyImport callback={handleTabClick} />
                      </div>
                    )}

                    {openTab === "RBAC" && (
                      <div
                        key="RBAC"
                        className="transition-opacity duration-150 ease-linear"
                      >
                        <RBACPanel callback={handleTabClick} />
                      </div>
                    )}

                    {openTab === "Job Seeker Analytics" && (
                      <div
                        key="Job Seeker Analytics"
                        className="transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                      >
                        <JobSeekerDashboard callback={handleTabClick} />
                      </div>
                    )}

                    {openTab === "Message Broadcast" && (
                      <div
                        key="Payment Logs"
                        className="transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                      >
                        <MessageBroadcast />
                      </div>
                    )}

                    {openTab === "Subscriptions" && (
                      <div
                        key="Subscriptions"
                        className="transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                      >
                        <Subscriptions />
                      </div>
                    )}
                    {openTab === "Hospitality Groups" && (
                      <div
                        key="Hospitality Groups"
                        className="transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                      >
                        <Groups />
                      </div>
                    )}
                    {openTab === "Alerts" && (
                      <div
                        key="RBAC"
                        className="transition-opacity duration-150 ease-linear"
                      >
                        <AlertsPanel callback={handleTabClick} />
                      </div>
                    )}
                    {openTab === "Notifications" && (
                      <div
                        key="Notifications"
                        className="transition-opacity duration-150 ease-linear"
                      >
                        <Notifications callback={handleTabClick} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AdminControlPanel;
