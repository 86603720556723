import React, { useEffect, useState } from "react";
import MultiSelect from "../../../../components/MultiSelect";
import EventModal from "../../../../components/modals/EventModal";
import Company from "../../../../api/Company";
import Notification from "../../../../api/Notification";
import { toast } from "react-hot-toast";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";

const Notifications = ({ callback = false }) => {
  const [companies, setCompanies] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [existingEvents, setExistingEvents] = useState([]);
  const [isLoadingEvents, setLoadingEvents] = useState(false);
  const [isLoadingCompanies, setLoadingCompanies] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectEvent, setSelectEvent] = useState(null);
  const [editEvent, setEditEvent] = useState(null);
  const [refreshEvents, setRefreshEvent] = useState(false);
  const [selectNotifyType, setSelectNotifyType] = useState(null);
  const [selectEditEvent, setSelectEditEvent] = useState(null);
  const [isLoadingAttachedEvents, setIsLoadingAttachedEvents] = useState(false);
  const [attachedEvents, setAttachedEvents] = useState([]);
  const [eventData, setEventData] = useState([]);
  const notificationTypes = [
    { value: 1, label: "SMS" },
    { value: 2, label: "Email" },
    { value: 3, label: "In-App" },
  ];
  const [locations, setLocations] = useState([]);
  const [isDisableLocations, setDisableLocations] = useState(true);
  const [isDisableEvents, setDisableEvents] = useState(true);
  const [isDisableNotify, setDisableNotify] = useState(true);
  const [isLoadingSubmit, setLoadingSubmit] = useState(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);
  const [attachedEventId, setAttachedEventId] = useState(false);
  const [selectedDeleteEvent, setSelectedDeleteEvent] = useState(null);
  const [isRefreshAttachedEvents, setRefreshAttachedEvents] = useState(false);

  const deleteAttachedEvent = async (item) => {
    setSelectedDeleteEvent(item);
    setAttachedEventId(item?.event_id);
    setOpenDeleteConfirmationModal(true);
  };

  const handleRemoveAttachedEvent = async (id) => {
    if (selectedCompany && selectedLocation && selectedDeleteEvent) {
      try {
        setIsLoadingAttachedEvents(true);
        await Notification.deleteGlobalNotification(selectedDeleteEvent?.id)
          .then((response) => {
            if (response?.data?.message === "Notification deleted") {
              setRefreshAttachedEvents(true);
              toast.success(response?.data?.message);
            }
          })
          .catch((err) => {
            console.log(err);
            setRefreshAttachedEvents(true);
          });

        setIsLoadingAttachedEvents(false);
      } catch (err) {
        console.error(err);
      }
    }
    setOpenDeleteConfirmationModal(false);
  };

  const captureCompany = async (value) => {
    setIsLoading(true);
    setSelectedCompany(value);
    setLocations([]);
    setSelectedLocation(null);
    setSelectNotifyType(null);
    setSelectEvent(null);
    setDisableLocations(true);
    setDisableEvents(true);
    setDisableNotify(true);
    setAttachedEvents([]);

    if (value !== null) {
      const selected = companyDetails?.find(
        (company) => company?.id === value?.value
      );
      if (selected) {
        let locationData = [];
        for (let i = 0; i < selected?.locations?.length; i++) {
          const el = selected?.locations[i];
          locationData.push({
            label: el.name,
            value: el.id,
          });
        }
        setLocations(locationData);
      }
      setDisableLocations(false);
    }
    setIsLoading(false);
  };

  const handleChange = (selected) => {
    if (selected === null) {
      setDisableEvents(true);
      setDisableNotify(true);
      setSelectEvent(null);
      setSelectNotifyType(null);
      setAttachedEvents([]);
    } else {
      setDisableEvents(false);
      setRefreshAttachedEvents(true);
      setDisableNotify(false);
    }
    setSelectedLocation(selected);
  };

  const handleEventChange = (selected) => {
    setSelectEvent(selected);
  };

  const handleNotificationChange = (selected) => {
    setSelectNotifyType(selected);
  };

  const handlePostNotify = async () => {
    if (
      selectedCompany &&
      selectedLocation &&
      selectEvent &&
      selectNotifyType
    ) {
      setLoadingSubmit(true);
      try {
        const payload = {
          company_id: selectedCompany?.value,
          location_id: selectedLocation?.value,
          event_id: selectEvent?.value,
          notification_type: selectNotifyType?.label,
        };
        const response = await Notification.addGlobalNotification(payload);
        if (response) {
          toast.success("Notification Updated!");
          setSelectEvent(null);
          setSelectNotifyType(null);
          setRefreshAttachedEvents(true);
        }
      } catch (error) {
        console.error("Error editing event:", error);
      }
      setLoadingSubmit(false);
    }
  };

  const handleEditEventChange = (selected) => {
    if (selected && eventData) {
      const matchedEvent = eventData?.find(
        (event) => event?.id === selected?.value
      );
      if (matchedEvent) {
        setSelectEditEvent(matchedEvent);
      }
      setShowModal(true);
    }
  };

  const loadExistingEvents = async () => {
    setLoadingEvents(true);
    setRefreshEvent(false);
    await Notification.getAllEvents().then((response) => {
      setEventData(response.data.data);
      let fetchdata = response.data.data;
      let data = [];
      for (let i = 0; i < fetchdata?.length; i++) {
        const el = fetchdata[i];
        data.push({
          label: el.name,
          value: el.id,
        });
      }
      setExistingEvents(data);
    });
    setLoadingEvents(false);
  };

  const loadCompanies = async () => {
    setLoadingCompanies(true);
    await Company.getAll().then((response) => {
      let fetchdata = response.data.data;
      setCompanyDetails(response.data.data);
      let companyData = [];

      for (let i = 0; i < fetchdata.length; i++) {
        const el = fetchdata[i];
        companyData.push({
          label: el.name,
          value: el.id,
        });
      }
      setCompanies(companyData);
    });
    setLoadingCompanies(false);
  };

  const getAllAttachedEvents = async (id) => {
    setIsLoadingAttachedEvents(true);
    await Notification.getEventByID(id)
      .then((response) => {
        if (response?.data?.message) {
          setAttachedEvents([]);
        } else if (response?.data) {
          setAttachedEvents(response?.data?.notifications);
          const matchedType = notificationTypes?.find(
            (type) => type.label === response?.data?.notification_type
          );
          if (matchedType) {
            setSelectNotifyType(matchedType);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching event by ID:", error);
      });

    setIsLoadingAttachedEvents(false);
  };

  useEffect(() => {
    if (selectedCompany && selectedLocation && isRefreshAttachedEvents) {
      getAllAttachedEvents(selectedLocation?.value);
    }
    setRefreshAttachedEvents(false);
  }, [isRefreshAttachedEvents]);

  useEffect(() => {
    loadCompanies();
  }, []);

  useEffect(() => {
    loadExistingEvents();
  }, [refreshEvents]);

  return (
    <div>
      {showModal && (
        <EventModal
          open={showModal}
          setOpen={setShowModal}
          editEvent={selectEditEvent}
          setEditEvent={setSelectEditEvent}
          setRefreshEvent={setRefreshEvent}
        />
      )}

      <ConfirmationModal
        openTrigger={openDeleteConfirmationModal}
        closeTrigger={() => setOpenDeleteConfirmationModal(false)}
        type="error"
        title="Confirm Action"
        confirmText="Delete"
        selectedId={attachedEventId}
        description="Are you sure you want to delete this event?"
        confirm={handleRemoveAttachedEvent}
      />

      <div className="my-8 flex gap-4 flex-col">
        <p className="text-xl mb-2 font-bold">Global Notifications</p>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-4 w-full xl:w-3/4 2xl:w-1/2">
            <div className="flex flex-col justify-center md:w-[300px] xl:w-[360px]">
              <p className="text-md font-medium">Select Company</p>
              <div className="mt-auto border border-warmgray-300 rounded-md">
                <MultiSelect
                  key="company"
                  options={companies}
                  onChange={captureCompany}
                  value={selectedCompany}
                  menuPlacement={"bottom"}
                  isLoading={isLoadingCompanies}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex flex-col justify-start md:min-w-[300px] xl:min-w-[360px]">
                <p className="text-md font-medium">Select Location</p>
                <div className="border border-warmgray-300 rounded-md">
                  <MultiSelect
                    key="location"
                    options={locations}
                    onChange={handleChange}
                    value={selectedLocation}
                    menuPlacement={"bottom"}
                    isLoading={isLoading}
                    disabledSelect={isDisableLocations}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex flex-col justify-center md:w-[300px] xl:w-[360px] mb-auto">
                <p className="text-md font-medium">Select Event Type</p>
                <div className="mt-auto border border-warmgray-300 rounded-md">
                  <MultiSelect
                    key="event"
                    options={existingEvents}
                    onChange={handleEventChange}
                    value={selectEvent}
                    menuPlacement={"bottom"}
                    disabledSelect={isDisableEvents}
                  />
                </div>
              </div>
              {selectedLocation && (
                <div>
                  {isLoadingAttachedEvents ? (
                    <div className="my-auto">
                      <p>Loading Attached Events...</p>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-1">
                      <p className="font-bold">Attached Events</p>
                      <div className="w-full flex flex-wrap gap-2">
                        {attachedEvents?.map((item) => {
                          return (
                            <div
                              key={item?.id}
                              className={`border-2 border-primary-500 px-2.5 py-1 rounded-xl flex justify-center items-center bg-primary-500`}
                            >
                              <p
                                className={`font-bold text-center text-white text-xs`}
                              >
                                {item?.event_name}
                              </p>
                              <i
                                className="fas fa-times-circle ml-1.5 text-white cursor-pointer"
                                onClick={() => deleteAttachedEvent(item)}
                              />
                            </div>
                          );
                        })}
                        {attachedEvents?.length === 0 && (
                          <p className="text-xs">No Events Attached Yet</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="flex flex-col justify-center md:w-[300px] xl:w-[360px]">
              <p className="text-md font-medium">Select Notification Type</p>
              <div className="mt-auto border border-warmgray-300 rounded-md">
                <MultiSelect
                  key="roles"
                  options={notificationTypes}
                  onChange={handleNotificationChange}
                  value={selectNotifyType}
                  menuPlacement={"bottom"}
                  disabledSelect={isDisableNotify}
                />
              </div>
            </div>

            <div className="mx-auto md:w-[300px] xl:w-[360px]">
              <button
                type="button"
                className="mt-4 mx-auto cursor-pointer font-bold text-sm px-6 py-2 w-fit rounded-md bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                onClick={() => handlePostNotify()}
                disabled={
                  selectedCompany &&
                  selectedLocation &&
                  selectEvent &&
                  selectNotifyType
                    ? false
                    : true
                }
              >
                Attach Notification
                {isLoadingSubmit && (
                  <i className="fad fa-circle-notch fa-spin my-auto ml-2" />
                )}
              </button>
            </div>
          </div>

          <hr className="border-b-1 border-gray-100 w-full xl:w-3/4 2xl:w-1/2 my-4" />

          <div className="flex flex-col md:flex-row gap-4 justify-between w-full xl:w-3/4 2xl:w-1/2">
            <div className="flex flex-col justify-center md:w-[300px] xl:w-[360px]">
              <p className="text-md font-medium">Edit Existing Events</p>
              <div className="mt-auto border border-warmgray-300 rounded-md">
                <MultiSelect
                  key="roles"
                  options={existingEvents}
                  onChange={handleEditEventChange}
                  value={editEvent}
                  menuPlacement={"bottom"}
                  isLoading={isLoadingEvents}
                />
              </div>
            </div>

            <div className="my-auto">
              <button
                type="button"
                className="cursor-pointer font-bold text-sm px-6 py-2 w-fit rounded-md bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                onClick={() => {
                  setShowModal(true);
                  setSelectEditEvent(null);
                }}
              >
                Add New Event
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
