import React, { useState, useEffect } from "react";
import query from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import HiringPlan from "./HiringPlan/HiringPlan";
import GoStep2 from "./GoStep2/GoStep2";

function Register() {
  const location = useLocation();
  const navigate = useNavigate();
  const { step = "0" } = query.parse(location.search);
  const [formData, setFormData] = useState({});
  const selectedPlan = JSON.parse(localStorage.getItem("selectedPlan")) || null;

  useEffect(() => {
    document.title = "HeyHire Portal-Register";
  }, []);

  const onSubmitStep = (values, nextStep) => {
    const updatedFormData = { ...formData, ...values };
    setFormData(updatedFormData);
    return navigate(`/register?step=${nextStep}`);
  };

  const onRegisterForm = (values) => {};

  return (
    <>
      {step === "0" && <HiringPlan onSubmit={onSubmitStep} />}
      {step === "1" && <Step0 onSubmit={onSubmitStep} />}
      {step === "2" && <Step1 onSubmit={onSubmitStep} data={formData} />}
      {step === "3" &&
        (selectedPlan?.planName === "go" ? (
          <GoStep2 onSubmit={onRegisterForm} data={formData} />
        ) : (
          <Step2 onSubmit={onRegisterForm} data={formData} />
        ))}
    </>
  );
}

export default Register;
