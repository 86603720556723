import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import JobOpeningCard from "../../../components/molecules/JobOpeningCard";
import JobPosition from "../../../api/JobPosition";
import { toast } from "react-hot-toast";
import { DashboardLayout } from "../../../components/organisms/Layout";
import { useSelector } from "react-redux";
import {
  selectUser,
  selectSelectedLocation,
  // chatList,
  // isChatListExpand,
  darkmode,
  sidebar,
} from "../../../store/authSlice";
import dayjs from "dayjs";
import { DateFormat } from "../../../utils";
import DashboardApi from "../../../api/Dashboard";
import { addIcon, defaultUserImage } from "../../../assets/images";
import { encodeIds } from "../../../utils";
import "../ViewJob/ViewJob.scss";
// import ShiftJobCard from "../../../components/molecules/ShiftJobCard/ShiftJobCard";
// import EventJobCard from "../../../components/molecules/EventJobCard/EventJobCard";
import Table from "../../../components/atoms/Tables/Table";
import TableLoading from "../../../components/Loading/TableLoading";
import JobOpeningCardLoading from "../../../components/Loading/JobOpeningCardLoading";
import User from "../../../api/User";
import IconSelector from "../../../components/atoms/Selector/IconSelector";

const CurrentJobOpenings = ({ mode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [jobPositions, setJobPositions] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedLocation = useSelector(selectSelectedLocation);
  const open = useSelector(sidebar);

  const loadJobPositions = async () => {
    try {
      const response = await JobPosition.getAll();
      const sortedJobs = response.data.data.filter(
        (j) => j?.archived_at == null && j?.deleted_at == null
      );
      setJobPositions(sortedJobs);
      setLoading(true);
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast.error(t("common.something-went-wrong-please-try-again"));
      }
      setLoading(true);
    }
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Dashboard";
    const timer = setTimeout(() => {
      setLoading(false);
      loadJobPositions();
    }, 500);
    return () => clearTimeout(timer);
  }, [selectedLocation]);

  return (
    <div className="pb-5 mx-auto">
      {!loading ? (
        <>
          <div className="flex flex-col my-4">
            <div className="animate-pulse h-3 bg-gray-300 dark:bg-[#363739] rounded w-1/3"></div>
            <div className="animate-pulse h-3 bg-gray-300 dark:bg-[#363739] rounded w-1/4 mt-3"></div>
          </div>
          <div className="my-4 bg-[#F6F6F6] dark:bg-[#242526] py-3 px-3 rounded-2xl flex flex-col gap-3">
            <ul
              className={`grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 ${
                !open && "2xl:grid-cols-4"
              }`}
            >
              {Array.from({ length: 6 }).map((_, index) => (
                <JobOpeningCardLoading />
              ))}
            </ul>
          </div>
        </>
      ) : (
        <>
          {jobPositions?.length > 0 ? (
            <div>
              <div className="flex flex-col lg:flex-row md:flex-row sm:flex-row">
                <div className="flex flex-col">
                  <h1 className="text-lg leading-6 font-bold text-gray-900 dark:text-white">
                    Current Job Openings{" "}
                  </h1>
                  <span className="text-sm font-bold mt-1 text-primary-500 dark:text-gray-500">
                    ({jobPositions.length}) Open Positions
                  </span>
                </div>

                <div className="mt-4 lg:mt-0 flex mb-2 ml-auto items-center p-2">
                  {/* {isDraftsView &&   */}
                  {/* <button
                        onClick={() => navigate("/job-positions/draft")}
                        className={
                          "text-sm mr-2 font-bold px-6 mx-auto rounded-xl bg-orange-900 text-white hover:bg-orange-400 transition-all hover:opacity-50 h-9"
                        }
                      >
                        Saved Drafts
                      </button> */}
                  {/* }   */}
                  <button
                    onClick={() => navigate("/job-positions/add")}
                    className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                  >
                    <div className="flex">
                      <img
                        src={addIcon}
                        alt="logo"
                        className="mr-2 my-auto w-4 h-4"
                      />
                      Add Position
                    </div>
                  </button>
                  <IconSelector
                    classNames="border border-warmgray-200 dark:border-darkGray ml-2"
                    color={mode ? "black" : "white"}
                    orientation="vertical"
                    menus={[
                      {
                        label: "Archived Positions",
                        action: () => {
                          navigate("/archived-jobs");
                        },
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="my-4 bg-[#F6F6F6] dark:bg-[#242526] py-3 px-3 rounded-2xl flex flex-col gap-3">
                <ul
                  className={`grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 ${
                    !open && "2xl:grid-cols-4"
                  }`}
                >
                  {jobPositions?.map((jb, i) => (
                    <JobOpeningCard
                      key={i}
                      jobPosition={jb}
                      loadJobs={loadJobPositions}
                      darmo={mode}
                    />
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <div
              className={`flex flex-1 flex-col justify-center items-center mt-20 ${
                mode ? "text-black" : "text-white"
              }`}
            >
              <h5 className={`leading-6 ${mode ? "text-black" : "text-white"}`}>
                No Job Posted Yet!
              </h5>

              <span className="my-2 text-base font-medium">
                Add a new position to get started.
              </span>
              <div className="flex mt-2">
                {/* {isDraftsView &&   */}
                {/* <button
                onClick={() => navigate("/job-positions/draft")}
                className={
                  "text-sm mr-2 font-bold px-6 mx-auto rounded-xl bg-orange-900 text-white hover:bg-orange-400 transition-all hover:opacity-50 h-9"
                }
              >
                Saved Drafts
              </button> */}
                {/* } */}
                <button
                  onClick={() => navigate("/job-positions/add")}
                  className="flex justify-center items-center rounded-full bg-primary-500 dark:bg-darkGray px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                >
                  <div className="flex">
                    <img
                      src={addIcon}
                      alt="logo"
                      className="mr-2 my-auto w-4 h-4"
                    />
                    Add Position
                  </div>
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

// const CurrentShiftsOpenings = ({ mode }) => {
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   const [jobPositions, setJobPositions] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const selectedLocation = useSelector(selectSelectedLocation);
//   const openChatList = useSelector(chatList);
//   const open = useSelector(sidebar);

//   const loadJobPositions = async () => {
//     try {
//       setLoading(false);
//       const response = await JobPosition.getAll();
//       const sortedJobs = response.data.data.filter(
//         (j) => j?.archived_at == null && j?.deleted_at == null
//       );
//       setJobPositions(sortedJobs);
//       setLoading(true);
//     } catch (error) {
//       if (error?.response?.status !== 403) {
//         toast.error(t("common.something-went-wrong-please-try-again"));
//       }
//       setLoading(true);
//     }
//   };

//   useEffect(() => {
//     document.title = "HeyHire Portal-Dashboard";
//     const timer = setTimeout(() => {
//       loadJobPositions();
//     }, 1000);
//     return () => clearTimeout(timer);
//   }, [selectedLocation]);

//   return (
//     <div className="lg:mt-4 pb-4 mx-auto">
//       {!loading ? (
//         <div>
//           <h1
//             className={`text-lg font-bold ${
//               mode ? "text-gray-900" : "text-white"
//             }`}
//           >
//             Current Shift Openings{" "}
//           </h1>
//           <div className="flex justify-center items-center min-h-[400px]">
//             <div className="animate-spin rounded-full h-12 w-12 border-4 border-orange-500 border-t-transparent"></div>
//           </div>
//         </div>
//       ) : jobPositions?.length > 0 ? (
//         <div className="m-auto">
//           <div
//             className={`flex flex-col lg:flex-row md:flex-row sm:flex-row ${
//               openChatList ? "" : "xl:w-full"
//             }`}
//           >
//             <div className="flex flex-col">
//               <h1
//                 className={`text-lg font-bold ${
//                   mode ? "text-gray-900" : "text-white"
//                 }`}
//               >
//                 Current Shift Openings{" "}
//               </h1>
//               <span
//                 className={`text-sm font-bold mt-1 ${
//                   mode ? "text-orange-500" : "text-gray-500"
//                 } leading-none`}
//               >
//                 ({jobPositions?.length}) Open Shifts
//               </span>
//             </div>

//             <div className="mt-4 lg:mt-0 flex mb-2 ml-auto">
//               <button
//                 onClick={() => navigate("/shifts")}
//                 className="cursor-pointer flex justify-center items-center rounded-full bg-orange-400 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-orange-400 transition-all hover:opacity-50"
//               >
//                 <div className="flex">
//                   <img
//                     src={addIcon}
//                     alt="logo"
//                     className="mr-2 my-auto w-4 h-4"
//                   />
//                   Add Shift
//                 </div>
//               </button>
//             </div>
//           </div>

//           <div
//             className={`mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 ${
//               !open && "2xl:grid-cols-4"
//             }`}
//           >
//             {jobPositions?.map((jb, i) => (
//               <ShiftJobCard
//                 key={i}
//                 jobPosition={jb}
//                 loadJobs={loadJobPositions}
//                 darmo={mode}
//               />
//             ))}
//           </div>
//         </div>
//       ) : (
//         <div
//           className={`flex flex-1 flex-col justify-center items-center mt-20 ${
//             mode ? "text-black" : "text-white"
//           }`}
//         >
//           <h5 className={`leading-6 ${mode ? "text-black" : "text-white"}`}>
//             No Shift Posted Yet!
//           </h5>
//           <span className="my-2 text-base font-medium">
//             Add a new shift to get started.
//           </span>
//           <button
//             onClick={() => navigate("/job-positions/add")}
//             className="cursor-pointer flex justify-center items-center rounded-full bg-orange-900 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-orange-400 transition-all hover:opacity-50"
//           >
//             <div className="flex">
//               <img src={addIcon} alt="logo" className="mr-2 my-auto w-4 h-4" />
//               Add Shift
//             </div>
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// const CurrentEventsOpenings = ({ mode }) => {
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   const [jobPositions, setJobPositions] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const selectedLocation = useSelector(selectSelectedLocation);
//   const openChatList = useSelector(chatList);
//   const isChatListOpen = useSelector(isChatListExpand);

//   const loadJobPositions = async () => {
//     try {
//       setLoading(false);
//       const response = await JobPosition.getAll();
//       const sortedJobs = response.data.data.filter(
//         (j) => j?.archived_at == null && j?.deleted_at == null
//       );
//       setJobPositions(sortedJobs);
//       setLoading(true);
//     } catch (error) {
//       if (error?.response?.status !== 403) {
//         toast.error(t("common.something-went-wrong-please-try-again"));
//       }
//       setLoading(true);
//     }
//   };

//   useEffect(() => {
//     document.title = "HeyHire Portal-Dashboard";
//     const timer = setTimeout(() => {
//       loadJobPositions();
//     }, 1000);
//     return () => clearTimeout(timer);
//   }, [selectedLocation]);

//   return (
//     <div className="lg:mt-4 pb-4 mx-auto">
//       {!loading ? (
//         <div>
//           <h1
//             className={`text-lg font-bold ${
//               mode ? "text-gray-900" : "text-white"
//             }`}
//           >
//             Current Event (Shifts) Openings{" "}
//           </h1>
//           <div className="flex justify-center items-center min-h-[400px]">
//             <div className="animate-spin rounded-full h-12 w-12 border-4 border-orange-500 border-t-transparent"></div>
//           </div>
//         </div>
//       ) : jobPositions.length > 0 ? (
//         <div className="m-auto">
//           <div
//             className={`flex flex-col lg:flex-row md:flex-row sm:flex-row ${
//               openChatList ? "" : "xl:w-full"
//             } ${isChatListOpen ? "xl:w-2/3" : "xl:w-5/6"}`}
//           >
//             <div className="flex flex-col">
//               <h1
//                 className={`text-lg font-bold ${
//                   mode ? "text-[#2C3E50]" : "text-white"
//                 }`}
//               >
//                 Current Event (Shifts) Openings{" "}
//               </h1>
//               <span
//                 className={`text-sm font-bold mt-1 ${
//                   mode ? "text-orange-500" : "text-gray-500"
//                 } leading-none`}
//               >
//                 ({jobPositions.length}) Open Events
//               </span>
//             </div>
//           </div>

//           {/* <div className={`my-4 ${openChatList ? "" : "xl:w-full"} ${isChatListOpen ? "xl:w-2/3" : "xl:w-5/6"}`}> */}
//           <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-full gap-4 mt-4">
//             {/* {loading ? (
//               <Loading size={"5xl"} count={6} />
//             ) : ( */}
//             {jobPositions?.map((jb, i) => (
//               <EventJobCard
//                 className="cursor-pointer"
//                 key={i}
//                 jobPosition={jb}
//                 loadJobs={loadJobPositions}
//                 darmo={mode}
//               />
//             ))}
//             {/* )} */}
//           </div>
//         </div>
//       ) : (
//         <div
//           className={`flex flex-1 flex-col justify-center items-center mt-20 ${
//             mode ? "text-black" : "text-white"
//           }`}
//         >
//           <h5 className={`leading-6 ${mode ? "text-black" : "text-white"}`}>
//             No Event Posted Yet!
//           </h5>
//           <span className="my-2 text-base font-medium">
//             Add a new event to get started.
//           </span>
//         </div>
//       )}
//     </div>
//   );
// };

const BusinessDashboard = () => {
  const { t } = useTranslation();
  const [recentApplications, setRecentApplications] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const selectedLocation = useSelector(selectSelectedLocation);
  const user = useSelector(selectUser);
  const mode = useSelector(darkmode);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingData(false);
      loadSubUsers();
      loadRecentApplications();
    }, 500);
    return () => clearTimeout(timer);
  }, [selectedLocation]);

  const filterSubUsers = (allUsers) => {};

  const filterApplications = (allRecentApplications) => {
    const _selectedLocation = selectedLocation
      ? selectedLocation
      : user?.location;
    const _tempApplications = allRecentApplications.filter(
      (item) => item?.location_id === _selectedLocation?.id
    );
    setRecentApplications(_tempApplications);
  };

  const loadRecentApplications = async () => {
    try {
      const response = await DashboardApi.getRecentApplications(
        selectedLocation?.name !== "Select A Location" && selectedLocation?.id
      );
      filterApplications(response.data.data);
      setLoadingData(true);
    } catch (error) {
      console.error("Error loading recent applications:", error);
      setLoadingData(true);
    }
  };

  const loadSubUsers = async () => {
    const response = await User.getAll();
    const subU = response.data.data.filter(
      (e) => e?.roles[0]?.name !== "owner" && e?.roles[0]?.name !== "super-user"
    );
    filterSubUsers(subU);
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="mx-auto">
        <div className="text-xl xl:text-2xl font-bold text-gray-900 dark:text-white">
          {selectedLocation?.name && user?.company?.is_enterprise !== 0
            ? "Location Dashboard"
            : "Your Dashboard"}
        </div>
        <div className="mt-4 grid grid-cols-1 gap-4">
          <div className="lg:overflow-hidden h-full">
            {!loadingData ? (
              <TableLoading />
            ) : (
              <Table
                title={t("dashboard.latest-application-submissions")}
                headers={
                  recentApplications?.length > 0
                    ? [
                        t("common.full-name"),
                        "Applied For",
                        t("dashboard.applied-on"),
                      ]
                    : ["No Applicants Yet"]
                }
                data={recentApplications?.slice(0, 5).map((item, index) => (
                  <tr key={index}>
                    <td
                      className="whitespace-nowrap py-3 pl-1 pr-3 text-sm md:text-base w-1/3 cursor-pointer"
                      onClick={() =>
                        navigate(
                          `/business/applicant/${encodeIds(
                            item.job_id
                          )}/${encodeIds(item.job_seeker_id)}`,
                          {
                            state: {
                              isSearchingSpecificApplication: true,
                            },
                          }
                        )
                      }
                    >
                      <div className="flex items-center">
                        <div className="h-9 w-9 flex-shrink-0 transform transition duration-200 ease-in-out hover:scale-110">
                          <img
                            alt=""
                            src={
                              item?.seeker?.photo?.thumb_url || defaultUserImage
                            }
                            className="h-9 w-9 rounded-full"
                          />
                        </div>
                        <div className="ml-2 md:ml-4">
                          <div className="font-medium text-gray-900 dark:text-white hover:underline">
                            {item?.seeker?.first_name} {item?.seeker?.last_name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base w-1/3">
                      <div className="text-gray-900 dark:text-white">
                        {item?.position?.title}
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base w-1/3">
                      <div className="text-gray-900 dark:text-white">
                        {dayjs(item?.created_at).format(DateFormat)}
                      </div>
                    </td>
                  </tr>
                ))}
              />
            )}
          </div>
        </div>
      </div>

      <hr className="my-10 border border-warmgray-200 dark:border-gray-400/50" />

      <CurrentJobOpenings mode={mode} />
      {/* <CurrentShiftsOpenings mode={mode} /> */}
      {/* <CurrentEventsOpenings mode={mode} /> */}
    </DashboardLayout>
  );
};

export default BusinessDashboard;
