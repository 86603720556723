import React from "react";
import { useNavigate } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/24/outline";
import { encodeIds } from "../../../utils";
import { useSelector } from "react-redux";
import { selectSelectedLocation } from "../../../store/authSlice";

export const PositionHeader = ({ selectedPosition }) => {
  const navigate = useNavigate();
  const domainFLag = process.env.REACT_APP_API_BASE_URL.search("devapi");
  const selectedLocation = useSelector(selectSelectedLocation);
  const availabilityList = [
    { value: "full-time", label: "Full Time" },
    { value: "part-time", label: "Part Time" },
  ];

  const handleOpenLink = () => {
    const positionUrl = `https://${
      domainFLag !== -1 ? "exp-amplify.heyhire" : "heyhire"
    }.app/${selectedLocation?.brand?.url_slug}/${selectedLocation?.url_slug}/${
      selectedPosition?.label
    }`;
    window.open(positionUrl, "_blank", "noopener,noreferrer");
  };

  const parseAvailability = () => {
    try {
      const parsed =
        typeof selectedPosition?.value?.availability === "string"
          ? JSON.parse(selectedPosition?.value?.availability)
          : selectedPosition?.value?.availability;

      return Array.isArray(parsed)
        ? parsed
            .map((value) =>
              availabilityList.find((item) => item.value === value)
            )
            .filter(Boolean)
        : [availabilityList.find((item) => item.value === parsed)].filter(
            Boolean
          );
    } catch {
      return [
        availabilityList.find(
          (item) => item.value === selectedPosition?.value?.availability
        ),
      ].filter(Boolean);
    }
  };

  return (
    <div className="bg-white dark:bg-[#242526] rounded-xl shadow-sm mb-2 border border-primary-200 dark:border-gray-400 p-4">
      <div className="flex justify-between gap-4">
        {/* Position Information */}
        <div className="space-y-2">
          <h2 className="text-lg font-bold text-primary-500 dark:text-gray-300 line-clamp-2">
            {selectedPosition?.label || "Select a Position"}
          </h2>

          <div className="flex flex-col gap-1">
            {selectedPosition?.value?.availability && (
              <div className="inline-flex">
                <span className="text-xs font-bold text-primary-500 dark:text-gray-300 rounded-full">
                  {parseAvailability()
                    ?.map((item) => item.label)
                    .join(" / ")}
                </span>
              </div>
            )}

            <span className="text-xs font-bold text-primary-500 dark:text-gray-300 rounded-full">
              {selectedPosition?.value?.hiring_status === 0
                ? "Accepting Applications"
                : "Immediately Hiring"}
            </span>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex gap-1.5">
          <button
            onClick={handleOpenLink}
            className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-primary-100 hover:bg-primary-200 dark:bg-gray-700 dark:hover:bg-gray-400 transition-colors"
            aria-label="Open position link in new tab"
            title="View Business Profile"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="h-4 w-4"
            >
              <path d="M304 24c0 13.3 10.7 24 24 24l102.1 0L207 271c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l223-223L464 184c0 13.3 10.7 24 24 24s24-10.7 24-24l0-160c0-13.3-10.7-24-24-24L328 0c-13.3 0-24 10.7-24 24zM72 32C32.2 32 0 64.2 0 104L0 440c0 39.8 32.2 72 72 72l336 0c39.8 0 72-32.2 72-72l0-128c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 128c0 13.3-10.7 24-24 24L72 464c-13.3 0-24-10.7-24-24l0-336c0-13.3 10.7-24 24-24l128 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L72 32z" />
            </svg>
          </button>

          <button
            onClick={() =>
              navigate(
                "/job-positions/edit/" + encodeIds(selectedPosition?.value?.id)
              )
            }
            title="Edit Position"
            className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-primary-100 hover:bg-primary-200 dark:bg-gray-700 dark:hover:bg-gray-400 transition-colors"
          >
            <PencilIcon className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PositionHeader;
