import React from "react";
import dayjs from "dayjs";
import { DateFormat } from "../../../utils";
import { DropActionDropdown } from "../../TableActionDropdown";
import "./DraftPositionsCard.scss";
import { useNavigate } from "react-router-dom";
import DraftApi from "../../../api/Draft";
import { toast } from "react-hot-toast";
import { encodeIds } from "../../../utils";

const DraftPositionsCard = ({
  className,
  draftPositions,
  loadDrafts,
  isPositionUpdated,
  isPositionDeleted,
  isChanged,
}) => {
  const navigate = useNavigate();
  const draft = JSON.parse(draftPositions.payload);

  const DeletePosition = async () => {
    try {
      await DraftApi.deleteDraft(draftPositions.id);
      loadDrafts();
      toast.success("Job Deleted Successfully");
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast.error("Failed to archive job. Please try again.");
      }
    }
  };

  const EditPosition = async () => {
    navigate("/job-positions/draft/edit/" + encodeIds(draftPositions.id));
  };

  return (
    <div className={className}>
      <div
        className={
          "px-5 py-4 rounded border border-gray-100 flex justify-between items-center"
        }
      >
        <img
          width={32}
          height={32}
          src={
            draftPositions?.location?.brand?.photo?.thumb_url ||
            "/logo/violet.svg"
          }
          alt={"apploy Me"}
        />
        <div className="flex flex-1 flex-col items-start md:items-center md:flex-row ml-3 md:ml-6">
          <span
            className={
              "font-semibold text-md text-primary-600 mr-auto self-center ant-collapse-row-text"
            }
          >
            Draft: {draft?.title} - {draft?.experience} - {draft?.description} -{" "}
            {draft?.availability}
          </span>
        </div>
        <div className="">
          <span
            className={
              "font-semibold text-md text-primary-600 mr-auto self-center"
            }
          >
            <i
              className={
                "hover:text-indigo-900 " +
                "text-grey-600" +
                " " +
                "far fa-calendar mr-2"
              }
            />
            Saved: {dayjs(draftPositions?.created_at).format(DateFormat)}
          </span>
        </div>

        {/* {(isPositionDeleted || isPositionUpdated) &&  */}
        <DropActionDropdown
          className="ml-5"
          icon="fas fa-cog"
          iconColor="text-primary-500"
          menus={[
            { label: "Edit", action: EditPosition },
            { label: "Delete", action: DeletePosition },
          ]}
          // isDelete={isPositionDeleted}
          // isUpdate={isPositionUpdated}
          // isChanged={isChanged}
        />
        {/* }  */}
      </div>
    </div>
  );
};

export default DraftPositionsCard;
